import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "../Components/Common/withRouter";
import axios from "axios";

//import Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import RightSidebar from "../Components/Common/RightSidebar";
import { useIdleTimer } from "react-idle-timer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarImageType,
  changeSidebarVisibility,
} from "../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const Layout = (props) => {
  const { className } = props;

  const [isopen, setOpen] = useState(false);
  const [openedByIdle, setOpenedByIdle] = useState(false);
  const [seconds, setSeconds] = useState(60); 
  const [activeTime, setActiveTime] = useState(720); 
  const [authenticated, setAuthenticated] = useState(!!localStorage.getItem('token'))
  const TENMINUTES = 5 *60* 1000
  const toggle = () => setOpen(!isopen);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (activeTime > 0) {
  //       setActiveTime((prevSeconds) => prevSeconds - 1);
  //      
  //     }
  //   }, 1000);
    useEffect(() => {
      const userId = localStorage.getItem('userId')
  
      if (authenticated && userId !== '') {
        const interval = setInterval(() => {
          refreshSession()
        }, TENMINUTES) 
        return () => { clearInterval(interval) }
      }
    }, [authenticated])

    // return () => clearInterval(intervalId);
  // }, [activeTime]);

  const handleModalClick = (e) => {
    if (isopen && e.target.closest(".modal-content")) {
      e.stopPropagation();
    }
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 15 * 60,
    onIdle: () => {
      setOpenedByIdle(true);
      setOpen(true);
    },
    onActive: () => {
      if (!openedByIdle) {
        setOpen(false);
      }
    }
  });

  const INITIAL_SECONDS_ACTIVE_TIME = 60;

  // useEffect(() => {
  //   let intervalId;
  //   if (isopen) {
  //     setSeconds(INITIAL_SECONDS_ACTIVE_TIME);
  //     intervalId = setInterval(() => {
  //       if (seconds > 0) {
  //         setSeconds((prevSeconds) => prevSeconds - 1);
  //       }
  //     }, 1000);
  //   }

  //   return () => clearInterval(intervalId);
  // }, [isopen]);

  // useEffect(() => {
  //   if (activeTime === 0) {
  //     refreshSession();
  //   }
  // }, [activeTime]);

  const INITIAL_ACTIVE_TIME = 720;

  const resetActiveTime = () => {
    setActiveTime(INITIAL_ACTIVE_TIME);
  };
  const resetSecondsTime = () => {
    setSeconds(INITIAL_SECONDS_ACTIVE_TIME);
  };

  const refreshSession = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const userId = localStorage.getItem("userId");
      const oldAccessToken = localStorage.getItem("token");
      const body = JSON.stringify({ refreshToken });
      resetActiveTime();
      resetSecondsTime();
      const response = await axios
        .post(
          `${window.env.REACT_APP_BASE_URL}/refreshToken`,
          body,
          {
            headers: {
              Authorization: `Bearer ${oldAccessToken}`,
            },
          }
        )
        .then((response) => {

          if (!response.error) {
            const newAccessToken = response.data.accessToken;
            localStorage.setItem("token", newAccessToken);

            const newRefreshToken = response.data.refreshToken;
            localStorage.setItem("refreshToken", newRefreshToken);

            setOpen(false);
          }
          if (response.error) {
            throw response.error.message;
          }
        })
        .catch((errorResp) => {});
    } catch (error) {
      return error;
    }
  };

  const handleExtendSession = async () => {
    refreshSession();
    resetActiveTime();
    setOpen(false);
    resetSecondsTime();
  };

  const dispatch = useDispatch();
  const selectLayoutState = (state) => state.Layout;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      layoutType: layout.layoutType,
      leftSidebarType: layout.leftSidebarType,
      layoutModeType: layout.layoutModeType,
      layoutWidthType: layout.layoutWidthType,
      layoutPositionType: layout.layoutPositionType,
      topbarThemeType: layout.topbarThemeType,
      leftsidbarSizeType: layout.leftsidbarSizeType,
      leftSidebarViewType: layout.leftSidebarViewType,
      leftSidebarImageType: layout.leftSidebarImageType,
      preloader: layout.preloader,
      sidebarVisibilitytype: layout.sidebarVisibilitytype,
    })
  );
  // Inside your component
  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    preloader,
    sidebarVisibilitytype,
  } = useSelector(selectLayoutProperties);

  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      leftSidebarImageType ||
      sidebarVisibilitytype
    ) {
      window.dispatchEvent(new Event("resize"));
      dispatch(changeLeftsidebarViewType(leftSidebarViewType));
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
      dispatch(changeSidebarTheme(leftSidebarType));
      dispatch(changeLayoutMode(layoutModeType));
      dispatch(changeLayoutWidth(layoutWidthType));
      dispatch(changeLayoutPosition(layoutPositionType));
      dispatch(changeTopbarTheme(topbarThemeType));
      dispatch(changeLayout(layoutType));
      dispatch(changeSidebarImageType(leftSidebarImageType));
      dispatch(changeSidebarVisibility(sidebarVisibilitytype));
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    sidebarVisibilitytype,
    dispatch,
  ]);
  /*
    call dark/light mode
    */
  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };

  const [headerClass, setHeaderClass] = useState("");
  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });
  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  }

  useEffect(() => {
    if (
      sidebarVisibilitytype === "show" ||
      layoutType === "vertical" ||
      layoutType === "twocolumn"
    ) {
      document.querySelector(".hamburger-icon").classList.remove("open");
    } else {
      document.querySelector(".hamburger-icon").classList.add("open");
    }
  }, [sidebarVisibilitytype, layoutType]);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          headerClass={headerClass}
          layoutModeType={layoutModeType}
          onChangeLayoutMode={onChangeLayoutMode}
        />
        <Sidebar layoutType={layoutType} />
        <div className="main-content">
          {props.children}
          {isopen ? (
            <Modal
              isOpen={true}
              centered={true}
              backdrop={true}
              className={className}
              onClick={handleModalClick}
            >
              <ModalHeader>Session snooze!</ModalHeader>
              <ModalBody>
                <i className="ri-logout-box-r-line"></i>{" "}
                   You have been idle, so the session has been expired. Please log in.
              </ModalBody>
              <ModalFooter>
                  <Button
                    color="secondary"
                    type="submit"
                    href={"/logout"}
                  >
                    Login
                  </Button>
              </ModalFooter>
            </Modal>
          ) : (
            <h1></h1>
          )}

          <Footer />
        </div>
      </div>
      <RightSidebar />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
};

export default withRouter(Layout);
