import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    uom : [],
    loading : false,
    error : null,
};

const uomSlice = createSlice({
    name:"uom",
    initialState,
    reducers: {
        fetchUomSuccess: (state,action) => {
            state.loading = false;
            state.uom = action.payload;
        },
        fetchUomFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchUomSuccess,
    fetchUomFailure
} = uomSlice.actions

export default uomSlice.reducer