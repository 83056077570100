import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getOrderByCustomerId: [],
    loading: false,
    error: null,
}

const getOrderByCustomerIdSlice = createSlice({
    name: "getOrderByCustomerId",
    initialState,
    reducers: {
        fetchGetOrderByCustomerIdSuccess: (state, action) => {
            state.loading = false;
            state.getOrderByCustomerId = action.payload;
        },
        fetchGetOrderByCustomerIdFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchGetOrderByCustomerIdSuccess, fetchGetOrderByCustomerIdFailure } = getOrderByCustomerIdSlice.actions;

export default getOrderByCustomerIdSlice.reducer;
