import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    logoView: [],
    loading: false,
    error: null,
};

const fetchlogoViewSlice = createSlice({
    name: "logoView",
    initialState,
    reducers: {
        fetchlogoViewSuccess: (state, action) => {
            state.loading = false;
            state.logoView = action.payload
        },
        fetchlogoViewFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchlogoViewFailure,
    fetchlogoViewSuccess
} = fetchlogoViewSlice.actions

export default fetchlogoViewSlice.reducer