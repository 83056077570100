import { MenuItem } from "@mui/material";

export const PolicyRowOptions = ({navigate, row, setMessageForPopUp, toggleModal, closeMenu}) => {
    return (
        [
            <MenuItem className='row-options' data-testid="update" key="update"  onClick={() => { navigate(`/edit-policies/${row.original.policyLabel}`) }} >
                <i className='bx bxs-edit me-2' /> Edit </MenuItem>,
            <MenuItem className='row-options' key="delete" onClick={() => { setMessageForPopUp(`Are you sure you want to Delete this Policy "${row.original.policyLabel}"?`), toggleModal(row), closeMenu() }}>
                <i className="ri ri-delete-bin-5-line me-2" />{" "}
                Delete
            </MenuItem>,
        ]
    )
}