import moment from "moment";

export const viewOrCancelTableData = (allOfferData, orderDataById, serviceData) => {
    let offerDataOfOrder = []
    let servName = ""
    let totalPrice = 0
    let subTotalValue = 0
    let taxValue = 0
    
    allOfferData?.forEach((value, index) => {
        let serviceFound = false;
        orderDataById?.productOrderItem?.forEach((val, idx) => {
            if (val.productOffering.id === value.offerCode) {
                serviceData.forEach((vals, idx) => {
                    if (value.serviceCode === vals.serviceCode) {
                        servName = vals.name
                        serviceFound = true;
                    }
                })
                if (!serviceFound) {
                    servName = "-";
                }
                subTotalValue += val?.itemTotalPrice[0]?.price?.dutyFreeAmount?.value;
                taxValue += val?.itemTotalPrice[0]?.price?.taxIncludedAmount?.value - val?.itemTotalPrice[0]?.price?.dutyFreeAmount?.value
                totalPrice += val?.itemTotalPrice[0]?.price?.taxIncludedAmount?.value;
                offerDataOfOrder.push({
                    subscriptionID: val.id,
                    serviceName: servName,
                    offerName: value.offerName,
                    duration: `${moment(val?.product?.startDate).format("MMM YY, DD")} - ${moment(val?.product?.terminationDate).format("MMM YY, DD")} `,
                    status: orderDataById?.state,
                    unitPrice: val.itemTotalPrice[0].price.dutyFreeAmount.value,
                    subTotal: val.itemTotalPrice[0].price.dutyFreeAmount.value
                })
            }
        })
    })
    return { offerDataOfOrder, servName, taxValue, subTotalValue, totalPrice }
}