import { Card, CardBody, CardHeader, InputGroup, InputGroupText, Col, Row } from "reactstrap";
import InputField from "../../Utils/InputField";
import SelectField from "../../Utils/SelectField";
import { handleAggregateForOffer, handlePolicyForOffer, handleServiceForPolicy } from "./constants/checkEmptyFields";

export default function AggregateResourcesForOffer({
    values, errors, touched,
    serviceData, isEdit, isSubscribed,
    aggregateResourceOptions,globalState,
    setServiceCode, setSelectedService,
    handleChange,
    setFieldValue
}) {
    return (
        <Card>
            <CardHeader>
                <h4 className={`card-title mb-0 flex-grow-1 ${isSubscribed ? 'title-disable-color-edit-offer' : ''}`}>Aggregate Resources</h4>
            </CardHeader>
            <CardBody>
                <Row>
                <Col xxl={8} md={8}>
                    <SelectField
                        className={(errors.aggregateVolume && touched.aggregateVolume) ? 'error-input' : 'mb-3'}
                        placeholder={"Select Aggregate Resources"}
                        name={`aggregateVolume`}
                        labelName={"aggregateVolume"}
                        value={values.aggregateVolume} 
                        // isDisabled={(isSubscribed || globalState) ? true : false}
                        isClearable
                        isMulti
                        // value = {initialValues.policyName ? serviceOptions?.filter((policyName) => {
                        //     policyName.label === initialValues.policyName.label
                        // }) : values.policyName}
                        handleChange={handleAggregateForOffer(values, setFieldValue)}
                        options={aggregateResourceOptions} />
                        
                </Col>
               
                </Row>
                
            </CardBody>
        </Card>
    )
}