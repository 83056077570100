import React, { useEffect, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Row, Col, Button } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Utils/Loader";
import { ToastContainer, toast } from "react-toastify";
import { MenuItem } from "@mui/material";
import {
  setSuccessMessage,
  setEditSuccessMessage,
  setDeleteSuccessMessage,
} from "../../slices/toastMessage/action";
import moment from "moment";
import { cancelStatus } from "../../slices/Device/DeleteStatus/thunk";
import { getAllDevice } from "../../slices/Device/Devicelist/thunk";
import { deviceColumns } from "./constants/coloumn"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { PAGE_TITLES } from "../Common/constants";
import { handleExportData } from "../Utils/ExportToCsv";

function DeviceListings() {
  const [loading, setLoading] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const permissions = useSelector((state) => state.permissions.permissions);
  const devicePermission = permissions.find((resource) => resource.resourceName === 'device');

  const showSuccessMessage = useSelector(
    (state) => state.SuccessToast.showSuccessMessage
  );
  const showEditSuccessMessage = useSelector(
    (state) => state.SuccessToast.showEditSuccessMessage
  );

  const showDeleteSuccessMessage = useSelector(
    (state) => state.SuccessToast.showDeleteSuccessMessage
  );

  let dispatch = useDispatch();
  const [modal, setModal] = useState(false);

  const closeModal = () => {
    setModal(!modal);
  };
  const toggleModal = (row) => {
    if (row && row.original) {
      const deviceId = row.original.deviceId;
      setDeviceId(deviceId);
      setModal((prevModal) => !prevModal);
    }
  };

  const device = useSelector((state) => state.device.device);

  const formattedDates = device && Array.isArray(device)
    ? device.map((item) => moment(item.createdAt).format("D MMM YYYY"))
    : [];

  const columns = deviceColumns(formattedDates)

  let navigate = useNavigate();

  useEffect(() => {
    if (showSuccessMessage) {
      toast.success(`Device Created Successfully`);
      dispatch(setSuccessMessage(false));
    }

    if (showEditSuccessMessage) {
      toast.success(`Device Updated Successfully`);
      dispatch(setEditSuccessMessage(false));
    }

    if (showDeleteSuccessMessage) {
      toast.success(`Device Status Updated Successfully`);
      dispatch(setDeleteSuccessMessage(false));
    }
  }, [showSuccessMessage, dispatch, showDeleteSuccessMessage]);

  const handleChangeStatus = async () => {
    dispatch(cancelStatus(deviceId, setLoading, navigate));
  };

  const navigateToDevice = () =>{
    navigate('/create-device')
  }

  useEffect(() => {
    dispatch(getAllDevice(setLoading));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div id="csms" className="page-content">
        <Container fluid>
          <BreadCrumb title={PAGE_TITLES.DEVICE_LIST} pageTitle={PAGE_TITLES.DEVICE} />
          <ToastContainer position="top-center" />
          <Row>
            <Col lg={12}>
              <div className="listingjs-table " id="customerList">
                <Card>
                  <CardBody>
                    <Row className="mb-3 mt-1 table-responsive">
                      {loading && <Loader />}
                      {device ? (
                        <MaterialReactTable
                          icons={{
                            DragHandleIcon: () => (
                              <i className="ri-drag-move-fill" />
                            ),
                          }}
                          muiTableHeadCellProps={{
                            sx: {
                              fontFamily: "Inter,sans-serif",
                            },
                          }}
                          muiTableBodyCellProps={{
                            sx: {
                              fontFamily: "Inter,sans-serif",
                            },
                          }}
                          columns={columns}
                          data={device}
                          enableColumnOrdering
                          enableColumnDragging
                          enableDensityToggle={false}
                          enableRowActions
                          initialState={{
                            columnVisibility: {
                              "mrt-row-expand": false,
                            },
                          }}
                          displayColumnDefOptions={{
                            "mrt-row-actions": {
                              header: "",
                              enableHiding: true,
                            },
                          }}
                          renderTopToolbarCustomActions={({ }) => (
                            <div className="d-flex flex-column flex-md-row align-items-md-center">
                              <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(device) }}>
                                <i className="ri-upload-line label-icon align-middle fs-16 me-2 " ></i> Export Data
                              </Button>
                              {devicePermission && devicePermission.permissionList.includes('create') && <Button color="secondary" className="btn-label me-2" onClick={navigateToDevice}>
                                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Device
                              </Button>}
                            </div>
                          )}
                          renderRowActionMenuItems={({ closeMenu, row }) => {
                            if (row.original.status === 'Terminated') {
                              return [
                                devicePermission && devicePermission.permissionList.includes('update') ? <MenuItem
                                  className="row-options"
                                  key="edit"
                                  onClick={() => {
                                    navigate(`/update-device/${row.original.deviceId}`);
                                  }}
                                >
                                  <i className="bx bxs-edit me-2" />
                                  Edit
                                </MenuItem> : null,
                              ];
                            } else {
                              return [
                                devicePermission && devicePermission.permissionList.includes('update') ? <MenuItem
                                  className="row-options"
                                  key="edit"
                                  onClick={() => {
                                    navigate(`/update-device/${row.original.deviceId}`);
                                  }}
                                >
                                  <i className="bx bxs-edit me-2" />
                                  Edit
                                </MenuItem> : null,
                                devicePermission && devicePermission.permissionList.includes('delete') ? <MenuItem
                                  key="preview"
                                  onClick={() => {
                                    closeMenu();
                                    toggleModal(row);
                                    closeModal();
                                  }}
                                >
                                  {" "}
                                  <i className="ri ri-delete-bin-5-line me-2 text-danger" />{" "}
                                  Delete
                                </MenuItem> : null,
                              ];
                            }
                          }}
                        />
                      ) : null}
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal backdrop="static" centered={true} toggle={closeModal} isOpen={modal} data-testid="modal">
          <ModalHeader toggle={closeModal} className="d-flex justify-content-end  align-items-center"></ModalHeader>
          <ModalBody className="fs-5 fw-bold text-center">Are you sure you want to Terminate this Device "{deviceId}"?</ModalBody>
          <ModalFooter className="d-flex justify-content-center align-items-center ">
            <Button onClick={() => closeModal()} className="d-flex justify-content-center align-items-center bg-transparent text-danger border-white shadow-none " data-testid="close-modal-button">
              <i className="ri-close-fill" />
              Close
            </Button>
            <Button color="secondary" type="submit" onClick={() => { handleChangeStatus(deviceId), closeModal(); }} >
              Yes, Delete It
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      {loading && <Loader />}
    </React.Fragment>
  );
}
export default DeviceListings;
