import React from 'react';
import ReactApexChart from 'react-apexcharts';

const AreaChart = ({ activeCustomerData, inactiveCustomerData }) => {
    const sanitizeData = (data) => {
        return data.map(item => ({
            x: item?.x || 'Unknown',
            y: item?.y ?? 0
        }));
    };

    const sanitizedActiveCustomerData = sanitizeData(activeCustomerData || []);
    const sanitizedInactiveCustomerData = sanitizeData(inactiveCustomerData || []);

    const seriesData = [
        {
            name: 'Added Customers',
            data: sanitizedActiveCustomerData
        },
        {
            name: 'Closed Customers',
            data: sanitizedInactiveCustomerData
        }
    ];

    const months = sanitizedActiveCustomerData.map(item => item.x);

    const options = {
        series: seriesData,
        colors: ['#04B400', '#FF3232'],
        chart: {
            type: 'area',
            height: 350
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: 0
        },
        xaxis: {
            type: 'category',
            categories: months,
            labels: {
                style: {
                    colors: '#8e8da4',
                },
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            title: {
                text: 'Month',
                style: {
                    color: '#000',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontFamily: 'Helvetica, Arial, sans-serif'
                }
            }
        },
        yaxis: {
            tickAmount: 4,
            floating: false,
            labels: {
                offsetY: -7,
                offsetX: 0,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false
            },
            title: {
                text: 'Customers',
                style: {
                    color: '#000',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontFamily: 'Helvetica, Arial, sans-serif'
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100],
            },
            colors: ['#04B400', '#FF3232']
        },
        tooltip: {
            x: {
                format: "yyyy",
            },
            style: {
                colors: '#04B400',
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif'
            },
            marker: {
                fillColors: ['#04B400', '#FF3232'],
            },
            fixed: {
                enabled: false,
                position: 'topRight'
            }
        },
        legend: {
            formatter: (seriesName, opts) => {
                const markerStyle = `
                    display: inline-block;
                    width: 13px;
                    height: 13px;
                    margin-right: 10px;
                    border-radius:2px;
                    background-color: ${opts.w.globals.colors[opts.seriesIndex]};
                `;
                return `<div style="display: flex; align-items: center;">
                            <span style="${markerStyle}"></span>
                            <span>${seriesName}</span>
                        </div>`;
            },
            markers: {
                width: 0,
                height: 0
            },
            labels: {
                colors: ['#04B400', '#FF3232'],
                useSeriesColors: true
            },
            itemMargin: {
                horizontal: 100,
                vertical: 0
            }
        },
        grid: {
            show: true,
            borderColor: '#888A99',
            strokeDashArray: 0,
            position: 'front',
            yaxis: {
                lines: {
                    show: true,
                }
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'center',
                        fontSize: '10px',
                        itemMargin: {
                            horizontal: 2, 
                            vertical: 0
                        }
                    }
                }
            }
        ]
    };

    return (
        <div className="area-chart">
            <ReactApexChart options={options} series={seriesData} type="area" height={400} />
        </div>
    );
};

export default AreaChart;
