import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getPolicies: [],
    loading: false,
    error: null,
}

const  getPoliciesSlice = createSlice({
    name: " getPolicies",
    initialState,
    reducers: {
        fetchGetPoliciesSucess: (state, action) => {
            state.loading = false
            state. getPolicies = action.payload;
        },
        fetchGetPoliciesFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchGetPoliciesSucess,
    fetchGetPoliciesFailure,
} =  getPoliciesSlice.actions;

export default getPoliciesSlice.reducer;