import { toast } from "react-toastify";
import { fetchforgotPasswordFailure, fetchforgotPasswordSuccess } from "./reducer";
import { FORGOT_PASSWORD } from "../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";

export const forgotPassword = (jsonObj, setLoading, navigate) => (dispatch) => {
    setLoading(true); 
    determineBaseUrlAndPerformApiCall(`${FORGOT_PASSWORD}`, 'POST', jsonObj)
        .then((response) => {
            setLoading(false); 
            if (!response.error) {
                dispatch(fetchforgotPasswordSuccess(response));
                toast.success(`Password reset link has been sent successfully.`, {
                    onClose: () => {
                        navigate('/');
                    }
                });
            } else {
                dispatch(fetchforgotPasswordFailure(response.error.message));
                toast.error(`Error: ${response.error.message}`);
            }
        })
        .catch((error) => {
            setLoading(false);
            dispatch(fetchforgotPasswordFailure(error.response.data.error.message));
            toast.error(`${error.response.data.error.message}`);
        });
};

