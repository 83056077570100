export function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      const formattedNum = (num / 1000).toFixed(2);
      return formattedNum.endsWith('.00') ? formattedNum.slice(0, -3) + 'K' : formattedNum + 'K';
    } else {
      const formattedNum = num.toFixed(2);
      return formattedNum.endsWith('.00') ? formattedNum.slice(0, -3) : formattedNum;
    }
  }
  