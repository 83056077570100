import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Form,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { editStation } from "../../slices/stations/editstation/thunk";
import { ToastContainer } from "react-toastify";
import { InitialValues } from "./constants/stationsIntialValues";
import FormLine1 from "./FormLine1";
import FormLine2 from "./FormLine2";
import FormLine3 from "./FormLine3";
import FormLine4 from "./FormLine4";
import { validationstation } from "./constants/Validationscheme";
import { Loader } from "../Utils/Loader";
import CommonButton from "../Utils/CommonButton";
import { PAGE_TITLES } from "../Common/constants";

export default function EditStation() {
  const location = useLocation();
  const state = location.state;
  const isEdit = location.pathname.includes("update-station");
  const navigate = useNavigate();
  const [loading, setLoading] = useState("");

  const dispatch = useDispatch();

  let defaultInitialValues = InitialValues;

  let initialValues = { ...defaultInitialValues };

  if (state && state.stationDetails) {
    const stationDetails = state.stationDetails;
    const { address, ...restDetails } = stationDetails;
    const [address_line1 = "", address_line2 = ""] = address
      ? address?.split(",")
      : ["", ""];
    initialValues = {
      ...defaultInitialValues,
      ...restDetails,
      address,
      address_line1: address_line1.trim(),
      address_line2: address_line2.trim(),
      modem: {
        ...defaultInitialValues.modem,
        ...restDetails.modem,
      },
      coordinates: {
        ...defaultInitialValues.coordinates,
        ...restDetails.coordinates,
      },
    };
  }

  const onSubmit = (values) => {
    const jsonObj = JSON.stringify({
      stationID: values.stationID,
      name: values.name,
      serialNumber: values.serialNumber,
      model: values.model,
      address: values.address_line1.trim() + "," + values.address_line2.trim(),
      vendorName: values.vendorName,
      firmwareVersion: values.firmwareVersion,
      modem: {
        iccid: values.modem.iccid,
        imsi: values.modem.imsi,
      },
      timeZone: values.timeZone,
      coordinates: {
        latitude: (values.coordinates.latitude)?.toString(),
        longitude: (values.coordinates.longitude)?.toString(),
      },
      adminStatus: values.adminStatus,
    });
    EditStation(jsonObj);
  };

  const EditStation = async (jsonObj) => {
    dispatch(editStation(jsonObj, state, setLoading, navigate));
  };

  return (
    <div>
      <Formik validationSchema={validationstation(state)} initialValues={initialValues}>
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          values,
          resetForm,
          setFieldTouched,
          setFieldValue,
        }) => {
          return (
            <Form
              id="csms"
              className="needs-validation"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
                if (
                  values.name !== "" &&
                  values.address_line1 !== "" &&
                  values.address_line2 !== ""
                ) {
                  onSubmit(values);
                }
              }}
            >
              <div>
                <div className="page-content">
                  <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.EDIT_STATION} pageTitle={PAGE_TITLES.STATION_LIST} />
                    <ToastContainer position="top-center" />
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody className="card-body">
                            <div className="live-preview">
                              <Row className="gy-4">
                                <FormLine1
                                  values={values}
                                  touched={touched}
                                  errors={errors}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  setFieldTouched={setFieldTouched}
                                  setFieldValue={setFieldValue}
                                />
                                <FormLine2
                                  values={values}
                                  touched={touched}
                                  errors={errors}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  setFieldTouched={setFieldTouched}
                                  setFieldValue={setFieldValue}
                                />
                                <FormLine3
                                  values={values}
                                  touched={touched}
                                  errors={errors}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  setFieldTouched={setFieldTouched}
                                  setFieldValue={setFieldValue}
                                />
                                <FormLine4
                                  values={values}
                                  touched={touched}
                                  errors={errors}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  setFieldTouched={setFieldTouched}
                                  setFieldValue={setFieldValue}
                                />
                              </Row>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row lg={6} className=" justify-content-end mb-4">
                      <div className="live-preview">
                        <div className="d-flex justify-content-end gap-2 ">
                          <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/station')} buttonText={"Cancel"} />
                          <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} />
                        </div>
                      </div>
                    </Row>
                  </Container>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      {loading && (<Loader />)}
    </div>
  );
}
