import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    balance : [],
    selectedResource:[],
    loading : false,
    error : null,
};

const balanceSlice = createSlice({
    name:"balance",
    initialState,
    reducers: {
        fetchBalanceSuccess: (state,action) => {
            state.loading = false;
            state.balance = action.payload
        },
        fetchSelectedResourceSuccess: (state,action) => {
            state.loading = false;
            state.selectedResource = action.payload
        },
        fetchBalanceFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchBalanceFailure,
    fetchBalanceSuccess,
    fetchSelectedResourceSuccess
} = balanceSlice.actions

export default balanceSlice.reducer