import { Col, FormFeedback, Row } from "reactstrap"
import { LabelText } from "../Utils/Input"
import InputField from "../Utils/InputField"

export const PolicyDetails = ({values,touched,errors,handleChange,handleBlur,getPolicyData,isEdit}) => {
    return (
        <Row className="gy-4">
            <Col xxl={5} md={6}>
                <div className="d-flex ">
                    <LabelText displayText={"Policy Name "} important={" *"} classLabelName={"policy-text-length d-flex justify-content-center align-items-center"} className={"text-danger"} />
                    <div className="flex-column ">
                        <InputField name={"policyName"} placeholder={"Enter your Policy Name"} type={"text"} className={" mb-0 form-control policy-name-input-length"} id={"policyName"} handleChange={handleChange} handleBlur={handleBlur} value={values.policyName}
                            invalid={touched.policyName && errors.policyName
                                ? true
                                : false} />
                        {touched.policyName &&
                            errors.policyName ? (
                            <FormFeedback type="invalid">
                                {errors.policyName}
                            </FormFeedback>
                        ) : null}
                    </div>
                </div>
            </Col>
            <Col xxl={isEdit ? 3 : 6} md={6}>
                <div className="d-flex">
                    <LabelText displayText={"Priority "} important={" *"} classLabelName={"policy-text-length d-flex justify-content-center align-items-center"} className={"text-danger"} />
                    <div className=" flex-column ">
                        <InputField name={"priority"} placeholder={"Enter your Policy Name"} type={"number"} className={" mb-0 form-control policy-name-input-length"} id={"priority"} handleChange={handleChange} handleBlur={handleBlur} value={values.priority}
                            invalid={touched.priority && errors.priority
                                ? true
                                : false} />
                        {touched.priority &&
                            errors.priority ? (
                            <FormFeedback type="invalid">
                                {errors.priority}
                            </FormFeedback>
                        ) : null}
                    </div>
                </div>
            </Col>
            {isEdit &&
                <Col xxl={4} md={6}>
                    <LabelText displayText={`Policy Id : ${getPolicyData.id}`} />
                </Col>
            }
        </Row>
    )
}