import { fetchCreateServicesFailure, fetchCreateServicesSuccess } from "./reducer"
import { toast } from "react-toastify";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { ADD_SERVICE_API } from "../../constants/constants";
import { setSuccessMessage } from "../../toastMessage/action";

export const addService = (jsonObj, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${ADD_SERVICE_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchCreateServicesSuccess(response))
            navigate('/service');
            dispatch(setSuccessMessage(true))
        }
        if (response.error) {
            dispatch(fetchCreateServicesFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}