export const PoliciesColoumn = () => {
  const columns = [
    {
      accessorKey: 'policyId',
      header: 'Policy ID',
      size: 150,
    },
    {
      accessorKey: 'policyLabel',
      header: 'Policy Label',
      size: 150,
     
    },
    {
      accessorKey: 'resourceName',
      header: 'Resource',
      size: 150,
     
    },
  ];

  return columns;
};