export const navigateToAddPolicy = (navigate) => {
    navigate("/add-policy")
}

export const handleChangeForAttributeValue = (handleChange) => (e) => {
    handleChange(e);
  }
  export function isEmptyOrInvalidField(index, values) {
    const previousField = values?.attributeList[index];
    return !previousField || !previousField.rangeStarts || !previousField.rangeEnds || !previousField.statusLabel;
  } 
  export const handleDeviceTypeChange = (setFieldValue) => (selectedDevice) => {
    setFieldValue("deviceType", selectedDevice);
  };
  
  export const handleStatusChange = (setFieldValue) => (selectedDevice) => {
    setFieldValue("status", selectedDevice);
  };
  
  
  
  
  
  