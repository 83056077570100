import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    login : [],
    loading : false,
    error : null,
};

const loginSlice = createSlice({
    name:"login",
    initialState,
    reducers: {
        fetchLoginSuccess: (state,action) => {
            state.loading = false;
            state.login = action.payload
        },
        fetchLoginFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchLoginFailure,
    fetchLoginSuccess
} = loginSlice.actions

export default loginSlice.reducer