import { CUSTOMER_LISTING_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchCustomerListForReservationSuccess, fetchCustomerListForReservationFailure } from "./reducer";

export const fetchCustomerListForReservation = (setLoading) => async (dispatch) => {
    setLoading(true)
    const graphqlQuery = {
        query: `
        query getCustomers($first:Int, $offset:Int, $filter: CustomerFilter) {
          customersPaginate(first:$first, offset:$offset, filter: $filter) {
              Count,
              TotalCount,
              Customers{
                  CustomerId
                  Name
                  Email
                  Mobile
                  Address
                  AttributeList{
                      Name
                      Value
                  }
              }
          }
      }
        `,
        variables: { first: 0, offset: 1, filter: {}, orderBy: {}, sortCriteria: { OrderBy: 'CreatedAt', Order: 'des' } },
    }

    determineBaseUrlAndPerformApiCall(`${CUSTOMER_LISTING_API}`, 'POST', graphqlQuery).then((data) => {
        setLoading(true)
        dispatch(fetchCustomerListForReservationSuccess(data?.data?.customersPaginate?.Customers.reverse()))
        setLoading(false)
    }).catch((error) => {
        dispatch(fetchCustomerListForReservationFailure(error.message))
    })
}