import { deleteDoc } from "../../../slices/customer/documentdelete/thunk";
import { resetDocumentViewState } from "../../../slices/customer/documentview/reducer";

export const handleRemoveLogo = (dispatch,setLoading,setLogoFile,setImageOn,setUpdate,fileInputRef,documentName,userId,isEdit) => {
    const payload = {
        "ID": userId,
        "documentName": documentName
    }
    {isEdit?dispatch(deleteDoc(setLoading, payload, userId)):null}
    setLogoFile(null);
    setImageOn(true)
    setUpdate(true)
    dispatch(resetDocumentViewState())

    if (fileInputRef.current) {
        fileInputRef.current.value = '';
    }
};