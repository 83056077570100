import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    getAllTasks : [],
    loading : false,
    error : null,
};

const getAllTasksSlice = createSlice({
    name:"getAllTasks",
    initialState,
    reducers: {
        fetchGetAllTasksSuccess: (state,action) => {
            state.loading = false;
            state.getAllTasks = action.payload
        },
        fetchGetAllTasksFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchGetAllTasksFailure,
    fetchGetAllTasksSuccess
} = getAllTasksSlice.actions

export default getAllTasksSlice.reducer