import { GET_ALL_DEFAULT_CONFIGURATION } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchOperatorDefaultConfigurationFailure, fetchOperatorDefaultConfigurationSuccess} from "./reducer";

export const fetchAllOperatorDefaultConfigurations = (setLoading,domain) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${GET_ALL_DEFAULT_CONFIGURATION}?domain=${domain}`,'GET')
        setLoading(false)
        dispatch(fetchOperatorDefaultConfigurationSuccess(response))
    }
    catch (error) {
        setLoading(false)
        dispatch(fetchOperatorDefaultConfigurationFailure(error.message))
    }
}