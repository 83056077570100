
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { setPasswordSuccessMessage, setSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";
import { USER_MANAGEMENT_CREATE_API } from "../../constants/constants";
import { fetchCreateNewPasswordFailure, fetchCreateNewPasswordSucess} from "./reducer";

export const createNewPassword = (jsonObj, setLoading, navigate,userId) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${USER_MANAGEMENT_CREATE_API}/password/${userId}`, 'PUT', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchCreateNewPasswordSucess(response))
            navigate('/');
            // dispatch(setPasswordSuccessMessage(true))
        }
        else {
            setLoading(false)
            dispatch(fetchCreateNewPasswordFailure(response.error.message))
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => {
        setLoading(false)
        toast.error('Cannot change password for another user')
     })
}