import { Card, CardBody, Row, Col, Input } from "reactstrap";
import { LabelText } from "../Utils/Input";
import SelectField from "../Utils/SelectField";
import { Loader } from "../Utils/Loader";
import { useEffect, useState } from "react";
import { fetchAllOperatorDefaultConfigurations } from "../../slices/OperatorManagement/GetAllDefaultConfiguraions/thunk";
import { useDispatch, useSelector } from "react-redux";

export default function ConfigurationForOperator({ setFinalValues, finalValues, values }) {
    const [loading, setLoading] = useState(false);
    const [switchState, setSwitchState] = useState({});
    const [selectState, setSelectState] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (values?.Domain) {
            dispatch(fetchAllOperatorDefaultConfigurations(setLoading, values.Domain.label));
        }
    }, [dispatch, values?.Domain]);

    const OperatorDefaultConfigurationList = useSelector((state) => state.GetAllDefaultConfiguration.OperatorDefaultConfiguration);

    const configDisplayNameMapping = {
        paymentManagement: "Payment",
        taskManagement: "Tasks",
        whatsAppManagement: "WhatsApp",
        notificationManagement: "Notifications",
        identityManagement: "IDM",
    };

    useEffect(() => {
        const initialSwitchState = {};
        const initialSelectState = {};
    
        OperatorDefaultConfigurationList.forEach(config => {
            initialSwitchState[config.name] = false;
        });
    
        setSwitchState(initialSwitchState);
        setSelectState(initialSelectState);
    }, [OperatorDefaultConfigurationList]);
    
    const handleSwitchToggle = (configName) => {
        setSwitchState(prevState => ({
            ...prevState,
            [configName]: !prevState[configName] 
        }));
    };

    const handleSelectChange = (key, selectedOption) => {
        setSelectState(prevState => ({
            ...prevState,
            [key]: selectedOption 
        }));
    };

    useEffect(() => {
        const combinedValues = {
            ...switchState,
            ...Object.fromEntries(
                Object.entries(selectState).map(([key, value]) => [key, value.value])
            )
        };
        setFinalValues(combinedValues);
    }, [switchState, selectState]);

    const configurationsWithSelectFields = ["identityManagement", "paymentManagement"]; 
    const combinedConfigurations = () => {
        const configList = [...OperatorDefaultConfigurationList];
    
        const paymentIndex = configList.findIndex(config => config.name === "paymentManagement");
        const identityIndex = configList.findIndex(config => config.name === "identityManagement");
    
        if (paymentIndex !== -1 && identityIndex !== -1) {
            const identityConfig = configList.splice(identityIndex, 1)[0]; 
            configList.splice(paymentIndex + 1, 0, identityConfig);
        }
    
        return configList;
    };

    return (
        <div>
            <Card>
                <h5 className="px-3 pt-3">Configurations</h5>
                <hr className="m-0 mt-1 hr-width ms-3" />
                {loading && (<Loader />)}
                <CardBody>
                    <Row className="gy-4">
                        {combinedConfigurations().map((config, configIndex) => (
                            <Row key={`${config.name}-${configIndex}`} className="mt-4">
                                <Col xxl={3} md={6}>
                                    <div className="d-flex align-items-center">
                                        <div className="label-fixed-width me-2">
                                            <LabelText displayText={configDisplayNameMapping[config.name] || config.name} />
                                        </div>
                                        <span className="mx-2">:</span>
                                        <div className="form-check form-switch ms-5">
                                            <Input
                                                type="switch"
                                                className="form-check-input"
                                                checked={switchState[config.name]}
                                                onChange={() => handleSwitchToggle(config.name)}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                {switchState[config.name] && configurationsWithSelectFields.includes(config.name) && (
                                    config.value.map((item, itemIndex) => (
                                        <Col key={`${config.name}-${itemIndex}`} xxl={3} md={6} className=" mb-2">
                                                <div className=" ">
                                                    {!(config.name === 'paymentManagement' && item.key === 'PaymentStatus') &&
                                                     !(config.name === 'identityManagement' && item.key === 'Enable') && (
                                                        <SelectField
                                                            name={item.key}
                                                            options={item.value.map(opt => ({ label: opt, value: opt }))}
                                                            value={selectState[item.key] || null}
                                                            handleChange={(selectedOption) => handleSelectChange(item.key, selectedOption)}
                                                            placeholder={
                                                                config.name === 'paymentManagement' ? 'Select Payment Provider' :
                                                                config.name === 'identityManagement' ? 'Select Identity Provider' :
                                                                'Select...'
                                                            }
                                                        />
                                                    )}
                                                </div>
                                        </Col>
                                    ))
                                )}
                            </Row>
                        ))}
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}
