import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    fetchCustomer : [],
    loading : false,
    error : null,
};

const fetchCustomerSlice = createSlice({
    name:"fetchCustomer",
    initialState,
    reducers: {
        fetchCustomerSuccess: (state,action) => {
            state.loading = false;
            state.fetchCustomer = action.payload
        },
        fetchCustomerFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchCustomerFailure,
    fetchCustomerSuccess
} = fetchCustomerSlice.actions

export default fetchCustomerSlice.reducer