import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { POLICY_LISTING_API } from "../constants/constants";
import { fetchGetAllPoliciesFailure, fetchGetAllPoliciesSuccess } from "./reducer";

export const getAllPolicies = (setLoading) => async (dispatch) => {
    try {
        setLoading(true);
        const response = await determineBaseUrlAndPerformApiCall(`${POLICY_LISTING_API}`, 'GET');
        if (response.error && response.error.code === 'NotFound') {
            dispatch(fetchGetAllPoliciesSuccess([]));
        } else {
            dispatch(fetchGetAllPoliciesSuccess(response.reverse()));
        }
        setLoading(false);
    } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 404) {
            dispatch(fetchGetAllPoliciesSuccess([]));
        } else {
            dispatch(fetchGetAllPoliciesFailure(error.message || "An error occurred"));
        }
    }
};
