import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  permissions: [],
  loading: false,
  error: null,
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    fetchPermissionsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchPermissionsSuccess: (state, action) => {
      state.loading = false;
      state.permissions = action.payload;
    },
    fetchPermissionsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchPermissionsStart,
  fetchPermissionsSuccess,
  fetchPermissionsFailure,
} = permissionsSlice.actions;

export const fetchPermissions = () => async (dispatch) => {
  dispatch(fetchPermissionsStart());
  try {
    const bearerToken = localStorage.getItem('token');
    const response = await axios.get(
      `${window.env.REACT_APP_PERMISSIONS_API}`,
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    dispatch(fetchPermissionsSuccess(response.data));
  } catch (error) {
    dispatch(fetchPermissionsFailure(error.message));
  }
};

export default permissionsSlice.reducer;
