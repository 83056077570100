import { toast } from "react-toastify";
import { fetchAddDeviceSuccess, fetchAddDeviceFailure } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { setSuccessMessage } from "../../toastMessage/action";
import { DEVICE_API } from "../../constants/constants";

export const addDevice =
  (jsonObj, setLoading, navigate) => async (dispatch) => {
    setLoading(true);
    await determineBaseUrlAndPerformApiCall(`${DEVICE_API}`, "POST", jsonObj)
      .then((response) => {
        if (!response.error) {
          setLoading(false)
          dispatch(fetchAddDeviceSuccess(response));
          navigate("/devices");
          dispatch(setSuccessMessage(true));
        }
        if (response.error) {
          setLoading(false)
          dispatch(fetchAddDeviceFailure(response.error.message));
          toast.error(`${response.error.message}`);
          dispatch(setSuccessMessage(true));
        }
      })
      .catch((errorResp) => { });
  };
