import React from 'react';
import moment from 'moment';
import { Box } from "@mui/material";
import { getStatusLabel } from '../../Utils/DecideStatus';
import { getCellStyle } from '../../Utils/DecideColor';

const PaymentColumns = [
    {
        accessorKey: 'paymentID',
        header: 'Payment ID ',
        Cell: ({ cell }) => (
            <Box>
                {cell.getValue() ? cell.getValue() : "-"}
            </Box>
        )
    },
    {
        accessorKey: 'orderID',
        header: 'Order ID ',
    },
    {
        accessorKey: 'createdDate',
        header: 'Created Date',
        Cell: ({ cell }) => (
            <Box>
                {moment(cell.getValue()).format("DD-MMM-YYYY")}
            </Box>
        )
    },
    {
        accessorFn: (row) => `${row.currency} ${row.amount}`,
        header: 'Amount',
    },
    {
        accessorKey: 'paymentStatus',
        header: 'Status',
        Cell: ({ cell }) => (
            <Box sx={(theme) => (cell.getValue() ? {
                borderRadius: '0.35rem',
                border: cell.getValue() === 'Triggered' ? '2px solid #3478F1' : cell.getValue() === 'Pending' ? '2px solid #3478F1' : cell.getValue() === "Failed" ? "2px solid #FF0000" : cell.getValue() === "Success" ? '2px solid #07BC0B' : "",
                color: cell.getValue() === 'Triggered' ? '#3478F1' : cell.getValue() === 'Pending' ? theme.palette.warning.light : cell.getValue() === "Failed" ? theme.palette.warning.dark : theme.palette.success.light,
                maxWidth: '9ch',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "1px"
            } : {})} >{cell.getValue() === "Triggered" ? "Triggered" : cell.getValue() === "Pending" ? "Pending" : cell.getValue() === "Failed" ? "Failed" : cell.getValue() === "Success" ? "Success" : "-"}</Box>
        )
    },
];

const InvoiceColumns = [
    {
        accessorKey: 'id',
        header: 'Invoice ID ',
    },
    {
        accessorKey: 'date',
        header: 'Date',
    },
    {
        accessorKey: 'amount',
        header: 'Amount',
    },
    {
        accessorKey: 'balance',
        header: 'Balance Due',
    },
    {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ cell }) => (
            <Box sx={(theme) => (cell.getValue() ? {
                ...getCellStyle(cell.getValue(),"InvoiceForCustomer")
            } : {})} >{getStatusLabel(cell.getValue())}</Box>
        )
    },
];

export { PaymentColumns, InvoiceColumns };
