import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    UomById: [],
    loading: false,
    error: null,
}

const UomByIdSlice = createSlice({
    name: "UomById",
    initialState,
    reducers: {
        fetchUomByIdStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchUomByIdSuccess: (state, action) => {
            state.loading = false,
                state.UomById = action.payload;
        },
        fetchUomByIdFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})
export const {
    fetchUomByIdStart,
    fetchUomByIdSuccess,
    fetchUomByIdFailure,
} = UomByIdSlice.actions;

export default UomByIdSlice.reducer;