const initialState = {
  offersselect: [],
  settlementOffer: [],
  selectedOffer: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_OFFER':
      return {
        ...state,
        offersselect: [...state.offersselect, ...action.payload],
      };
    case 'ADD_OFFER_DUPLICATE':
      return {
        ...state,
        settlementOffer: [...state.settlementOffer, ...action.payload],
      };
    case 'SELECT_ORDER':
      return {
        ...state,
        selectedOffer: action.payload,
      };
    case 'UPDATE_ORDER':
      return {
        ...state,
        offersselect: state.offersselect.map(order =>
          order.subscriptionID === action.payload.subscriptionID ? action.payload : order
        ),
        selectedOffer: null,
      };
    case 'REMOVE_OFFER':
      return {
        ...state,
        settlementOffer: state.settlementOffer.filter(order =>{ 
          return order.offerCode !== action.payload}),
        selectedOffer: null,
      };
    case 'CLEAR_OFFERS':
      return {
        ...state,
        settlementOffer: [],
        selectedOffer: null,
      };
    case 'CLEAR_OFFERS_DUPLICATE':
      return {
        ...state,
        settlementOffer: [],
      };
    default:
      return state;
  }
};

export default orderReducer;
