import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPopularPlan } from "../../../slices/PopularPlan/thunk";

export const usePlans = (setLoading) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPopularPlan(setLoading));
    }, [dispatch, setLoading]);

    const plans = useSelector((state) => state?.GetPopularPlan?.getPopularPlan ?? null);

    return plans;
};
