import { Col, FormGroup, Row } from "reactstrap"

export const CustomerProfile = ({fetchCustomerData}) => {
    return (
        <Row className="gy-4">
        <Col xxl={5} md={5}>
            <FormGroup className="ms-md-5">
                <p className="fw-bolder">First Name</p>
                <p>{fetchCustomerData?.customerInfo?.firstName ? fetchCustomerData?.customerInfo?.firstName : "-"}</p>
            </FormGroup>
            <FormGroup className="ms-md-5">
                <p className="fw-bolder">Last Name</p>
                <p>{fetchCustomerData?.customerInfo?.lastName ? fetchCustomerData?.customerInfo?.lastName : "-"}</p>
            </FormGroup>
            <FormGroup className="ms-md-5">
                <p className="fw-bolder">User Name</p>
                <p>{fetchCustomerData?.customerInfo?.userName ? fetchCustomerData?.customerInfo?.userName :"-"}</p>
            </FormGroup>
            <FormGroup className="ms-md-5">
                <p className="fw-bolder">Account Number</p>
                <p>{fetchCustomerData?.customerInfo?.customerId ? fetchCustomerData?.customerInfo?.customerId : "-"}</p>
            </FormGroup>
            <FormGroup className="ms-md-5">
                <p className="fw-bolder">Phone Number</p>
                <p>{fetchCustomerData?.customerInfo?.dialCode}-{fetchCustomerData?.customerInfo?.mobile}</p>
            </FormGroup>
            <FormGroup className="ms-md-5">
                <p className="fw-bolder">Email Address</p>
                <p>{fetchCustomerData?.customerInfo?.email ? fetchCustomerData?.customerInfo?.email :"-"}</p>
            </FormGroup>
        </Col>
        <Col xxl={2} md={2} >
        <div className="custom-border-lg-end d-block me-5"></div>
        </Col>
        <Col xxl={5} md={5}>
            <FormGroup>
                <p className="fw-bolder">Address 1</p>
                <p>{fetchCustomerData?.customerInfo?.addressLine1 ? fetchCustomerData?.customerInfo?.addressLine1 :"-"}</p>
            </FormGroup>
            <FormGroup>
                <p className="fw-bolder">Address 2</p>
                <p>{fetchCustomerData?.customerInfo?.addressLine2 ? fetchCustomerData?.customerInfo?.addressLine2 :"-"}</p>
            </FormGroup>
            <FormGroup>
                <p className="fw-bolder">Country</p>
                <p>{fetchCustomerData?.customerInfo?.country ? fetchCustomerData?.customerInfo?.country :"-"}</p>
            </FormGroup>
            <FormGroup>
                <p className="fw-bolder">State</p>
                <p>{fetchCustomerData?.customerInfo?.state ? fetchCustomerData?.customerInfo?.state :"-"}</p>
            </FormGroup>
            <FormGroup>
                <p className="fw-bolder">City</p>
                <p>{fetchCustomerData?.customerInfo?.city ? fetchCustomerData?.customerInfo?.city :"-"}</p>
            </FormGroup>
            <FormGroup>
                <p className="fw-bolder">Zipcode</p>
                <p>{fetchCustomerData?.customerInfo?.zipCode ? fetchCustomerData?.customerInfo?.zipCode :"-"}</p>
            </FormGroup>
        </Col>
    </Row>
    
    )
}