import moment from "moment";
import { uploadDocument } from "../../../slices/customer/documentupload/thunk";
import { formatDateForOffer } from "../../Utils/UtilityFunctions";
import { handleStartDateChange } from "./constantFunctions";
import { verifyDocument } from "../../../slices/customer/documentverify/thunk";
import { updateDoc } from "../../../slices/customer/documentupdate/thunk";
import { downloadDoc } from "../../../slices/customer/documentdownload/thunk";
import { viewDoc } from "../../../slices/customer/documentview/thunk";

export const handleChangeForUom = (setSelectedUom, setFieldTouched, setFieldValue) => (uomUpdate) => {
    setSelectedUom(uomUpdate);
    setFieldValue('uom', uomUpdate, false)
    setFieldTouched('uom', false)
}

export const handleChangeForStartDateOfUom = (values, handleStartDateChange, setStartDate, setFieldTouched) => (data) => {
    values.eventStartTime = data
    handleStartDateChange(data, setStartDate);
    setFieldTouched(`eventStartTime`, false)
}

export const handleChangeForEndDateOfUom = (values, setFieldTouched) => (data) => {
    values.eventEndTime = data
    setFieldTouched(`eventEndTime`, false)
}

export const handleChangeForName = (setFieldValue, name) => (e) => {
    let value = e.target.value
    value = value.replace(/[^A-Za-z]/gi, '')
    setFieldValue(`${name}`, value, false)
}
export const handleChangeForImsi = (setFieldValue, name) => (e) => {
    let value = e.target.value
    value = value.replace(/[^0-9]/gi, '')
    setFieldValue(`${name}`, value, false)
}

export const handleChangeForCountry = (setSelectedCountry, setFieldValue, setFieldTouched, setSelectedState, setSelectedCity, setCities) => (countryUpdate) => {
    setSelectedCountry(countryUpdate)
    setFieldValue('country', countryUpdate, false)
    setFieldTouched('country', false)
    setSelectedState('')
    setSelectedCity('')
    setCities([])
    setFieldValue('city', '')
    setFieldValue('state', '')
}

export const handleChangeForState = (setSelectedState, setFieldValue) => (stateUpdate) => {
    setSelectedState(stateUpdate);
    setFieldValue('state', stateUpdate, false)
    setFieldValue('city', '')
}

export const handleChangeForCity = (setSelectedCity, setFieldValue) => (cityUpdate) => {
    setSelectedCity(cityUpdate)
    setFieldValue('city', cityUpdate, false)
}

export const handleChangeForAttributeName = (values, index, setFieldTouched, setFieldValue) => (val) => {
    values.attributeList[index].name = val
    setFieldValue(`attributeList[${index}].name`, val);
    setFieldTouched(`attributeList[${index}].name`, false);
}

export const handleChangeForAttributeValue = (handleChange) => (e) => {
    handleChange(e);
}

export const handleChangeForStartDateOfOffer = (index, setStartDate, startDate, setFieldTouched, setFieldValue) => (data) => {
    handleStartDateChange(data, index, setStartDate, startDate);
    setFieldTouched(`subscriptions[${index}].validFrom`, false)
    setFieldValue(`subscriptions[${index}].validFrom`, data[0], false)
}

export const handleChangeForEndDateOfOffer = (setFieldTouched, index, setFieldValue) => (data) => {
    setFieldTouched(`subscriptions[${index}].validTo`, false)
    setFieldValue(`subscriptions[${index}].validTo`, data[0], false)
}

export const handleChangeForOfferCode = (index, setFieldTouched, setFieldValue) => (offerUpdate) => {
    setFieldValue(`subscriptions[${index}].offerCode`, offerUpdate, false);
    setFieldTouched(`subscriptions[${index}].offerCode`, false);
}

export const formatConsumedBalance = (consumedBalance) => {
    if (consumedBalance === 1.7976931348623157e308) {
        return ''
    } else if (consumedBalance !== undefined) {
        if (consumedBalance >= 100000) {
            return (consumedBalance / 100000).toFixed(2) + 'Lakh Consumed'
        } else {
            return `${consumedBalance.toLocaleString()} Consumed`
        }
    } else {
        return '0 Consumed'
    }
}

export const handleChangeForDocumentType = (setFieldValue, setFieldTouched, setState) => (documentType) => {
    setFieldValue('documentType', documentType, false);
    setFieldTouched('documentType', false);
    setState(true)
}

export const handleChangeForDocumentName = (setState, handleChange) => (e) => {
    handleChange(e)
    setState(true)
}

export const getFile = (setFile, setFileName, handleChange, setState, setValidationTriggered,setFileSizeValid) => (e) => {
    handleChange(e);
    setState(true);
    setValidationTriggered(true);
    const file = e.target.files[0];
    if (file) {
        if ((file.size / (1024 * 1024)) <= 10) {
            setValidationTriggered(false);
            setFileSizeValid(true)
            setFile(file);
            setFileName(file?.name);
        } else {
            setFile(file);
            setFileName(file?.name);
            setFileSizeValid((file.size / (1024 * 1024)) <= 10)
        }
    }
};

export const emptyFieldsOfDocuments = (setFieldValue) => {
    setFieldValue("documentType", "")
    setFieldValue("documentName", "")
    setFieldValue("selectedFile", "")
}

export const toggleModal = (setIsOpen, open) => {
    setIsOpen(!open)
    document.body.style.overflow = 'auto'
}

export const toggleModalForDelete = (row, closeMenu, setIsOpen, setButtonText, setMessageForPopUp) => {
    closeMenu()
    setIsOpen(true)
    setButtonText('Yes,Delete It')
    setMessageForPopUp(`Are you sure you want to Delete this document \'${row.original.documentName}\'?`)
}

export const submitDocument = (file, values, customerId, dispatch, setLoading) => {
    const formData = new FormData()
    formData.append("ID", customerId)
    formData.append("file", file);
    formData.append("documentName", values.documentName);
    formData.append("documentType", values.documentType.value);
    formData.append('uploadedBy', localStorage.getItem('userId'))
    documentUploadApiCall(formData, dispatch, setLoading, customerId)
}

export const setFieldEmpty = (setFieldValue, setState, setFieldTouched) => {
    setFieldValue("documentType", "")
    setFieldValue("documentName", "")
    setFieldValue("selectedFile", "")
    setFieldTouched("selectedFile", false)
    setFieldTouched("documentName", false)
    setFieldTouched("documentType", false)
    setState(false)
}

export const documentUploadApiCall = (formData, dispatch, setLoading, customerId) => {
    dispatch(uploadDocument(formData, setLoading, customerId))
}

export let payload = {}
export const approveDocument = (customerId, documentName, approvePayload) => {
    payload = approvePayload
    payload = {
        "ID": customerId,
        "documentName": documentName,
        "status": "Approved"
    }
    return payload
}

export const deleteDocument = (customerId, documentName) => {
    payload = {
        "ID": customerId,
        "documentName": documentName
    }
    return payload
}

export const editDocument = (customerId, oldDocumentName, newDocumentName, documentType, dispatch, setLoading) => {
    payload = {
        "ID": customerId,
        "documentName": oldDocumentName,
        "newDocumentName": newDocumentName,
        "documentType": documentType.value
    }
    updateDocApiCall(dispatch, setLoading, payload, customerId)
    return payload
}

export const updateDocApiCall = (dispatch, setLoading, jsonObj, customerId) => {
    dispatch(updateDoc(jsonObj, setLoading, customerId))
}

export const rejectDocument = (customerId, documentName, rejectPayload) => {
    payload = rejectPayload
    payload = {
        "ID": customerId,
        "documentName": documentName,
        "status": "Rejected"
    }
    return payload
}

export const formatAmount = (value) => {
    
    if(value === 0){
        return 0;
    }

    if (!value || !value || !Number.isFinite(value)) {
        return '-';
    }

    return Number.isFinite(value) && value % 1 !== 0 
        ? (value).toFixed(2) 
        : value;
};


export const invoiceChangeFunctionForCustomer = (invoiceListData, invoiceArray) => {
    invoiceListData.forEach((value, index) => {
        invoiceArray.push({
            id: value.id,
            date: moment(value.issued_at).format("DD/MM/YYYY"),
            amount: value.currency + formatAmount(value.gross_amount),
            balance: value.currency + formatAmount(value.amount_due),
            notifiedDate: moment(value.issued_at).format("DD/MM/YYYY"),
            status: value.status
        })
    })
    return invoiceArray
}

export const invoiceChangeFunctionForAll = (invoiceListData, invoiceArray) => {
    invoiceListData.forEach((value, index) => {
        invoiceArray.push({
            id: value.id,
            customer: value.customer_details.name,
            email: value.customer_details.email,
            date: moment(value.issued_at).format("DD/MM/YYYY"),
            amount: value.currency + formatAmount(value.gross_amount),
            balance: value.currency + formatAmount(value.amount_due),
            status: value.status
        })
    })
    return invoiceArray
}

export const verifyDocumentApiCall = (dispatch, payload, setLoading, customerId) => {
    dispatch(verifyDocument(payload, setLoading, customerId))
}

export const downloadDocApiCall = (dispatch, setLoading, customerId, documentName) => {
    dispatch(downloadDoc(setLoading, customerId, documentName))
}

export const viewDocApiCall = (dispatch, setLoading, customerId, documentName, setProfileData) => {
    dispatch(viewDoc(setLoading, customerId, documentName, "Window",setProfileData))
}

export const formatNumber = (number) => {
    if (number >= 100000 && number < 1000000) {
        return `${(number / 1000).toFixed(2)}K`;
    } else if (number >= 1000000) {
        return `${(number / 1000000).toFixed(2)}M`;
    } else {
        return `${number}`;
    }
};

export const formatNumberForStatistics = (number) => {
    if (number >= 1000 && number < 100000) {
        return `${(number / 1000).toFixed(3)}K`;
    }
    else if (number >= 100000 && number < 1000000) {
        return `${(number / 1000).toFixed(2)}K`;
    } else if (number >= 1000000) {
        return `${(number / 1000000).toFixed(2)}M`;
    } else {
        return `${number}`;
    }
};