import { MaterialReactTable } from "material-react-table";
import { BillsColumns } from "./Constants/BillsColoumn";
import { Card, Button } from "reactstrap";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import { useDispatch, useSelector } from "react-redux";
import { fetchBillsForPartner } from "../../slices/PartnerMangement/GetPartnerBill/thunk";
import { useEffect,useState } from "react";
export default function Bills() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const PartnerId = customerIdData()
  useEffect(() => {
   dispatch(fetchBillsForPartner(PartnerId, setLoading))
}, [dispatch])
const coloumnData=useSelector(state=>state?.GetBillsForPartner?.getBillsPartner)
const columns = BillsColumns(coloumnData);
  return (
    <Card>
      <div className="d-flex justify-content-end align-items-center mb-2">
        <Button color="secondary" className="px-4" >
          Apply
        </Button>
      </div>
      <MaterialReactTable
        columns={columns}
        data={coloumnData}
        muiTableHeadCellProps={{
          sx: {
            fontFamily: "Inter,sans-serif",
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
        }}
        muiTableBodyCellProps={{
          sx: {
            fontFamily: "Inter,sans-serif",
            border: "none",
          },
        }}
        enableBottomToolbar={false}
        enableSorting={false}
        enableColumnActions={false}
        enablePagination={false}
         enableDensityToggle={false}
      />
    </Card>
  );
}