import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    documentDelete : [],
    loading : false,
    error : null,
};

const fetchDocumentDeleteSlice = createSlice({
    name:"documentDelete",
    initialState,
    reducers: {
        fetchDocumentDeleteSuccess: (state,action) => {
            state.loading = false;
            state.documentDelete = action.payload
        },
        fetchDocumentDeleteFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchDocumentDeleteSuccess,
    fetchDocumentDeleteFailure
} = fetchDocumentDeleteSlice.actions

export default fetchDocumentDeleteSlice.reducer