export const useStateArray = (formData, serviceName, offerName, offerCode, termValidity, validityFrom, validityTo, status, totalChargeAmount, subTotal, unitPrice, CustomerId, CustomerName, orderID) => {
    const formattedSubscriptionStart = formData?.subscriptionStartsOn === 'Invalid date' ? "" : (formData?.subscriptionStartsOn?.toString() || "");
    const formattedSubscriptionEnd = formData?.subscriptionEndsOn === 'Invalid date' ? "" : (formData?.subscriptionEndsOn?.toString() || "");

    return [
        {
            subscriptionID: formData?.subscriptionID,
            serviceName,
            offerName,
            offerCode,
            termValidity,
            validityFrom,
            validityTo,
            totalChargeAmount,
            unitPrice,
            subTotal,
            CustomerId,
            CustomerName,
            subscriptionStartsOn: formattedSubscriptionStart,
            subscriptionEndsOn: formattedSubscriptionEnd,
            value: formData?.value,
            selectedDevice: formData?.selectedDevice,
            status,
            order: orderID
        },
    ];
};
