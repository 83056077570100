import React from 'react';
import { Row, Col, Card, CardBody, CardHeader, FormGroup, FormFeedback } from 'reactstrap';
import { LabelText } from '../Utils/Input';
import InputField from '../Utils/InputField';
import SelectField from '../Utils/SelectField';

const PrimaryContactForm = ({
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    isEdit,
    UserRoleOptions
}) => {
    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody className="card-body">
                        <div className="live-preview">
                            <Row className="gy-2">
                                <CardHeader >
                                    <h4 className="card-title flex-grow-1">Primary Contact</h4>
                                </CardHeader>
                                <Col xxl={3} >
                                    <div>
                                        <FormGroup >
                                            <LabelText htmlFor={"firstName"} className={"text-danger"} displayText={"First Name"} important={"*"} />
                                            <InputField name={"firstName"} placeholder={"Enter your First Name"} type={"text"} className={"form-control"} id={"firstName"} handleChange={handleChange} handleBlur={handleBlur} value={values.firstName}
                                                invalid={touched.firstName && errors.firstName
                                                    ? true
                                                    : false} />
                                            {touched.firstName &&
                                                errors.firstName ? (
                                                <FormFeedback type="invalid">
                                                    {errors.firstName}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col xxl={3} >
                                    <div>
                                        <FormGroup >
                                            <LabelText htmlFor={"lastName"} className={"text-danger"} displayText={"Last Name"} important={"*"} />
                                            <InputField name={"lastName"} placeholder={"Enter your Last Name"} type={"text"} className={"form-control"} id={"lastName"} handleChange={handleChange} handleBlur={handleBlur} value={values.lastName}
                                                invalid={touched.lastName && errors.lastName
                                                    ? true
                                                    : false} />
                                            {touched.lastName &&
                                                errors.lastName ? (
                                                <FormFeedback type="invalid">
                                                    {errors.lastName}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col xxl={3} >
                                    <div>
                                        <FormGroup >
                                            <LabelText htmlFor={"email1"} className={"text-danger"} displayText={"Email Address"} important={"*"} />
                                            <InputField name={"email1"} placeholder={"Enter your Email"} type={"email1"} className={"form-control"} id={"email1"} handleChange={handleChange} handleBlur={handleBlur} value={values.email1}
                                                invalid={touched.email1 && errors.email1
                                                    ? true
                                                    : false} />
                                            {touched.email1 &&
                                                errors.email1 ? (
                                                <FormFeedback type="invalid">
                                                    {errors.email1}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col xxl={3} >
                                    <div>
                                        <FormGroup >
                                            <LabelText htmlFor={"mobile1"} className={"text-danger"} displayText={"Phone Number"} important={"*"} />
                                            <InputField name={"mobile1"} placeholder={"Enter your Phone Number"} type={"number"} className={"form-control"} id={"mobile1"} handleChange={handleChange} handleBlur={handleBlur} value={values.mobile1}
                                                invalid={touched.mobile1 && errors.mobile1
                                                    ? true
                                                    : false} />
                                            {touched.mobile1 &&
                                                errors.mobile1 ? (
                                                <FormFeedback type="invalid">
                                                    {errors.mobile1}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col xxl={3} >
                                    <div>
                                        <FormGroup >
                                            <LabelText htmlFor={"userName"} className={"text-danger"} displayText={"User Name"} important={"*"} />
                                            <InputField name={"userName"} placeholder={"Enter your User Name"} type={"text"} className={"form-control"} id={"UserName"} handleChange={handleChange} handleBlur={handleBlur} value={values.userName}
                                                invalid={touched.userName && errors.userName
                                                    ? true
                                                    : false}
                                                disabled={isEdit} />
                                            {touched.userName &&
                                                errors.userName ? (
                                                <FormFeedback type="invalid">
                                                    {errors.userName}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col xxl={3} >
                                    <div>
                                        <LabelText htmlFor={"choices-single-default"} className={"text-danger"} displayText={"User Role"} important={"*"} />
                                        <SelectField className={(errors.userRole && touched.userRole) || false ? 'error-input' : ''}
                                            value={values.userRole}
                                            placeholder={"Select User Role"}
                                            handleChange={(selectedVal) => setFieldValue("userRole", selectedVal)}
                                            isClearable={true}
                                            isDisabled={isEdit}
                                            options={[
                                                ...UserRoleOptions,
                                            ]} />
                                        {touched.userRole && errors.userRole ? (
                                            <p className="text-danger">
                                                {errors.userRole}
                                            </p>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default PrimaryContactForm;
