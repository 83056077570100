import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { POLICY_API } from "../../constants/constants";
import { fetchGetAllPoliciesFailure, fetchGetAllPoliciesSuccess } from "../reducer";

export const getPoliciesByName = (name, setLoading, setInitialValues) => (dispatch) => {
  setLoading(true);
  determineBaseUrlAndPerformApiCall(`${POLICY_API}/${name}`, 'GET').then((response) => {
    setLoading(false);
    setInitialValues({
        policyLabel: response?.policyLabel,
        resource: { value: response?.resourceName, label: response?.resourceName},
        status: response?.status,
        attributeList: response.rules?.map(rule => ({
          rangeStarts: rule?.rangeStart === 0 ? '0' : rule?.rangeStart,
          rangeEnds: rule?.rangeEnd,
          statusLabel: rule?.statusLabel,
        })),
      });
    dispatch(fetchGetAllPoliciesSuccess(response));
  }).catch((errorResp) => {
    setLoading(false);
    dispatch(fetchGetAllPoliciesFailure(errorResp.message));
  });
};
