import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody, CardHeader, Col, FormGroup, Row, Table } from "reactstrap"
import { LabelText } from "../Utils/Input"
import { useState } from "react"
import { Link } from "react-router-dom"
import CommonDateTimePicker from "../Utils/CommonDateTimePicker"
import moment from "moment"
import InputField from "../Utils/InputField"
import formSchema from "../Utils/schema.json";
import { RenderField } from "../Utils/renderFields";
import { handleRadioButtonChange } from "./constants/SimulateFunctions"

export const OfferFilterAccordion = ({ setSelectedOfferName, setOfferAttributes, immediate, immediateMinus, nextBillCycle, setImmediate, setImmediateMinus, setNextBillCycle, offerCode, open, toggle, loading, filteredResults, navigate, startsOn, setEndsOn, setStartsOn, endsOn, setSelectedOfferCode, customerIdPath, setQuantity, quantity }) => {
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [selectedServiceIndex, setSelectedServiceIndex] = useState(null)
    const [attributes, setAttributes] = useState(false);

    return (
        <Accordion open={open} toggle={toggle}>
            {loading ? (
                <p></p>
            ) : (
                <>
                    {filteredResults?.map(([serviceName, offers], index) => (
                        serviceName && (
                            <AccordionItem key={index}>
                                <AccordionHeader targetId={`${index}`}>
                                    {serviceName}
                                </AccordionHeader>
                                <AccordionBody accordionId={`${index}`}>
                                    {offers.map((offer, offerIndex) => (
                                        serviceName && (
                                            <>
                                                <Row className={`gy-4 mb-2 ms-2`}>
                                                    <Col className="p-3" xxl={4} md={6}>
                                                        <div>
                                                            <div className="d-flex">
                                                                <h5 className="offer-name me-2 text-black">{offer.offerName}</h5>
                                                            </div>
                                                            <div className="d-flex">
                                                                {offer.Grants?.length > 0 && (
                                                                    <div className="badge-for-order me-2 p-1">
                                                                        <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Grants?.length} />
                                                                        <LabelText classLabelName="mb-0" displayText="Grant" />
                                                                    </div>
                                                                )}
                                                                {offer.Charges?.length > 0 && (
                                                                    <div className="badge-for-order me-2 p-1">
                                                                        <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Charges?.length} />
                                                                        <LabelText classLabelName="mb-0" displayText="Charge" />
                                                                    </div>
                                                                )}
                                                                {offer.Usage?.length > 0 && (
                                                                    <div className="badge-for-order me-2 p-1">
                                                                        <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Usage?.length} />
                                                                        <LabelText classLabelName="mb-0" displayText="Usage" />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="p-3" xxl={4} md={6}>
                                                        <h5 className="offer-name offer-code-color">Offer Code : <span className="text-black">{offer.offerCode}</span></h5>
                                                    </Col>
                                                    <Col xxl={3} md={6}>
                                                        <div className="d-flex flex-column justify-content-end align-items-start">
                                                            <div className="d-flex">
                                                                <h5 className="fs-6 pe-2 mt-3 fw-semibold offer-code-color">
                                                                    Term Validity:
                                                                </h5>
                                                                <h5 className="fs-6 mt-3 fw-lighter text-black">{offer.termValidity}</h5>
                                                            </div>
                                                            <div className="d-flex justify-content-center align-items-center mt-3">
                                                                <h5 className="fs-6 pe-2 fw-semibold offer-code-color" >
                                                                    Offer Validity:
                                                                </h5>
                                                                {offer.validityTo !== "Jan 01,0001" ? (
                                                                    <h5 className="fs-6 text-black fw-lighter">
                                                                        {offer.validityFrom} - {offer.validityTo}
                                                                    </h5>
                                                                ) : (
                                                                    <h5 className="fs-6 text-black fw-lighter">Never Expiring</h5>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={1} md={6}>
                                                        {offer.offerCode !== offerCode ? <div>
                                                            <Button
                                                                color="secondary"
                                                                className="mt-3"
                                                                onClick={() => {
                                                                    setSelectedItemIndex(offerIndex);
                                                                    setSelectedServiceIndex(index);
                                                                    setQuantity(1);
                                                                    setAttributes(offer.provisioningAttributes.length > 0);
                                                                    if (customerIdPath === "create-customer") {
                                                                        setStartsOn(null);
                                                                        setEndsOn(null);
                                                                        setSelectedOfferCode(offer.offerCode);
                                                                    }
                                                                    else {
                                                                        setImmediate(true)
                                                                        setImmediateMinus(false)
                                                                        setNextBillCycle(false)
                                                                        setOfferAttributes(offer?.provisioningAttributes)
                                                                        setSelectedOfferCode(offer?.offerCode);
                                                                        setSelectedOfferName(offer?.offerName)
                                                                    }
                                                                }}
                                                            >
                                                                Select
                                                            </Button>
                                                        </div> : <p className="set-current-plan">Current Plan</p>}
                                                    </Col>

                                                </Row>

                                                {(selectedItemIndex === offerIndex && selectedServiceIndex === index) &&
                                                    <>
                                                        {customerIdPath === "create-customer" ?
                                                            <Card>
                                                                <CardHeader>
                                                                    <h4 className="card-title mb-0 flex-grow-1">Subscription</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col xxl={4} md={4} className=" mb-4">
                                                                            <LabelText htmlFor={"startsOn"} displayText={"Starts On"} />
                                                                            <CommonDateTimePicker
                                                                                className={`form-control`}
                                                                                value={startsOn}
                                                                                name={`startsOn`}
                                                                                handleChange={(values) => { setStartsOn(values) }}
                                                                                format="DD/MM/YYYY"
                                                                                minDate={moment()}
                                                                                views={['year', 'month', 'day']}
                                                                            />
                                                                        </Col>
                                                                        <Col xxl={4} md={4} className=" mb-4">
                                                                            <LabelText htmlFor={"endsOn"} displayText={"Ends On"} />
                                                                            <CommonDateTimePicker
                                                                                className={`form-control`}
                                                                                value={endsOn}
                                                                                name={`endsOn`}
                                                                                handleChange={(values) => { setEndsOn(values) }}
                                                                                format="DD/MM/YYYY"
                                                                                minDate={startsOn}
                                                                                views={['year', 'month', 'day']}
                                                                            />
                                                                        </Col>
                                                                        <Col xxl={4} md={4} className="d-flex flex-column align-items-center justify-content-start">
                                                                            <LabelText htmlFor={"Quantity"} displayText={"Quantity"} className="mb-2" /> {/* Label is now above */}
                                                                            <div className="quantity-control d-flex align-items-center">
                                                                                <button
                                                                                    className="btn-circle"
                                                                                    type="button"
                                                                                    onClick={() => setQuantity(prev => Math.max(1, prev - 1))}>
                                                                                    <span>-</span>
                                                                                </button>
                                                                                <input
                                                                                    type="number"
                                                                                    className="quantity-input mx-2"
                                                                                    id="quantity"
                                                                                    value={quantity}
                                                                                    onChange={(e) => {
                                                                                        const value = e.target.value;
                                                                                        if (value === '') {
                                                                                            setQuantity('');
                                                                                        } else {
                                                                                            setQuantity(Math.max(1, Number(value)));
                                                                                        }
                                                                                    }}
                                                                                    min={1}
                                                                                />

                                                                                <button
                                                                                    className="btn-circle"
                                                                                    type="button"
                                                                                    onClick={() => setQuantity(prev => prev + 1)}>
                                                                                    <span>+</span>
                                                                                </button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card> : <>
                                                                <Col xxl={6}>
                                                                    <CardBody className="d-flex">
                                                                        {formSchema.map((section, sectionIndex) => (
                                                                            section.title === "Change Offer" && section.sections.map((subSection, subSectionIndex) => (
                                                                                subSection.fields && subSection.fields.map((field, fieldIndex) => (
                                                                                    <RenderField setRadioValue={() => handleRadioButtonChange(field.label, setImmediate, setImmediateMinus, setNextBillCycle)} radioValue={field.label === "Immediate" ? immediate : field.label === "Immediate Minus Used" ? immediateMinus : nextBillCycle} field={field} />
                                                                                ))))))}
                                                                    </CardBody>
                                                                </Col>
                                                            </>}
                                                        {attributes && <Card>
                                                            <CardHeader className="d-flex justify-content-between align-items-center ">
                                                                <h4 className="card-title mb-0 flex-grow-1">Attributes</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <Table className=' table-border-none'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Name</th>
                                                                            <th>Value</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {offer?.provisioningAttributes.map((attribute, index) => (
                                                                            <tr key={index}>
                                                                                <td>{attribute.name}</td>
                                                                                <td>{attribute.value}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </CardBody>
                                                        </Card>}
                                                    </>
                                                }
                                                <hr />
                                            </>
                                        )
                                    ))}
                                </AccordionBody>

                            </AccordionItem>

                        )
                    ))}
                </>
            )}
        </Accordion>
    )
}