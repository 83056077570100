import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editOperator: [],
    loading: false,
    error: null,
}

const editOperatorSlice = createSlice({
    name: "editOperator",
    initialState,
    reducers: {
        fetchEditOperatorSuccess: (state, action) => {
            state.loading = false,
            state.editOperator = action.payload;
        },
        fetchEditOperatorFailure: (state, action) => {
            state.loading = false,
            state.error = action.payload;
        },
    },
})

export const {
    fetchEditOperatorSuccess,
    fetchEditOperatorFailure,
} = editOperatorSlice.actions;

export default editOperatorSlice.reducer;