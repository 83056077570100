import React, { useEffect, useState } from 'react';
import {
    Card, CardBody, Col, Row, FormGroup, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import formSchema from '../Utils/schema.json';
import { RenderField } from '../Utils/renderFields';
import { RenderErrorMessage } from '../Utils/renderErrorMessages';
import { LabelText } from '../Utils/Input';
import 'react-phone-number-input/style.css';
import { tabChange } from "../Utils/SetDefaultRowData";
import CommonButton from '../Utils/CommonButton';
import { useSelector } from 'react-redux';
import { countryOptionss, OperatorOptionsFields, OperatorStatusOptions } from '../OperatorManagement/Constant/Options';
import { fetchCountries } from '../../slices/customer/countries/thunk';
import { fetchStates } from '../../slices/customer/states/thunk';
import { fetchCities } from '../../slices/customer/cities/thunk';

export default function OperatorProviderDetail({
    values, handleChange, handleBlur, isEdit, errors, touched, setFieldTouched, setFieldValue, operatorData, navigate, dispatch, setLoading, profile, setChangePasswordStatus
}) {
    const [activeTab, setActiveTab] = useState('1');
    const [stateData, setStatedata] = useState();
    const [cityData, setCityData] = useState()
    const [selectedCountry, setSelectedCountry] = useState()
    const [selectedState, setSelectedState] = useState()
    const [callState, setCallState] = useState(false)
    const [callCity, setCallCity] = useState(false)

    useEffect(() => {
        dispatch(fetchCountries(setLoading, setCallState))
    }, [dispatch]);
    const countries = useSelector(state => state.Countries.countries)
    const countryOptions = countryOptionss(countries?.countries)

    useEffect(() => {
        if (callState) {
            dispatch(fetchStates(selectedCountry, setCallCity, "isOperator", setStatedata))
        }
    }, [dispatch, isEdit, selectedCountry]);

    useEffect(() => {
        if (callCity) {
            dispatch(fetchCities(selectedCountry, selectedState, "isOperator", setCityData))
        }
    }, [dispatch, selectedCountry, selectedState]);

    const operatorOptions = [
        {
            name: "Domain",
            value: "Operator",
            options: OperatorOptionsFields
        },
        {
            name: "country",
            value: "Operator",
            options: countryOptions
        },
        {
            name: "Status",
            value: "Operator",
            options: OperatorStatusOptions
        }
    ]
    
    return (
        <div className="live-preview">
            <Card>
                <CardHeader className="p-3">
                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "1" })}
                                onClick={() => tabChange("1", activeTab, setActiveTab)}
                            >
                                <i className="fas fa-home"></i>
                                Details
                            </NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody>
                    <TabContent activeTab={activeTab}>
                        {!isEdit ?
                            <TabPane tabId="1">
                                <Row className='gy-4'>
                                    <Col xs={12} md={12} lg={12}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">Business Name</p>
                                            <p>{operatorData?.tradingName}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">Domain</p>
                                            <p>{operatorData?.domain}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">End Date </p>
                                            <p>{operatorData?.existsDuring?.endDateTime}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">Address</p>
                                            <p>{operatorData?.street1} </p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">Address 2</p>
                                            <p>{operatorData?.street2}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">Country</p>
                                            <p>{operatorData?.country || ''}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">State</p>
                                            <p>{operatorData?.state}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">City</p>
                                            <p>{operatorData?.city}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <FormGroup className="ms-md-5">
                                            <p className=" fw-bolder">Zip Code</p>
                                            <p>{operatorData?.postcode}</p>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </TabPane> :
                            <TabPane tabId="1" >
                                {formSchema.map((section, sectionIndex) => (
                                    <>
                                        {section.title === "Operator" && section.sections &&
                                            section.sections.map((subSection, subSectionIndex) => (
                                                subSection.sectionTitle === "Business Profile" && (
                                                    <Card key={subSectionIndex} className='mb-4'>
                                                        <CardBody>
                                                            <Row className="gy-4" key={sectionIndex}>
                                                                {subSection.fields.filter(field => {
                                                                    if (field.name === "country" && stateData) {
                                                                        operatorOptions?.push(stateData)
                                                                    }
                                                                    if (field.name === "state" && cityData) {
                                                                        operatorOptions?.push(cityData)
                                                                    }
                                                                    if (isEdit && (field.name === "Domain" || field.name === "username" || field.name === "email")) {
                                                                        field.disable = true
                                                                    } else {
                                                                        field.disable = false
                                                                    }
                                                                    if (field.name === "Status") {
                                                                        return false
                                                                    }
                                                                    return true;
                                                                }).map((field, fieldIndex) => (
                                                                    <Col
                                                                        xxl={field.name === "businessName" ? 12 : field.name === "Domain" ? 6 : field.name === "EndDate" ? 6 : field.name === "Address" ? 6 : field.name === "Address2" ? 6 : field.name === "Address"}
                                                                        md={field.name === "businessName" ? 3 : 6}
                                                                        lg={field.name === "businessName" ? 3 : 6}
                                                                        key={`${subSectionIndex}-${fieldIndex}`}
                                                                    >
                                                                        <LabelText
                                                                            htmlFor={field.name}
                                                                            className={field.required ? "text-danger" : ""}
                                                                            displayText={field.label}
                                                                            important={field.required ? "*" : ""}
                                                                        />
                                                                        <>
                                                                            <RenderField
                                                                                field={field}
                                                                                handleChange={handleChange}
                                                                                handleBlur={handleBlur}
                                                                                values={values}
                                                                                touched={touched}
                                                                                errors={errors}
                                                                                setFieldTouched={setFieldTouched}
                                                                                setFieldValue={setFieldValue}
                                                                                options={operatorOptions}
                                                                                setSelectedCountry={setSelectedCountry}
                                                                                setSelectedState={setSelectedState}
                                                                            />
                                                                            <RenderErrorMessage
                                                                                field={field}
                                                                                touched={touched}
                                                                                errors={errors}
                                                                            />
                                                                        </>

                                                                    </Col>
                                                                ))}
                                                                {subSection?.sectionTitle === "Business Profile" && subSection.fieldsOne.filter(field => {
                                                                    if (field.name === "country" && stateData) {
                                                                        operatorOptions?.push(stateData)
                                                                    }
                                                                    if (field.name === "state" && cityData) {
                                                                        operatorOptions?.push(cityData)
                                                                    }
                                                                    if (isEdit && (field.name === "Domain" || field.name === "username" || field.name === "email")) {
                                                                        field.disable = true
                                                                    }
                                                                    else {
                                                                        field.disable = false
                                                                    }
                                                                    if (field.name === "Status") {
                                                                        return false
                                                                    }
                                                                    return true
                                                                }).map((field, fieldIndex) => (
                                                                    <Col
                                                                        xxl={6}
                                                                        md={6}
                                                                        lg={6}
                                                                        key={`${subSectionIndex}-one-${fieldIndex}`}
                                                                    >
                                                                        <LabelText
                                                                            htmlFor={field.name}
                                                                            className={field.required ? "text-danger" : ""}
                                                                            displayText={field.label}
                                                                            important={field.required ? "*" : ""}
                                                                        />
                                                                        <RenderField
                                                                            field={field}
                                                                            handleChange={handleChange}
                                                                            handleBlur={handleBlur}
                                                                            values={values}
                                                                            touched={touched}
                                                                            errors={errors}
                                                                            setFieldTouched={setFieldTouched}
                                                                            setFieldValue={setFieldValue}
                                                                            options={operatorOptions}
                                                                            setSelectedCountry={setSelectedCountry}
                                                                            setSelectedState={setSelectedState}
                                                                        />
                                                                        <RenderErrorMessage
                                                                            field={field}
                                                                            touched={touched}
                                                                            errors={errors}
                                                                        />
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                )
                                            ))
                                        }
                                    </>
                                ))}
                            </TabPane>}
                    </TabContent>
                </CardBody>
            </Card>
            {isEdit && activeTab === "1" && (
                <Col lg={12} className="d-flex justify-content-end mt-3">
                    <CommonButton outline={true} className={"cancel-button-background me-3"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/dashboard')} buttonText={"Cancel"} />
                    <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} />
                </Col>
            )}
        </div>
    );
}