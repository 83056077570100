import { Card, CardBody } from "reactstrap"

export const SelectOrderDetails = ({selectedOffer}) => {
    return (
        <Card>
            <CardBody className='d-flex flex-lg-row flex-column justify-content-between align-items-start align-lg-center'>
                <h5 className='d-inline '>{selectedOffer?.offerName}</h5>
                <h5 className='d-inline'>Service Name : {selectedOffer?.serviceName}</h5>
            </CardBody>
            <CardBody className='d-flex flex-lg-row flex-column justify-content-between align-items-start align-lg-center' style={{ marginTop: '-10px' }}>
                <h6 className='d-inline '>Offer Code : {selectedOffer?.offerCode}</h6>
                <h6 className='d-inline'>Time Period : {selectedOffer?.termValidity}</h6>
                <h6 className='d-inline'>Offer Validity : {selectedOffer?.validityFrom} - {selectedOffer?.validityTo}</h6>
            </CardBody>
            <CardBody className='d-flex flex-lg-row flex-column align-items-start align-lg-center' style={{ marginTop: '-10px' }}>
                <h6 className='d-inline '>Description</h6>
            </CardBody>
            <CardBody className='d-flex flex-lg-row flex-column align-items-start align-lg-center' style={{ marginTop: '-35px' }}>
                <h6 className='d-inline'>{selectedOffer?.description}</h6>
            </CardBody>
        </Card>
    )
}