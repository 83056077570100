import { toast } from "react-toastify";
import { fetchEditOperatorSuccess, fetchEditOperatorFailure } from "./reducer";
import { setEditSuccessMessage } from "../../toastMessage/action";
import { OPERATOR_CREATE_AND_EDIT_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { documentUpdateFile } from "../../customer/documentupdatefile/thunk";
import { documentUpdateJson, uploadDocApiCall } from "../../../Components/OperatorManagement/Constant/OperatorListingJson";
import { useState } from "react";
import { fetchOperatorbyId } from "../GetOperatorbyID/thunk";

export const editOperator = (jsonObj, operatorId, setLoading, navigate, profile, update, setUpdate, fileSelected, logoFile, editData, setFileSelected,setEditData) => async (dispatch) => {
    setLoading(true)
    const initialValues = {};
    const operatorOptions=[];
    const OperatorStatusOptions=[];
    const roleName=localStorage.getItem('roleName')
    await determineBaseUrlAndPerformApiCall(`${OPERATOR_CREATE_AND_EDIT_API}/${operatorId}`, 'PATCH', jsonObj).then((response) => {
        if (response.error) {
            dispatch(fetchEditOperatorFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
        if (!response.error) {
            setLoading(false)
            dispatch(fetchEditOperatorSuccess(response))
            if (logoFile) {
                if (profile && update) {
                    setUpdate(false)
                }
                if (fileSelected && logoFile !== null) {
                    if (profile && update) {
                        const jsonObj = documentUpdateJson(logoFile, operatorId, editData)
                        let partnerOrCustomer = "Operator";
                        dispatch(documentUpdateFile(setLoading, jsonObj, operatorId, editData?.tradingName, partnerOrCustomer, setUpdate, setFileSelected, navigate));
                    }
                    else {
                        uploadDocApiCall(logoFile, operatorId, editData, setLoading, setFileSelected, dispatch, navigate)
                    }
                }
                else{
                    navigate('/operator-listing');
                    dispatch(setEditSuccessMessage(true))
                }
            }
            else if (roleName === "systemUser") {
                navigate('/operator-listing');
                dispatch(setEditSuccessMessage(true))
            }
            else if(roleName !== "systemUser"){
                dispatch(fetchOperatorbyId(setLoading, operatorId, initialValues, operatorOptions, OperatorStatusOptions,setEditData));
                navigate('/dashboard');
                dispatch(setEditSuccessMessage(true))
            }
        }
    }).catch((errorResp) => { })
};