import { Button, CardBody, Col, FormFeedback, Row } from "reactstrap"
import InputField from "../Utils/InputField"
import { handleClearSearch, handleInputChange, handleSearch, navigateToCreateCustomer } from "./constants/ListingCustomerFunctions"
import { handleInputSearchFieldChange } from "./constants/TelcoSubmitFunctions"
import { isAnyFieldFilled } from "./constants/SimulateFunctions"

export const TelcoHeaderForCustomer = ({custId, msisdn, imsi, accountNumber, errors, dispatch, setLoading, setGetTable, setMsisdn, setImsi, setAccountNumber, customerPermission, navigate, setErrors, setErrorMessage, setErrorIcon, integerAndTextAttributes, searchFields, setSearchFields,setRenderSimulate,stationPermission,reservationPermission, searchCustomerData,setCustId,searchExpanding,setSearchExpanding }) => {
    const anySearchFieldFilled = () => {
        return isAnyFieldFilled(searchFields) || msisdn || imsi;
    };
    
    return (
        <CardBody>
            <div className="row d-flex justify-content-end mb-2">
                <div className="col-12 col-md-auto mb-2">
                    {customerPermission && customerPermission.permissionList.includes('create') &&
                        <Button
                            color="secondary"
                            className="btn-label me-2 w-100"
                            onClick={() => navigateToCreateCustomer(navigate)}
                        >
                            <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>
                            New Customer
                        </Button>
                    }
                </div>
                
            </div>
            <Row className="mb-3">
                <Col lg={12}>
                    <Row className="gy-4">
                        {integerAndTextAttributes.map((value, index) => (
                            <Col lg={4} xxl={4} key={index} >
                                <InputField
                                    placeholder={`Enter ${value?.name}`}
                                    value={searchFields[index]?.value}
                                    maxLength={value?.maxValue}
                                    minLength={value?.minValue}
                                    handleChange={(e) => handleInputSearchFieldChange(value.name, e.target.value, setSearchFields)}
                                />
                            </Col>
                        ))}
                        <Col lg={4} xxl={4}>
                            <InputField
                                placeholder="Enter Account Number"
                                value={accountNumber}
                                handleChange={(e) => handleInputChange('accountNumber', e.target.value, setErrors, setMsisdn, setImsi, setAccountNumber)}
                                className={`form-control ${errors.accountNumber ? 'is-invalid' : ''}`}
                            />
                            {errors.accountNumber && <FormFeedback>{errors.accountNumber}</FormFeedback>}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="row d-flex justify-content-end mb-2">
            <div className="d-flex me-md-2 col-12 col-md-auto mb-2">
                    <div className="me-md-2">
                        <Button
                            onClick={() => {
                                handleSearch(dispatch, setLoading, msisdn, imsi, accountNumber, setGetTable, setErrors, setErrorMessage, setErrorIcon,searchFields,setRenderSimulate,reservationPermission,stationPermission,customerPermission, searchCustomerData,custId,setCustId,setSearchExpanding)
                            }}
                            disabled={!accountNumber && !anySearchFieldFilled()}
                            color='main-color'
                            className="btn w-100"
                        >
                            Search
                        </Button>
                    </div>
                    <div>
                        <Button
                            onClick={() => handleClearSearch(setMsisdn, setImsi, setAccountNumber, dispatch, setLoading, setGetTable, setErrors, setErrorMessage, setErrorIcon,searchFields,setSearchExpanding,setSearchFields)}
                            outline={true} className={"cancel-button-background btn w-100"} color={"main-color"}
                            style={{ flex: 'none' }}
                        >
                            Clear
                        </Button>
                    </div>
                </div>
                </div>
        </CardBody>
    )
}