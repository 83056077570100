import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ResetPasswordCreate: [],
    loading: false,
    error: null,
}

const ResetPasswordCreateSlice = createSlice({
    name: "ResetPasswordCreate",
    initialState,
    reducers: {
        fetchResetPasswordCreateSucess: (state, action) => {
            state.loading = false
            state.ResetPasswordCreate = action.payload;
        },
        fetchResetPasswordCreateFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchResetPasswordCreateSucess,
    fetchResetPasswordCreateFailure,
} = ResetPasswordCreateSlice.actions;

export default ResetPasswordCreateSlice.reducer;