import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { STATISTICS_OF_TASKS } from "../../constants/constants";
import { fetchGetTaskStatisticsFailure, fetchGetTaskStatisticsSuccess } from "./reducer";

export const fetchStatisticsOfTasks = (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${STATISTICS_OF_TASKS}`,'GET')
        setLoading(false)
        dispatch(fetchGetTaskStatisticsSuccess(response))
    }
    catch (error) {
        setLoading(false)
        dispatch(fetchGetTaskStatisticsFailure(error.message))
    }
}