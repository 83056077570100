import { fetchPolicyListFailure, fetchPolicyListSuccess } from "./reducer"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { POLICY_LIST_API } from "../../constants/constants";

export const getAllPolicies = (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${POLICY_LIST_API}`, 'GET')
        setLoading(false)
        dispatch(fetchPolicyListSuccess(response.reverse()));
    } catch (error) {
        dispatch(fetchPolicyListFailure(error.message));
    }
};

