import { countryOptionss } from "../../../Components/OperatorManagement/Constant/Options";
import { COUNTRIES_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchStatesSuccess, fetchStatesFailure } from "./reducer";

export const fetchStates = (selectedCountry, setCallCity, isOperator, setStatedata) => async (dispatch) => {
    try {
        await determineBaseUrlAndPerformApiCall(`${COUNTRIES_API}?country=${selectedCountry?.label}`, 'GET').then((response) => {

            if (isOperator === "isOperator") {
                let stateOptions = countryOptionss(response?.states)
                const extraObject = {
                    name: "state",
                    value: "Operator",
                    options: stateOptions
                };
                setStatedata(extraObject)
            }
            dispatch(fetchStatesSuccess(response));
            setCallCity(true)
        })

    } catch (error) { dispatch(fetchStatesFailure(error.message)) }
}