export const InitialValues = {
    stationID: '',
    name: '',
    serialNumber: '',
    model: '',
    address: '',
    address_line1: '',
    address_line2: '',
    vendorName: '',
    firmwareVersion: '',
    modem: {
      iccid: '',
      imsi: '',
    },
    timeZone: '',
    coordinates: {
      latitude: '',
      longitude: '',
    },
    adminStatus: '',
  };

