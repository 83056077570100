import { fetchEditPartnerSuccess, fetchEditPartnerFailure } from "./reducer"
import { toast } from "react-toastify"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { EDIT_PARTNER_API } from "../../constants/constants";
import { setEditSuccessMessage } from "../../toastMessage/action";
import { documentUpdateFile } from "../../customer/documentupdatefile/thunk";
import { uploadDocApiCall, uploadDocApiCallForPartner } from "../../../Components/OperatorManagement/Constant/OperatorListingJson";
import { documentUpdateJsonForPartner } from "../../../Components/UserManagement/Constant/JsonConstant";

export const editPartner = (jsonObj, setLoading, navigate, PartnerId,logoFile,profile,update,setUpdate,fileSelected,editData,setFileSelected) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${EDIT_PARTNER_API}/${PartnerId}`, 'PATCH', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchEditPartnerSuccess(response))
            if (logoFile) {
                if (profile && update) {
                    setUpdate(false)
                }
                if (fileSelected && logoFile !== null) {
                    if (profile && update) {         
                        const jsonObj = documentUpdateJsonForPartner(logoFile, PartnerId, response?.businessName)
                        let partnerOrCustomer = "Operator";
                        dispatch(documentUpdateFile(setLoading, jsonObj, PartnerId, response?.businessName, partnerOrCustomer, setUpdate, setFileSelected, navigate));
                    }
                    else {
                        uploadDocApiCallForPartner(logoFile, PartnerId, response?.businessName, setLoading, setFileSelected, dispatch, navigate)
                    }
                }
                else {
                    navigate('/partner-listing');
                    dispatch(setEditSuccessMessage(true))
                }
            }
            else {
                navigate('/partner-listing');
                dispatch(setEditSuccessMessage(true))
            }
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchEditPartnerFailure(response.error.message))
        }
    }).catch((error) => { 
        // setLoading(false);
        // toast.error("An error occurred. Please try again later.");
    })
}