import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { INVOICE_FOR_CUSTOMER_AND_LISTING_API } from "../../constants/constants"
import { fetchInvoiceListSuccess,fetchInvoiceListFailure } from "./reducer"

export const fetchAllInvoices = (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${INVOICE_FOR_CUSTOMER_AND_LISTING_API}`,'GET')
        setLoading(false)
        dispatch(fetchInvoiceListSuccess(response.reverse()))
    }
    catch (error) {dispatch(fetchInvoiceListFailure(error.message)) }
}