import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    customerEvents : [],
    loading : false,
    error : null,
};

const customerEventsSlice = createSlice({
    name:"customerEvents",
    initialState,
    reducers: {
        fetchCustomerEventsSuccess: (state,action) => {
            state.loading = false;
            state.customerEvents = action.payload
        },
        fetchCustomerEventsFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchCustomerEventsFailure,
    fetchCustomerEventsSuccess
} = customerEventsSlice.actions

export default customerEventsSlice.reducer