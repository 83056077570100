import { GET_BILLS_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchGetBillsPartnerFailure, fetchGetBillsPartnerSuccess } from "./reducer";


export const fetchBillsForPartner = (partnerId,setLoading) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${GET_BILLS_API}/${partnerId}`,'GET').then((response)=>{
        setLoading(false)
        dispatch(fetchGetBillsPartnerSuccess(response))
    }).catch((error)=>{
        setLoading(false)
        dispatch(fetchGetBillsPartnerFailure(error.message))
    })
}