import React, { useEffect, useState, useMemo } from 'react'
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import BreadCrumb from '../Common/BreadCrumb';
import { Row, Col, Button } from 'reactstrap'
import { MaterialReactTable } from 'material-react-table';
import { ToastContainer, toast } from 'react-toastify';
import { MenuItem } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getPayment } from '../../slices/payment/thunk';
import { PaymentColumns } from './Constant/LightWeightColoumn';
import { PAGE_TITLES } from '../Common/constants';
function LightWeightPayment() {
    const [loading, setLoading] = useState("false");
    const dispatch = useDispatch();
     const customerId = localStorage.getItem("customerId");
    useEffect(() => {
        dispatch(getPayment(customerId, setLoading));
    }, [])
    const PaymentDataById = useSelector((state) => state?.GetPaymentForCustomer?.getPayment)
    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb  pageTitle={PAGE_TITLES.PAYMENT}title={PAGE_TITLES.PAYMENT_HISTORY}  />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <div className="listingjs-table " id="customerList">
                                <Card>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {PaymentDataById ?
                                                <MaterialReactTable
                                                    icons={{
                                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                    }}
                                                    muiTableHeadCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    muiTableBodyCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    columns={PaymentColumns}
                                                    data={PaymentDataById}
                                                    enableColumnOrdering
                                                    enableColumnDragging
                                                    enableDensityToggle={false}
                                                    enableTopToolbar={false}
                                                    enableRowActions={true}
                                                    displayColumnDefOptions={{
                                                        'mrt-row-actions': {
                                                            header: '',
                                                            enableHiding: true
                                                        }
                                                    }}
                                                    renderRowActionMenuItems={({ closeMenu, row }) => {

                                                        return [
                                                            <MenuItem
                                                                className='row-options'
                                                                key={row.index}
                                                                onClick={() => {
                                                                    closeMenu();
                                                                }}
                                                            >
                                                                < i className='ri-account-circle-line me-2' />
                                                                View
                                                            </MenuItem>,
                                                        ]
                                                    }}
                                                />
                                                : null
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default LightWeightPayment;