import { Box } from '@mui/material';
import { getCellStyle } from './DecideColor';

export const RatePlanOrAttributesColumn = ({ charge, chargeCount, grant, grantsCount, usage, usageCount, offerOrPolicy, provisioning, serviceUsage }) => {
    return (
        <Box sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1px',
        })}>
            {charge && (
                <span style={{
                    borderRadius: '0.45rem',
                    fontSize: '12px',
                    ...getCellStyle(charge, offerOrPolicy ? "" : "Offer"),
                    padding: '5px',
                    marginRight: 'auto', 
                }}>
                    {offerOrPolicy ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="mb-0 me-1 ps-1 badge-for-offer-count ">{chargeCount}</div>
                            <div className="fw-semibold">{charge}</div>
                        </div>
                    ) : charge}
                </span>
            )}

            {usage && (
                <span style={{
                    borderRadius: '0.45rem',
                    fontSize: '12px',
                    ...getCellStyle(usage, offerOrPolicy ? "" : "Offer"),
                    padding: offerOrPolicy ? '4px' : '5px',
                }}>
                    {offerOrPolicy ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="mb-0 me-1 ps-1 badge-for-offer-count ">{usageCount}</div>
                            <div className="fw-semibold">{usage}</div>
                        </div>
                    ) : usage}
                </span>
            )}

            {grant && (
                <span className={offerOrPolicy ? "badge-for-order" : ""} style={{
                    borderRadius: '0.45rem',
                    fontSize: '12px',
                    color:'#fff',
                    ...getCellStyle(grant, offerOrPolicy ? "" : "Offer"),
                    padding: offerOrPolicy ? '4px' : '5px',
                    marginLeft: 'auto', 
                }}>
                    {offerOrPolicy ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="mb-0 me-1 ps-1 badge-for-offer-count ">{grantsCount}</div>
                            <div className="fw-semibold">{grant}</div>
                        </div>
                    ) : grant}
                </span>
            )}

            {provisioning && (
                <span style={{
                    ...getCellStyle(provisioning, "Service"),
                    backgroundColor: '#fff',
                    borderRadius: '0.45rem',
                    fontSize: '12px',
                    padding: '5px',
                    marginRight: 'auto', 
                }}>
                    {provisioning}
                </span>
            )}

            {serviceUsage && (
                <span style={{
                    ...getCellStyle(serviceUsage, "Service"),
                    backgroundColor: '#fff',
                    borderRadius: '0.45rem',
                    fontSize: '12px',
                    padding: '5px',
                    marginLeft: 'auto'
                }}>
                    {serviceUsage}
                </span>
            )}
        </Box>
    )
}
