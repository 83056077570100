import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    partnerViewList : [],
    loading : false,
    error : null,
};

const partnerViewListSlice = createSlice({
    name:"partnerViewList",
    initialState,
    reducers: {
        fetchPartnerViewListSuccess: (state,action) => {
            state.loading = false;
            state.partnerViewList = action.payload;
        },
        fetchPartnerViewListFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchPartnerViewListSuccess,
    fetchPartnerViewListFailure
} = partnerViewListSlice.actions

export default partnerViewListSlice.reducer