import React, { useEffect, useState } from "react";
import { validationSchema } from "./constants/ValidationSchema";
import { Formik } from "formik";
import { Col, Container, Form, Row } from "reactstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../Utils/CommonButton";
import { onSubmit } from "./constants/AttributeJsonObj";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../Utils/Loader";
import { customerIdData } from "../../Customers/constants/constantFunctionsView";
import { getAttributeByName } from "../../../slices/attribute/getattributebyname/thunk";
import AttributeDetails from "./AttributeDetails";

export default function CreateEditAttribute() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState()
    const [checked,setIsChecked] = useState()
    const [mandatoryChecked,setMandatoryIsChecked] = useState()
    const [mandatoryCheckedSelfcare,setMandatoryIsCheckedSelfcare] = useState()
    const isEdit = location.pathname.includes('update-attribute')
    const attribute = customerIdData()
    const [state,setState] = useState()
    const [formValues, setFormValues] = useState({
        name: "",
        type: "",
        valueType: "",
        description: "",
        attributeValue: "",
        defaultValue: "",
        minimumValue: "",
        maximumValue: "",
        SelfcareAttribute: ""
    });

    const initialValues = {
        name: "",
        type: "",
        valueType: "",
        description: "",
        attributeValue:"",
        defaultValue: "",
        minimumValue: "",
        maximumValue: "",
        SelfcareAttribute: ""
    }

    useEffect(() => {
        if (isEdit) {
            dispatch(getAttributeByName(attribute, setLoading, initialValues))
        }
    }, [dispatch])
    const getAttributeDataByName = useSelector((state)=>state.GetAttributeByName.getAttribute)

    useEffect(()=>{
        if(isEdit){
            if(getAttributeDataByName.searchable === true){
                setIsChecked(true)
            }
            else{
                setIsChecked(false)
            }
            if(getAttributeDataByName.mandatory === true){
                setMandatoryIsChecked(true)
            }
            if(getAttributeDataByName.selfcareVisible === true){
                setMandatoryIsCheckedSelfcare(true)
            }
            else{
                setMandatoryIsChecked(false)
            }
        }
    },[getAttributeDataByName,isEdit])

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
            <Formik
                    validationSchema={validationSchema(state,formValues)}
                    initialValues={initialValues}
                    onSubmit={onSubmit(dispatch, setLoading, navigate, isEdit, attribute,checked,mandatoryChecked,mandatoryCheckedSelfcare)}
                >
                    {({ handleChange, handleBlur, handleSubmit, errors, touched, values, setFieldValue, setFieldTouched, setFieldError }) => {
                        useEffect(() => {
                            setFormValues(values);
                        }, [values]); 
                        return (
                            <Form className='needs-validation' onSubmit={handleSubmit}>
                            <Container fluid>
                                <BreadCrumb title={isEdit ? 'Update Attribute' : 'Add Attribute'} pageTitle='Attributes' />
                                <ToastContainer position='top-center' />
                                <Row>
                                    {loading && (<Loader />)}
                                    <Col lg={12}>
                                        <div className='listingjs-table ' id='attributeList'>
                                            <AttributeDetails setState={setState} setMandatoryIsChecked={setMandatoryIsChecked} setMandatoryIsCheckedSelfcare={setMandatoryIsCheckedSelfcare} mandatoryCheckedSelfcare={mandatoryCheckedSelfcare}  mandatoryChecked={mandatoryChecked} checked={checked} setIsChecked={setIsChecked} values={values} errors={errors} touched={touched} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} handleBlur={handleBlur} handleChange={handleChange} setFieldError={setFieldError} />
                                            <Row lg={6} className=' justify-content-end mb-4'>
                                                <div className='live-preview'>
                                                    <div className='d-flex justify-content-end gap-2 '>
                                                        <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/attribute')} buttonText={"Cancel"} />
                                                        <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} />
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                        )
                    }}
                </Formik>
            </div>
        </React.Fragment>
    )
}