import { MaterialReactTable } from "material-react-table";
import { Button, Card, CardBody, Row } from "reactstrap";
import { MenuItem } from "@mui/material";
import { handleAddToOrder, handleSaveOrder, removeSubscription } from "./constants/HandleReasonChange";

export const TableDataForViewCancel = ({isCancel,isView,showSaveButton,OrderID,orderID,allOrderData,dispatch,setLoading,navigate,setDisplayButton,state,setCreateOrderButton,CustomerId,stateArray,viewColumns,columns,offerDataOfOrder,setTaxAmount,setTotalAmountWithTax,setOrderID}) => {
    return (
        <Card>
        <CardBody>
            {(!isView && !isCancel) && <div className="d-flex justify-content-end align-items-center mb-2">
                {!showSaveButton && (OrderID !== "" || orderID !== "") && (
                    <Button className="btn-label me-2 bg-secondary px-5" onClick={()=>handleSaveOrder(allOrderData, dispatch, setLoading, OrderID, navigate, "Create", setDisplayButton, state, setCreateOrderButton,setTaxAmount,setTotalAmountWithTax,setOrderID)}>
                        Save
                    </Button>
                )}
                <Button className="btn-label me-2 bg-secondary px-5" onClick={()=>handleAddToOrder( stateArray, setCreateOrderButton, navigate, CustomerId, orderID)}>
                    <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> ADD
                </Button>
            </div>}
            <Row className="mb-3 mt-1 table-responsive">
                {allOrderData ? (
                    <MaterialReactTable
                        columns={(isView || isCancel) ? viewColumns : columns}
                        data={(isView || isCancel) ? offerDataOfOrder : allOrderData}
                        enableColumnOrdering
                        enableColumnDragging
                        enableDensityToggle={false}
                        muiTableHeadCellProps={{
                            sx: {
                                fontFamily: "Inter,sans-serif"
                            }
                        }}
                        muiTableBodyCellProps={{
                            sx: {
                                fontFamily: "Inter,sans-serif"
                            }
                        }}
                        enableTopToolbar={false}
                        enableRowActions={(isView || isCancel) ? false : true}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: '',
                                enableHiding: true
                            }
                        }}
                        renderRowActionMenuItems={({ closeMenu, row }) => {
                            if (!isView) {
                                return [
                                    <MenuItem
                                        className='row-options'
                                        key={row.index}
                                        onClick={() => {
                                            removeSubscription(row.original.subscriptionID,dispatch)
                                            closeMenu();
                                        }}
                                    >
                                        <i className="ri ri-delete-bin-5-line me-2 text-danger" />
                                        Delete
                                    </MenuItem>,
                                ]
                            }
                        }}
                    />
                ) : null}
            </Row>
        </CardBody>
    </Card>
    )
}