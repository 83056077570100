import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    OperatorDefaultConfiguration : [],
    loading : false,
    error : null,
};

const OperatorDefaultConfigurationSlice = createSlice({
    name:"OperatorDefaultConfiguration",
    initialState,
    reducers: {
        fetchOperatorDefaultConfigurationSuccess: (state,action) => {
            state.loading = false;
            state.OperatorDefaultConfiguration = action.payload;
        },
        fetchOperatorDefaultConfigurationFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchOperatorDefaultConfigurationSuccess,
    fetchOperatorDefaultConfigurationFailure
} = OperatorDefaultConfigurationSlice.actions

export default OperatorDefaultConfigurationSlice.reducer