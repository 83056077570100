import {  fetchDeleteUserFailure, fetchDeleteUserSuccess, fetchResendLinkFailure, fetchResendLinkSuccess } from "./reducer"
import { toast } from "react-toastify";
import { RESEND_PASSWORD_LINK } from "../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";

export const resendLink = (setLoading,userId) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${RESEND_PASSWORD_LINK}/${userId}`, 'POST').then((response) => {
        if (!response.error) {
            dispatch(fetchResendLinkSuccess(response))
            setLoading(false)
            toast.success(`User registration link has been resent successfully.`)
        }
        if (response.error) {
            dispatch(fetchResendLinkFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}