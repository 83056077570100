import {  fetchUomByIdSuccess, fetchUomByIdFailure } from "./reducer"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { GET_UOM_BY__ID_API } from '../../constants/constants'
import { typevalue } from "../../../Components/Pricings/Resources/constants/Initialvalues";

export const getUomById = (setLoading,uomName,initialValues,ValueTypeOptions) => (dispatch) => {
    setLoading(true);
    determineBaseUrlAndPerformApiCall(`${GET_UOM_BY__ID_API}/${uomName}`, 'GET')
        .then((response) => {
            setLoading(false);
            dispatch(fetchUomByIdSuccess(response));
            initialValues.valuetype = ValueTypeOptions.find(attr => attr.name === 'valuetype')?.options?.find(option => option.value === response.valueType);
            initialValues.name = response.name
        })
    .catch ((errorResp) => { dispatch(fetchUomByIdFailure(errorResp.message)); })
}