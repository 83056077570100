import moment from "moment";
import { setValueForSubValEndsOn, setValueForSubValStartsOn } from "./displayInfinite";



export const handleChangeForSubscriptionValidity = (initialValues, setFieldValue, startOrEnd) => (selectedValue) => {
    if (startOrEnd === "Start") {
        setValueForSubValStartsOn(initialValues, setFieldValue, selectedValue)
    }
    else {
        setValueForSubValEndsOn(initialValues, setFieldValue, selectedValue)
    }
}