import { toast } from "react-toastify";
import { fetchexpiryCheckSuccess, fetchexpiryCheckFailure} from "./reducer";
import { EXPIRY_CHECK_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";

export const expiryCheck = (token, setLoading,setTime) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${EXPIRY_CHECK_API}/${token}`,'GET').then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchexpiryCheckSuccess(response))
        }
        if (response.error) {
            setTime(true)
            setLoading(false)
            dispatch(fetchexpiryCheckFailure(response))
        }
    }).catch((errorResp) => { })
}
