import React from "react";
import { Card, CardHeader, CardBody, Row, Col, Label } from "reactstrap";

export const CustomerViewDetails = ({ customerData, customerPermission }) => {
    return (
        <Col lg={6}>
            <Card className="border border-2">
                <CardHeader className="d-flex align-items-center">
                    <i className="ri-account-circle-line fs-3 me-2 invoice-percent-and-icon-color"></i>
                    <Label className="invoice-percent-and-icon-color fs-5 mt-2">Customer Details</Label>
                </CardHeader>
                <CardBody>
                    <Row lg={6}>
                        <Col lg={6}>
                            <div className="container">
                                <div className="row">
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>{customerPermission?.autoload ? "Account Number" : "Customer Id"}</Label></div>
                                        <div>{customerData?.customerInfo?.customerId}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>Firstname</Label></div>
                                        <div>{customerData?.customerInfo?.firstName}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>Lastname</Label></div>
                                        <div>{customerData?.customerInfo?.lastName}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="container">
                                <div className="row">
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>Email</Label></div>
                                        <div>{customerData?.customerInfo?.email}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>{customerPermission?.autoload ? "MSISDN" : "Contact No"}</Label></div>
                                        <div>{customerData?.customerInfo?.dialCode + '-' + customerData?.customerInfo?.mobile}</div>
                                    </div>
                                    {customerPermission?.autoload &&
                                        <div className="row mb-3">
                                            <div className="col fs-5"><Label>IMSI</Label></div>
                                            <div>{customerData?.customerInfo?.attributeList?.find(attr => attr.name === "imsi")?.value || "IMSI not found"}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    );
};

export const CustomerAddress = ({ customerData }) => {
    return (
        <Col lg={6}>
            <Card className="border border-2">
                <CardHeader className="d-flex align-items-center">
                    <i className="bx bx-map fs-3 me-2 invoice-percent-and-icon-color"></i>
                    <Label className="invoice-percent-and-icon-color fs-5 mt-2">Address</Label>
                </CardHeader>
                <CardBody>
                    <Row lg={6}>
                        <Col lg={6}>
                            <div className="container">
                                <div className="row">
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>Address 1</Label></div>
                                        <div>{customerData?.customerInfo?.addressLine1}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>Address 2</Label></div>
                                        <div>{customerData?.customerInfo?.addressLine2}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>Country</Label></div>
                                        <div>{customerData?.customerInfo?.country}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="container">
                                <div className="row">
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>State</Label></div>
                                        <div>{customerData?.customerInfo?.state}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>City</Label></div>
                                        <div>{customerData?.customerInfo?.city}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>Zip Code</Label></div>
                                        <div>{customerData?.customerInfo?.zipCode}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    );
};