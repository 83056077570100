import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    partnerDocumentUpload: [],
    loading: false,
    error: null,
};

const fetchPartnerDocumentUploadSlice = createSlice({
    name: "partnerDocumentUpload",
    initialState,
    reducers: {
        fetchPartnerDocumentUploadSuccess: (state, action) => {
            state.loading = false;
            state.partnerDocumentUpload = action.payload
        },
        fetchPartnerDocumentUploadFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchPartnerDocumentUploadFailure,
    fetchPartnerDocumentUploadSuccess
} = fetchPartnerDocumentUploadSlice.actions

export default fetchPartnerDocumentUploadSlice.reducer