import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getPopularPlan: [],
    loading: false,
    error: null,
}

const getPopularPlanSlice = createSlice({
    name: "getPopularPlan",
    initialState,
    reducers: {
        fetchGetPopularPlanSuccess: (state, action) => {
            state.loading = false;
            state.getPopularPlan = action.payload;
        },
        fetchGetPopularPlanFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchGetPopularPlanSuccess, fetchGetPopularPlanFailure } = getPopularPlanSlice.actions;

export default getPopularPlanSlice.reducer;
