import axios from "axios";
import { fetchUpdateReservationSuccess, fetchUpdateReservationFailure } from "./reducer";
import { toast } from "react-toastify";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { UPDATE_RESERVATION_API } from "../../constants/constants";
import { setEditSuccessMessage } from "../../toastMessage/action";

export const updateReservation = (requestBody, isEdit, navigate, setLoadingData) => async (dispatch) => {
  setLoadingData(true)
  determineBaseUrlAndPerformApiCall(`${UPDATE_RESERVATION_API}`, 'PUT', requestBody)
    .then((response) => {
      if (!response.error) {
        setLoadingData(false)
        dispatch(fetchUpdateReservationSuccess(response))
        navigate('/reservation');
        dispatch(setEditSuccessMessage(true))
      }
      if (response.error) {
        setLoadingData(false)
        dispatch(fetchUpdateReservationFailure(response.error.message))
        toast.error(
          `Error ${isEdit ? 'updating' : 'creating'} reservation: ${response.error.message
          }`
        )
      }
    })
    .catch((error) => { });
}