export const ChargesDropDownOptions = [
    { value : 'OneTime', label:'One Time'},
    { value : "Recurring", label : 'Recurring'}
]

export const CommissionTypeDropDownOptions = [
    { value : "FixedWholesale" , label:"Fixed Wholesale"},
    { value : "PercentageWholesale" , label:"Percentage Wholesale"},
    { value : "FixedMarkup" , label:"Fixed Markup"},
    { value : "PercentageMarkup" , label:"Percentage Markup"},
]