import React, { useState, useMemo } from "react";
import { Card, CardHeader, CardBody, Row, Col, Label, FormGroup } from "reactstrap";
import { LabelText } from "../Utils/Input";
import PieChart from "./constants/PieChart";
import { CustomerAddress, CustomerViewDetails } from "./CustomerViewDetail";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from "reactstrap";
import SelectField from "../Utils/SelectField";

export const CustomerProfile = ({ customerData, fetchCustomerSummaryData, formatDate, customerPermission, resourceData }) => {
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const groupedResources = useMemo(() => {
        return fetchCustomerSummaryData?.resources?.reduce((acc, resource) => {
            const key = `${resource.subscriptionId}-${resource.offerCode}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(resource);
            return acc;
        }, {});
    }, [fetchCustomerSummaryData]);

    const handleSubscriptionChange = (selectedOption) => {
        setSelectedSubscriptionId(selectedOption ? selectedOption.value : "");
        setActiveIndex(0);
    };

    const next = (resources) => {
        if (animating) return;
        const nextIndex = activeIndex === resources.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = (resources) => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? resources.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const renderGrantsCarousel = (resources) => {
        const slides = resources.map((resource) => (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={resource.resourceName}
            >
                <Card className="border border-2 customer-view-screen">
                    <CardHeader className="d-flex align-items-center customer-view-screen">
                        <Label className="fs-5 mt-2">Grant Bucket</Label>
                    </CardHeader>
                    <CardBody className="px-5">
                        <div className="d-flex flex-column align-items-center flex-md-row">
                            <div>
                                <PieChart
                                    remainingBalance={resource.remainingBalance}
                                    originalBalance={resource.originalBalance}
                                    width={"100%"}
                                    height={200}
                                />
                            </div>
                            <div>
                                <p className="fw-bolder fs-5">{resource.resourceName}</p>
                                <p>
                                    <b>{resource.remainingBalance}</b> left of <b>{resource.originalBalance}</b>
                                </p>
                                <p className="d-flex justify-content-start">
                                    <LabelText displayText={`${formatDate(resource.validFrom)} - ${formatDate(resource.validTo)}`} />
                                </p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </CarouselItem>
        ));

        return (
            <Carousel activeIndex={activeIndex} next={() => next(resources)} previous={() => previous(resources)} interval={false}>
                <CarouselIndicators items={resources} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => previous(resources)} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={() => next(resources)} />
            </Carousel>
        );
    };

    const uniqueSubscriptionIds = useMemo(() => {
        return [...new Set(
            fetchCustomerSummaryData?.resources?.map(resource => resource.subscriptionId)
        )];
    }, [fetchCustomerSummaryData]);

    const getUnitOfMeasure = (resourceName) => {
        const matchedResource = resourceData.find(res => res.name === resourceName);
        return matchedResource ? matchedResource.unitofmeasurement : 'N/A';
    };

    return (
        <Row lg={12}>
            <CustomerViewDetails customerData={customerData} customerPermission={customerPermission} />
            <CustomerAddress customerData={customerData} />
            <Col lg={12}>
                {groupedResources && (
                    <Card className="border border-2">
                        <CardHeader className="d-flex align-items-center">
                            <Label className="fs-5 mt-2">Balance</Label>
                        </CardHeader>
                        <Col xxl={4} lg={4} md={4} xs={11} className="ms-3 mt-2">
                            <FormGroup>
                                <Label for="subscriptionSelect">Subscription ID</Label>
                                <SelectField
                                    id="subscriptionSelect"
                                    value={selectedSubscriptionId ? { value: selectedSubscriptionId, label: selectedSubscriptionId } : null}
                                    placeholder="Select Subscription ID"
                                    handleChange={handleSubscriptionChange}
                                    options={uniqueSubscriptionIds.map(id => ({ value: id, label: id }))}
                                    isClearable
                                    isMulti={false}
                                />
                            </FormGroup>
                        </Col>
                        {selectedSubscriptionId &&
                            Object.keys(groupedResources).filter(key => key.startsWith(selectedSubscriptionId)).map((key, index) => {
                                const resources = groupedResources[key];
                                return (
                                    <CardBody key={index} className="card-body">
                                        <Row className="gy-4">
                                            <Col lg={6}>
                                                {resources.length > 1 ? renderGrantsCarousel(resources) : (
                                                    <Card className="border border-2 customer-view-screen">
                                                        <CardHeader className="d-flex align-items-center customer-view-screen">
                                                            <Label className="fs-5 mt-2">Grant</Label>
                                                        </CardHeader>
                                                        <CardBody className="px-5">
                                                            <div className="d-flex flex-column align-items-center flex-md-row">
                                                                <PieChart
                                                                    remainingBalance={resources[0].remainingBalance}
                                                                    originalBalance={resources[0].originalBalance}
                                                                    width={"100%"}
                                                                    height={200}
                                                                />
                                                                <div>
                                                                    <p className="fw-bolder fs-5">{resources[0].resourceName}</p>
                                                                    <p>
                                                                        <b>{resources[0].remainingBalance}</b> left of <b>{resources[0].originalBalance}</b>
                                                                    </p>
                                                                    <p className="d-flex justify-content-start">
                                                                        <LabelText displayText={`${formatDate(resources[0].validFrom)} - ${formatDate(resources[0].validTo)}`} />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                )}
                                            </Col>
                                            <Col lg={6}>
                                                {resources.some(resource => resource.isCounter) && (
                                                    <Card className="border border-2 mt-3" style={{ height: '271px' }}>
                                                        <CardHeader className="d-flex align-items-center mt-n3 border border-2">
                                                            <Label className="fs-5 mt-4 mb-n2">Counters & Grants</Label>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <div className="table-responsive">
                                                                <table className="table table-borderless table-nowrap ms-4">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Name</th>
                                                                            <th scope="col">Value</th>
                                                                            <th scope="col" className="ps-5">UoM</th>
                                                                            <th scope="col" className="ps-5">Time Period</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {resources.map((resource, idx) => (
                                                                            resource.isCounter && (
                                                                                <tr key={idx}>
                                                                                    <td>{resource.resourceName}</td>
                                                                                    <td>{resource.remainingBalance}</td>
                                                                                    <td className="ps-5">{getUnitOfMeasure(resource.resourceName)}</td>
                                                                                    <td className="ps-5">{`${formatDate(resource.validFrom)} - ${formatDate(resource.validTo)}`}</td>
                                                                                </tr>
                                                                            )
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                )}
                                            </Col>
                                        </Row>
                                        <hr />
                                    </CardBody>
                                );
                            })}
                    </Card>
                )}
            </Col>
        </Row>
    );
};
