import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    updatePolicies : [],
    loading : false,
    error : null,
};

const updatePoliciesSlice = createSlice({
    name:"updatePolicies",
    initialState,
    reducers: {
        fetchUpdatePoliciesSuccess: (state,action) => {
            state.loading = false;
            state.updatePolicies = action.payload
        },
        fetchUpdatePoliciesFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchUpdatePoliciesSuccess,
    fetchUpdatePoliciesFailure
} = updatePoliciesSlice.actions

export default updatePoliciesSlice.reducer