import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    getPolicy : [],
    partnerId : [],
    loading : false,
    error : null,
};

const getPolicySlice = createSlice({
    name:"getPolicy",
    initialState,
    reducers: {
        fetchGetPolicySuccess: (state,action) => {
            state.loading = false;
            state.getPolicy = action.payload
        },
        fetchGetPolicyFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        },
        fetchPartnerId: (state,action) => {
            state.loading = false;
            state.partnerId = action.payload
        },
    }
})

export const {
    fetchGetPolicyFailure,
    fetchGetPolicySuccess,fetchPartnerId
} = getPolicySlice.actions

export default getPolicySlice.reducer