import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    getTaskById : [],
    loading : false,
    error : null,
};

const getTaskByIdSlice = createSlice({
    name:"getTaskById",
    initialState,
    reducers: {
        fetchGetTaskByIdSuccess: (state,action) => {
            state.loading = false;
            state.getTaskById = action.payload
        },
        fetchGetTaskByIdFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchGetTaskByIdFailure,
    fetchGetTaskByIdSuccess
} = getTaskByIdSlice.actions

export default getTaskByIdSlice.reducer