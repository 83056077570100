import React, { useEffect, useState, useMemo } from 'react'
import { Card, CardBody, Container, Button, Input } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Row, Col } from 'reactstrap'
import { MaterialReactTable } from 'material-react-table';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../Components/Utils/Loader';
import { fetchAllPartner } from '../../slices/PartnerMangement/thunk'
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { PartnerDataModificationFunctionsForMailId, PartnerDataModificationFunctionsForPhoneNumber, formattedOrganizationFunction, formattedOrganizationsForDate, handleSelectPartner, partnerDataFrommTable, toggleSelectAllForPartner } from './Constants/ModifyAddPartnerFunctions';
import { partnerListInsidePolicy } from './Constants/PartnerInPolicyColumn';
import { filterPartnerResults } from './Constants/ModifyFilterColumns';
import { PAGE_TITLES } from '../Common/constants';

function AddPartnerForPolicy() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const policyId = location.state?.policyId
    const allOffers = location.state?.selectAllOffers
    let values = location.state?.values
    const isEdit = location.pathname.includes("update-partner-for-policy")
    const selectedOffers = location.state.selectedOffers;
    const [selectedPartners, setSelectedPartners] = useState([])
    const [selectAllPartners, setSelectAllPartners] = useState(false)
    const [allData, setAllData] = useState([])
    const [selectedOnes, setSelectedOnes] = useState(false)
    const settlementPartner = useSelector((state) => state.SelectedPartner.settlementPartner);
    useEffect(() => {
        dispatch(fetchAllPartner(setLoading))
        if (isEdit) {
            setSelectedPartners(settlementPartner)
        }
    }, [dispatch])
    const PartnerData = useSelector((state) => state.Partner.partner)
    const formattedOrganizations = formattedOrganizationsForDate(PartnerData)
    const organizationsWithMaidIds = [];
    PartnerDataModificationFunctionsForMailId(PartnerData, organizationsWithMaidIds)
    const organizationsWithPhoneNum = [];
    PartnerDataModificationFunctionsForPhoneNumber(PartnerData, organizationsWithPhoneNum)
    const extractedIds = PartnerData.map(item => item.id.split('/').pop());
    useEffect(() => {
        if (isEdit) {
            setAllData(formattedOrganizationFunction(formattedOrganizations, PartnerData, organizationsWithMaidIds, organizationsWithPhoneNum, extractedIds))
        }
    }, [])
    useEffect(() => {
        if (!isEdit && formattedOrganizations.length > 0 && !allData.length) {
            setAllData(formattedOrganizationFunction(formattedOrganizations, PartnerData, organizationsWithMaidIds, organizationsWithPhoneNum, extractedIds))
        }
    }, [isEdit, formattedOrganizations, allData, PartnerData, organizationsWithMaidIds, organizationsWithPhoneNum, extractedIds]);

    const columns = partnerListInsidePolicy(organizationsWithMaidIds, organizationsWithPhoneNum, formattedOrganizations, setSelectedPartners, selectedPartners, allData, setSelectedOnes)
    const partnerId = partnerDataFrommTable(selectedPartners)

    filterPartnerResults(isEdit, selectedOnes, selectedPartners, allData)

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.ADD_SETTLEMENT_POLICY} pageTitle={PAGE_TITLES.SETTLEMENT_POLICY_LIST} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <div className="listingjs-table " id="customerList">
                                <Card>
                                    <ToastContainer position='top-center' />
                                    <CardBody>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {loading && (<Loader />)}
                                            {allData ?
                                                <MaterialReactTable
                                                    icons={{
                                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                    }}
                                                    defaultColumn={{
                                                        minSize: 10,
                                                        maxSize: 9001,
                                                        muiTableBodyCellProps: {
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif"
                                                            }
                                                        },
                                                        muiTableHeadCellProps: {
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif"
                                                            }
                                                        }
                                                    }}
                                                    columns={columns}
                                                    data={allData}
                                                    enableColumnOrdering
                                                    enableDensityToggle={false}
                                                    enableColumnDragging
                                                    renderTopToolbarCustomActions={({ }) => (
                                                        <div className="d-flex flex-column flex-md-row align-items-md-center">
                                                            <Input type={"checkbox"} checked={selectAllPartners} onChange={() => { toggleSelectAllForPartner(setSelectAllPartners, selectAllPartners, allData, setSelectedPartners) }} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} />
                                                        </div>
                                                    )}
                                                    getRowId={(row) => row.extractedId}
                                                /> : null}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row lg={6} className=" justify-content-end mb-4">
                        <div className="live-preview">
                            <div className="d-flex justify-content-end gap-2 ">
                                <Button color="secondary" type="button" onClick={() => {
                                    isEdit ? navigate('/update-offer-for-policy', { state: { selectedOffers, partnerId, policyId } }) : navigate('/offer-for-policy')
                                }} > Back </Button>
                                <Button color="secondary" type="button" onClick={() => handleSelectPartner(isEdit, partnerId, selectedOffers, policyId, navigate, toast, allOffers, selectAllPartners,values)}> Next </Button>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default AddPartnerForPolicy;