import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    invoiceListForCustomer : [],
    loading : false,
    error : null,
};

const invoiceListForCustomerSlice = createSlice({
    name:"invoiceListForCustomer",
    initialState,
    reducers: {
        fetchInvoiceListForCustomerSuccess: (state,action) => {
            state.loading = false;
            state.invoiceListForCustomer = action.payload
        },
        fetchInvoiceListForCustomerFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchInvoiceListForCustomerSuccess,
    fetchInvoiceListForCustomerFailure
} = invoiceListForCustomerSlice.actions

export default invoiceListForCustomerSlice.reducer