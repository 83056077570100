import { CUSTOMER_LISTING_API } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { fetchCustomerListSuccess, fetchCustomerListFailure } from "./reducer"

export const fetchCustomerList = (setLoading, pageIndex, pageSize,selectedValue,searchValue) => async (dispatch) => {
  setLoading(true)
  const graphqlQuery = {
    query: `
    query getCustomers($first:Int, $offset:Int, $filter: CustomerFilter, $sortCriteria: SortCriteria) {
      customersPaginate(first:$first, offset:$offset, filter: $filter, sortCriteria: $sortCriteria) {
          Count,
          TotalCount,
          Customers{
              CustomerId
              Name
              Email
              Mobile
              Address
              AttributeList{
                  Name
                  Value
              }
          }
      }
  }
    `,
    variables: { first: pageSize, offset: pageIndex,filter: { [selectedValue]: searchValue }, orderBy: {}, sortCriteria: { OrderBy: 'UpdatedAt', Order: 'des' } },
  }

  try {
    const response = await determineBaseUrlAndPerformApiCall(
      `${CUSTOMER_LISTING_API}`,
      'POST',
      graphqlQuery
    );
    setLoading(true)
    const customerList = response?.data?.customersPaginate
    dispatch(fetchCustomerListSuccess(customerList))
  } catch (error) {
    dispatch(fetchCustomerListFailure(error.message))
  } finally {
    setLoading(false)
  }
}

