import { fetchCreateResourceFailure, fetchCreateResourceSuccess } from "./reducer"
import { toast } from "react-toastify";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { ADD_RESOURCE_API } from "../../constants/constants";
import { setSuccessMessage } from "../../toastMessage/action";

export const addresource = (jsonObj, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${ADD_RESOURCE_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchCreateResourceSuccess(response))
            navigate('/resource')
            dispatch(setSuccessMessage(true))
        }
        if (response.error) {
            dispatch(fetchCreateResourceFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}