import React from 'react';
import { Card, CardBody, CardText } from 'reactstrap';

export default function CustomerSubscriptionCard({ fetchCustomerSummaryData }) {
    const addOnResources = fetchCustomerSummaryData?.resources?.filter(resource => resource.isAddOn);
    const nonAddOnResources = fetchCustomerSummaryData?.resources?.filter(resource => !resource.isAddOn);
    const uniqueBaseOffers = nonAddOnResources?.filter((offer, index, self) =>
        index === self?.findIndex((o) => o?.offerCode === offer?.offerCode)
    );
    const uniqueAddOnOffers = addOnResources?.filter((offer, index, self) =>
        index === self?.findIndex((o) => o?.offerCode === offer?.offerCode)
    );
    const uniqueAddOnOfferLength = uniqueAddOnOffers?.length || 0;
    const uniqueBaseOfferLength = uniqueBaseOffers?.length || 0;
    const totalSubscriptions = uniqueAddOnOfferLength + uniqueBaseOfferLength;

    return (
        <Card className="shadow-sm flex-fill">
            <CardBody className="d-flex justify-content-center align-items-stretch">
                <div className="row w-100">
                    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center border-end">
                        <h2 className="mb-3 mb-md-4">{totalSubscriptions}</h2>
                        <CardText tag="h5" className="text-center  fw-medium mb-4">
                            {totalSubscriptions <= 1 ? "Active Subscription" : "Active Subscriptions"}
                        </CardText>
                    </div>
                    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center text-center">
                        <h2 className="mb-3 mb-md-4">{uniqueBaseOfferLength}</h2>
                        <CardText tag="h5" className="mb-3 mb-md-5  fw-medium">Base Offer</CardText>
                        <h2 className="mb-3 mb-md-4">{uniqueAddOnOfferLength}</h2>
                        <CardText tag="h5" className=" fw-medium">Add On Offer</CardText>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}