
export const handleChangeForAttributeValue = (handleChange) => (e) => {
  handleChange(e);
}

export const handleChangeForAttributeName = (values, index, setFieldTouched, setFieldValue) => (val) => {
  values.attributeList[index].name = val
  setFieldValue(`attributeList[${index}].name`, val);
  setFieldTouched(`attributeList[${index}].name`, false);
}

export function isEmptyOrInvalidField(index, values) {
  const previousField = values?.attributeList[index];
  return !previousField || !previousField.name || !previousField.value;
}

export const deviceJsonObj = (values, isEdit, newAttributes = null) => {
  let attributesToUse = newAttributes || values.attributeList;
  let jsonObj = {};
  if (!isEdit) {
    jsonObj = JSON.stringify({
      deviceId: values.deviceId,
      deviceType: values.deviceType.value,
      status: "Unassigned" || "",
      attributeList: attributesToUse.map((attribute) => ({
        name: attribute.name,
        value: attribute.value,
      })),
    });
  } else {
    let attributesArray = attributesToUse.map((attribute) => ({
      name: attribute.name,
      value: attribute.value,
    }));
    jsonObj = JSON.stringify({
      deviceId: values.deviceId,
      deviceType: values.deviceType.label,
      status: values.status.label,
      attributeList: attributesArray,
    });
  }
  return jsonObj;
};
export const handleDeviceTypeChange = (setFieldValue) => (selectedDevice) => {
  setFieldValue("deviceType", selectedDevice);
};
export const getStatusValue = (initialValues, StatusType, values) => {
  return initialValues.status
    ? StatusType?.filter((status) => status.label === initialValues.status)
    : values.status;
};

export const handleStatusChange = (setFieldValue) => (selectedDevice) => {
  setFieldValue("status", selectedDevice);
};





