import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deactivate: [],
    loading: false,
    error: null,
}

const DeactivateSlice = createSlice({
    name: "deactivate",
    initialState,
    reducers: {
        fetchDeactivateSucess: (state, action) => {
            state.loading = false
            state.deactivate = action.payload;
        },
        fetchDeactivateFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchDeactivateSucess,
    fetchDeactivateFailure,
} = DeactivateSlice.actions;

export default DeactivateSlice.reducer;