import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { fetchGetOrderFailure, fetchGetOrderSuccess } from "./reducer"
import { ORDERS_LIST_API } from "../../constants/constants"
import { fetchCustomer } from "../../customer/fetchcustomer/thunk"
import { initialFormValues } from "../../../Components/Customers/constants/constantValues"

export const getOrderById = (orderId, setLoading, setCustomerData) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${ORDERS_LIST_API}/${orderId}`, 'GET').then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchGetOrderSuccess(response))
        }
        if (response.error) {
            setLoading(false)
            dispatch(fetchGetOrderFailure(response.error.message))
        }
    }).catch((error) => { 
        setLoading(false);
        toast.error("An error occurred. Please try again later.");
    })
}