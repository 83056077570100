import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    updateCustomer : [],
    loading : false,
    error : null,
};

const updateCustomerSlice = createSlice({
    name:"updateCustomer",
    initialState,
    reducers: {
        fetchUpdateCustomerSuccess: (state,action) => {
            state.loading = false;
            state.updateCustomer = action.payload
        },
        fetchUpdateCustomerFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchUpdateCustomerFailure,
    fetchUpdateCustomerSuccess
} = updateCustomerSlice.actions

export default updateCustomerSlice.reducer