import moment from "moment";

export const filterOfferResults = (isEdit, selectedOnes, selectedOffers, filteredResults) => {
    if (isEdit && !selectedOnes) {
        selectedOffers.forEach(selectedOffer => {
            filteredResults.forEach((result, index) => {
                const [, offers] = result;
                const indexToRemove = offers.findIndex(offer => offer.offerCode === selectedOffer.offerCode);
                if (indexToRemove !== -1) {
                    offers.splice(indexToRemove, 1);
                    if (offers.length === 0) {
                        filteredResults.splice(index, 1);
                    }
                }
            });
        });
    }
}

export const filterPartnerResults = (isEdit, selectedOnes, selectedPartners, allData) => {
    if (isEdit && !selectedOnes) {
        selectedPartners.forEach(value => {
            allData.forEach((result, index) => {
                if (result.extractedId === value.extractedId) {
                    allData.splice(index, 1);
                }
            });
        });
    }
}

//future use
// export const filteringOffers = (filteredResults, notSelectingOffer) => {
//     filteredResults.map(([serviceName, offers], index) => (
//         serviceName && (notSelectingOffer = offers)
//     ))
// }

export const extractPartnerData = (item, idType) => {
    const emailMedium = item.contactMedium && item.contactMedium.find(medium => medium.mediumType === "email address");
    const phoneNumberMedium = item.contactMedium && item.contactMedium.find(medium => medium.mediumType === "telephone number");
    
    return {
        [idType]: item.id.split('/').pop(),
        businessName: item.name || '-',
        type: item.organizationType || '-',
        createdOn: moment(item.createdAt).format('DD-MMM-YYYY'),
        email: emailMedium ? emailMedium.characteristic.emailAddress : '-',
        phone: phoneNumberMedium ? phoneNumberMedium.characteristic.phoneNumber : '-'
    };
};

export const formatOfferData = (value, serviceName) => {
    return {
        "offerName": value.offerName,
        "offerCode": value.offerCode,
        "GlobalScope": value.globalScope === false ? "No" : "Yes",
        "validityFrom": moment(value.validity.from).format('MMM DD,YYYY'),
        "validityTo": moment(value.validity.to).format('MMM DD,YYYY'),
        "serviceName": serviceName,
        ...(value.Grants && { "Grants": value.Grants }),
        ...(value.Charges && { "Charges": value.Charges }),
        ...(value.usageRatePlan && { "Usage": value.usageRatePlan })
    };
};

export const setInitialValues = (values,initialValues) => {
    if (values) {
        initialValues.policyName = values.policyName
        initialValues.priority = values.priority
        initialValues.commissionRules = values.commissionRules
    }
}