import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { PARTNER_LISTING_API } from "../constants/constants";
import { fetchPartnerSuccess, fetchPartnerFailure } from "./reducer";

export const fetchAllPartner = (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${PARTNER_LISTING_API}`, 'GET')
        setLoading(false)
        dispatch(fetchPartnerSuccess(response.reverse()))
    }
    catch (error) {
        setLoading(false)
        dispatch(fetchPartnerFailure(error.message))
    }
}