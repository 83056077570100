import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    createConfigOperator: [],
    loading: false,
    error: null,
}

const createConfigOperatorSlice = createSlice({
    name: "createConfigOperator",
    initialState,
    reducers: {
        fetchcreateConfigOperatorSucess: (state, action) => {
            state.loading = false
            state.createConfigOperator = action.payload;
        },
        fetchcreateConfigOperatorFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchcreateConfigOperatorSucess,
    fetchcreateConfigOperatorFailure,
} = createConfigOperatorSlice.actions;

export default createConfigOperatorSlice.reducer;