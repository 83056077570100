import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    resendLink: [],
    loading: false,
    error: null,
}

const resendLinkSlice = createSlice({
    name: "resendLink",
    initialState,
    reducers: {
        fetchResendLinkSuccess: (state, action) => {
            state.loading = false
            state.resendLink = action.payload;
        },
        fetchResendLinkFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchResendLinkSuccess,
    fetchResendLinkFailure,
} = resendLinkSlice.actions;

export default resendLinkSlice.reducer;