import { toast } from "react-toastify"
import { CREATE_POLICY_API } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { fetchEditPolicyFailure, fetchEditPolicySuccess } from "./reducer"
import { setEditSuccessMessage } from "../../toastMessage/action"

export const editPolicy = (jsonObj,setLoading,navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${CREATE_POLICY_API}`,'PATCH',jsonObj).then((response)=>{
        if(!response.error){
            setLoading(false)
            dispatch(fetchEditPolicySuccess(response))
            navigate('/policy')
            dispatch(setEditSuccessMessage(true))
        }
        if(response.error){
            setLoading(false)
            toast.error(`${response.error.message}`)
            dispatch(fetchEditPolicyFailure(response))
        }
    })
}