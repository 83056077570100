import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { setSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";
import { fetchCreatePoliciesFailure, fetchCreatePoliciesSucess } from "./reducer";
import { POLICY_API } from "../../constants/constants";

export const createPolicies = (jsonObj, setLoading, navigate) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${POLICY_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchCreatePoliciesSucess(response))
            navigate('/policies');
            dispatch(setSuccessMessage(true))
        }
        if (response.error) {
            dispatch(fetchCreatePoliciesFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}