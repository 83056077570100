import React, { useEffect, useState, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllOffers } from '../../slices/offer/thunk';
import { getAllReservation } from '../../slices/reservations/thunk';
import { getAllStation } from '../../slices/stations/thunk';
import { Box } from '@mui/material';

function EvseList(stationID) {
  const dispatch = useDispatch();
  const [stations, setStations] = useState([]);

  useEffect(() => {
    dispatch(fetchAllOffers());
    dispatch(getAllReservation());
    dispatch(getAllStation());
  }, [dispatch]);

  const offer = useSelector((state) => state.Offer.offer);
  const responses = useSelector((state) => state.Stations.station);
  const stationDetailsArray = responses.map((station) => station.stationDetails);
  const reservation = useSelector((state) => state.Reservations.reservations);

  let allConnectorTableData = [];
  let connectorType = [];
  reservation?.forEach((reservationItem, i) => {
    if (reservationItem.stationID === stationID.stationID) {
      connectorType[i] = reservationItem.ConnectorType;
    } else {
      connectorType[i] = '-';
    }
  });

  if (stationDetailsArray) {
    stationDetailsArray.forEach((station) => {
      if (station.stationID === stationID.stationID) {
        if (station.evse) {
          station.evse.forEach((evse) => {
            const connectorTableData = evse.connectors.map((connector) => ({
              'EVSE ID': evse.evseid,
              'Connector ID': connector.connectorId,
              'Status': connector.connectorStatus,
            }));
            allConnectorTableData = allConnectorTableData.concat(connectorTableData);
          });
        }
      }
    });
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'EVSE ID',
        header: 'Evse',
      },
      {
        accessorKey: 'Connector ID',
        header: 'Connector ID',
      },
      {
        accessorFn: (allConnectorTableData, i) => `${connectorType[i]}`,
        header: 'Connector Type',
      },
      {
        accessorKey: 'Status',
        header: 'Status',
        Cell: ({ cell }) => (
          <Box sx={(theme) => (cell.getValue() ? {
            backgroundColor: "#fff",
            borderRadius: '0.35rem',
            border: cell.getValue() === 'Available' || 'Reserved' ? '2px solid green' : cell.getValue() === 'Occupied' ? '2px solid yellow' : '2px solid red',
            borderColor: cell.getValue() === 'Available' || 'Reserved' ? theme.palette.success.light : cell.getValue() === 'Occupied' ? theme.palette.warning.light : theme.palette.warning.dark,
            color: cell.getValue() === 'Available' || 'Reserved' ? theme.palette.success.light : cell.getValue() === 'Occupied' ? theme.palette.warning.light : theme.palette.warning.dark,
            maxWidth: '9ch',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1px"
          } : {})} >{cell.getValue()}</Box>
        )
      },
    ],
    [connectorType],
  );

  return (
    <div className="table-responsive">
      {allConnectorTableData ?
        (
          <MaterialReactTable columns={columns} data={allConnectorTableData}
            muiTableHeadCellProps={{
              sx: {
                fontFamily: "Inter,sans-serif"
              }
            }}
            icons={{
              DragHandleIcon: () => <i className="ri-drag-move-fill" />,
            }}
            muiTableBodyCellProps={{
              sx: {
                fontFamily: "Inter,sans-serif"
              }
            }}
          />
        ) : null}
    </div>
  );
}

export default EvseList;
