import { fetchUpdateCustomerSuccess, fetchUpdateCustomerFailure } from "./reducer";
import { toast } from "react-toastify"
import { UPDATE_CUSTOMER_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { setEditSuccessMessage, setEmailUpdateSuccessMessage } from "../../toastMessage/action";
import { fetchCustomer } from "../fetchcustomer/thunk";

export const updateCustomer = (jsonObj, customerId, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    const roleName = localStorage.getItem("roleName")
    await determineBaseUrlAndPerformApiCall(`${UPDATE_CUSTOMER_API}/${customerId}`, 'PUT', jsonObj).then((response) => {
        if (roleName !== 'Customer') {
            if (!response.error) {
                setLoading(false)
                dispatch(fetchUpdateCustomerSuccess(response))
                navigate('/customer')
                if(response.message){
                    dispatch(setEmailUpdateSuccessMessage(true))
                }
                else{
                    dispatch(setEditSuccessMessage(true))
                }
            }
        } else {
            if (!response.error) {
                setLoading(false)
                if(response.message){
                    dispatch(setEmailUpdateSuccessMessage(true))
                }
                else{
                    dispatch(setEditSuccessMessage(true))
                }
                dispatch(fetchCustomer(setLoading,customerId))
                dispatch(fetchUpdateCustomerSuccess(response))
            }
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchUpdateCustomerFailure(response.error.message))
        }
    }).catch((errorResp) => {
    })
}