import { Formik } from "formik";
import { Form, Card, Container, Row, CardBody, Col } from "reactstrap";
import { ToastContainer } from "react-toastify";
import BreadCrumb from "../Common/BreadCrumb";
import { useNavigate } from "react-router-dom";
import { validationSchemaForCommissionRules } from "./Constants/validationSchema";
import { createPolicyDataFunction, editPolicyDataFunction } from "./Constants/ModifyDataFunctions";
import { useLocation } from "react-router-dom";
import { OfferList } from "./OfferList";
import { PartnerList } from "./PartnerList";
import CommonButton from "../Utils/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { getPolicy } from "../../slices/settlementPolicy/Getpolicy/thunk";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import { getAllService } from "../../slices/services/thunk";
import { settlementPolicyPayload } from "./Constants/PolicyJsonObj";
import { setInitialValues } from "./Constants/ModifyFilterColumns";
import { PAGE_TITLES } from "../Common/constants";
import { Loader } from '../Utils/Loader';
import { PolicyDetails } from "./PolicyDetails";
import {SettlementCommissionRules} from './SettlementCommissionRules'
export const CreateEditPolicy = () => {
    const location = useLocation();
    const isEdit = location.pathname.includes("update-policy");
    const policyId = customerIdData();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const selectedOffers = location.state?.selectedOffers;
    const partnerId = location.state?.partnerId;
    const [isAllOffersForEdit, setIsAllOffersForEdit] = useState(false);
    const [isAllPartnersForEdit, setIsAllPartnersForEdit] = useState(false);
    const [mergedArray, setMergedArray] = useState([]);
    const [mergedPartners, setMergedPartners] = useState([]);
    const initialValues = useMemo(() => ({
        policyName: '',
        priority: '',
        commissionRules: []
    }), []);

    useEffect(() => {
        if (location.state?.values) {
            setInitialValues(location.state.values, initialValues);
        }
    }, [location.state?.values, initialValues]);

    useEffect(() => {
        if (isEdit) {
            dispatch(getPolicy(policyId, setLoading, initialValues, setIsAllOffersForEdit, setIsAllPartnersForEdit));
            dispatch(getAllService(setLoading));
        }
    }, [dispatch, isEdit, policyId, initialValues]);

    const partnerdatas = useSelector((state) => state.SelectedPartner.settlementPartner);
    const fetchPartner = useSelector((state) => state.Partner.partner);
    const orderData = useSelector((state) => state.SelectedOffer.settlementOffer);

    const createPolicyData = useMemo(() => createPolicyDataFunction(useSelector, undefined, undefined, undefined, partnerdatas, fetchPartner, orderData), [partnerdatas, fetchPartner, orderData]);

    let uniqueOfferCodes, partnerIdsss, partnerIdArray, partnerCreateArray;
    if (!isEdit) {
        uniqueOfferCodes = createPolicyData?.uniqueOfferCodes;
        partnerIdsss = createPolicyData?.partnerIdsss;
        partnerIdArray = createPolicyData?.partnerArray;
        partnerCreateArray = createPolicyData?.partnerIdArray;
    }

    const getPolicyData = useSelector((state) => state.GetSettlementPolicy.getPolicy);
    const fetchPartners = useSelector((state) => state.GetSettlementPolicy.partnerId);
    const serviceData = useSelector((state) => state.Services.services);
    const PartnerData = useSelector((state) => state.Partner.partner);
    const fetchOffers = useSelector((state) => state.Offer.offer);

    const [offerEditArray, setOfferEditArray] = useState([]);
    const [uniqueOffCodesEditPayload, setUniqueOffCodesEditPayload] = useState([]);
    const [partnerJson, setPartnerJson] = useState("{}");
    const [partnerEditArray, setPartnerEditArray] = useState([]);

    useEffect(() => {
        if (isEdit) {
            dispatch({ type: 'CLEAR_OFFERS_DUPLICATE' });
            
            const result = editPolicyDataFunction(partnerJson, partnerId, useSelector, selectedOffers, isAllOffersForEdit, isAllPartnersForEdit, getPolicyData, fetchPartners, serviceData, PartnerData, fetchOffers);
            
         
            setOfferEditArray(result.offerEditArray);
            setUniqueOffCodesEditPayload(result.uniqueOffCodesEditPayload);
            setPartnerJson(result.partnerJson);
            setPartnerEditArray(result.partnerEditArray);
            
            dispatch({ type: 'ADD_OFFER_DUPLICATE', payload: result.offerEditArray });
            dispatch({ type: 'CLEAR_PARTNERS_DUPLICATE' });
            dispatch({ type: 'ADD_PARTNER_DUPLICATE', payload: result.partnerEditArray });
        }
    }, [dispatch, isAllOffersForEdit, isAllPartnersForEdit, getPolicyData, fetchPartners, serviceData, PartnerData, fetchOffers, partnerId, selectedOffers, isEdit]);

    const partnerDataArray = [];
    const offerDataArray = [];
    const partnerdata = useSelector((state) => state.SelectedPartner.partnerselect);
    const orderdata = useSelector((state) => state.SelectedOffer.offersselect);

    return (
        <div>
            <Formik initialValues={initialValues} validationSchema={validationSchemaForCommissionRules} onSubmit={async (values) => {
                const partnerJsonStr = JSON.stringify({ partnerId: partnerIdsss });

                try {
                    await settlementPolicyPayload(
                        values, orderdata, partnerDataArray, isEdit, dispatch, setLoading, navigate, uniqueOfferCodes, partnerIdsss, partnerdata, undefined, partnerJsonStr, getPolicyData, undefined, undefined, mergedArray, mergedPartners
                    );
                } catch (error) {
                    console.error('Error in handleSubmit:', error);
                }
            }}>
                {({ handleChange, handleSubmit, values, touched, errors, setFieldTouched, setFieldValue, setTouched, handleBlur }) => (
                    <Form className="needs-validation" onSubmit={handleSubmit}>
                        <div>
                            <ToastContainer position="top-center" />
                            {loading && <Loader />}
                            <div className="page-content">
                                <Container fluid>
                                    <BreadCrumb title={isEdit ? PAGE_TITLES.EDIT_SETTLEMET_POLICY : PAGE_TITLES.ADD_SETTLEMENT_POLICY} pageTitle={PAGE_TITLES.SETTLEMENT_POLICY_LIST} />
                                    <Row>
                                        <Col lg={12}>
                                            <Card>
                                                <CardBody>
                                                    <PolicyDetails values={values} handleBlur={handleBlur} handleChange={handleChange} touched={touched} errors={errors} getPolicyData={getPolicyData} isEdit={isEdit} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <PartnerList values={values} isEdit={isEdit} mergedPartners={mergedPartners} setMergedPartners={setMergedPartners} partnerId={isEdit ? partnerCreateArray : partnerId} selectedOffers={isEdit ? partnerId : selectedOffers} />
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <OfferList values={values} isEdit={isEdit} mergedArray={mergedArray} setMergedArray={setMergedArray} offerDataArray={offerDataArray} selectedOffers={isEdit ? offerEditArray : selectedOffers} />
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <SettlementCommissionRules values={values} setFieldValue={setFieldValue} handleChange={handleChange} setTouched={setTouched} initialValues={initialValues} touched={touched} errors={errors} />
                                    <Row lg={6} className="justify-content-end mb-4">
                                        <div className="live-preview">
                                            <div className="d-flex justify-content-end gap-2">
                                                <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => { navigate('/policy') }} buttonText={"Cancel"} />
                                                <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} />
                                            </div>
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};