export const createOrderPayloaderssss = (orders) => {
    const payloadArray = orders.map(order => {
        return {
            id: order.subscriptionID,
            "@type": "ProductOrderItem",
            action: "add",
            productOffering: {
                id: order.offerCode,
                "@type": "ProductOfferingRef"
            },
            quantity:order.quantity
        };
    });
    return {
        productOrderItem: payloadArray,
    };
};
