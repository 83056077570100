import { fetchChangePlanFailure, fetchChangePlanSuccess } from "./reducer"
import { toast } from "react-toastify"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { CHANGE_PLAN } from "../../constants/constants";
import { setSuccessMessage } from "../../toastMessage/action";
import { fetchCustomerSummary } from "../simulate/thunk";
import { fetchBalance } from "../balance/thunk";

export const changePlan = (jsonObj,id, setLoading, setCondition) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${CHANGE_PLAN}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchChangePlanSuccess({}))
            dispatch(fetchChangePlanSuccess(response))
            dispatch(fetchBalance(id,setLoading,response))
            setCondition(true)
            dispatch(setSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchChangePlanFailure(response.error.message))
        }
    })
}