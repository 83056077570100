import { toast } from "react-toastify";
import { fetchEmailVerificationFailure, fetchEmailVerificationSuccess} from "./reducer";
import { EMAIL_VERIFICATION } from "../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";

export const emailVerificationCheck = (token, setLoading,setTime) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${EMAIL_VERIFICATION}/${token}`,'PATCH').then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchEmailVerificationSuccess(response))
        }
        if (response.status === 400) {
            setTime(true)
            setLoading(false)
            dispatch(fetchEmailVerificationFailure(response))
        }
    }).catch((errorResp) => { })
}
