import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    changePlan : [],
    loading : false,
    error : null,
};

const changePlanSlice = createSlice({
    name:"changePlan",
    initialState,
    reducers: {
        fetchChangePlanSuccess: (state,action) => {
            state.loading = false;
            state.changePlan = action.payload
        },
        fetchChangePlanFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        },
        fetchChangePlanEmpty: (state,action) => {
            state.loading = false;
            state.changePlan = {}
        }
    }
})

export const {
    fetchChangePlanFailure,
    fetchChangePlanSuccess,
    fetchChangePlanEmpty
} = changePlanSlice.actions

export default changePlanSlice.reducer