import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    documentUpdate : [],
    loading : false,
    error : null,
};

const fetchDocumentUpdateSlice = createSlice({
    name:"documentUpdate",
    initialState,
    reducers: {
        fetchDocumentUpdateSuccess: (state,action) => {
            state.loading = false;
            state.documentUpdate = action.payload
        },
        fetchDocumentUpdateFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchDocumentUpdateSuccess,
    fetchDocumentUpdateFailure
} = fetchDocumentUpdateSlice.actions

export default fetchDocumentUpdateSlice.reducer