import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    partner : [],
    loading : false,
    error : null,
};

const partnerSlice = createSlice({
    name:"partner",
    initialState,
    reducers: {
        fetchPartnerSuccess: (state,action) => {
            state.loading = false;
            state.partner = action.payload;
        },
        fetchPartnerFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchPartnerSuccess,
    fetchPartnerFailure
} = partnerSlice.actions

export default partnerSlice.reducer