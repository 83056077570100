import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getPayment: [],
    loading: false,
    error: null,
}

const getPaymentSlice = createSlice({
    name: "getPayment",
    initialState,
    reducers: {
        fetchGetPaymentSuccess: (state, action) => {
            state.loading = false;
            state.getPayment = action.payload;
        },
        fetchGetPaymentFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchGetPaymentSuccess, fetchGetPaymentFailure } = getPaymentSlice.actions;

export default getPaymentSlice.reducer;
