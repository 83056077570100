import moment from "moment"
import { Card, CardBody, CardHeader } from "reactstrap"

export const OrderDetailsInViewCancel = ({ isView, isCancel, orderDataById, orderID, status, currentDate }) => {
    return (
        <Card>
            <CardHeader className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                <h4 className="card-title mb-2 mb-md-0">ORDER DETAILS</h4>
            </CardHeader>
            <CardBody>
                <div>
                    <h4 className="card-title mb-3 mb-md-2">Order Number : {(isView || isCancel) ? orderDataById.id : orderID}</h4>
                </div>
                <div className="d-flex justify-content-between  align-items-end ">
                    <div className="d-flex flex-column">
                        <h4 className="card-title mb-2 mb-md-0">Order status :
                            <span className={(isView || isCancel) ? (orderDataById.state === "pending" ? "text-warning " : orderDataById.state === "acknowledged" ? "text-secondary " : (orderDataById.state === "failed" || orderDataById.state === "cancelled" ) ? "text-danger " : "text-success ") : (status === "Pending" ? "text-warning " : status === "Acknowledged" ? "text-secondary " : (status === "Failed" || status === "Cancelled") ? "text-danger " : status==="Completed" ? "text-success " : "")}>
                                {(isView || isCancel) ? ` ${orderDataById.state === "pending" ? "Pending" : orderDataById.state === "acknowledged" ? "Acknowledged" : orderDataById.state === "failed" ? "Failed" : orderDataById.state === "cancelled" ? "Cancelled" : orderDataById.state === "completed" ? "Completed" : ""}` : ` ${status}`}
                            </span>
                        </h4>
                    </div>
                    <div className="d-flex justify-content-end align-items-end ms-5 mt-3">
                        <h4 className="card-title mt-0 mb-md-0">Created Date : {(isView || isCancel) ? moment(orderDataById.creationDate).format('DD MMM YYYY') : currentDate.format('DD MMM YYYY')}</h4>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}