import { fetchGetAttributeFailure, fetchGetAttributeSucess, fetchSearchableAttributeSucess, fetchNonSearchableAttributeSucess } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { GET_ALL_ATTRIBUTE_API } from "../../constants/constants";

export const getAttributeByType = (attributeType, setLoading) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${GET_ALL_ATTRIBUTE_API}?type=${attributeType}`, 'GET').then((response) => {
        setLoading(false)
        const searchableAttributes = [];
        const nonSearchableAttributes = [];

        response.forEach((val) => {
            if (val.searchable) {
                searchableAttributes.push(val);
            } else {
                nonSearchableAttributes.push(val);
            }
        });

        if (searchableAttributes.length > 0) {
            dispatch(fetchSearchableAttributeSucess(searchableAttributes.reverse()));
        }

        if (nonSearchableAttributes.length > 0) {
            dispatch(fetchNonSearchableAttributeSucess(nonSearchableAttributes.reverse()));
        }
        dispatch(fetchGetAttributeSucess(response))
    }).catch((errorResp) => { 
        dispatch(fetchSearchableAttributeSucess([]));
        dispatch(fetchNonSearchableAttributeSucess([]));
        dispatch(fetchGetAttributeSucess([]))
        dispatch(fetchGetAttributeFailure(errorResp.message))
     })
}