import moment from "moment"

export const exportDataForService = (service) => {
    let serviceArray = [];
    
    let maxAttributes = Math.max(...service.map(value => value.provisioningAttributes ? value.provisioningAttributes.length : 0));
    
    service.forEach((value, index) => {
        let serviceObj = {
            "Service Name": value.name || '',
            "Service Code": value.serviceCode || '',
            "Created Date and Time": value.createdDate ? moment(value.createdDate).format("DD-MM-YYYY, hh:mm:ss A") : '',
            "Modified Date and Time": value.modifiedDate ? moment(value.modifiedDate).format("DD-MM-YYYY, hh:mm:ss A") : '',
            "Start Date and Time": value?.Validity?.from ? moment(value.Validity.from).format("DD-MM-YYYY, hh:mm:ss A") : '',
            "End Date and Time": value?.Validity?.to ? moment(value.Validity.to).format("DD-MM-YYYY, hh:mm:ss A") : '',
            "Event Configuration": value.eventConfigurations ? value.eventConfigurations.map(event => event.replace('events.', '')).join(", ") : '',
            "Usage Attributes": value.usageAttributes ? `${value.usageAttributes}` : ''
        };

        if (value.provisioningAttributes && value.provisioningAttributes.length > 0) {
            new Array(maxAttributes).fill().forEach((_, i) => {
                if (i < value.provisioningAttributes.length) {
                    const attr = value.provisioningAttributes[i];
                    serviceObj[`Provisioning Attributes Name ${i}`] = attr.name;
                    serviceObj[`Provisioning Attributes Description ${i}`] = attr.description;
                    serviceObj[`Provisioning Attributes Type ${i}`] = attr.general;
                    serviceObj[`Provisioning Attributes Value Type ${i}`] = attr.valueType;
                    serviceObj[`Provisioning Attributes Default ${i}`] = attr.default;
                    serviceObj[`Provisioning Attributes Min Value ${i}`] = attr.minValue;
                    serviceObj[`Provisioning Attributes Max Value ${i}`] = attr.maxValue;
                } else {
                    serviceObj[`Provisioning Attributes Name ${i}`] = '';
                    serviceObj[`Provisioning Attributes Description ${i}`] = '';
                    serviceObj[`Provisioning Attributes Type ${i}`] = '';
                    serviceObj[`Provisioning Attributes Value Type ${i}`] = '';
                    serviceObj[`Provisioning Attributes Default ${i}`] = '';
                    serviceObj[`Provisioning Attributes Min Value ${i}`] = '';
                    serviceObj[`Provisioning Attributes Max Value ${i}`] = '';
                }
            });
        } else {
            new Array(maxAttributes).fill().forEach((_, i) => {
                serviceObj[`Provisioning Attributes Name ${i}`] = '';
                serviceObj[`Provisioning Attributes Description ${i}`] = '';
                serviceObj[`Provisioning Attributes Type ${i}`] = '';
                serviceObj[`Provisioning Attributes Value Type ${i}`] = '';
                serviceObj[`Provisioning Attributes Default ${i}`] = '';
                serviceObj[`Provisioning Attributes Min Value ${i}`] = '';
                serviceObj[`Provisioning Attributes Max Value ${i}`] = '';
            });
        }

        serviceArray.push(serviceObj);
    });
    return serviceArray;
}

