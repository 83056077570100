import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getUsersByName: [],
    loading: false,
    error: null,
}

const  getUsersByNameSlice = createSlice({
    name: " getUsersByName",
    initialState,
    reducers: {
        fetchGetUserNameSucess: (state, action) => {
            state.loading = false
            state. getUsersByName = action.payload;
        },
        fetchGetUserNameFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchGetUserNameSucess,
    fetchGetUserNameFailure,
} =  getUsersByNameSlice.actions;

export default getUsersByNameSlice.reducer;