import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Spinner
} from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ToastContainer } from 'react-toastify';
import ParticlesAuth from '../../pages/AuthenticationInner/ParticlesAuth';
import { expiryCheck } from '../../slices/ResetPassword/expiryCheck/thunk';
import { Loader } from '../Utils/Loader';
import { ResetPasswordExpiryBox } from '../Utils/ResetPasswordExpiry';
import { CreateResetPassword } from '../../slices/ResetPassword/ResetPasswordCreate/thunk';

const ResetPassword = ({ userManager }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const validation = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required('Please enter your new password')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[@$!%*?&#]/, 'Password must contain at least one special character')
        .matches(/^\S*$/, 'Password cannot contain spaces'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Please confirm your password'),
    }),    
    onSubmit: async (values) => {
      const jsonObj = {
        "NewPassword": values.newPassword,
        "ConfirmNewPassword": values.confirmPassword,
        "PasswordUrl": token
      };

      dispatch(CreateResetPassword(jsonObj, setLoading, navigate));
    },
  });

  document.title = 'K2charge';

  useEffect(() => {
    dispatch(expiryCheck(token, setLoading, setTime));
  }, [dispatch, token]);

  const expiryError = useSelector((state) => state.ExpiryCheck.expiryCheck);
  const shouldShowResetPasswordExpiryBox =
    expiryError?.error || (Array.isArray(expiryError) && expiryError.length === 0);

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <ParticlesAuth>
      <div className='auth-page-content'>
        <Container>
          <ToastContainer position='top-center' />
          {loading && <Loader />}
          {shouldShowResetPasswordExpiryBox && time ? (
            <ResetPasswordExpiryBox />
          ) : (
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='login-box'>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-main-color'>Welcome Back!</h5>
                      <p className='text-muted'>Reset your password for K2charge</p>
                    </div>
                    <div className='p-2 mt-4'>
                      <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                        <div className='mb-3'>
                          <Label className='form-label' htmlFor='newPassword'>New Password</Label>
                          <div className='position-relative auth-pass-inputgroup mb-3'>
                            <Input
                              id='newPassword'
                              name='newPassword'
                              type={showNewPassword ? 'text' : 'password'}
                              value={validation.values.newPassword}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.newPassword && !!validation.errors.newPassword}
                              className='form-control pe-5'
                              placeholder='Enter New Password'
                            />
                            {validation.touched.newPassword && validation.errors.newPassword && (
                              <FormFeedback>{validation.errors.newPassword}</FormFeedback>
                            )}
                            <button
                              className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none'
                              onClick={toggleShowNewPassword}
                              type='button'
                              id='newPassword-addon'
                            >
                              <i className={showNewPassword ? 'ri-eye-off-fill align-middle' : 'ri-eye-fill align-middle'}></i>
                            </button>
                          </div>
                        </div>
                        <div className='mb-3'>
                          <Label className='form-label' htmlFor='confirmPassword'>Confirm Password</Label>
                          <div className='position-relative auth-pass-inputgroup mb-3'>
                            <Input
                              id='confirmPassword'
                              name='confirmPassword'
                              type={showConfirmPassword ? 'text' : 'password'}
                              value={validation.values.confirmPassword}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.confirmPassword && !!validation.errors.confirmPassword}
                              className='form-control pe-5'
                              placeholder='Confirm Password'
                            />
                            {validation.touched.confirmPassword && validation.errors.confirmPassword && (
                              <FormFeedback>{validation.errors.confirmPassword}</FormFeedback>
                            )}
                            <button
                              className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none'
                              onClick={toggleShowConfirmPassword}
                              type='button'
                              id='confirmPassword-addon'
                            >
                              <i className={showConfirmPassword ? 'ri-eye-off-fill align-middle' : 'ri-eye-fill align-middle'}></i>
                            </button>
                          </div>
                        </div>
                        <Button
                          type='submit'
                          className='btn bg-secondary w-100 mt-2'
                          disabled={loading}
                        >
                          {loading ? <Spinner size='sm' className='me-2' /> : null}
                          Reset Password
                        </Button>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </ParticlesAuth>
  );
};

export default ResetPassword;