import { POLICY_API } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { setEditSuccessMessage } from "../../toastMessage/action"
import { fetchUpdatePoliciesFailure, fetchUpdatePoliciesSuccess } from "./reducer"
import { toast } from "react-toastify"

export const updatePolicies = (setLoading,formattedValues,navigate) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${POLICY_API}`,'PUT',formattedValues).then((response)=>{
        if (!response.error) {
            setLoading(false)
            dispatch(fetchUpdatePoliciesSuccess(response))
            navigate('/policies')
            dispatch(setEditSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchUpdatePoliciesFailure(response.error.message))
        }
    }).catch((error)=>{
        toast.error(
            `${error.message}`
        )
    })
}