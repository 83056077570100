import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getAllPolicies: [],
    loading: false,
    error: null,
}

const getAllPoliciesSlice = createSlice({
    name: "getAllPolicies",
    initialState,
    reducers: {
        fetchGetAllPoliciesSuccess: (state, action) => {
            state.loading = false;
            state.getAllPolicies = action.payload;
        },
        fetchGetAllPoliciesFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchGetAllPoliciesSuccess, fetchGetAllPoliciesFailure } = getAllPoliciesSlice.actions;

export default getAllPoliciesSlice.reducer;
