import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    getBills : [],
    loading : false,
    error : null,
};

const fetchGetBillsSlice = createSlice({
    name:"getBills",
    initialState,
    reducers: {
        fetchGetBillsSuccess: (state,action) => {
            state.loading = false;
            state.getBills = action.payload
        },
        fetchGetBillsFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchGetBillsSuccess,
    fetchGetBillsFailure
} = fetchGetBillsSlice.actions

export default fetchGetBillsSlice.reducer