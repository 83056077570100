import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    resetPassword: [],
    loading: false,
    error: null,
}

const resetPasswordSlice = createSlice({
    name: "resetPassword",
    initialState,
    reducers: {
        fetchresetPasswordSuccess: (state, action) => {
            state.loading = false;
            state.resetPassword = action.payload;
        },
        fetchresetPasswordFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchresetPasswordSuccess, fetchresetPasswordFailure } = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
