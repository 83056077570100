import { Box } from '@mui/material';
import { getStatusLabel } from '../../Utils/DecideStatus';
import { getCellStyle } from '../../Utils/DecideColor';

const TableColumns = (status, handleQuantityChange, quantities,isAcknowledged) => {
    const columns = [
        { accessorKey: 'subscriptionID', header: 'Subscription ID' },
        // { accessorKey: 'serviceName', header: 'Service' },
        { accessorKey: 'offerName', header: 'Offer' },
        {
            accessorFn: (row) => {
                const start = row.subscriptionStartsOn || row.validityFrom;
                const end = row.subscriptionEndsOn || row.validityTo;
                return `${start ? start : 'N/A'} - ${end ? end : 'N/A'}`;
            },
            header: 'Duration',
        },
        {
            header: 'Quantity',
            Cell: ({ row }) => {
                const subscriptionID = row.original.subscriptionID; // Get subscription ID
                const quantity = quantities[subscriptionID] || 1; // Default to 1 if not set
                return (
                   <div className="quantity-control d-flex align-items-center">
                        <button
                            className="btn-circle"
                            type="button"
                            onClick={() => handleQuantityChange(subscriptionID, Math.max(1, quantity - 1))}
                            disabled={isAcknowledged} // Disable button if status is 'Acknowledged'
                        >
                            <span>-</span>
                        </button>
                        <input
                            type="number"
                            className="quantity-input mx-2"
                            value={quantity}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value === '') {
                                    handleQuantityChange(subscriptionID, ''); // Handle empty input
                                } else {
                                    handleQuantityChange(subscriptionID, Math.max(1, Number(value))); // Update quantity
                                }
                            }}
                            min={1}
                            disabled={isAcknowledged} // Disable input if status is 'Acknowledged'
                            style={{
                                backgroundColor: isAcknowledged ? '#E6E9EB' : '#fff',  // Dark background if isAcknowledged is true
                            }}
                        />
                        <button
                            className="btn-circle"
                            type="button"
                            onClick={() => handleQuantityChange(subscriptionID, quantity + 1)}
                            disabled={isAcknowledged} // Disable button if status is 'Acknowledged'
                        >
                            <span>+</span>
                        </button>
                    </div>
                );
            },
        },
        // {
        //     accessorFn: () => `${status}`,
        //     header: 'Status',
        //     Cell: ({ cell }) => (
        //         <Box sx={(theme) => (cell.getValue() ? {
        //             ...getCellStyle(cell.getValue(), "CreateOrder"),
        //         } : {})}>
        //             {getStatusLabel(status)}
        //         </Box>
        //     ),
        // },
        { accessorKey: 'unitPrice', header: 'Unit Price' },
        { accessorKey: 'subTotal', header: 'Subtotal' },
       
    ];

    const viewColumns = [
        { accessorKey: 'subscriptionID', header: 'Subscription ID' },
        { accessorKey: 'serviceName', header: 'Service' },
        { accessorKey: 'offerName', header: 'Offer' },
        { accessorKey: 'duration', header: 'Duration' },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => (
                <Box sx={(theme) => (cell.getValue() ? {
                    ...getCellStyle(cell.getValue(), "ViewOrder"),
                } : {})}>
                    {getStatusLabel(cell.getValue())}
                </Box>
            ),
        },
        { accessorKey: 'unitPrice', header: 'Unit Price' },
        { accessorKey: 'subTotal', header: 'Subtotal' },
    ];

    return { columns, viewColumns };
};

export default TableColumns;
