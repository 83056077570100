import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { useDispatch, useSelector } from 'react-redux';

function ComponentList() {
  const dispatch = useDispatch()
  const arr = [];
  const columns = useMemo(
    () => [
      {
        accessorKey: "of",
        header: "   Component Name",
      },
      {
        accessorKey: "offe",
        header: "Variable Name",
      },
    ],
    []
  );

  return (
    <div className="table-responsive">
      {arr ?
        (
          <MaterialReactTable columns={columns} data={arr}
            muiTableHeadCellProps={{
              sx: {
                fontFamily: "Inter,sans-serif"
              }
            }}
            icons={{
              DragHandleIcon: () => <i className="ri-drag-move-fill" />,
            }}
            muiTableBodyCellProps={{
              sx: {
                fontFamily: "Inter,sans-serif"
              }
            }}
          />
        ) : null}
    </div>
  );
}

export default ComponentList;
