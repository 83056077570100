import { useLocation, useNavigate } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import ParticlesAuth from "../../pages/AuthenticationInner/ParticlesAuth"
import { emailVerificationCheck } from "../../slices/EmailVerification/thunk"
import { useDispatch, useSelector } from "react-redux"
import { ResetPasswordExpiryBox } from "../Utils/ResetPasswordExpiry"
import { Loader } from "../Utils/Loader"
import { useEffect, useState } from "react"

export const EmailVerifiedPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    useEffect(() => {
        dispatch(emailVerificationCheck(token, setLoading, setTime));
    }, [dispatch, token]);

    const expiryError = useSelector((state) => state.EmailVerification.emailVerification);
    
    const shouldShowResetPasswordExpiryBox =
        expiryError.status === 400 || (Array.isArray(expiryError) && expiryError.length === 0);

    return (
        <ParticlesAuth>
            <div className='auth-page-content'>
                <Container>
                    {loading && <Loader/>}
                    {shouldShowResetPasswordExpiryBox && time ? (
                        <ResetPasswordExpiryBox />
                    ) : (
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="login-box">
                                    <CardBody className="p-4 text-center">
                                        <div className="avatar-lg mx-auto mt-2">
                                            <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                                <i className="ri-checkbox-circle-fill"></i>
                                            </div>
                                        </div>
                                        <div className="mt-4 pt-2">
                                            <h4>Email Verified</h4>
                                            <p className="text-muted mx-4">Your email address was successfully verified.</p>
                                            <div className="mt-4">
                                                <Button onClick={() => { navigate("/") }} className="w-100">Return to Login</Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>)}
                </Container>
            </div>
        </ParticlesAuth>
    )
}