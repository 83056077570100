import { Card, CardBody, Col } from "reactstrap"
import { LabelText } from "../../Utils/Input"
import DateTimePicker from "../../Utils/DateTimePicker"
import { ErrorMessage } from "formik"
import { handleEffectiveAtDate } from "./constants/initialValues"
import CommonDateTimePicker from "../../Utils/CommonDateTimePicker"

export const EffectiveAt = ({ touched, values, errors, setFieldTouched, setFieldValue }) => {
    return (
        <Card>
            <CardBody>
                <Col xxl={4} md={6}>
                    <LabelText htmlFor={"effectiveAt"} className={"text-danger"} displayText={"Effective Date"} important={"*"} />
                    {/* <DateTimePicker
                            className={`form-control ${touched.effectiveAt &&
                                !values.effectiveAt ? (
                                "is-invalid"
                            ) : ""}`}
                            options={{
                                dateFormat: "Y-m-d",
                                allowInput: true
                            }}
                            value={values.effectiveAt || ""}
                            placeholder="Select effective at"
                            name={`effectiveAt`}
                            autoComplete="off"
                            handleChange={handleEffectiveAtDate(setFieldTouched,setFieldValue)}
                        /> */}
                    <CommonDateTimePicker
                        className={`form-control ${touched.effectiveAt &&
                            !values.effectiveAt ? (
                            "error-input"
                        ) : ""}`}
                        value={values.effectiveAt}
                        name={`effectiveAt`}
                        handleChange={handleEffectiveAtDate(setFieldTouched, setFieldValue)}
                        format="DD/MM/YYYY"
                        views={['year', 'month', 'day']}
                    />
                    {errors.effectiveAt &&
                        touched.effectiveAt && (
                            <p className="text-danger error">
                                {" "}
                                {errors.effectiveAt}{" "}
                            </p>
                        )}
                </Col>
            </CardBody>
        </Card>
    )
}