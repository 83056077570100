import * as Yup from "yup";
import { useSelector } from "react-redux";

export const initialFormValues = {
    name: "",
    code: "",
    eventConfigurations: '',
    startsOn: null,
    endson: null,
    services: "",
    attributeList: []
};

export const validationSchema = (values, state) => {
  return Yup.object().shape({
    name: Yup.string().required('Kindly Enter the name'),
    code: Yup.mixed().required('Kindly Enter the service code'),
    eventConfigurations: Yup.mixed().required('Kindly Enter the Event Configurations'),
    startsOn: Yup.mixed().required('Kindly Enter the Start Date'),
    endson: Yup.mixed().required('Kindly Enter the End Date'),
    services: state !== false ? Yup.mixed().required('Kindly Select Service for Bundle') : Yup.mixed(),
  });
};

export const ServiceOptions = () => {
  const serviceData = useSelector((state) => state.Services.services);
  const options = serviceData?.map((item) => ({
    label: item?.name,
    value: item?.serviceCode,
  })) || [];

  return options;
};
export const EventOptions = () => {
    const eventdatas = useSelector((state) => state.Config.config);
    const eventOptions = eventdatas?.map((item) => ({
      label: item?.Name,
      value: item?.Name,
    })) ||[];
  
    return eventOptions;
  };
  export const getProvisionOptions = (provisionalAttribute) => {
    if (Array.isArray(provisionalAttribute)) {
      const provisionalItems = [];
      const usageItems = [];
  
      provisionalAttribute.forEach((item) => {
        const formattedItem = {
          label: item?.name,
          value: item?.name,
          valueType: item.valueType,
          default: item.default,
        };
  
        if (item.type === 'provisioning') {
          provisionalItems.push(formattedItem);
        } else if (item.type === 'usage') {
          usageItems.push(formattedItem);
        }
      });
  
      return { provisionalItems, usageItems };
    }
    return { provisionalItems: [], usageItems: [] };
  };
  
  
  export const getUsageOptions = () => {
    const usageAttribute = useSelector((state) => state.Uom.uom);
    const usageOptions = usageAttribute?.map((item) => ({
      label: item?.name,
      value: item?.name,
    })) || [];
  
    return usageOptions;
  };

  export const AttributeTypeOptions = [
    { value: 'Provisional Attributes', label: 'Provisional Attributes' },
    { value: 'Usage Attributes', label: 'Usage Attributes' },
  ];


//future use
// const handleAttributeList  =  (val, index, setFieldValue, setFieldTouched)  => {
//   const fieldName = `attributeList[${index}].type`;
//   const updatedValues = [...values.attributeList];
//   updatedValues[index].type = val;
//   setFieldValue(fieldName, val);
//   setFieldTouched(fieldName, false);
//   }
  
//   export const handlingAttributeList  = handleAttributeList(handleAttributeList);
//   const handleSelectedAttributeName  = (handleAttributenameList) => (values,setFieldValue,setFieldTouched) => (value) => {
//     values.attributeList[index].name    = value
//    handleAttributenameList(value,setFieldValue,setFieldTouched)
//       setFieldValue(`attributeList[${index}].name`, value);
//     }
//     export const handlingAttributeNameList  = handleSelectedAttributeName(handleSelectedAttributeName);
  




 

