import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    updateOffer : [],
    loading : false,
    error : null,
};

const updateOfferSlice = createSlice({
    name:"updateOffer",
    initialState,
    reducers: {
        fetchUpdateOfferSuccess: (state,action) => {
            state.loading = false;
            state.updateOffer = action.payload
        },
        fetchUpdateOfferFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchUpdateOfferSuccess,
    fetchUpdateOfferFailure
} = updateOfferSlice.actions

export default updateOfferSlice.reducer