import axios from "axios";
import { fetchConfigFailure, fetchConfigSuccess } from "./reducer"
import { determineBaseUrlAndPerformApiCall } from "../../../interceptor/interceptor";
import { CONFIG_API } from "../../../constants/constants";

export const getConfig = () => async (dispatch) => {
    try {
        const graphqlQuery = {
            query: "query searchConfigurations($searchText: String) {\n  searchConfigurations(searchText: $searchText) {\n    Name\n    __typename\n  }\n}",
            variables: { searchText: "events" }
        };
        const response = await determineBaseUrlAndPerformApiCall(`${CONFIG_API}`, 'POST', graphqlQuery)
        dispatch(fetchConfigSuccess(response.data.searchConfigurations));
    } catch (error) {
        dispatch(fetchConfigFailure(error.message));
    }
};

