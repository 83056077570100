import React from 'react';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';

const DashboardHeader = ({ roleName }) => {
  const trimmedRoleName = roleName ? roleName.trim() : '';
  return (
    <Col lg={12}>
      <CardHeader className='d-flex justify-content-between '>
        <h4 className='card-title mb-0 align-self-center fw-bold fs-4'>
          {roleName === 'genericAdmin'
            ? 'TOP USERS: ALL TEAM'
            : 'TOP CUSTOMERS BASED ON USAGE'}
        </h4>
      </CardHeader>
    </Col>
  );
};

export default DashboardHeader;
