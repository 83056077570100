import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getOrder: [],
    loading: false,
    error: null,
}

const getOrderSlice = createSlice({
    name: "getOrder",
    initialState,
    reducers: {
        fetchGetOrderSuccess: (state, action) => {
            state.loading = false;
            state.getOrder = action.payload;
        },
        fetchGetOrderFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchGetOrderSuccess, fetchGetOrderFailure } = getOrderSlice.actions;

export default getOrderSlice.reducer;
