import { formatPhoneNumberIntl } from "react-phone-number-input";
import { updateCustomer } from "../../../slices/customer/update/thunk";

export const handleSubmitFunction = (values, dispatch, setLoading, customerId, setEnableEdit, dialCode,navigate) => {
    let phoneNumber = formatPhoneNumberIntl(dialCode)
    let dialCodeWithPlus = phoneNumber.match(/^\+\d+(\s|$)/)[0];
    let restOfNumber = phoneNumber.slice(dialCodeWithPlus.length);
    let mobileNumberWithoutSpaces = restOfNumber.replace(/\s/g, '');
    let dialCodeWithoutSpaces = dialCodeWithPlus.replace(/\s/g, '');
    let attributesArray = []

    values?.attributeList?.forEach(item => {
        let value = item.value;
        let name = item.name
        if (typeof value === 'object' && value !== null) {
            value = value.value;
        }
        if (typeof name === 'object' && name !== null) {
            name = name.value;
        }
        if (item.value !== '') {
            attributesArray.push({
                "name": name,
                "value": value
            });
        }
    });
    const jsonData = {
        customerId: values.accountNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        userName: values.userName,
        email: values.email,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city.value,
        country: values.country.value,
        dialCode: dialCodeWithoutSpaces,
        mobile: parseInt(mobileNumberWithoutSpaces),
        state: values.state.value,
        zipCode: +values.zipCode,
        ...(attributesArray.length !== 0 && {
            attributeList: attributesArray,
        }),
    };
    dispatch(updateCustomer(jsonData, customerId, setLoading,navigate))
    setEnableEdit(false);
};
