import { Box } from "@mui/material";
import moment from "moment";
import { getCellStyle } from "../../Utils/DecideColor";
import { getStatusLabel } from "../../Utils/DecideStatus";
export const BillsColumns = (docTableMockData) => {
    const columns = [
        {
            accessorKey: 'billId',
            header: 'Invoice ID',
            size: 100,
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        color: '#3478F1',

                    } : {})}
                >
                    {cell.getValue()}
                </Box>
            )
        },
        {
            accessorKey: 'startTime',
            header: 'Start Date',
            size: 100,
            Cell: ({ cell }) => (
                <Box>
                    {moment(cell.getValue()).format("DD-MMM-YYYY")}
                </Box>
            )
        },
        {
            accessorKey: 'endTime',
            header: 'End Date',
            Cell: ({ cell }) => (
                <Box>
                    {moment(cell.getValue()).format("DD-MMM-YYYY")}
                </Box>
            )
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => (
                <Box sx={(theme) => (cell.getValue() ? {
                    ...getCellStyle(cell.getValue(),"BillsForPartner"),
                } : {})} >{getStatusLabel(cell.getValue())}</Box>
            )
        },
        {
            accessorKey: 'dueAmount',
            header: 'Balance Due'
        },
        {
            accessorKey: 'taxAmount',
            header: 'Amount'
        },
    ]
    return columns
}