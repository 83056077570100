import {  fetchDeleteUserFailure, fetchDeleteUserSuccess } from "./reducer"
import { toast } from "react-toastify";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { getAllUser } from "../thunk";
import { USER_MANAGEMENT_CREATE_API } from "../../constants/constants";

export const deleteUser = (name, setLoading,operatorId) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${USER_MANAGEMENT_CREATE_API}/${name}`, 'DELETE').then((response) => {
        if (!response.error) {
            dispatch(getAllUser(setLoading,operatorId));
            dispatch(fetchDeleteUserSuccess(response))
            setLoading(false)
            toast.success(`User Deleted Successfully`)
        }
        if (response.error) {
            dispatch(fetchDeleteUserFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}