import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    profileLogo: [],
    loading: false,
    error: null,
};

const fetchProfileLogoSlice = createSlice({
    name: "profileLogo",
    initialState,
    reducers: {
        fetchProfileLogoSuccess: (state, action) => {
            state.loading = false;
            state.profileLogo = action.payload
        },
        fetchProfileLogoFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchProfileLogoFailure,
    fetchProfileLogoSuccess
} = fetchProfileLogoSlice.actions

export default fetchProfileLogoSlice.reducer