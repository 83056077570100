 export const initialValues = {
    subscriptionID: '',
    selectDevice: '',
    subscriptionStartsOn: null,
    subscriptionEndsOn: null
}

export const initialCancelValues = {
    reason:'',
    comment:''
}

export const CancelOrderOptions = [
    { value: "Change of Mind", label: "Change of Mind" },
    { value: "Order not Satisfied", label: 'Order not Satisfied' },
    { value: "Duplicate Order", label: "Duplicate Order" },
    { value: "Other", label: "Other" }
]