import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    addDevice : [],
    loading : false,
    error : null,
};

const addDeviceSlice = createSlice({
    name:"addDevice",
    initialState,
    reducers: {
        fetchAddDeviceSuccess: (state,action) => {
            state.loading = false;
            state.addDevice = action.payload;
        },
        fetchAddDeviceFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchAddDeviceSuccess,
    fetchAddDeviceFailure
} = addDeviceSlice.actions

export default addDeviceSlice.reducer

