import { fetchGetViewFailure, fetchGetViewSucess } from "./reducer";
import { VIEW_API } from "../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";

export const getView = (setLoading) => (dispatch) => {
    setLoading(true);
    determineBaseUrlAndPerformApiCall(`${VIEW_API}`, 'GET')
        .then((response) => {
            setLoading(false);
            
            dispatch(fetchGetViewSucess(response))
               
            })
    .catch ((errorResp) => { dispatch(fetchGetViewFailure(errorResp.message)) })
}


