import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteService: [],
    loading: false,
    error: null,
}

const deleteServiceSlice = createSlice({
    name: "deleteService",
    initialState,
    reducers: {
        fetchDeleteServiceSuccess: (state, action) => {
            state.loading = false
            state.deleteService = action.payload;
        },
        fetchDeleteServiceFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchDeleteServiceSuccess,
    fetchDeleteServiceFailure,
} = deleteServiceSlice.actions;

export default deleteServiceSlice.reducer;