import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    cancelSubscription : [],
    loading : false,
    error : null,
};

const cancelSubscriptionSlice = createSlice({
    name:"cancelSubscription",
    initialState,
    reducers: {
        fetchCancelSubscriptionSuccess: (state,action) => {
            state.loading = false;
            state.cancelSubscription = action.payload
        },
        fetchCancelSubscriptionFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchCancelSubscriptionFailure,
    fetchCancelSubscriptionSuccess
} = cancelSubscriptionSlice.actions

export default cancelSubscriptionSlice.reducer