import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteUom: [],
    loading: false,
    error: null,
}

const deleteUomSlice = createSlice({
    name: "deleteUom",
    initialState,
    reducers: {
        fetchdeleteUomSuccess: (state, action) => {
            state.loading = false,
                state.deleteUom = action.payload;
        },
        fetchdeleteUomFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchdeleteUomSuccess,
    fetchdeleteUomFailure,
} = deleteUomSlice.actions;

export default deleteUomSlice.reducer;