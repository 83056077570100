
import {POLICY_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { getAllPolicies } from "../thunk";
import { fetchDeletePoliciesFailure, fetchDeletePoliciesSuccess } from "./reducer"
import { toast } from "react-toastify";
export const deletePolicies = (name,setLoading) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${POLICY_API}/${name}`, 'DELETE').then((response) => {
        if (!response.error) {
            dispatch(getAllPolicies(setLoading));
            dispatch(fetchDeletePoliciesSuccess(response))
            setLoading(false)
            toast.success(` Policy Deleted Successfully`)
           
        }
        if (response.error) {
            dispatch(fetchDeletePoliciesFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}