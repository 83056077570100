import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Input, Row } from "reactstrap"
import { LabelText } from "../Utils/Input"
import { handleCheckboxChange, handleServiceClick } from "./Constants/ModifyAddOfferFunctions"

export const AddOfferForPolicyAccordion = ({ setSelectedOnes, open, toggle, loading, filteredResults, setExpandedServices, selectedOffers, setSelectedOffers }) => {
    return (
        <Accordion open={open} toggle={toggle}>
            {loading ? (
                <p></p>
            ) : (
                <>
                    {filteredResults.map(([serviceName, offers], index) => (
                        serviceName && (
                            <AccordionItem key={index}>
                                <AccordionHeader targetId={`${index}`} onClick={() => handleServiceClick(serviceName, setExpandedServices)}>
                                    {serviceName}
                                </AccordionHeader>
                                <AccordionBody accordionId={`${index}`}>
                                    {offers.map((offer) => (
                                        serviceName && (
                                            <Row className="gy-4 mb-2 ms-2" key={offer.offerCode}>
                                                <Col xxl={1} md={6}>
                                                    <div>
                                                        <div className='m-4'>
                                                            <Input type="checkbox" className=' border-black fs-5' checked={selectedOffers.some(o => o.offerCode === offer.offerCode)} onChange={() => handleCheckboxChange(offer, selectedOffers, setSelectedOffers, setSelectedOnes)} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className='p-3' xxl={8} md={6}>
                                                    <div>
                                                        <div className='d-flex'>
                                                            <h5 className=' offer-name me-2'>{offer.offerName}</h5>
                                                            <h5 className='offer-name offer-code-color'>{offer.offerCode}</h5>
                                                        </div>
                                                        <div className='d-flex'>
                                                            {offer.Grants?.length > 0 && (
                                                                <div className='badge-for-order me-2 p-1'>
                                                                    <LabelText classLabelName={'mb-0 me-1 ps-1 badge-for-offer-count'} displayText={offer.Grants?.length} />
                                                                    <LabelText classLabelName={'mb-0'} displayText={"Grants"} />
                                                                </div>
                                                            )}
                                                            {offer.Charges?.length > 0 && (
                                                                <div className='badge-for-order me-2 p-1'>
                                                                    <LabelText classLabelName={'mb-0 me-1 ps-1 badge-for-offer-count'} displayText={offer.Charges?.length} />
                                                                    <LabelText classLabelName={'mb-0'} displayText={"Charges"} />
                                                                </div>
                                                            )}
                                                            {offer.Usage?.length > 0 && (
                                                                <div className='badge-for-order me-2 p-1'>
                                                                    <LabelText classLabelName={'mb-0 me-1 ps-1 badge-for-offer-count'} displayText={offer.Usage?.length} />
                                                                    <LabelText classLabelName={'mb-0 '} displayText={"Usage"} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={3} md={6}>
                                                    <div className='align-items-center'>
                                                        <h5 className='pe-2 mt-3' style={{ whiteSpace: 'nowrap' }}>
                                                            Term Validity: {offer.termValidity}
                                                        </h5>
                                                        <div>
                                                            <LabelText classLabelName='' displayText={`${offer.validityFrom} ${"-"} ${offer.validityTo}`} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <hr></hr>
                                            </Row>
                                        )
                                    ))}
                                </AccordionBody>
                            </AccordionItem>
                        )
                    ))}
                </>
            )}
        </Accordion>
    )
}