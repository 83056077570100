import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    expiryCheck: [],
    loading: false,
    error: null,
}

const expiryCheckSlice = createSlice({
    name: "expiryCheck",
    initialState,
    reducers: {
        fetchexpiryCheckSuccess: (state, action) => {
            state.loading = false;
            state.expiryCheck = action.payload;
        },
        fetchexpiryCheckFailure: (state, action) => {
            state.loading = false;
            state.expiryCheck = action.payload;
        },
    },
});

export const { fetchexpiryCheckSuccess, fetchexpiryCheckFailure } = expiryCheckSlice.actions;

export default expiryCheckSlice.reducer;
