import React, { useEffect, useState } from 'react';
import { InputGroup, Input, Button, InputGroupText } from 'reactstrap';
import { getCustomerAI } from '../../slices/AI/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { searchCustomerJsonData } from '../Customers/constants/CustomerJsonObj';
import ListingCustomers from '../Customers/ListingCustomers';
import { Loader } from '../Utils/Loader';
import { ToastContainer } from "react-toastify";
import CreateEditCustomer from '../Customers/CreateEditCustomer';
import { bdomDataFunction } from '../Customers/constants/TelcoSubmitFunctions';
import { fetchgetCustomerAISuccess } from '../../slices/AI/reducer';
import AddUnit from '../Pricings/Uoms/AddUnit'
import { ValueTypeOptions } from '../Pricings/Uoms/constants/initialvalues';
import AddResources from '../Pricings/Resources/AddResources';
import { typevalue } from '../Pricings/Resources/constants/selectoptions';
import AddService from '../Pricings/Services/AddService';
import moment from 'moment';

export default function CustomerAI() {
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [getTable, setGetTable] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [mobileWithDialCode, setMobileWithDialCode] = useState("");
    const [errorForMessage, setErrorForMessage] = useState('');
    const [errorIcon, setErrorIcon] = useState('');
    const [dontDisplay, setDontDisplay] = useState(false);
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setDontDisplay(false);
    };
    const handleClearInput = () => {
        setInputValue('');
        setErrorMessage(null);
        setSuccessMessage(null);
        dispatch(fetchgetCustomerAISuccess({}));
    };
    const handleGenerate = () => {
        let newTextObject = {
            text: inputValue
        };
        dispatch(getCustomerAI(setLoading, setGetTable, setErrorMessage, setSuccessMessage, newTextObject, setErrorForMessage, setErrorIcon, setDontDisplay));
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && inputValue) {
            handleGenerate();
        }
    };
    const CustomerResponse = useSelector((state) => state.GetCustomerAIbyId.GetCustomerAI);
    const searchCustomerData = useSelector((state) => state.SearchedCustomer.searchCustomer);
    const searchedCustomerDataJsonObj = searchCustomerJsonData(searchCustomerData);
    function determineFlag(response) {
        if (typeof response.text === 'object' && response.text !== null) {
            return true;
        } else {
            return false;
        }
    }
    let TableShow = determineFlag(CustomerResponse);
    let isCreateCustomer = true;
    let bdomOptions = bdomDataFunction();
    const roleName = localStorage.getItem('roleName');
    const [initialAIValues, setInitialAIValues] = useState({
        customerId: "",
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        password: "",
        mobile: "",
        bdom: { value: "", label: "" } || '',
        addressLine1: "",
        addressLine2: "",
        country: { value: "", label: "" },
        state: { value: "", label: "" },
        city: { value: "", label: "" },
        zipCode: "",
        dialCode: "",
        documentType: "",
        documentName: "",
        selectedFile: "",
        subscriptions: [],
        attributeList: [],

    });
    const [initialAIUomValues, setInitialAIUomValues] = useState({
        name: "",
        valuetype: { value: "", label: "" } || ''
    });
    const [initialAIResourceValues, setInitialAIResourceValues] = useState({
        isCurrency: '',
        name: "",
        resourceId: "",
        currencyCode: "",
        ceiling: "",
        floor: "",
        uom: '',
    });
    const [initialAIServiceValues, setInitialAIServiceValues] = useState({
        name: "",
        code: "",
        eventConfigurations: '',
        startsOn: null,
        endson: null,
        services: "",
        attributeList: []
    });
    useEffect(() => {
        if (CustomerResponse) {
            const newUomValues = {
                name: CustomerResponse?.body?.name || "",
                valuetype: ValueTypeOptions.find(attr => attr.name === 'valuetype')?.options?.find(option => option.value === CustomerResponse?.body?.valueType) || ""

            };
            setInitialAIUomValues(newUomValues);
            const newResourceValues = {
                isCurrency: typevalue.options.find(option => option.value === CustomerResponse?.body?.monetary) || '',
                name: CustomerResponse?.body?.name || "",
                resourceId: CustomerResponse?.body?.resourceId || "",
                currencyCode: { value: CustomerResponse?.body?.currencyCode, label: CustomerResponse?.body?.currencyCode },
                ceiling: CustomerResponse?.body?.ceiling?.toString() || "",
                floor: CustomerResponse?.body?.floor || "",
                uom: CustomerResponse?.body?.monetary ? '' : {
                    value: CustomerResponse?.body?.unitofmeasurement,
                    label: CustomerResponse?.body?.unitofmeasurement
                }
            };
            setInitialAIResourceValues(newResourceValues);
            const eventConfigurationsArray = CustomerResponse?.body?.eventConfigurations?.split(', ') || [];
            const eventConfigurations = eventConfigurationsArray.map(event => ({
                value: event,
                label: event
            }));
            const newServiceValues = {
                name: CustomerResponse?.body?.name,
                code: CustomerResponse?.body?.serviceCode,
                eventConfigurations: eventConfigurations,
                startsOn: moment(CustomerResponse?.body?.ValidFrom),
                endson: moment(CustomerResponse?.body?.ValidFrom),
                services: "",
                attributeList: []
            }
            setInitialAIServiceValues(newServiceValues)
            const newValues = {
                customerId: roleName === "telcoAdmin" || roleName === "telcoOperationUser" || roleName === "telcoBusinessUser" ? CustomerResponse?.body?.customerId : "",
                firstName: CustomerResponse?.body?.firstName || "",
                lastName: CustomerResponse?.body?.lastName || "",
                userName: CustomerResponse?.body?.username || "",
                email: CustomerResponse?.body?.email || "",
                password: "",
                accountNumber: CustomerResponse?.body?.customerId || "",
                mobile: CustomerResponse?.body?.mobile || "",
                bdom: { value: CustomerResponse?.body?.billingProfile?.bdom || "", label: CustomerResponse?.body?.billingProfile?.bdom || "" },
                addressLine1: CustomerResponse?.body?.addressLine1 || "",
                addressLine2: "",
                country: { value: CustomerResponse?.body?.country || "", label: CustomerResponse?.body?.country || "" },
                state: { value: CustomerResponse?.body?.state || "", label: CustomerResponse?.body?.state || "" },
                city: { value: CustomerResponse?.body?.city || "", label: CustomerResponse?.body?.city || "" },
                zipCode: CustomerResponse?.body?.zipcode || "",
                dialCode: CustomerResponse?.body?.dialCode || "",
                documentType: "",
                documentName: "",
                selectedFile: "",
                subscriptions: [],
                attributeList: [],
            };
            setInitialAIValues(newValues);
        }
    }, [CustomerResponse, roleName]);

    useEffect(() => {
        setMobileWithDialCode(`${CustomerResponse?.body?.dialCode}${CustomerResponse?.body?.mobile}`);
    }, [CustomerResponse]);
    return (
        <>
            {loading && (<Loader />)}
            <ToastContainer position="top-center" />
            <div className="page-content d-flex justify-content-center align-items-center">
                <InputGroup className='ai-input-box-field'>
                    <InputGroupText className=' bg-white border-0'>
                        <i className='mdi mdi-star-four-points-outline mdi-cog-outline fs-24'></i>
                    </InputGroupText>
                    <Input
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        placeholder="Welcome! Ask K2Charge what data to find (e.g. find customer with this msisdn number 123456)"
                        className="ai-input-box"
                    />
                    <Button
                        onClick={handleClearInput}
                        className="bg-transparent border-0 shadow-none">
                        <i className="ri-close-line fs-24 me-2 orange-main-colour"></i>
                    </Button>
                    <Button
                        onClick={handleGenerate}
                        disabled={!inputValue}
                        className='generate-button'
                    >
                        Generate
                        <i className='ri-send-plane-fill fs-18 ms-1 rotate-45'></i>
                    </Button>
                </InputGroup>
            </div>
            {errorMessage && (
                <div className="message-container">
                    <div className="error-message-box">
                        <i className="ri-information-fill icon"></i>
                        {errorMessage}
                    </div>
                </div>
            )}
            {successMessage && (
                <div className="message-container">
                    <div className="message-box success">
                        <i className="ri-information-fill icon"></i>
                        {successMessage}
                    </div>
                </div>
            )}
            <>
                {CustomerResponse?.api_endpoint === "/registerCustomer" ? (
                    <div>
                        {dontDisplay ? (
                            <CreateEditCustomer
                                initialAIValues={initialAIValues}
                                mobileWithDialCode={mobileWithDialCode}
                            />
                        ) : null}
                    </div>
                ) : CustomerResponse?.api_endpoint === "/unitOfMeasurement" ? (
                    <div>
                        {dontDisplay ? (
                            <AddUnit
                                initialAIUomValues={initialAIUomValues}
                            />
                        ) : null}
                    </div>
                ) : CustomerResponse?.api_endpoint === "/resource" ? (
                    <div>
                        {dontDisplay ? (
                            <AddResources
                                initialAIResourceValues={initialAIResourceValues}
                            />
                        ) : null}
                    </div>
                ) : CustomerResponse?.api_endpoint === "/service" ? (
                    <div>
                        {dontDisplay ? (
                            <AddService
                                initialAIServiceValues={initialAIServiceValues}
                            />
                        ) : null}
                    </div>
                ) : (
                    <div>
                        <ListingCustomers
                            AIData={searchedCustomerDataJsonObj}
                            AISetTable={setGetTable}
                            TableShow={TableShow}
                        />
                    </div>
                )}
            </>
        </>
    );
}
