import { Button } from "reactstrap";

export default function CommonButton ({
    color,type,buttonAction,buttonText,className,outline
}) {
    return(
        <Button color={color} outline={outline} className={className} type={type} onClick={buttonAction} >
            {buttonText}
        </Button>
    )
}