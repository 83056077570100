import moment from "moment";
import { termValidity } from "../../Pricings/Offers/constants/checkEmptyFields";

export const handleSelectOffer = (isEdit, selectedOffers, policyId, navigate, toast, selectAllOffers,values) => {
    if (isEdit && selectedOffers?.length > 0) {
        navigate('/update-partner-for-policy', { state: { selectedOffers, policyId, selectAllOffers } });
    }
    else if (selectedOffers?.length > 0) {
        navigate('/partner-for-policy', { state: { selectedOffers, selectAllOffers,values} });
    } else {
        toast.error('Kindly Select an Offer')
    }
};

export const filteredResultsForOffer = (result, searchTerm) => {
    return result.filter(([serviceName]) =>
        serviceName.toLowerCase().includes(searchTerm.toLowerCase())
    );
}

export const toggleSelectAll = (setSelectAllOffers, selectAllOffers, result, setSelectedOffers, setNewoffer) => {
    setSelectAllOffers(!selectAllOffers);
    if (!selectAllOffers) {
        const allOffers = result
            .filter(([serviceName]) => serviceName !== '')
            .reduce((acc, [, offers]) => {
                return [...acc, ...offers];
            }, []);
        setSelectedOffers(allOffers);
        setNewoffer(true);
    } else {
        setSelectedOffers([]);
        setNewoffer(false);
    }
};

export const handleCheckboxChange = (offer, selectedOffers, setSelectedOffers, setSelectedOnes) => {
    setSelectedOnes(true)
    const isSelected = selectedOffers.some(o => o.offerCode === offer.offerCode);
    if (isSelected) {
        setSelectedOffers(selectedOffers.filter(o => o.offerCode !== offer.offerCode));
    } else {
        setSelectedOffers([...selectedOffers, offer]);
    }
};

//future use
// export const handleCheckboxChange = (offer, selectedOffers, setSelectedOffers) => {
//     const isSelected = selectedOffers.some(o => o.offerCode === offer.offerCode);
//     if (isSelected) {
//         setSelectedOffers(prevSelectedOffers => prevSelectedOffers.filter(o => o.offerCode !== offer.offerCode));
//     } else {
//         setSelectedOffers(prevSelectedOffers => [...prevSelectedOffers, offer]);
//     }
// };

export const handleServiceClick = (serviceName, setExpandedServices) => {
    setExpandedServices((prevExpanded) => {
        if (prevExpanded.includes(serviceName)) {
            return [];
        } else {
            return [serviceName];
        }
    });
};

export const groupedResultsForOffer = (offerResponse, serviceResponse) => {
    const groupedResults = offerResponse.reduce((grouped, offer) => {
        const matchingService = serviceResponse.find(
            (service) => service.serviceCode === offer.serviceCode
        );
        const serviceName = matchingService ? matchingService.name : '';
        if (!grouped[serviceName]) {
            grouped[serviceName] = [];
        }
        const validityFrom = moment(offer.validity.from).format('MMM DD,YYYY');
        const validityTo = moment(offer.validity.to).format('MMM DD,YYYY');
        const termValidityLabel = offer.subscriptionValidity.relativeEndTimeUnit
            ? `${termValidity(
                offer.subscriptionValidity.relativeEndTimeUnit,
                offer.subscriptionValidity.relativeEndTimeValue
            ).props.children[0]}${termValidity(
                offer.subscriptionValidity.relativeEndTimeUnit,
                offer.subscriptionValidity.relativeEndTimeValue
            ).props.children[1]}`
            : "Never Ending";
        const totalChargeAmount = offer.Charges
            ? offer.Charges.reduce((sum, charge) => sum + charge.amount, 0)
            : 0;
        grouped[serviceName].push({
            offerCode: offer.offerCode,
            offerName: offer.offerName,
            validityFrom: validityFrom,
            validityTo: validityTo,
            termValidity: termValidityLabel,
            GlobalScope: offer.GlobalScope ? "Yes" : "No",
            Grants: offer.Grants ? offer.Grants : [],
            Charges: offer.Charges ? offer.Charges : [],
            Usage: offer.usageRatePlan ? offer.usageRatePlan : [],
            serviceName: serviceName,
            totalChargeAmount: totalChargeAmount,
        });
        return grouped;
    }, {});

    return groupedResults
}

export const toggleAccordion = (open, setOpen) => {
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    return toggle
}

//future use
// export const removeSelectedOffersFromFilteredResults = (selectedOffers, filteredResults) => {
//     console.log("selectedOffers",selectedOffers)
//     console.log("filteredResults",filteredResults)
//     selectedOffers.forEach(selectedOffer => {
//         filteredResults.forEach((result, index) => {
//             const [, offers] = result;
//             const indexToRemove = offers.findIndex(offer => offer.offerCode === selectedOffer.offerCode);
//             if (indexToRemove !== -1) {
//                 offers.splice(indexToRemove, 1);
//                 if (offers.length === 0) {
//                     filteredResults.splice(index, 1);
//                 }
//             }
//         });
//     });
// };