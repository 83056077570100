import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    GetCustomerAI: [],
    loading: false,
    error: null,
}

const getCustomerAISlice = createSlice({
    name: "GetCustomerAI",
    initialState,
    reducers: {
        fetchgetCustomerAISuccess: (state, action) => {
            state.loading = false;
            state.GetCustomerAI = action.payload;
        },
        fetchgetCustomerAIFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchgetCustomerAISuccess, fetchgetCustomerAIFailure } = getCustomerAISlice.actions;

export default getCustomerAISlice.reducer;
