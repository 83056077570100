import React, { useState, useEffect } from 'react';
import DashboardHeader from './DashboardHeader';
import Sidebar from './Sidebar';
import ChartSection from './ChartSection';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStation } from '../../slices/stations/thunk';
import { ToastContainer, toast } from 'react-toastify';
import { setWelcomeToast } from '../../slices/welcomeToaster/action';
import { SingleOptions } from './Constant/options';
import * as DashboardFunctions from './Constant/dashboardFunction';
import { setEditSuccessMessage, setEmailUpdateSuccessMessage, setPasswordSuccessMessage } from '../../slices/toastMessage/action';

const Dashboard = function () {
  document.title = 'Dashboard';
  const dispatch = useDispatch();
  const roleName = localStorage.getItem('roleName');
  const [selectedSingle, setSelectedSingle] = useState('');
  const [selectedDateTimeStart, setSelectedDateTimeStart] = useState(null);
  const [selectedDateTimeEnd, setSelectedDateTimeEnd] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [teamData, setTeamdata] = useState([]);
  const [metricsData, setMetricsData] = useState([]);
  const [selectedCustomerUnits, setSelectedCustomerUnits] = useState([]);
  const [selectedStation, setSelectedStation] = useState({ value: '', label: 'Select a station' });
  const [activity, setActivity] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);

  useEffect(function () {
    dispatch(getAllStation(setLoading));
  }, [dispatch]);

  const stationData = useSelector(function (state) { return state.Stations.station; }) || [];
  const showWelcomeToast = useSelector(function (state) { return state.welcomeToast.showWelcomeToast; });
  const showPasswordSuccessMessage = useSelector(
    (state) => state.SuccessToast.showPasswordSuccessMessage
  );
  const stationIDs = stationData.map(function (station) { return station.stationDetails.stationID; }) || [];
  const options = stationIDs.map(function (stationID) { return { value: stationID, label: stationID }; });

  useEffect(function () {
    const customersDatas = chartData?.[0]?.customers || [];
    setMetricsData(customersDatas);
  }, [chartData, selectedDateTimeStart, selectedDateTimeEnd]);

  const handleGroupClickCallback = function (groupName) {
    DashboardFunctions.handleGroupClick(groupName, teamData, setActivity);
  };

  const filteredActivitys = DashboardFunctions.getFilteredActivitys(teamData, searchQuery);

  const handleSelectSingleCallback = function (selectedSingle) {
    DashboardFunctions.handleSelectSingle(setSelectedCustomer, setSelectedSingle, selectedSingle);
  };

  const handleDateTimeChangeStartCallback = function (dateStr) {
    DashboardFunctions.handleDateTimeChangeStart(setSelectedDateTimeStart, dateStr);
  };

  const handleDateTimeChangeEndCallback = function (dateStr) {
    DashboardFunctions.handleDateTimeChangeEnd(setSelectedDateTimeEnd, dateStr);
  };

  const handleCustomerClickCallback = function (customer) {
    DashboardFunctions.handleCustomerClick(setSelectedCustomerUnits, setSelectedCustomer, customer);
  };

  const handleSelectChangeCallback = function (selectedStation) {
    DashboardFunctions.handleSelectChange(setSelectedStation, selectedStation);
  };

  const handleGroupClickWithHighlightCallback = function (groupName) {
    DashboardFunctions.handleGroupClickWithHighlight(handleGroupClickCallback, setActiveGroup, groupName);
  };

  const { startDateandTime, endDateandTime } = DashboardFunctions.getFormattedDateRange(selectedDateTimeStart, selectedDateTimeEnd);

  const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);
  const showEmailUpdateSuccessMessage = useSelector((state) => state.SuccessToast.showEmailUpdateSuccessMessage)
  useEffect(function () {
    if (showWelcomeToast) {
      toast.success(`Welcome ${localStorage.getItem('userName')}`);
      dispatch(setWelcomeToast(false));
    }
    else if (showPasswordSuccessMessage) {
      toast.success(`Password Changed Successfully`)
      dispatch(setPasswordSuccessMessage(false));
    }
    if (showEditSuccessMessage) {
      toast.success(`User Updated Successfully`);
      dispatch(setEditSuccessMessage(false));
    }
    if (showEmailUpdateSuccessMessage) {
      toast.success(`Verification link has been sent to your updated email`);
      dispatch(setEmailUpdateSuccessMessage(false));
    }
  }, [showWelcomeToast, showPasswordSuccessMessage, showEditSuccessMessage, showEmailUpdateSuccessMessage, dispatch]);

  return (
    <React.Fragment>
      <ToastContainer position='top-center' />
      <div className='page-content'>
        <Container fluid>
          <Card>
            <CardBody>
              <DashboardHeader roleName={roleName} />
              <Row>
                <Col lg={3}>
                  <Sidebar
                    roleName={roleName}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    metricsData={metricsData}
                    selectedCustomer={selectedCustomer}
                    handleCustomerClick={handleCustomerClickCallback}
                    teamData={teamData}
                    filteredActivitys={filteredActivitys}
                    handleGroupClickWithHighlight={handleGroupClickWithHighlightCallback}
                    activeGroup={activeGroup}
                  />
                </Col>
                <Col lg={9}>
                  <ChartSection
                    roleName={roleName}
                    selectedStation={selectedStation}
                    handleSelectChange={handleSelectChangeCallback}
                    selectedSingle={selectedSingle}
                    handleSelectSingle={handleSelectSingleCallback}
                    selectedDateTimeStart={selectedDateTimeStart}
                    selectedDateTimeEnd={selectedDateTimeEnd}
                    handleDateTimeChangeStart={handleDateTimeChangeStartCallback}
                    handleDateTimeChangeEnd={handleDateTimeChangeEndCallback}
                    options={options}
                    endDateandTime={endDateandTime}
                    startDateandTime={startDateandTime}
                    setChartData={setChartData}
                    chartData={chartData}
                    selectedCustomerUnits={selectedCustomerUnits}
                    setTeamdata={setTeamdata}
                    teamData={teamData}
                    activity={activity}
                    SingleOptions={SingleOptions}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
