import React, { useEffect, useState, useMemo } from 'react'
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import BreadCrumb from '../Common/BreadCrumb';
import { Row, Col, Button } from 'reactstrap'
import { MaterialReactTable } from 'material-react-table';
import { ToastContainer, toast } from 'react-toastify';
import { MenuItem, Box } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getOrderByCustomerId } from '../../slices/orderManagement/GetOrderByCustomerId/thunk';
import { orderData } from '../OrderManagement/constants/OrderData';
import { OrderListColumnsForCustomer } from './Constant/LightWeightColoumnforOrder';
import { PAGE_TITLES } from '../Common/constants';

function LightWeightOrder() {
    let namesArray = [];
    let createdBy = [];
    const orderDataArray = []
    const [loading, setLoading] = useState("false");
    const dispatch = useDispatch();
    const customerId = localStorage.getItem("customerId");
    useEffect(() => {
        dispatch(getOrderByCustomerId(customerId, setLoading));
    }, [])
    const orderDataById = useSelector((state) => state.GetOrderByCustomerId.getOrderByCustomerId)
    orderDataById.forEach(orderDataById => {
        let author = '-';
        if (orderDataById?.note && orderDataById?.note?.length > 0) {
            author = orderDataById?.note[0]?.author || '-';
        }
        if (orderDataById?.productOrderItem) {
            orderDataById?.productOrderItem?.forEach(productOrderItem => {
                if (productOrderItem?.note && productOrderItem?.note?.length > 0) {
                    author = productOrderItem?.note[0]?.author || '-';
                }
                createdBy.push(author);
            });
        }
    });
    const taxIncludedValues = orderDataById.map(productOrder => {
        const totalPrice = productOrder?.orderTotalPrice?.find(price => price?.priceType === "nonRecurring" || price?.priceType === "Recurring");
        return totalPrice ? totalPrice?.price?.taxIncludedAmount?.value : null;
    });
    const orderList = orderData(orderDataById, orderDataArray, taxIncludedValues, namesArray, createdBy)
    const columns = OrderListColumnsForCustomer()

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.CUSTOMER_ORDER}  pageTitle={PAGE_TITLES.ORDER}/>
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <div className="listingjs-table " id="customerList">
                                <Card>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {orderList ?
                                                <MaterialReactTable
                                                    icons={{
                                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                    }}
                                                    muiTableHeadCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    muiTableBodyCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    columns={columns}
                                                    data={orderList}
                                                    enableColumnOrdering
                                                    enableColumnDragging
                                                    enableDensityToggle={false}
                                                    enableRowActions={true}
                                                    displayColumnDefOptions={{
                                                        'mrt-row-actions': {
                                                            header: '',
                                                            enableHiding: true
                                                        }
                                                    }}
                                                    renderRowActionMenuItems={({ closeMenu, row }) => {

                                                        return [
                                                            <MenuItem
                                                                className='row-options'
                                                                key={row.index}
                                                                onClick={() => {
                                                                    closeMenu();
                                                                }}
                                                            >
                                                                < i className='ri-account-circle-line me-2' />
                                                                View
                                                            </MenuItem>,
                                                        ]
                                                    }}
                                                />
                                                : null
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default LightWeightOrder;