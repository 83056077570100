export const jsonObj = (values) => {
    const jsonObj = {
        "businessName": values.businessName,
        "type": values.type.value,
        "emailAddress": values.email,
        "mobile": values.mobile,
        "websiteHref": values.websiteLink,
        "addressLine1": values.Address,
        "addressLine2": values.addressLine2,
        "country": values.country.value,
        "state": values.state.value,
        "city": values.city.value,
        "zipcode": (values.zipCode).toString(),
        "primaryContact": {
            "firstName": values.firstName,
            "lastName": values.lastName,
            "emailAddress": values.email1,
            "mobile": values.mobile1
        },
        "partnerUser": {
            "username": values.userName,
            "userRole": values.userRole.value
        },
        "characteristic": values.attributeList
    };
    return jsonObj;
};

export const editJsonObj = (values) => {
    const editJsonObj = {
        "businessName": values.businessName,
        "type": values.type.value,
        "emailAddress": values.email,
        "mobile": values.mobile,
        "websiteHref": values.websiteLink,
        "addressLine1": values.Address,
        "addressLine2": values.addressLine2,
        "country": values.country.value,
        "state": values.state.value,
        "city": values.city.value,
        "zipcode": (values.zipCode).toString(),
        "primaryContact": {
            "id": values.id,
            "firstName": values.firstName,
            "lastName": values.lastName,
            "emailAddress": values.email1,
            "mobile": values.mobile1
        },
        "characteristic": values.attributeList
    };
    return editJsonObj;
};

export const UserRoleOptions = [
    { value: 'ISPPartnerAdmin', label: 'ISP Partner Admin' },
];

export const TypeOptions = [
    { value: 'reseller', label: 'reseller' },
];