import axios from "axios";
import { fetchDeviceSucess, fetchDeviceFailure } from "./reducer";
import { DEVICE_MANAGEMENT_API } from "../../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../../interceptor/interceptor";
export const getselectDevice = (setLoading) => async (dispatch) => {
    const operatorId = localStorage.getItem("operatorId");
    try {
        const response = await determineBaseUrlAndPerformApiCall(`${DEVICE_MANAGEMENT_API}`, 'GET')
        if (!response.error) {
            dispatch(fetchDeviceSucess(response));
        } else {
            dispatch(fetchDeviceFailure(response.error.message));
            setLoading(false);
        }
    } catch (error) {
        dispatch(fetchDeviceFailure(error.message));
        setLoading(false);
    }
};