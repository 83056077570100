import { logoViewDoc } from "../../Logo/thunk"
import { DEVICE_UPDATE_API } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { setDocumentSuccessMessage, setEditSuccessMessage } from "../../toastMessage/action"
import { fetchDocumentUploadFailure } from "../documentupload/reducer"
import { viewDoc } from "../documentview/thunk"
import { fetchDocumentUpdateFileFailure, fetchDocumentUpdateFileSuccess } from "./reducer"
import { toast } from "react-toastify";

export const documentUpdateFile = (setLoading, jsonObj, PartnerId, businessName, partnerOrCustomer, setUpdate, setFileSelected, navigate,setProfileData) => async (dispatch) => {
    setLoading(true)
    const roleName=localStorage.getItem('roleName')
    await determineBaseUrlAndPerformApiCall(`${DEVICE_UPDATE_API}`, 'PUT', jsonObj).then((response) => {
        if (!response.error) {
            setUpdate(false)
            setFileSelected(false)
            setLoading(false)
            dispatch(fetchDocumentUpdateFileSuccess(response))
            dispatch(viewDoc(setLoading, PartnerId, "logo", partnerOrCustomer,setProfileData))
            if (partnerOrCustomer === "User" && roleName!=='systemUser' ) {
                navigate("/user")
                dispatch(setEditSuccessMessage(true))
            }
            else if(partnerOrCustomer === "Customer"){
                dispatch(viewDoc(setLoading, PartnerId,"logo", partnerOrCustomer,setProfileData));
                dispatch(setEditSuccessMessage(true))
            }
            else if (partnerOrCustomer === "Operator" && roleName === "systemUser") {
                navigate('/operator-listing')
                dispatch(setEditSuccessMessage(true))
            }
            else if (partnerOrCustomer === "Partner") {
                navigate('/partner-listing')
                dispatch(setEditSuccessMessage(true))
            }
            else if(partnerOrCustomer==="Business"){
                navigate('/dashboard')
                dispatch(setEditSuccessMessage(true))
                dispatch(logoViewDoc(setLoading, PartnerId, "logo", partnerOrCustomer, setProfileData));
            }
            else {
                toast.success(
                    'Logo Updated Successfully'
                )
                dispatch(setDocumentSuccessMessage(true))
            }
        }
        if (response.error) {
            setLoading(false)
            dispatch(fetchDocumentUpdateFileFailure(`${response.error.message}`))
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchDocumentUploadFailure(response.error.message))
        }
    })
}