import { fetchDeactivateFailure, fetchDeactivateSucess } from "./reducer"
import { toast } from "react-toastify";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { DEACTIVATE } from "../../constants/constants";
import { getAllUser } from "../thunk";

export const deactivateUser = (userId, setLoading, location, operatorIdFromOptions) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`user/${userId}/${DEACTIVATE}`, 'POST').then((response) => {
        if (!response.error) {
            dispatch(fetchDeactivateSucess(response))
            setLoading(false)
            toast.success(` User Deactivated Successfully`)
            const roleName = localStorage.getItem("roleName")
            const isInView = location?.pathname?.includes('view-operator')
            let operatorId
            if (roleName === "systemUser") {
                if (isInView) {
                    operatorId = operatorIdFromOptions
                }
            }
            else {
                operatorId = localStorage.getItem("operatorId")
            }
            dispatch(getAllUser(setLoading, operatorId, location))
        }
        if (response.error) {
            dispatch(fetchDeactivateFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    })
}