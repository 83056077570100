import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    editPartner: [],
    loading: false,
    error: null,
};

const editPartnerSlice = createSlice({
    name: "editPartner",
    initialState,
    reducers: {
        fetchEditPartnerSuccess: (state, action) => {
            state.loading = false;
            state.editPartner = action.payload
        },
        fetchEditPartnerFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchEditPartnerFailure,
    fetchEditPartnerSuccess
} = editPartnerSlice.actions

export default editPartnerSlice.reducer