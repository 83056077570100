import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Container } from 'reactstrap';
import { Row, Col, Button } from 'reactstrap'
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { PAGE_TITLES } from '../Common/constants';
import { setEditSuccessMessage, setEmailUpdateSuccessMessage, setSuccessMessage } from '../../slices/toastMessage/action';
import { Loader } from '../Utils/Loader';
import { fetchAllUom } from '../../slices/uom/thunk';
import BreadCrumb from '../Common/BreadCrumb';
import { operatorColoumns } from './Constant/OperatorColoumn';
import { extractedData } from './Constant/OperatorListingJson';
import { MenuItem } from '@mui/material';
import { fetchAllOperatorList } from '../../slices/OperatorManagement/thunk';
import { setWelcomeToast } from '../../slices/welcomeToaster/action';

function OperatorList() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    let navigate = useNavigate()
    const columns = operatorColoumns()
    const dispatch = useDispatch()
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);
    const showEmailUpdateSuccessMessage = useSelector((state) => state.SuccessToast.showEmailUpdateSuccessMessage)
    const showWelcomeToast = useSelector(
        (state) => state.welcomeToast.showWelcomeToast
    );

    useEffect(() => {
        dispatch(fetchAllOperatorList(setLoading))
    }, [dispatch])

    useEffect(() => {
        if (showWelcomeToast) {
            toast.success(`Welcome ${localStorage.getItem("userName")}`);
            dispatch(setWelcomeToast(false));
        }
    }, [showWelcomeToast, dispatch]);
    const OperatorList = useSelector((state) => state.GetOperatorList.OperatorList)
    const updatedData = useMemo(() => {
        const listingData = extractedData(OperatorList);
        return listingData.map(item => ({
            ...item,
            status: item.status === 'validated' ? 'Active' : (item.status === 'initialized' ? 'Inactive' : 'Closed')
        }));
    }, [OperatorList]);
    const navigateToAddOperator = () => {
        let path = "/create-operator"
        navigate(path)
    }
    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Operator Created Successfully`);
            dispatch(setSuccessMessage(false))
        }
        if (showEditSuccessMessage) {
            toast.success(`Operator Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
        if (showEmailUpdateSuccessMessage) {
            toast.success(`Verification link has been sent to your updated email`);
            dispatch(setEmailUpdateSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage, showEmailUpdateSuccessMessage, dispatch])

    useEffect(() => {
        const filteredData = updatedData.filter(row => {
            const globalFilterLower = globalFilter.toLowerCase();
            const columnFilterChecks = columnFilters.map(filter => {
                const filterValue = filter?.value?.toLowerCase();
                const cellValue = row[filter.id]?.toString().toLowerCase();
                if (filter.id === 'status') {
                    return cellValue === filterValue;
                }
                return cellValue?.includes(filterValue);
            });

            const matchesGlobalFilter = !globalFilter || (
                (row?.endDateTime && row?.endDateTime?.toLowerCase()?.includes(globalFilterLower)) ||
                (row?.operatorId && row?.operatorId?.toLowerCase()?.includes(globalFilterLower)) ||
                (row?.organizationType && row?.organizationType?.toLowerCase()?.includes(globalFilterLower)) ||
                (row?.startDateTime && row?.startDateTime?.toLowerCase()?.includes(globalFilterLower)) ||
                (row?.status && row?.status?.toLowerCase() === globalFilterLower) ||  // Exact match for status
                (row?.tradingName && row?.tradingName?.toLowerCase()?.includes(globalFilterLower))
            );

            return matchesGlobalFilter && columnFilterChecks.every(Boolean);
        });
        setData(filteredData);
    }, [globalFilter, columnFilters, updatedData]);

    const handleCancel = () => {
        setGlobalFilter('');
        setColumnFilters([]);
        setData(updatedData);
    };

    const handleGlobalFilterChange = (filter) => {
        setGlobalFilter(filter);
        if (!filter) {
            handleCancel();
        }
    };

    const permissions = useSelector((state) => state.permissions.permissions);
    const operatorPermission = permissions.find((resource) => resource.resourceName === 'operator');
    return (
        <React.Fragment>
            <ToastContainer position="top-center" />
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.OPERATOR_LIST} pageTitle={PAGE_TITLES.OPERATOR} />
                    <Row>
                        <Col lg={12}>
                            <div className="listingjs-table " id="customerList">
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {loading && (<Loader />)}
                                            {OperatorList ?
                                                <MaterialReactTable
                                                    icons={{
                                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                    }}
                                                    enableRowActions
                                                    displayColumnDefOptions={{
                                                        'mrt-row-actions': {
                                                            header: '',
                                                            enableHiding: true,
                                                            size: 53,
                                                            grow: true
                                                        },
                                                    }}
                                                    defaultColumn={{
                                                        minSize: 10,
                                                        maxSize: 9001,
                                                        muiTableBodyCellProps: {
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif"
                                                            }
                                                        },
                                                        muiTableHeadCellProps: {
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif"
                                                            }
                                                        }
                                                    }}
                                                    renderRowActionMenuItems={({ row, closeMenu }) =>
                                                        [
                                                            <MenuItem key={`${row.index}.1`} onClick={() => { navigate(`/view-operator/${row.original.operatorId}`) }}><i className="ri-eye-line me-2"></i>View</MenuItem>,
                                                            <MenuItem key={`${row.index}.2`} onClick={() => { navigate(`/edit-operator/${row.original.operatorId}`) }} ><i className="bx bxs-edit me-2" /> Edit</MenuItem>
                                                        ]
                                                    }
                                                    renderTopToolbarCustomActions={({ }) => (
                                                        <div className="d-flex flex-column flex-md-row align-items-md-center">
                                                            {/* <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(uom) }}>
                                                                <i className="ri-upload-line label-icon align-middle fs-16 me-2 " ></i> Export Data
                                                            </Button> */}
                                                            {operatorPermission && operatorPermission.permissionList.includes('create') && <Button color="secondary" className="btn-label me-2" onClick={navigateToAddOperator}>
                                                                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Operator
                                                            </Button>}
                                                        </div>
                                                    )}
                                                    initialState={{
                                                        columnVisibility: {
                                                            'operatorId': false,
                                                        },
                                                    }}
                                                    columns={columns}
                                                    data={data}
                                                    manualFiltering
                                                    onColumnFiltersChange={setColumnFilters}
                                                    onGlobalFilterChange={handleGlobalFilterChange}
                                                    state={{ globalFilter }}
                                                    enableColumnOrdering
                                                    enableColumnDragging
                                                    enableDensityToggle={false}
                                                />
                                                : null
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default OperatorList;