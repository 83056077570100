import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    documentVerify : [],
    loading : false,
    error : null,
};

const fetchDocumentVerifySlice = createSlice({
    name:"documentVerify",
    initialState,
    reducers: {
        fetchDocumentVerifySuccess: (state,action) => {
            state.loading = false;
            state.documentVerify = action.payload
        },
        fetchDocumentVerifyFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchDocumentVerifyFailure,
    fetchDocumentVerifySuccess
} = fetchDocumentVerifySlice.actions

export default fetchDocumentVerifySlice.reducer