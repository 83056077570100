import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deletecustomer: [],
    loading: false,
    error: null,
}

const deletecustomerSlice = createSlice({
    name: "deletecustomer",
    initialState,
    reducers: {
        fetchdeletecustomeSruccess: (state, action) => {
            state.loading = false,
                state.deletecustomer = action.payload;
        },
        fetchdeletecustomerFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchdeletecustomerSuccess,
    fetchdeletecustomerFailure,
} = deletecustomerSlice.actions;

export default deletecustomerSlice.reducer;