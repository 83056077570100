import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    postReservations: [],
    loading: false,
    error: null,
}

const postReservationSlice = createSlice({
    name: "postReservations",
    initialState,
    reducers: {
        fetchPostReservationSuccess: (state, action) => {
            state.loading = false,
                state.postReservations = action.payload;
        },
        fetchPostReservationFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchPostReservationSuccess,
    fetchPostReservationFailure,
} = postReservationSlice.actions;

export default postReservationSlice.reducer;