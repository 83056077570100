import React from 'react';
import { Col, Card, CardHeader, CardBody } from 'reactstrap';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { BasicLineChartcommon } from '../../Components/Charts/Basiclinechart';
import SelectField from '../../Components/Utils/SelectField';

const ChartSection = ({
  roleName,
  selectedStation,
  handleSelectChange,
  selectedSingle,
  handleSelectSingle,
  selectedDateTimeStart,
  selectedDateTimeEnd,
  handleDateTimeChangeStart,
  handleDateTimeChangeEnd,
  options,
  endDateandTime,
  startDateandTime,
  setChartData,
  chartData,
  selectedCustomerUnits,
  setTeamdata,
  teamData,
  activity,
  SingleOptions
}) => {
  return (
    <Card>
      {roleName !== 'genericAdmin' && (
        <CardHeader>
          <div className='d-flex align-items-center'>
            <div className='col-md-3' style={{ marginRight: '10px' }}>
              <SelectField
                value={selectedStation}
                handleChange={handleSelectChange}
                options={options}
                aria-labelledby="station-label"
              />
            </div>
            <div className='col-md-3' style={{ marginRight: '10px' }}>
            <SelectField
                value={selectedSingle}
                handleChange={handleSelectSingle}
                options={SingleOptions}
              />
            </div>
            {selectedSingle.value === 'custom' && (
              <div className=' d-flex justify-content-end'>
                <div className='mr-2 col-md-5'>
                  <Flatpickr
                    defaultValue='Start Time'
                    className='form-control'
                    autoComplete="off"
                    options={{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      onChange: handleDateTimeChangeStart,
                    }}
                  />
                </div>
                <div className='col-md-5' style={{ marginLeft: '10px' }}>
                  <Flatpickr
                    defaultValue='End Time'
                    autoComplete="off"
                    className='form-control'
                    options={{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      onChange: handleDateTimeChangeEnd,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </CardHeader>
      )}
      <CardBody style={{ height: '382px' }}>
        <div>
          <BasicLineChartcommon
            selectedSingle={selectedSingle}
            selectedDateTimeEnd={endDateandTime}
            selectedDateTimeStart={startDateandTime}
            setChartData={setChartData}
            chartData={chartData}
            selectedCustomerUnits={selectedCustomerUnits}
            selectedStation={selectedStation}
            setTeamdata={setTeamdata}
            teamData={teamData}
            teamActivitydata={activity}
            dataColors='["--vz-primary"]'
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default ChartSection;
