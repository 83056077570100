import { Input } from "reactstrap"

export default function InputField({
    name,
    placeholder,
    type,
    className,
    id,
    value,
    handleChange,
    handleBlur,
    invalid,
    checked,minLength,defaultChecked,
    disabled, style, accept,maxLength,onKeyDown

}) {
    return (
        <Input
            defaultChecked={defaultChecked}
            accept={accept}
            name={name}
            type={type}
            style={style}
            maxLength={maxLength}
            placeholder={placeholder}
            className={className}
            id={id}
            minLength={minLength}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            invalid={invalid}
            disabled={disabled}
            checked={checked}
            onKeyDown={onKeyDown}
        />
    )
}