import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import InputField from '../../Utils/InputField';

const ThresholdNotification = ({
  thresholds,
  addThreshold,
  handleThresholdChange,
  removeThreshold,
  handleChange,
  touched,
  errors,
  values,
  setFieldValue,
  thresoldType,
  isEdit
}) => {
  const [activeButtons, setActiveButtons] = useState([]);
  
  const getInitialButtonState = () => {
    if (thresholds[0]?.percentage !== '') {
      return 1;
    }
    return 0; 
  };

  useEffect(() => {
    if (isEdit) {
      const newActiveButtons = thresholds.map(() => 
        thresoldType === "Percentage" ? 1 : 0
      );
      setActiveButtons(newActiveButtons);
    } else {
      const currentState = getInitialButtonState();
      setActiveButtons(new Array(thresholds.length).fill(currentState));
    }
  }, [thresholds.length, thresoldType, isEdit]);

  const toggleButton = (index, buttonIndex) => {
    if (isEdit && thresoldType) {
      return;
    }

    const newActiveButtons = [...activeButtons];
    newActiveButtons.fill(buttonIndex);
    setActiveButtons(newActiveButtons);
    thresholds.forEach((_, i) => {
      if (buttonIndex === 0) {
        handleThresholdChange(i, 'percentage', '');
      } else {
        handleThresholdChange(i, 'thresholdValue', '');
      }
    });
  };

  const handleAddThreshold = () => {
    addThreshold();
    const currentType = activeButtons[0] || 0;
    setActiveButtons([...activeButtons, currentType]);
  };

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center mb-0">
            <h5>Threshold Notification</h5>
            <button type="button" className="btn btn-primary" onClick={handleAddThreshold}>
              + Add
            </button>
          </CardHeader>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-2">Configure absolute or percentage threshold notification values.</p>
            </div>
            {thresholds.map((threshold, index) => (
              <Row key={index} className="mb-3">
                <Col lg={1} md={3} sm={3}>
                  <div className="btn-group" role="group" aria-label="Basic example">
                    <button
                      type="button"
                      className={`btn ${!isEdit ? (activeButtons[index] === 0 ? 'btn-primary' : 'btn-outline-secondary') : 
                        (thresoldType === "Fixed" ? 'btn-primary' : 'btn-outline-secondary')}`}
                      onClick={() => toggleButton(index, 0)}
                      disabled={isEdit && thresoldType === "Percentage"}
                    >
                      123
                    </button>
                    <button
                      type="button"
                      className={`btn ${!isEdit ? (activeButtons[index] === 1 ? 'btn-primary' : 'btn-outline-secondary') : 
                        (thresoldType === "Percentage" ? 'btn-primary' : 'btn-outline-secondary')}`}
                      onClick={() => toggleButton(index, 1)}
                      disabled={isEdit && thresoldType === "Fixed"}
                    >
                      %
                    </button>
                  </div>
                </Col>
                <Col lg={3} md={6} sm={6}>
                  {threshold && (
                    <InputField
                      type="number"
                      name={`thresholds[${index}].${activeButtons[index] === 0 ? 'thresholdValue' : 'percentage'}`}
                      className={
                        touched.thresholds?.[index]?.[activeButtons[index] === 0 ? 'thresholdValue' : 'percentage'] &&
                        !values.thresholds[index][activeButtons[index] === 0 ? 'thresholdValue' : 'percentage']
                          ? 'is-invalid'
                          : ''
                      }
                      value={activeButtons[index] === 0 ? threshold.thresholdValue : threshold.percentage}
                      placeholder={`Enter ${activeButtons[index] === 0 ? 'threshold value' : 'percentage'}`}
                      handleChange={(e) => {
                        handleThresholdChange(
                          index,
                          activeButtons[index] === 0 ? 'thresholdValue' : 'percentage',
                          e.target.value
                        );
                        handleChange(e);
                      }}
                      invalid={
                        errors.thresholds?.[index]?.[activeButtons[index] === 0 ? 'thresholdValue' : 'percentage'] &&
                        touched.thresholds?.[index]?.[activeButtons[index] === 0 ? 'thresholdValue' : 'percentage']
                      }
                      required
                    />
                  )}
                  {errors.thresholds?.[index]?.[activeButtons[index] === 0 ? 'thresholdValue' : 'percentage'] &&
                    touched.thresholds?.[index]?.[activeButtons[index] === 0 ? 'thresholdValue' : 'percentage'] && (
                    <p className="text-danger">
                      {errors.thresholds[index][activeButtons[index] === 0 ? 'thresholdValue' : 'percentage']}
                    </p>
                  )}
                </Col>
                <Col lg={2} md={3} sm={3} className="mt-1">
                  <Link to="#" className="link-danger fs-4" onClick={() => removeThreshold(index, setFieldValue, values)}>
                    <i data-testid="delete" className="ri-delete-bin-5-line"></i>
                  </Link>
                </Col>
              </Row>
            ))}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ThresholdNotification;