import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    editOffer : [],
    loading : false,
    error : null,
};

const editOfferSlice = createSlice({
    name:"editOffer",
    initialState,
    reducers: {
        fetchEditOfferSuccess: (state,action) => {
            state.loading = false;
            state.editOffer = action.payload
        },
        fetchEditOfferFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchEditOfferSuccess,
    fetchEditOfferFailure
} = editOfferSlice.actions

export default editOfferSlice.reducer