import { download } from "export-to-csv";
import { csvConfig } from "../../Utils/ExportToCsv";

export const handleExportDataForPartner = (value) => {
    if (value.length > 0) {
        const csvHeader = [
            "@baseType", "@type",
            "contactMedium_emailAddress", "contactMedium_city", "contactMedium_country", "contactMedium_phoneNumber", "contactMedium_socialNetworkId",
            "href", "id", "name",
            "organizationIdentification_id", "organizationIdentification_identificationType", "organizationIdentification_issuingAuthority",
            "organizationType", "relatedParty_id", "relatedParty_name", "relatedParty_role",
            "tradingName", "createdAt", "billingProfile_currentBill", "billingProfile_state", "operatorId"
        ];
        const csvRows = value.map(row => {
            return [
                row["@baseType"],
                row["@type"],
                row.contactMedium.find(cm => cm.mediumType === "email address")?.characteristic?.emailAddress || '',
                row.contactMedium.find(cm => cm.mediumType === "postal address")?.characteristic?.city || '',
                row.contactMedium.find(cm => cm.mediumType === "postal address")?.characteristic?.country || '',
                row.contactMedium.find(cm => cm.mediumType === "telephone number")?.characteristic?.phoneNumber || '',
                row.contactMedium.find(cm => cm.mediumType === "website")?.characteristic?.socialNetworkId || '',
                row.href,
                row.id,
                row.name,
                row.organizationIdentification[0]?.identificationId || '',
                row.organizationIdentification[0]?.identificationType || '',
                row.organizationIdentification[0]?.issuingAuthority || '',
                row.organizationType,
                row.relatedParty[0]?.id || '',
                row.relatedParty[0]?.name || '',
                row.relatedParty[0]?.role || '',
                row.tradingName,
                row.createdAt,
                row.billingProfile.currentBill,
                row.billingProfile.state,
                row.operatorId
            ].join(csvConfig.fieldSeparator);
        });

        const csvContent = [csvHeader.join(csvConfig.fieldSeparator), ...csvRows].join('\n');
        download(csvConfig)(csvContent);
    } else {
        console.error('No data to export.');
    }
};