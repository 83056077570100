import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    policyList : [],
    loading : false,
    error : null,
};

const policyListSlice = createSlice({
    name:"policyList",
    initialState,
    reducers: {
        fetchPolicyListSuccess: (state,action) => {
            state.loading = false;
            state.policyList = action.payload;
        },
        fetchPolicyListFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchPolicyListSuccess,
    fetchPolicyListFailure
} = policyListSlice.actions

export default policyListSlice.reducer