import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllOffers } from '../../slices/offer/thunk';
import { getAllService } from '../../slices/services/thunk';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody, Card, CardBody, Container, Row, Col, Button, Input } from 'reactstrap';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { LabelText } from '../Utils/Input';
import { termValidity } from '../Pricings/Offers/constants/checkEmptyFields';
import { useNavigate, useLocation } from 'react-router-dom';
import BreadCrumb from '../Common/BreadCrumb';
import { Loader } from '../Utils/Loader';
import { filteredResultsForOffer, groupedResultsForOffer, handleCheckboxChange, handleSelectOffer, handleServiceClick, toggleAccordion, toggleSelectAll } from './Constants/ModifyAddOfferFunctions';
import { AddOfferForPolicyAccordion } from './AddOfferForPolicyAccordion';
import { filterOfferResults } from './Constants/ModifyFilterColumns';
import { PAGE_TITLES } from '../Common/constants';

const AddOfferForPolicy = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    let location = useLocation();
    const isEdit = location.pathname.includes("update-offer-for-policy")
    const policyId = location.state?.policyId
    let values = location.state
    const [offerResponse, setOfferResponse] = useState([]);
    const [open, setOpen] = useState('1');
    const [serviceResponse, setServiceResponse] = useState([]);
    const [result, setResult] = useState([]);
    const [expandedServices, setExpandedServices] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOffers, setSelectedOffers] = useState([]);
    const [selectAllOffers, setSelectAllOffers] = useState(false);
    const [newoffer, setNewoffer] = useState(false);
    const [selectedOnes, setSelectedOnes] = useState(false)
    const navigate = useNavigate();
    const toggle = toggleAccordion(open, setOpen)
    const settlementOffers = useSelector((state) => state.SelectedOffer.settlementOffer)

    useEffect(() => {
        dispatch(fetchAllOffers(setLoading));
        dispatch(getAllService(setLoading));
        if (isEdit) { setSelectedOffers(settlementOffers) }
    }, [dispatch]);

    const serviceData = useSelector((state) => state.Services.services);
    const offer = useSelector((state) => state.Offer.offer);
    useEffect(() => {
        const sampleOfferResponse = offer;
        const sampleServiceResponse = serviceData;
        setOfferResponse(sampleOfferResponse);
        setServiceResponse(sampleServiceResponse);
    }, [offer, serviceData]);

    useEffect(() => {
        if (offerResponse.length > 0 && serviceResponse.length > 0) {
            const groupedResults = groupedResultsForOffer(offerResponse, serviceResponse)
            setResult(Object.entries(groupedResults));
        }
    }, [offerResponse, serviceResponse]);
    const filteredResults = filteredResultsForOffer(result, searchTerm)
  
    //future use
    // let notSelectingOffer
    // const notSelectedOffer = filteringOffers(filteredResults, notSelectingOffer)

    filterOfferResults(isEdit, selectedOnes, selectedOffers, filteredResults)

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    {loading && <Loader />}
                    <BreadCrumb title={PAGE_TITLES.ADD_SETTLEMENT_POLICY} pageTitle={PAGE_TITLES.SETTLEMENT_POLICY_LIST} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className='row mt-3'>
                                        <div className='col-xl-7 col-md-8 text-md-end'>
                                            <div className='search-box'>
                                                <span className='ri-search-line search-icon'></span>
                                                <input
                                                    type='text'
                                                    className='form-control bg-light'
                                                    placeholder='Search...'
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex col-xl-5 justify-content-end px-3 mt-2" style={{ marginLeft: '-15px' }}>
                                            <Input type="checkbox" className='fs-5' checked={selectAllOffers} onChange={() => toggleSelectAll(setSelectAllOffers, selectAllOffers, result, setSelectedOffers, setNewoffer)} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} />{' '}
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <AddOfferForPolicyAccordion open={open} setSelectedOnes={setSelectedOnes} toggle={toggle} loading={loading} filteredResults={filteredResults} setExpandedServices={setExpandedServices} selectedOffers={selectedOffers} setSelectedOffers={setSelectedOffers} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row lg={6} className=" justify-content-end mb-4">
                        <div className="live-preview">
                            <div className="d-flex justify-content-end gap-2 ">
                                <Button color="secondary" type="button" onClick={() => navigate('/policy')}  > Back </Button>
                                <Button color="secondary" type="button" onClick={() => handleSelectOffer(isEdit, selectedOffers, policyId, navigate, toast, selectAllOffers,values)}> Next </Button>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default AddOfferForPolicy;
