import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    OperatorList : [],
    loading : false,
    error : null,
};

const operatorListSlice = createSlice({
    name:"OperatorList",
    initialState,
    reducers: {
        fetchOperatorListSuccess: (state,action) => {
            state.loading = false;
            state.OperatorList = action.payload;
        },
        fetchOperatorListFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchOperatorListSuccess,
    fetchOperatorListFailure
} = operatorListSlice.actions

export default operatorListSlice.reducer