import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    fetchDevice : [],
    loading : false,
    error : null,
};

const fetchDeviceSlice = createSlice({
    name:"fetchDevice",
    initialState,
    reducers: {
        fetchDeviceSuccess: (state,action) => {
            state.loading = false;
            state.fetchDevice = action.payload
        },
        fetchDeviceFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchDeviceSuccess,
    fetchDeviceFailure
} = fetchDeviceSlice.actions

export default fetchDeviceSlice.reducer