import { UPDATE_OFFER_API } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { setEditSuccessMessage } from "../../toastMessage/action"
import { fetchUpdateOfferFailure, fetchUpdateOfferSuccess } from "./reducer"
import { toast } from "react-toastify"

export const updateOffer = (setLoading,offerCode,jsonObj,navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${UPDATE_OFFER_API}/${offerCode}`,'PUT',jsonObj).then((response)=>{
        if (!response.error) {
            setLoading(false)
            dispatch(fetchUpdateOfferSuccess(response))
            navigate('/offer')
            dispatch(setEditSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchUpdateOfferFailure(response.error.message))
        }
    }).catch((error)=>{
        toast.error(
            `${error.message}`
        )
    })
}