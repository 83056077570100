import { fetchRateUsageFailure, fetchRateUsageSuccess } from "./reducer";
import { toast } from "react-toastify"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { RATE_USAGE_API } from "../../constants/constants";

export const rateUsageInSimulate = (jsonObj, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${RATE_USAGE_API}`, 'POST', jsonObj).then((response) => {
        if (response.error) {
            dispatch(fetchRateUsageFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
        if (!response.error) {
            dispatch(fetchRateUsageSuccess(response))
            setLoading(false)
            toast.success(
                `Simulate Updated Successfully`,
                {
                    onClose: () => {
                        navigate('/customer');
                    },
                }
            );
        }
    }).catch((errorResp) => {
    })
}