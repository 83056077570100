import moment from "moment";
export const createJsonObj = (values, state, AddService, service) => {
  let validityArray = { from: '', to: '' };
  let provisioningAttributes = [];
  let usageAttributes = [];

  values.attributeList.forEach((value, index) => {
    if (values.attributeList[index].type.value === "Provisional Attributes") {
      provisioningAttributes.push({
        "name": values.attributeList[index].name.value,
        "value": values.attributeList[index].value,
        "valueType": values.attributeList[index].name.valueType
      });
    } else {
      usageAttributes.push(
        values.attributeList[index].name.value
      );
    }
  });

  const configuration = values.eventConfigurations.map((item) => {
    return `events.${item?.value?.replace(/ /g, '')}`;
  });

  const convertedStartDateTime = moment(values?.startsOn).startOf('day').format();
  const appendedY = moment(convertedStartDateTime).set({ hour: 0, minute: 0, second: 0}).format()
  const convertedEndDateTime = moment(values?.endson).endOf('day').format();
  const appendedZ = moment(convertedEndDateTime).utc().set({ hour: 23, minute: 59, second: 59 }).format();
  validityArray.from = appendedY.slice(0,19)+"Z"
  validityArray.to = appendedZ;

  const setBundleServices = [];
  {
    state ? values.services.forEach((item) => {
      setBundleServices.push(item.value);
    }) : null;
  }

  const jsonObj = JSON.stringify({
    name: values?.name,
    serviceCode: values?.code,
    description: values?.description,
    createdDate: values?.startsOn,
    validity: validityArray,
    eventConfigurations: configuration,
    isbundle: state,
    ...(state && {
      BundledServices: setBundleServices,
    }),
    provisioningAttributes: provisioningAttributes,
    usageAttributes: usageAttributes,
  });

  AddService(jsonObj);
};