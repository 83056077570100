// BasicLineChartcommon.js
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import getChartColorsArray from '../Common/ChartsDynamicColor';
import noData from '../../assets/images/NoData.gif';
import axios from 'axios';
import { determineBaseUrlAndPerformApiCall } from '../../slices/interceptor/interceptor';
import { DASHBOARD_METRICS_API, TOP_GROUP_METRICS_API } from '../../slices/constants/constants';

const BasicLineChartcommon = ({
  dataColors,
  selectedSingle,
  selectedDateTimeStart,
  selectedDateTimeEnd,
  setChartData,
  chartData,
  selectedCustomerUnits,
  selectedStation,
  teamData,
  setTeamdata,
  teamActivitydata
}) => {
  const [loading, setLoading] = useState(true);
  const [formattedData, setFormattedData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [activitys, setActivitys] = useState([]);
  const OperatorId = localStorage.getItem('operatorId');
  const accessToken = localStorage.getItem('token');
  const roleName = localStorage.getItem('roleName');

  const teamActivty = async () => {
    try {
      const stationDetails = await determineBaseUrlAndPerformApiCall(`${TOP_GROUP_METRICS_API}`,'GET')
      setTeamdata(stationDetails);

      const groupNameAndValue = stationDetails.map((item) => ({
        name: item.group.name,
        value: item.value
      }));

      if (groupNameAndValue.length) {
        let data = [];
        groupNameAndValue.forEach((result) => {
          data.push({
            key: result.name,
            value: result.value
          });
        });

        if (data.length) {
          data = data.sort((a, b) => a.key.localeCompare(b.key));
          const transformedData = data.map(item => ({
            x: item.key,
            y: item.value
          }));
          setActivitys(transformedData);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(true);
    }
  };

  if (roleName === 'genericAdmin') {
    useEffect(() => {
      setActivitys(teamActivitydata);
    }, [teamActivitydata]);

    useEffect(() => {
      teamActivty();
    }, []);
  }

  useEffect(() => {
    if (roleName !== 'genericAdmin') {
      const fetchData = async () => {
        try {
          let response;

          if (selectedSingle.value === 'custom') {
            response = await determineBaseUrlAndPerformApiCall(
              `${DASHBOARD_METRICS_API}?count=100&unit=Wh&duration=${selectedSingle.value}&startTime=${selectedDateTimeStart}&endTime=${selectedDateTimeEnd}&stationId=${selectedStation.value}`,
              'GET');
          } else {
            response = await determineBaseUrlAndPerformApiCall(
              `${DASHBOARD_METRICS_API}?stationId=${selectedStation.value}&count=100&unit=Wh&duration=${selectedSingle.value}`,
              'GET'
            );
          }

          setChartData(response);
          setLoading(false);
        } catch (error) {
          setLoading(true);
        }
      };
      fetchData();
    }
  }, [
    selectedSingle,
    selectedDateTimeStart,
    selectedDateTimeEnd,
    selectedStation
  ]);
  useEffect(() => {
    const units = [];

    if (Array.isArray(chartData)) {
      chartData.forEach((item) => {
        if (item && item.customers && Array.isArray(item.customers)) {
          item.customers.forEach((customer) => {
            if (customer && customer.unitsConsumed && Array.isArray(customer.unitsConsumed)) {
              customer.unitsConsumed.forEach((unitData) => {
                units.push(unitData);
              });
            }
          });
        }
      });
    } else {
      setLoading(true)
      return
    }

    if (selectedSingle.value === 'yearly') {
      const transformedData = units.map((item) => {
        const monthDict = {
          January: '01',
          February: '02',
          March: '03',
          April: '04',
          May: '05',
          June: '06',
          July: '07',
          August: '08',
          September: '09',
          October: '10',
          November: '11',
          December: '12',
        };

        const month = monthDict[item.time] || '00';
        const unit = item.unit !== undefined ? item.unit.toFixed(2) : undefined;

        return { x: `${month}-1-2023`, y: unit };
      });

      const sorted = transformedData.slice().sort((a, b) => {
        const dateA = new Date(a.x);
        const dateB = new Date(b.x);
        return dateA - dateB;
      });
      sorted.sort((a, b) => {
        const partsA = a.x.split('-');
        const partsB = b.x.split('-');
        const dateA = new Date(`${partsA[2]}-${partsA[0]}-${partsA[1]}`);
        const dateB = new Date(`${partsB[2]}-${partsB[0]}-${partsB[1]}`);

        if (dateA.getFullYear() !== dateB.getFullYear()) {
          return dateA.getFullYear() - dateB.getFullYear();
        }

        if (dateA.getMonth() !== dateB.getMonth()) {
          return dateA.getMonth() - dateB.getMonth();
        }

        return parseFloat(a.y) - parseFloat(b.y);
      });
      setSortedData(sorted);
    }

    const formattedData = units.map((item) => {
      const date = new Date(item.time);
      const formattedDate = `${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date
          .getDate()
          .toString()
          .padStart(2, '0')}-${date.getFullYear()}`;
      return { x: formattedDate, y: item.unit };
    });

    formattedData.sort((a, b) => {
      const dateA = new Date(a.x);
      const dateB = new Date(b.x);
      return dateA - dateB;
    });
    formattedData.forEach((item) => {
      if (item.y !== undefined) {
        item.y = item.y.toFixed(2);
      }
    });

    formattedData.forEach((item) => {
      item.date = new Date(item.x);
    });

    formattedData.sort((a, b) => {
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;
      return parseFloat(a.y) - parseFloat(b.y);
    });

    formattedData.forEach((item) => {
      delete item.date;
    });
    setFormattedData(formattedData);
  }, [chartData, selectedSingle]);

  useEffect(() => {
    if (selectedSingle.value === 'yearly') {
      const transformedData = selectedCustomerUnits.map((item) => {
        const monthDict = {
          January: '01',
          February: '02',
          March: '03',
          April: '04',
          May: '05',
          June: '06',
          July: '07',
          August: '08',
          September: '09',
          October: '10',
          November: '11',
          December: '12',
        };

        const month = monthDict[item.time] || '00';
        const unit = item.unit !== undefined ? item.unit.toFixed(2) : undefined;

        return { x: `${month}-1-2023`, y: unit };
      });

      const sorted = transformedData.slice().sort((a, b) => {
        const dateA = new Date(a.x);
        const dateB = new Date(b.x);
        return dateA - dateB;
      });
      sorted.sort((a, b) => {
        const partsA = a.x.split('-');
        const partsB = b.x.split('-');
        const dateA = new Date(`${partsA[2]}-${partsA[0]}-${partsA[1]}`);
        const dateB = new Date(`${partsB[2]}-${partsB[0]}-${partsB[1]}`);

        if (dateA.getFullYear() !== dateB.getFullYear()) {
          return dateA.getFullYear() - dateB.getFullYear();
        }

        if (dateA.getMonth() !== dateB.getMonth()) {
          return dateA.getMonth() - dateB.getMonth();
        }

        return parseFloat(a.y) - parseFloat(b.y);
      });

      setSortedData(sorted);
    } else {
      const formattedData = selectedCustomerUnits.map((item) => {
        const date = new Date(item.time);
        const formattedDate = `${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${date
            .getDate()
            .toString()
            .padStart(2, '0')}-${date.getFullYear()}`;
        return { x: formattedDate, y: item.unit };
      });

      formattedData.sort((a, b) => {
        const dateA = new Date(a.x);
        const dateB = new Date(b.x);
        return dateA - dateB;
      });
      formattedData.forEach((item) => {
        if (item.y !== undefined) {
          item.y = item.y.toFixed(2);
        }
      });

      formattedData.forEach((item) => {
        item.date = new Date(item.x);
      });

      formattedData.sort((a, b) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        return parseFloat(a.y) - parseFloat(b.y);
      });

      formattedData.forEach((item) => {
        delete item.date;
      });
      setFormattedData(formattedData);
    }
  }, [selectedSingle.value, selectedCustomerUnits]);

  var linechartBasicColors = getChartColorsArray(dataColors);

  let series;

  if (roleName === 'genericAdmin') {
    series = [
      {
        data: activitys,
      },
    ];
  } else {
    series = [
      {
        name: 'Units Consumed',
        data: selectedSingle.value === 'yearly' ? sortedData : formattedData,
      },
    ];
  }

  var options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4,
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1,
          },
        },
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: 'zoom',
      },
    },
    markers: {
      size: 4,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    colors: linechartBasicColors,
    title: {
      text: 'All Period',
      align: 'left',
      style: {
        fontWeight: 500,
      },
    },
    xaxis: roleName === 'genericAdmin' ? { type: 'category' } : { type: 'datetime' },

  };
  return (
    <React.Fragment>
      {loading ? (
        <div
          style={{ height: '382px' }}
          className='d-flex align-items-center justify-content-center'
        >
          <img src={noData} width={320} alt="No Data" />
        </div>
      ) : (
        <ReactApexChart
          dir='ltr'
          options={options}
          series={series}
          type='line'
          height='350'
          className='apex-charts'
        />
      )}
    </React.Fragment>
  );
};

export { BasicLineChartcommon };
