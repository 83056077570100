import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    SetPasswordCreate: [],
    loading: false,
    error: null,
}

const SetPasswordCreateSlice = createSlice({
    name: "SetPasswordCreate",
    initialState,
    reducers: {
        fetchSetPasswordCreateSucess: (state, action) => {
            state.loading = false
            state.SetPasswordCreate = action.payload;
        },
        fetchSetPasswordCreateFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchSetPasswordCreateSucess,
    fetchSetPasswordCreateFailure,
} = SetPasswordCreateSlice.actions;

export default SetPasswordCreateSlice.reducer;