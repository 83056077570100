import { fetchCreateAttributeFailure,fetchCreateAttributeSucess } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { CREATE_EDIT_GET_ATTRIBUTE_API } from "../../constants/constants";
import { setSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";

export const createAttribute = (jsonObj, setLoading, navigate) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${CREATE_EDIT_GET_ATTRIBUTE_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchCreateAttributeSucess(response))
            navigate('/attribute');
            dispatch(setSuccessMessage(true))
        }
        if (response.error) {
            dispatch(fetchCreateAttributeFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}