import { Box } from "@mui/material";
import moment from "moment";
import { getStatusLabel } from "../../Utils/DecideStatus";
import { getCellStyle } from "../../Utils/DecideColor";

export const subscriptionColumns = [
    {
        accessorKey: 'offerName',
        header: 'Offer Name',
        size: 150,
    },
    {
        accessorKey: 'validFrom',
        header: 'Offer Starts',
        Cell: ({ cell }) => moment(cell.getValue()).format("DD MMM YYYY"),
        size: 150,
    },
    {
        accessorKey: 'validTo',
        header: 'Offer Ends',
        Cell: ({ cell }) => moment(cell.getValue()).format("DD MMM YYYY"),
        size: 150,
    },
    {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
        Cell: ({ cell }) => (
            <Box sx={(theme) => (cell.getValue() ? {
                ...getCellStyle(cell.getValue(), "Station"),
            } : {})}>
                {getStatusLabel(cell.getValue())}
            </Box>
        )
    },
    {
        accessorKey: 'addOnOffer',
        header: 'Type',
        size: 150,
        Cell: ({ cell }) => cell.getValue() ? 'Add-On' : 'Base Offer',
    }
];
