import axios from "axios";
import { fetchPostReservationSuccess, fetchPostReservationFailure } from "./reducer";
import { toast } from "react-toastify"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { POST_RESERVATION_API } from "../../constants/constants";
import { setSuccessMessage } from "../../toastMessage/action";

export const postReservation = (requestBody, isEdit, navigate, setLoadingData) => async (dispatch) => {
  setLoadingData(true)
  determineBaseUrlAndPerformApiCall(`${POST_RESERVATION_API}`, 'POST', requestBody)
    .then((response) => {
      if (!response.error) {
        setLoadingData(false)
        dispatch(fetchPostReservationSuccess(response))
        navigate('/reservation')
        dispatch(setSuccessMessage(true))
      }
      if (response.error) {
        setLoadingData(false)
        dispatch(fetchPostReservationFailure(response.error.message))
        toast.error(
          `Error ${isEdit ? 'updating' : 'creating'} reservation: ${response.error.message
          }`
        )
      }
    })
    .catch((error) => { });
}