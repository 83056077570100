import { Box } from '@mui/material';
import { getCellStyle } from '../../Utils/DecideColor';
import { getStatusLabel } from '../../Utils/DecideStatus';

export const OrderListColumnsForCustomer = () => {
    const columns = [
        {
            accessorKey: 'id',
            header: 'Order ID ',
        },
        {
            accessorKey: 'createdDate',
            header: 'Created Date',
        },
        {
            accessorKey: 'createdBy',
            header: 'Created by',
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
        },
        {
            accessorKey: 'state',
            header: 'Status',
            Cell: ({ cell }) => (
                <Box sx={(theme) => (cell.getValue() ? {
                    ...getCellStyle(cell.getValue(),"OverallOrders"),
                } : {})} >{getStatusLabel(cell.getValue())}</Box>
            )
        },
    ]
    return columns
}