import { GET_BILLS_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchGetBillsFailure,fetchGetBillsSuccess } from "./reducer";

export const fetchBillsForCustomer = (customerId,setLoading) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${GET_BILLS_API}/${customerId}`,'GET').then((response)=>{
        setLoading(false)
        dispatch(fetchGetBillsSuccess(response))
    }).catch((error)=>{
        setLoading(false)
        dispatch(fetchGetBillsFailure(error.message))
    })
}