import { fetchAllPartner } from "../../PartnerMangement/thunk"
import { CREATE_POLICY_API } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { fetchAllOffers } from "../../offer/thunk"
import { fetchGetPolicyFailure, fetchGetPolicySuccess,fetchPartnerId} from "./reducer"

export const getPolicy = (policyId,setLoading,initialValues,setIsAllOffersForEdit,setIsAllPartnersForEdit) => async (dispatch) =>  {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${CREATE_POLICY_API}/${policyId}`,'GET').then((response)=>{
        if (!response.error) {
            setLoading(false)
            dispatch(fetchGetPolicySuccess(response))
            initialValues.policyName = response.name
            initialValues.priority = response.precedence
            if ("commissions" in response) {
                initialValues.commissionRules = []
                response.commissions.forEach((resp, index) => {
                    initialValues.commissionRules.push({
                        "charge": { "value": resp.chargeType, "label": resp.chargeType },
                        "type": { "value": resp.commissionType, "label": resp.commissionType },
                        "value":resp.commissionValue
                    })
                })
            }
            if(response.allOffers === true){
                setIsAllOffersForEdit(true)
            }
            else{
                setIsAllOffersForEdit(false)
            }
            if(response.allPartners === true){
                setIsAllPartnersForEdit(true)
            }
            else{
                setIsAllPartnersForEdit(false)
            }
            dispatch(fetchPartnerId(response.partners))
            dispatch(fetchAllOffers(setLoading))
            
        }
        if (response.error) {
            setLoading(false)
            dispatch(fetchGetPolicyFailure(response.error.message))
        }
    })
}