import moment from "moment"
import { extractPartnerData, formatOfferData } from "./ModifyFilterColumns";

export const policyDataFunction = (data) => {
    let dataArray = []
    data.forEach((value, index) => {
        const commissions = data[0]?.commissions || [];
        dataArray.push({
            id: value.id,
            name: value.name,
            offer: value.offers ? value.offers : ["All Offers"],
            partner: value.partners ? value.partners : ["All Partners"],
            priority: value.precedence,
            createdBy: value.createdBy ? value.createdBy : "-",
            createdAt: moment(value.createdAt).format("DD/MM/YYYY")
        })
    })
    return dataArray
}

export const isEmptyOrInvalidFieldForCommission = (index, values) => {
    const previousField = values.commissionRules[index];
    return !previousField || !previousField.charge || !previousField.type || !previousField.value;
}

export const tableDataFunctions = (selectedOffers, tableDataArray) => {
    selectedOffers.forEach((value, index) => {
        tableDataArray.push({
            "offerName": value.offerName,
            "offerCode": value.offerCode,
            "GlobalScope": value.globalScope === false ? "No" : "Yes",
            "validityFrom": moment(value.validity.from).format('MMM DD,YYYY'),
            "validityTo": moment(value.validity.to).format('MMM DD,YYYY'),
        })
    })
}

export const columnDataFormatForRatePlan = (offer, grant, charge, usage, grantsCount, chargeCount, usageCount) => {
    offer.forEach((value, index) => {
        if (offer[index]?.Charges?.length > 0) {
            charge[index] = "Charge"
            chargeCount[index] = offer[index]?.Charges?.length
        }
        else {
            charge[index] = ""
        }
        if (offer[index]?.Usage?.length > 0) {
            usage[index] = "Usage"
            usageCount[index] = offer[index]?.Usage?.length
        }
        else {
            usage[index] = ""
        }
        if (offer[index]?.Grants?.length > 0) {
            grant[index] = "Grant"
            grantsCount[index] = offer[index]?.Grants?.length
        }
        else {
            grant[index] = ""
        }
    })
}

export const createPolicyDataFunction = (useSelector, uniqueOfferCodes, partnerIdsss,partnerIdArray,partnerdatas,fetchPartner,orderData) => {
    const operatorId = localStorage.getItem('operatorId');
    let uniquePartnerIds = new Set();
    let partnerArray = []
    partnerdatas.forEach((value) => {
        fetchPartner.forEach((item)=>{
            if(value === item.id.split("/").pop()){
                partnerArray.push(extractPartnerData(item, 'extractedId'));
            }
        })
        
    });
    partnerdatas.forEach((item)=>{
        if (!uniquePartnerIds.has(item.extractedId)) {
            uniquePartnerIds.add(`${operatorId}/${item.extractedId}`);
        }
    })
    let offerCodes = orderData?.map(obj => obj.offerCode);
    uniqueOfferCodes = [...new Set(offerCodes)];
    partnerIdsss = [...uniquePartnerIds];
    return { uniqueOfferCodes, partnerIdsss,partnerIdArray,partnerArray }
}

export const editPolicyDataFunction = (partnerJson, partnerId, useSelector, selectedOffers, allOffers, allPartners,getPolicyData,fetchPartners,serviceData,PartnerData,fetchOffers) => {
    let partnerEditArray = []
    PartnerData.forEach((item) => {
        if (!allPartners) {
            fetchPartners?.forEach((value) => {
                if (value === item.id) {
                    partnerEditArray.push(extractPartnerData(item, 'extractedId'));
                }
            })
        }
        else {
            partnerEditArray.push(extractPartnerData(item, 'extractedId'));
        }
    });

    let outputObject = {};
    let partnerData = !allPartners ? fetchPartners : PartnerData
    partnerData?.forEach(item => {
        const partnerId = allPartners ? item?.id?.split('/')[1] : item?.split('/')[1]
        if (!outputObject.partnerId) {
            outputObject.partnerId = [];
        }
        outputObject.partnerId.push(partnerId);
    })
    if (partnerJson) {
        outputObject = JSON.parse(partnerJson);
    }
    outputObject.partnerId = outputObject.partnerId || [];

    if (partnerId) {
        outputObject.partnerId.push(...partnerId);
    }

    partnerJson = JSON.stringify(outputObject);
    partnerJson?.partnerId?.push(partnerId)

    let serviceName
    let uniqueOffCodesEditPayload = []
    let offerEditArray = []
    fetchOffers.forEach((value, index) => {
        if (allOffers) {
            if (value.serviceCode) {
                serviceData.forEach((vals, index) => {
                    if (vals.serviceCode === value.serviceCode) {
                        serviceName = vals.name
                    }
                })
                offerEditArray.push(formatOfferData(value, serviceName))
                uniqueOffCodesEditPayload?.push(value.offerCode);
            }
        }
        else {
            getPolicyData?.offers?.forEach((val, idx) => {
                if (val === value.offerCode) {
                    serviceData.forEach((vals, index) => {
                        if (vals.serviceCode === value.serviceCode) {
                            serviceName = vals.name
                        }
                    })
                    offerEditArray.push(formatOfferData(value, serviceName))
                    uniqueOffCodesEditPayload?.push(value.offerCode);
                }
            })
        }
    })

    if (selectedOffers) {
        offerEditArray.push(...selectedOffers)
    }
    if (partnerId) {
        let selectedPartnerArray = []
        PartnerData.forEach((item) => {
            partnerId.forEach((value) => {
                if (value === item.id.split('/').pop()) {
                    selectedPartnerArray.push(extractPartnerData(item, 'extractedId'));
                }
            })
        })
        partnerEditArray.push(...selectedPartnerArray)
    }

    return { offerEditArray, uniqueOffCodesEditPayload, partnerJson, partnerEditArray }
}