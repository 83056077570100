import { Card, CardBody, CardHeader, InputGroup, InputGroupText, Col, Row } from "reactstrap";
import InputField from "../../Utils/InputField";
import SelectField from "../../Utils/SelectField";
import {  handleServiceForOffer } from "./constants/checkEmptyFields";

export default function ServiceForOffer({
    values, errors, touched,
    serviceData, isEdit, isSubscribed,
    serviceOptions,
    setServiceCode, setSelectedService,
    handleChange,
    setFieldValue
}) {
    return (
        <Card>
            <CardHeader>
                <h4 className={`card-title mb-0 flex-grow-1 ${isSubscribed ? 'title-disable-color-edit-offer' : ''}`}>Service Name<span className="text-danger"> *</span></h4>
            </CardHeader>
            <CardBody>
                <Row>
                <Col xxl={4} md={6}>
                    <SelectField
                        className={(errors.serviceName && touched.serviceName) ? 'error-input' : 'mb-3'}
                        placeholder={"Select a Service"}
                        name={`serviceName`}
                        labelName={"serviceName"}
                        value={values.serviceName} isDisabled={isSubscribed ? true : false}
                        // value = {initialValues.serviceName ? serviceOptions?.filter((serviceName) => {
                        //     serviceName.label === initialValues.serviceName.label
                        // }) : values.serviceName}
                        handleChange={handleServiceForOffer(values, setFieldValue)}
                        options={serviceOptions} />
                        
                </Col>
                <Col xxl={6} md={6}>
                    {serviceData.filter((service) =>
                        service.serviceCode === values.serviceName?.value
                    )?.map((value, index) => {
                        return value.provisioningAttributes?.map((attribute) => (
                            <div key={index}>
                                <InputGroup className="mb-4">
                                    <InputGroupText>
                                        {attribute.name}
                                    </InputGroupText>
                                    <InputField disabled={true} value={attribute.default} handleChange={handleChange} />
                                </InputGroup>
                            </div>
                        ))
                    })
                    }
                </Col>
                </Row>
                {touched.serviceName && errors.serviceName ? (
                    <p className="text-danger">
                        {errors.serviceName}
                    </p>
                ) : null}
            </CardBody>
        </Card>
    )
}