import { Box } from "@mui/material";
import { getCellStyle } from "../../Utils/DecideColor";
import moment from "moment";

export const deviceColumns = (formattedDates) => {
  return [
    {
      accessorKey: "deviceId",
      header: "Device ID",
      size: 150,
    },
    {
      accessorKey: "deviceType",
      header: "Device Type",
      size: 150,
    },
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ cell }) => (
        <Box
          sx={(theme) => (cell.getValue() ? {
            ...getCellStyle(cell.getValue(), "Device"),
          } : {})}
        >
          {cell.getValue()}
        </Box>
      ),
      size: 150,
    },
    {
      accessorKey: "customerName",
      header: "Customer Name",
      size: 150,
      Cell: ({ cell }) => {
        const customerName = cell.row.original.customerName;
        return (
          <Box>
            {customerName ? customerName : "-"}
          </Box>
        );
      }
    },
    {
      accessorKey: "customerId",
      header: "Customer Id",
      size: 150,
      Cell: ({ cell }) => {
        const customerId = cell.row.original.customerId;
        return (
          <Box>
            {customerId ? customerId : "-"}
          </Box>
        );
      }
    },
    {
      accessorKey: "subscriptionId",
      header: "Subscription ID",
      size: 150,
      Cell: ({ cell }) => {
        const subscriptionId = cell.row.original.subscriptionId;
        return (
          <Box>
            {subscriptionId ? subscriptionId : "-"}
          </Box>
        );
      }
    },
    {
      accessorKey: `createdAt`,
      header: "Created Date",
      size: 150,
      Cell: ({ cell }) => formattedDates[cell.row.index],
    },
  ];
};
