import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    deleteOffer : [],
    loading : false,
    error : null,
};

const deleteOfferSlice = createSlice({
    name:"deleteOffer",
    initialState,
    reducers: {
        fetchDeleteOfferSuccess: (state,action) => {
            state.loading = false;
            state.deleteOffer = action.payload
        },
        fetchDeleteOfferFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchDeleteOfferFailure,
    fetchDeleteOfferSuccess
} = deleteOfferSlice.actions

export default deleteOfferSlice.reducer