import { Input } from 'reactstrap';
import { handleCheckboxChange } from './ModifyAddPartnerFunctions';

export const partnerListInsidePolicy = (organizationsWithMaidIds,organizationsWithPhoneNum,formattedOrganizations,setSelectedPartners,selectedPartners,allData,setSelectedOnes) => {
    const columns = [
        {
            accessorKey: ' ',
            header: '',
            enableColumnActions: false,
            enableColumnDragging:false,
            Cell: ({ row }) => (
                <Input type="checkbox" className=' border-black fs-5' checked={selectedPartners.some(p => p.extractedId === row.original.extractedId)} onChange={() => {handleCheckboxChange(row, selectedPartners, setSelectedPartners,setSelectedOnes)}} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} />
            ),
            size:60
        },
        {
            accessorKey: 'extractedId',
            header: 'Partner ID',
        },
        {
            accessorKey: 'tradingName',
            header: 'Business Name',
        },
        {
            accessorKey: 'organizationType',
            header: 'Type',
        },
        {
            accessorFn: (PartnerData, index) => `${organizationsWithMaidIds[index].maidIds}`,
            header: 'Email',
        },
        {
            accessorFn: (PartnerData, index) => `${organizationsWithPhoneNum[index].PhoneNums}`,
            header: 'Phone',
        },
        {
            accessorFn: (PartnerData, index) => `${formattedOrganizations[index].createdAt}`,
            header: 'Created On',
        },
    ]
    return columns
}