import { useMemo } from "react";
import { Button, Input } from "reactstrap";
import { Box } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import InputField from "../../Utils/InputField";
import SelectField from "../../Utils/SelectField";
import { DocumentUploadOptions } from "./constantValues";
import { approveDocument, editDocument, formatNumber, rejectDocument } from "./HandleChangeFunctions";
import moment from "moment";
import { approveButtonFunction, callEditDocument, dontCallEditDocument, handleEditedDocumentName, handleEditedDocumentType, rejectButtonFunction } from "./DocumentTableFunctions";
import { getCellStyle } from "../../Utils/DecideColor";
import { getStatusLabel } from "../../Utils/DecideStatus";

export const customerColumns = (selectAllCustomers, setSelectAllCustomers, customers, setSelectedCustomer, selectedCustomer, checkboxRef, isMobile) => {
    const columns = [
        {
            accessorKey: 'checkbox',
            header: 'checkbox',
            Header: ({ row }) => (
                <Input type="checkbox" ref={checkboxRef} className=' border-black checkbox-size rounded-1' checked={selectAllCustomers} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
                    setSelectAllCustomers(!selectAllCustomers)
                    if (!selectAllCustomers) {
                        setSelectedCustomer(customers)
                    }
                    else {
                        setSelectedCustomer([])
                    }
                }} />
            ),
            enableColumnActions: false,
            enableColumnDragging: false,
            showColumnFilters: false,
            enableColumnFilter: false,
            Cell: ({ row }) => (
                <Input type="checkbox" className=' border-black checkbox-size  rounded-1' checked={selectedCustomer.some(p => p.CustomerId === row.original.CustomerId)} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
                    handleCheckboxChange(row, selectedCustomer, setSelectedCustomer)
                }
                } />
            ),
            size: isMobile ? 50 : 27
        },
        {
            accessorKey: "CustomerId",
            header: "Customer ID",
            size: 150,
        },
        {
            accessorFn: (row) => `${row.Name}`,
            id: "FullName",
            header: "Full Name",
            size: 150,
        },
        {
            accessorKey: "Email",
            header: "Email",
            size: 200,
        },
        {
            accessorKey: "Mobile",
            header: "Phone",
            size: 150,
        },
        {
            accessorKey: "Address",
            header: "Address",
            size: 150,
        },
    ];
    return columns;
};

export const reservationColumns = (date, startDuration, endDuration, hours, stationName, stationAddress) => {
    const columns = [
        {
            accessorFn: (reservation, index) => `${date[index]}`,
            header: 'Date',
            size: 150,
        },
        {
            accessorFn: (reservation, index) => `${startDuration[index]} - ${endDuration[index]}`,
            header: 'Duration',
            size: 150,
        },
        {
            accessorFn: (reservation, index) => `${hours[index]}`,
            header: 'Hours',
            size: 150,
        },
        {
            accessorFn: (stations, index) => `${stationName[index]}`,
            header: "Station Name",
            size: 150
        },
        {
            accessorFn: (stations, index) => `${stationAddress[index]}`,
            header: "Station Address",
            size: 200
        }
    ]
    return columns
}
export const subscriptionColumns = (termValidity, validFrom, validTo, status, addOnOffer) => {
    const columns = [
        {
            accessorKey: `offerName`,
            header: 'Offer Name',
            size: 150,
        },
        {
            accessorFn: (offer, i) => `${termValidity[i]}`,
            header: 'Term Validity',
            size: 150,
        },
        {
            accessorFn: (offer, i) => `${validFrom[i]}`,
            header: 'Offer Starts',
            size: 150,
        },
        {
            accessorFn: (offer, i) => `${validTo[i]}`,
            header: 'Offer Ends',
            size: 150,
        },
        {
            accessorFn: (offer, i) => `${status[i]}`,
            header: 'Status',
            size: 150,
            Cell: ({ cell }) => (
                <Box sx={(theme) => (cell.getValue() ? {
                    ...getCellStyle(cell.getValue(), "Station"),
                } : {})} >{getStatusLabel(cell.getValue())}</Box>
            )
        },
        {
            accessorFn: (offer, i) => `${addOnOffer[i]}`,
            header: 'Offer Type',
            size: 150,
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        ...getCellStyle(cell.getValue(), "Subscriptions"),
                    } : {})}
                >
                    {cell.getValue()}
                </Box>
            )
        },
    ]
    return columns
}

export const documentTableColumns = (documents, open, setIsOpen, setButtonText, setMessageForPopUp, fields, setFields, handleChange, editingRow, editedDocumentName, setEditedDocumentName, editedDocumentType, setEditedDocumentType, customerId, approvePayload, rejectPayload, dispatch, setLoading) => {
    const allApproved = documents.every(doc => doc.status === 'Approved');
    const conditionCheck = !allApproved;
    const roleName = localStorage.getItem('roleName')
    const columns = [
        {
            accessorKey: 'documentName',
            header: 'Document Name',
            Cell: ({ cell, row }) => {
                return (
                    (fields && editingRow === row.index) ?
                        <InputField type={'text'} className={'form-control'} name={`documents_${row.index}`} handleChange={(e) => setEditedDocumentName(e.target.value)} value={editedDocumentName} />
                        : cell.getValue()
                )
            }
        },
        {
            accessorKey: 'fileName',
            header: 'File Name'
        },
        {
            accessorKey: 'documentType',
            header: 'Document Type',
            Cell: ({ cell, row }) => {
                const docType = { value: row.original.documentType, label: row.original.documentType }
                return (
                    (fields && editingRow === row.index) ?
                        <div className="d-flex justify-content-center align-items-center  ">
                            <SelectField menuPortalTarget={document.querySelector('body')} className={"me-2"} value={editedDocumentType} handleChange={(selectedOption) => setEditedDocumentType(selectedOption)} options={DocumentUploadOptions} />
                            <i className="ri-check-line me-2 fs-4" onClick={() => { editDocument(customerId, row.original.documentName, editedDocumentName, editedDocumentType, dispatch, setLoading), setFields(!fields) }} /><i className="ri-close-fill fs-4" onClick={() => setFields(!fields)} />
                        </div>
                        : cell.getValue()
                )
            },
            size: fields ? 225 : 150
        },
        {
            accessorKey: 'uploadedtime',
            header: 'Uploaded Date & Time',
            size: 200,
            Cell: ({ cell }) => (
                <Box>
                    {moment(cell.getValue()).format('MM/DD/YYYY hh:mm A')}
                </Box>
            )
        },
        // {
        //     accessorKey: 'uploadedBy',
        //     header: 'Uploaded By'
        // },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        ...getCellStyle(cell.getValue(), "Document"),
                    } : {})}
                >
                    {cell.getValue()}
                </Box>
            )
        },
        ...(conditionCheck && !roleName === "Customer" ? [
            {
                header: '',
                id: 'statusButton',
                Cell: ({ cell, row }) => (
                    row.original.status === 'Pending' && (
                        <div>
                            <Button onClick={() => approveButtonFunction(setIsOpen, setButtonText, setMessageForPopUp, customerId, row.original.documentName, approvePayload)} type="button" className="p-1 me-2 approve-or-reject" color='main-color'>
                                Approve
                            </Button>
                            <Button onClick={() => rejectButtonFunction(setIsOpen, setButtonText, setMessageForPopUp, customerId, row.original.documentName, rejectPayload)} type="button" className="p-1 approve-or-reject cancel-button-background" outline={true} color="main-color">
                                Reject
                            </Button>
                        </div>
                    )
                )
            }
        ] : []),
    ]
    const filteredColumns = columns.filter(column => documents.every(doc => doc.status !== 'pending'));
    return filteredColumns;
}


export const invoiceListColumns = () => {
    const columns = [
        {
            accessorKey: 'id',
            header: 'Invoice ID',
            Cell: ({ cell }) => (
                <Box sx={{ color: "#3478F1" }}>{cell.getValue()}</Box>
            ),
        },
        {
            accessorKey: 'customer',
            header: 'Customer',
            Cell: ({ cell }) => (
                <Box>
                    {cell.getValue() === "" ? "-" : cell.getValue()}
                </Box>
            )
        },
        {
            accessorKey: 'email',
            header: 'Email',
            Cell: ({ cell }) => (
                <Box>
                    {cell.getValue() === "" ? "-" : cell.getValue()}
                </Box>
            )
        },
        {
            accessorKey: 'date',
            header: 'Date',
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            Cell: ({ cell }) => (
                <Box>
                    {formatNumber(cell.getValue())}
                </Box>
            )
        },
        {
            accessorKey: 'balance',
            header: 'Balance Due',
            Cell: ({ cell }) => (
                <Box>
                    {formatNumber(cell.getValue())}
                </Box>
            )
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        ...getCellStyle(cell.getValue(), "InvoiceForAll"),
                    } : {})}
                >
                    {getStatusLabel(cell.getValue())}
                </Box>
            ),
        },
    ]
    return columns
}

export const invoiceColumns = () => {
    const columns = [
        {
            accessorKey: 'id',
            header: 'Invoice ID',
            size: 100,
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        color: '#3478F1',

                    } : {})}
                >
                    {cell.getValue()}
                </Box>
            )
        },
        {
            accessorKey: 'date',
            header: 'Created Date',
            size: 100,
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            Cell: ({ cell }) => (
                <Box>
                    {formatNumber(cell.getValue())}
                </Box>
            )
        },
        {
            accessorKey: 'balance',
            header: 'BalanceDue',
            Cell: ({ cell }) => (
                <Box>
                    {formatNumber(cell.getValue())}
                </Box>
            )
        },
        {
            accessorKey: 'notifiedDate',
            header: 'Notified Date'
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => (
                <Box sx={(theme) => (cell.getValue() ? {
                    ...getCellStyle(cell.getValue(), "InvoiceForCustomer")
                } : {})} >{getStatusLabel(cell.getValue())}</Box>
            )
        },
        {
            accessorKey: ' ',
            header: '',
            Cell: ({ row }) => (
                <Checkbox className="p-0"
                    checked={row.isSelected}
                />
            ),
        },
    ]
    return columns
}

export const BillsColumns = (docTableMockData) => {
    const columns = [
        {
            accessorKey: 'billId',
            header: 'Bill No',
            size: 100,
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        color: '#3478F1',

                    } : {})}
                >
                    {cell.getValue()}
                </Box>
            )
        },
        {
            accessorKey: 'startTime',
            header: 'Start Date',
            size: 100,
            Cell: ({ cell }) => (
                <Box>
                    {moment(cell.getValue()).format("DD-MMM-YYYY")}
                </Box>
            )
        },
        {
            accessorKey: 'endTime',
            header: 'End Date',
            Cell: ({ cell }) => (
                <Box>
                    {moment(cell.getValue()).format("DD-MMM-YYYY")}
                </Box>
            )
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => (
                <Box sx={(theme) => (cell.getValue() ? {
                    ...getCellStyle(cell.getValue(), "BillsForCustomer"),
                } : {})} >{getStatusLabel(cell.getValue())}</Box>
            )
        },
        {
            accessorKey: 'dueAmount',
            header: 'Balance Due',
            Cell: ({ cell }) => (
                <Box>
                    {`${docTableMockData[cell.row.index].currencyCode} ${cell.getValue()}`}
                </Box>
            )
        },
        {
            accessorKey: 'totalAmount',
            header: 'Amount',
            Cell: ({ cell }) => (
                <Box>
                    {`${docTableMockData[cell.row.index].currencyCode} ${cell.getValue()}`}
                </Box>
            )
        },
    ]
    return columns
}

export const customerSearchColumns = () => {
    const columns = [
        {
            accessorKey: "CustomerId",
            header: "A/C Number",
        },
        {
            accessorKey: "Name",
            header: "Full Name",
        },
        {
            accessorKey: "Email",
            header: "Email",
        },
        {
            accessorKey: "msisdn",
            header: "MSISDN",
        },
        {
            accessorKey: "imsi",
            header: "IMSI",
        },
        {
            accessorKey: "Address",
            header: "Address",
            size: 250
        },
    ];
    return columns;
};

export const handleCheckboxChange = (row, selectedCustomer, setSelectedCustomer) => {
    let isChecked = selectedCustomer.some(p => p.CustomerId === row.original.CustomerId);
    let updatedSelectedCustomer = isChecked
        ? selectedCustomer.filter(p => p.CustomerId !== row.original.CustomerId)
        : [...selectedCustomer, row.original];
    setSelectedCustomer(updatedSelectedCustomer);
};