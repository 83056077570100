import { toast } from "react-toastify";
import { fetchCancelStatusFailure, fetchCancelStatusSuccess } from "./reducer";
import { setDeleteSuccessMessage } from "../../toastMessage/action";
import { DEVICE_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { getAllDevice } from "../Devicelist/thunk";

export const cancelStatus = (deviceId, setLoading, navigate) => async (dispatch) => {
  setLoading(true)
  await determineBaseUrlAndPerformApiCall(`${DEVICE_API}/${deviceId}`, 'DELETE').then((response) => {
    if (!response.error) {
      dispatch(fetchCancelStatusSuccess(response.data));
      setLoading(false);
      navigate('/devices');
      dispatch(getAllDevice(setLoading))
      dispatch(setDeleteSuccessMessage(true));
    }
    if (response.error) {
      dispatch(fetchCancelStatusFailure(response.data.error.message));
      setLoading(false);
      toast.error(`${response.data.error.message}`);
      dispatch(setDeleteSuccessMessage(true))

    }
  }).catch((errorResp) => { })
};
