import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    station: [],
    loading: false,
    error: null,
}

const stationSlice = createSlice({
    name: "station",
    initialState,
    reducers: {
        fetchStationStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchStationSuccess: (state, action) => {
            state.loading = false,
                state.station = action.payload;
        },
        fetchStationFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchStationStart,
    fetchStationSuccess,
    fetchStationFailure,
} = stationSlice.actions;

export default stationSlice.reducer;