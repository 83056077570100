import React from 'react';
import { Col, FormFeedback } from 'reactstrap';
import InputField from "../Utils/InputField";
import { LabelText } from "../Utils/Input";
import SelectField from "../Utils/SelectField";
import { handleDeviceTypeChange, handleStatusChange, getStatusValue } from "./constants/constantFunctions"

export function DeviceForm({
  values,
  isEdit,
  errors,
  touched,
  setFieldValue,
  initialValues,
  DeviceType,
  StatusType,
  handleChange
}) {
  return (
    <>
      <Col xxl={3} md={6}>
        <LabelText
          htmlFor="Device ID"
          className="text-danger"
          displayText="Device ID"
          important={"*"}
        />
        <InputField
          value={values.deviceId}
          type={"text"}
          disabled={isEdit ? true : false}
          className={"form-control"}
          handleChange={handleChange}
          name={"deviceId"}
          placeholder={"Enter the device ID"}
          invalid={touched.deviceId && errors.deviceId ? true : false}
        />
        {touched.deviceId && errors.deviceId ? (
          <FormFeedback type="invalid">{errors.deviceId}</FormFeedback>
        ) : null}
      </Col>
      <Col xxl={3} md={6}>
        <LabelText
          htmlFor="Device Type"
          className="text-danger"
          displayText="Device Type"
          important={"*"}
        />
        <SelectField
          isDisabled={isEdit ? true : false}
          className={
            (errors.deviceType && touched.deviceType) || false
              ? "error-input"
              : ""
          }
          id="deviceType"
          value={
            initialValues.deviceType
              ? DeviceType?.filter((deviceType) => {
                deviceType.label === initialValues.deviceType;
              })
              : values.deviceType
          }
          placeholder={"Select a Device Type"}
          handleChange={handleDeviceTypeChange(setFieldValue)}
          options={[...DeviceType]}
        />
        {touched.deviceType && errors.deviceType ? (
          <p className="text-danger">{errors.deviceType}</p>
        ) : null}
      </Col>
      <Col xxl={3} md={6}>
        <LabelText htmlFor="Status" displayText="Status" />
        {!isEdit ? (
          <div>
            <LabelText
              classLabelName={
                "mt-1 text-secondary border border-2 rounded-pill border-secondary p-1"
              }
              displayText={"Unassigned"}
            />
          </div>
        ) : (
          <SelectField
            value={
              getStatusValue(initialValues, StatusType, values)
            }
            isDisabled={values.status.label === "Assigned" ? true : false}
            handleChange={handleStatusChange(setFieldValue)}
            options={[...StatusType]}
          />
        )}
      </Col>
    </>
  );
}
