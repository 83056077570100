import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    documentView : [],
    loading : false,
    error : null,
};

const fetchDocumentViewSlice = createSlice({
    name:"documentView",
    initialState,
    reducers: {
        fetchDocumentViewSuccess: (state,action) => {
            state.loading = false;
            state.documentView = action.payload
        },
        fetchDocumentViewFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        },
        resetDocumentViewState: (state) => {
            state.loading = false;
            state.documentView = null;
            state.error = null;
        },
    }
})

export const {
    fetchDocumentViewFailure,
    fetchDocumentViewSuccess,
    resetDocumentViewState
} = fetchDocumentViewSlice.actions

export default fetchDocumentViewSlice.reducer