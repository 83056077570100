import { MaterialReactTable } from "material-react-table";
import { invoiceColumns } from "./constants/CustomerTableColumns";
import { Card, Button, CardHeader } from "reactstrap";
import { invoiceChangeFunctionForCustomer } from "./constants/HandleChangeFunctions";

export default function Invoice({invoiceData}) {
  const invoiceArray = []
  const invoiceDataForCustomer = invoiceChangeFunctionForCustomer(invoiceData, invoiceArray)
  const columns = invoiceColumns();
  return (
    <Card>
      <div className="d-flex justify-content-end align-items-center mb-2">
        <Button color="secondary" className="px-4">
          Pay
        </Button>
      </div>
      <MaterialReactTable
        columns={columns}
        data={invoiceDataForCustomer}
        muiTableHeadCellProps={{
          sx: {
            fontFamily: "Inter,sans-serif",
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
        }}
        muiTableBodyCellProps={{
          sx: {
            fontFamily: "Inter,sans-serif",
            border: "none",
          },
        }}
        enableBottomToolbar={false}
        enableDensityToggle={false}
        enableSorting={false}
        enableColumnActions={false}
      />
    </Card>
  );
}
