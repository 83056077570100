import { CardBody, Col, FormGroup, Row } from "reactstrap"
import { LabelText } from "../Utils/Input"
import { RenderField } from "../Utils/renderFields"
import { RenderErrorMessage } from "../Utils/renderErrorMessages"
import formSchema from '../Utils/schema.json';
import PhoneInput from 'react-phone-number-input';
import React from "react";

export const CustomerEditProfile = ({ setSelectedState, setSelectedCountry, profileOptions, values, setFieldTouched, setFieldValue, touched, errors, handleBlur, handleChange, stateData, cityData }) => {
    return (
        <Row>
            <Col xxl={5} md={5}>
                {formSchema.map((section, sectionIndex) => (
                    <React.Fragment key={sectionIndex}>
                        {section.title === "Customer" && section.sections &&
                            section.sections.map((subSection, subSectionIndex) => (
                                (subSection.sectionTitle !== "Primary Contact 2" && <CardBody key={subSectionIndex}>
                                    <Row className='gy-4'>
                                        {subSection.fields.map((field, fieldIndex) => (
                                            <Col
                                                xxl={12}
                                                md={12}
                                                lg={12}
                                                key={`${subSectionIndex}-${fieldIndex}`}
                                            >
                                                <LabelText
                                                    htmlFor={field.name}
                                                    className={field.required ? "text-danger" : ""}
                                                    displayText={field.label}
                                                    important={field.required ? "*" : ""}
                                                />
                                                {field.type === 'phone' ? (
                                                    <>
                                                        <PhoneInput
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            className={`form-control ${touched[field.name] && errors[field.name] ? 'error-input' : ''}`}
                                                            placeholder={field.placeholder}
                                                            value={values[field.name]}
                                                            onChange={(value) => setFieldValue(field.name, value)}
                                                            onBlur={() => setFieldTouched(field.name, true)}
                                                            disabled={field?.autoload && field.isEdit}
                                                        />
                                                        {touched[field.name] && errors[field.name] && (
                                                            <div className="text-danger validation-font-size">
                                                                {errors[field.name]}
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <RenderField
                                                            field={field}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            values={values}
                                                            touched={touched}
                                                            errors={errors}
                                                            setFieldTouched={setFieldTouched}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                        <RenderErrorMessage
                                                            field={field}
                                                            touched={touched}
                                                            errors={errors}
                                                        />
                                                    </>
                                                )}
                                            </Col>
                                        ))}
                                    </Row>
                                </CardBody>
                                )
                            ))}
                    </React.Fragment>
                ))}
            </Col>
            <Col xxl={2} md={2} >
                <div className="custom-border-lg-end d-block me-5"></div>
            </Col>
            <Col xxl={5} md={5}>
                {formSchema.map((section, sectionIndex) => (
                    <React.Fragment key={sectionIndex}>
                        {section.title === "Customer" && section.sections &&
                            section.sections.map((subSection, subSectionIndex) => (
                                (subSection.sectionTitle !== "Primary Contact 1" && <CardBody key={subSectionIndex}>
                                    <Row className='gy-4'>
                                        {subSection.fields.filter(field => {
                                            if (field.name === "country" && stateData) {
                                                profileOptions.push(stateData)
                                            }
                                            if (field.name === "state" && cityData) {
                                                profileOptions.push(cityData)
                                            }
                                            return true
                                        }).map((field, fieldIndex) => (
                                            <Col
                                                xxl={12}
                                                md={12}
                                                lg={12}
                                                key={`${subSectionIndex}-${fieldIndex}`}
                                            >
                                                <LabelText
                                                    htmlFor={field.name}
                                                    className={field.required ? "text-danger" : ""}
                                                    displayText={field.label}
                                                    important={field.required ? "*" : ""}
                                                />
                                                <RenderField
                                                    field={field}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    touched={touched}
                                                    errors={errors}
                                                    setFieldTouched={setFieldTouched}
                                                    setFieldValue={setFieldValue}
                                                    setSelectedCountry={setSelectedCountry}
                                                    setSelectedState={setSelectedState}
                                                    options={profileOptions}
                                                />
                                                <RenderErrorMessage
                                                    field={field}
                                                    touched={touched}
                                                    errors={errors}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </CardBody>
                                )
                            ))}
                    </React.Fragment>
                ))}
            </Col>
        </Row>
    )
}