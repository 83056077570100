import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    resourceById: [],
    loading: false,
    error: null,
}

const resourceByIdSlice = createSlice({
    name: "resourceById",
    initialState,
    reducers: {
        fetchResourceByIdStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchResourceByIdSuccess: (state, action) => {
            state.loading = false,
                state.resourceById = action.payload;
        },
        fetchResourceByIdFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})
export const {
    fetchResourceByIdStart,
    fetchResourceByIdSuccess,
    fetchResourceByIdFailure,
} = resourceByIdSlice.actions;

export default resourceByIdSlice.reducer;