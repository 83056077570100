import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    businessName: Yup.string().required('Kindly Enter the business name'),
    Domain: Yup.object().required('Kindly Enter the Domain name'),
    endDate: Yup.mixed().required('Kindly select end date'),
    Address: Yup.string().max(30, 'Must be 30 characters or less').required('Kindly enter Address'),
    country: Yup.object().required("Kindly select Country"),
    state: Yup.object().required('Kindly select State'),
    city: Yup.object().required('Kindly select City'),
    zipCode: Yup.number().typeError('It doesn\'t look like a zip code').positive('A zip code can\'t start with a minus').integer('It can\'t include a decimal point').required('Kindly enter Zip code'),
    firstname: Yup.string().required('Kindly Enter the first name'),
    lastname: Yup.string().required('Kindly Enter the last name'),
    username: Yup.string().required('Kindly Enter the user name'),
    phone: Yup.string().required('Kindly Enter the phone').test('len', 'Must be exactly 10 digits', val => val?.toString().length === 13),
    email: Yup.string().email('Kindly enter valid email').required('Kindly enter Email'),
    // attributeList: Yup.array().of(
    //     Yup.object().shape({
    //         name: Yup.string().required('Kindly enter the attribute name'),
    //         value: Yup.string().required("Kindly enter attribute value"),
    //     })
    // ),
});