import { Card, Col } from "reactstrap"
import { LabelText } from "../Utils/Input"
import { formatNumberForStatistics } from "./constants/HandleChangeFunctions"

export const InvoiceAnalyticsCard = ({heading,currencyCode,totalAmount,particularTotalCount,icon }) => {
    return (
        <Col xxl={3} md={6}>
            <Card>
                <div className="d-flex justify-content-between align-items-center ">
                    <LabelText classLabelName={'fw-bold invoice-text-color fs-5 ps-3 pt-3'} displayText={heading} />
                </div>
                <div>
                    <LabelText classLabelName={"fw-bold fs-2 ps-3 pt-3"} displayText={`${currencyCode || ''} ${formatNumberForStatistics(totalAmount || 0)}`} />
                </div>
                <div className="d-flex justify-content-between  align-items-center">
                    <div className="ps-3 pt-4">
                        <LabelText classLabelName={"invoice-count text-white px-3 rounded me-2 fw-bold"} displayText={particularTotalCount || 0} />
                        <LabelText classLabelName={"invoice-text-color fw-bold"} displayText={"Invoices Sent"} />
                    </div>
                    <i className={`${icon} invoice-percent-and-icon-color invoice-icon-size pe-4`}></i>
                </div>
            </Card>
        </Col>
    )
}