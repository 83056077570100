import { RESOURCE_LISTING_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchAggregateResourcesFailure, fetchAggregateResourcesStart, fetchAggregateResourcesSuccess } from "./reducer";

export const getAggregateResource = (setLoading) => async (dispatch) => {
    dispatch(fetchAggregateResourcesStart());
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${RESOURCE_LISTING_API}?av=true`, 'GET')
        setLoading(false)
        if (response.error && response.error.code === 'NotFound') {
            dispatch(fetchAggregateResourcesSuccess([]));
        }
        else {
            dispatch(fetchAggregateResourcesSuccess(response.reverse()));
        }
    } catch (error) {
        dispatch(fetchAggregateResourcesFailure(error.message));
    }
};

