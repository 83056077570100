import React from 'react';
import { Row, Col, CardBody, Table } from 'reactstrap';

const OperatorDetails = ({ operatorData }) => {
    const {
        tradingName,
        existsDuring,
        domain,
        state,
        city,
        country,
        postcode,
        street1,
        street2,
        status,
        partyCharacteristic = [],
        adminDetails ,
        operatorId
    } = operatorData || {};

    return (
        <>
            <CardBody>
                <Row>
                    <Col xxl={6}>
                        <div className="p-4 border rounded ">
                            <Row className="mb-3">
                                <Col className="mb-3" xxl={6}><strong>Operator ID</strong><br />{operatorId}</Col>
                                <Col className="mb-3" xxl={6}><strong>Domain</strong><br />{domain}</Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="mb-3" xxl={6}><strong>Business Name</strong><br />{tradingName}</Col>
                                <Col className="mb-3" xxl={6}><strong>Status</strong><br />{status}</Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="mb-3" xxl={12}><strong>Address</strong><br />{street1}, {street2}</Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="mb-3" xxl={3}><strong>Country</strong><br />{country}</Col>
                                <Col className="mb-3" xxl={3}><strong>State</strong><br />{state}</Col>
                                <Col className="mb-3" xxl={3}><strong>City</strong><br />{city}</Col>
                                <Col className="mb-3" xxl={3}><strong>Zipcode</strong><br />{postcode}</Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xxl={6}>
                        <div className="p-4 border rounded h-100">
                                <React.Fragment>
                                    <Row className="mb-3">
                                        <Col className="mb-3" xxl={12}><strong>Primary Contact</strong><br />{adminDetails?.profile?.firstName} {adminDetails?.profile?.lastName}</Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col className="mb-3" xxl={6}><strong>Phone number</strong><br />{adminDetails?.dialCode}-{adminDetails?.phone}</Col>
                                        <Col className="mb-3" xxl={6}><strong>Email Address</strong><br />{adminDetails?.email}</Col>
                                    </Row>
                                </React.Fragment>
                        </div>
                    </Col>
                </Row>
            </CardBody>
            {/* <CardBody>
                <div className="p-4 border rounded">
                    <h5 className="mb-4">Attributes</h5>
                    <Table className=' table-border-none'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {partyCharacteristic.map((attribute, index) => (
                                <tr key={index}>
                                    <td>{attribute.name}</td>
                                    <td>{attribute.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </CardBody> */}
        </>
    );
}

export default OperatorDetails;
