import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscribeOrder: [],
    loading: false,
    error: null,
}

const subscribeOrderSlice = createSlice({
    name: "subscribeOrder",
    initialState,
    reducers: {
        fetchSubscribeOrderSucess: (state, action) => {
            state.loading = false
            state.subscribeOrder = action.payload;
        },
        fetchSubscribeOrderFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchSubscribeOrderSucess,
    fetchSubscribeOrderFailure,
} = subscribeOrderSlice.actions;

export default subscribeOrderSlice.reducer;