import { GET_CUSTOMER_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchCustomerSuccess, fetchCustomerFailure } from "./reducer";

export const fetchCustomer = (setLoading, customerId, initialValues, customerPermission, setSubscriptionId, isEdit, setDialCode) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${GET_CUSTOMER_API}/${customerId}?includeSubscription=true`, 'GET').then((response) => {
        dispatch(fetchCustomerSuccess(response))
        const roleName = localStorage.getItem("roleName")
        if (isEdit) {
            const { firstName, lastName, userName, email, mobile, dialCode, addressLine1, addressLine2, zipCode, country, state, city, subscriptionList, attributeList, billingProfile } = response.customerInfo;
            initialValues.firstName = firstName;
            initialValues.lastName = lastName;
            initialValues.userName = userName;
            initialValues.email = email;
            initialValues.mobile = mobile;
            initialValues.bdom = { "value": billingProfile.bdom, "label": billingProfile.bdom }
            setDialCode(`${dialCode}${mobile}`);
            initialValues.addressLine1 = addressLine1;
            initialValues.addressLine2 = addressLine2;
            initialValues.zipCode = zipCode;
            initialValues.country = { "value": country, "label": country };
            initialValues.state = { "value": state, "label": state };
            initialValues.city = { "value": city, "label": city };
            if (customerPermission.autoload || roleName === "Customer") {
                initialValues.phone = response?.customerInfo?.dialCode + response?.customerInfo?.mobile
                initialValues.accountNumber = response?.customerInfo?.customerId
                const imsiAttribute = response?.customerInfo?.attributeList?.find(attr => attr.name === "imsi");
                initialValues.imsi = imsiAttribute ? imsiAttribute.value : "";
            }

            if (subscriptionList) {
                initialValues.subscriptions = [];
                subscriptionList.forEach((resp) => {
                    setSubscriptionId((id) => [...id, resp.subscriptionId]);
                    initialValues.subscriptions.push({
                        "validFrom": resp.subscribedOffers[0].validFrom,
                        "offerCode": { "value": resp.subscribedOffers[0].offerCode, "label": resp.subscribedOffers[0].offerCode },
                        "validTo": resp.subscribedOffers[0].validTo
                    });
                });
            }

            if (attributeList) {
                initialValues.attributeList = [];
                attributeList.forEach((resp) => {
                    initialValues.attributeList.push({
                        "value": resp.value,
                        "name": { "value": resp.name, "label": resp.name }
                    });
                });
            }
        }

    }).catch((error) => {
        dispatch(fetchCustomerFailure(error.message))
    })
    setLoading(false)
}