import React from 'react';
import image from '../../../assets/images/404.png';

const ErrorPage = () => {
    document.title = '404 Error';

    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <h2 className=" fw-semibold w-100 text-center ">
                You don't have access to view this page</h2>
        </div>
    );
};

export default ErrorPage;
