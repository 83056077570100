import * as Yup from 'yup';

export const DeviceType = [
    { value: 'Modem', label: 'Modem' },
    { value: 'Router', label: 'Router' },
];

export const StatusType = [
    { value: 'Unassigned', label: 'Unassigned' },
    { value: 'Closed', label: 'Closed' }
];

export const validationSchema = Yup.object().shape({
    deviceType: Yup.mixed().required('Kindly Enter the  type'),
});

export const initialFormValues = {
    deviceId: '',
    deviceType: '',
    status: '',
    attributeList: [],
};





