import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    states : [],
    loading : false,
    error : null,
};

const statesSlice = createSlice({
    name:"states",
    initialState,
    reducers: {
        fetchStatesSuccess: (state,action) => {
            state.loading = false;
            state.states = action.payload
        },
        fetchStatesFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchStatesFailure,
    fetchStatesSuccess
} = statesSlice.actions

export default statesSlice.reducer