import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    documentDownload : [],
    loading : false,
    error : null,
};

const fetchDocumentDownloadSlice = createSlice({
    name:"documentDownload",
    initialState,
    reducers: {
        fetchDocumentDownloadSuccess: (state,action) => {
            state.loading = false;
            state.documentDownload = action.payload
        },
        fetchDocumentDownloadFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchDocumentDownloadSuccess,
    fetchDocumentDownloadFailure
} = fetchDocumentDownloadSlice.actions

export default fetchDocumentDownloadSlice.reducer