import { Box } from '@mui/material';
import { getStatusLabel } from '../../Utils/DecideStatus';
import { getCellStyle } from '../../Utils/DecideColor';
export const staionsColumnData = (evse, connector,StationName,StationAddress) => {
    const Columns =  [
        {
            accessorKey: 'stationDetails.stationID',
            header: 'Station ID',
        },
        {
            accessorFn: (stations, index) => `${StationName[index]}`,
            header: 'Station Name',
        },
        {
            accessorFn: (stations, index) => `${StationAddress[index]}`,
            header: 'Address',
        },
        {
            accessorFn: (stations, index) => `${evse[index]}`,
            header: 'Total EVSE',
        },
        {
            accessorFn: (stations, index) => `${connector[index]}`,
            header: 'No Of Connectors',
        },
        {
            accessorKey: 'stationDetails.status',
            header: 'Status',
            Cell: ({ cell }) => (
                <Box sx={(theme) => (cell.getValue()?{
                    ...getCellStyle(cell.getValue(),"Station"),
                }:{})} >{getStatusLabel(cell.getValue())}</Box>
            )
        },
        {
            accessorKey: 'stationDetails.adminStatus',
            header: 'Admin Action',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue()?{
                        ...getCellStyle(cell.getValue(),"Station"),
                    }:{})}
                >
                    {getStatusLabel(cell.getValue())}
                </Box>
            )
        },
    ]
    return Columns
}