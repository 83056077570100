import { rateUsageInSimulate } from "../../../slices/customer/rateusage/thunk"
import { simulateJsonObj } from "./CustomerJsonObj"

export const rateUsage = async (jsonObj,dispatch,setLoading,navigate,row) => {
    dispatch(rateUsageInSimulate(jsonObj, setLoading, navigate))
    row.toggleExpanded(false)
}

export const onSubmit = (values,customerId,urpOffers,fetchSummaryData,randomString,dispatch,setLoading,navigate,row) => {
    let jsonObj = simulateJsonObj(values, customerId, urpOffers, fetchSummaryData,randomString)
    rateUsage(jsonObj,dispatch,setLoading,navigate,row)
}

export const onSubmitForSimulateForm = (customerId,urpOffers,fetchSummaryData,randomString,dispatch,setLoading,navigate,row) => (values) => {
    if (values.uom !== "" && values.usageRatePlan !== "" && values.eventStartTime !== null && values.eventEndTime !== null) {
        onSubmit(values,customerId,urpOffers,fetchSummaryData,randomString,dispatch,setLoading,navigate,row)
    }
}

export const handleChangeForAccountNumber = (setFieldValue, number) => (e) => {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z0-9]/gi, ''); 
    setFieldValue(number, value, false);
};


export const handleRadioButtonChange = (selectedRadio,setImmediate,setImmediateMinus,setNextBillCycle) => {
    if (selectedRadio === "Immediate") {
      setImmediate(true);
      setImmediateMinus(false);
      setNextBillCycle(false);
    } else if (selectedRadio === "Immediate Minus Used") {
      setImmediate(false);
      setImmediateMinus(true);
      setNextBillCycle(false);
    } else if (selectedRadio === "Next Bill Cycle") {
      setImmediate(false);
      setImmediateMinus(false);
      setNextBillCycle(true);
    }
  };

  export const isAnyFieldFilled = (searchFields) => {
    let isSearchFieldFilled = false;
    searchFields?.forEach((field) => {
        if (field?.value) {
            isSearchFieldFilled = true;
        }
    });
    return isSearchFieldFilled;
};
