import { Card, CardBody, CardHeader, Col, Container, Row, Button, Form, Nav, NavItem, NavLink, Progress, TabContent, TabPane } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import Attributes from "./Attributes";
import Offer from "./Offer";
import CustomerDetails from "./CustomerDetails";
import { validationSchemaForCustomer } from "./constants/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { createCustomer } from "../../slices/customer/create/thunk";
import { updateCustomer } from "../../slices/customer/update/thunk";
import { fetchCustomer } from "../../slices/customer/fetchcustomer/thunk";
import { ToastContainer } from "react-toastify";
import { customerJsonObj } from "./constants/CustomerJsonObj";
import { fetchCountries } from "../../slices/customer/countries/thunk";
import { commonIDFunction, optionsForCustomerDetails } from "./constants/constantFunctions";
import { fetchStates } from "../../slices/customer/states/thunk";
import { customerIdData } from "./constants/constantFunctionsView";
import { fetchCities } from "../../slices/customer/cities/thunk";
import { Loader } from "../Utils/Loader";
import { AttributeNameOptions } from "./constants/constantValues";
import UploadDocument from "./UploadDocument";
import { fetchDocumentsForCustomer } from "../../slices/customer/documentlist/thunk";
import CommonButton from "../Utils/CommonButton";
import { customerDialCodeErrorThrow, onSubmit, onSubmitCustomerFunction, registerOrEditCustomer } from "./constants/DocumentTableFunctions";
import { isValidPhoneNumber } from "react-phone-number-input";
import { PAGE_TITLES } from "../Common/constants";
import classnames from "classnames";
import { SubscriptionWhileCustomerCreation } from "./SubscriptionWhileCustomerCreation";
import CommonModal from "../Utils/CommonModal";
import { bdomDataFunction, checkConditions, checkMandatoryFields, toggleModal, toggleTab, updateValues, validateForm } from "./constants/TelcoSubmitFunctions";
import { CommonCustomerDetails } from "./CommonCustomerDetails";
import { getAttributeByType } from "../../slices/attribute/getattributebytype/thunk";

export default function CreateEditCustomer({ initialAIValues, mobileWithDialCode }) {
    const [cities, setCities] = useState([])
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState('')
    const [open, setIsOpen] = useState(false)
    const [state, setState] = useState(false)
    const [file, setFile] = useState()
    const [fileName, setFileName] = useState()
    const [enableFormReinitialize, setEnableFormReinitialize] = useState(false)
    const [subscriptionId, setSubscriptionId] = useState([])
    const [dialCode, setDialCode] = useState("")
    const [onfocus, setOnFocus] = useState(false)
    const [progressbarvalue, setprogressbarvalue] = useState(0);
    const [activeTab, setactiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1]);
    const [modal, setModal] = useState(false);
    const [valuesForSubmit, setValuesForSubmit] = useState()
    const [confirmed, setConfirmed] = useState()
    const [searchableFormErrorsValues, setSearchableFormErrorValues] = useState()
    const [nonSearchableFormErrorsValues, setNonSearchableFormErrorValues] = useState()
    const [callState, setCallState] = useState(false)
    const [callCity, setCallCity] = useState(false)
    const [quantity, setQuantity] = useState(1);

    let existingArrayLength = 0
    let optionCity = []
    let optionState = []

    let navigate = useNavigate();
    const roleName = localStorage.getItem('roleName')
    const customerId = roleName === "Customer" 
    ? localStorage.getItem('customerId') 
    : customerIdData();
    
    const isEdit = location.pathname.includes('update-customer')
    const isCreate = location.pathname.includes('create-customer')
    const isAIPath = window.location.pathname.includes('/chatbot');
    const permissions = useSelector((state) => state.permissions.permissions);
    const documentPermission = permissions.find((resource) => resource.resourceName === 'document');
    const customerPermission = permissions.find((resource) => resource.resourceName === 'customer');
    const subscriptionPermission = permissions.find((resource) => resource.resourceName === 'subscription');
    let bdomOptions = bdomDataFunction()

    const dispatch = useDispatch()

    const initialFormValues = {
        customerId: "",
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        password: "",
        mobile: "",
        bdom: bdomOptions[0],
        ...(roleName === "telcoAdmin" && { accountNumber: "" }),
        addressLine1: "",
        addressLine2: "",
        country: "",
        state: "",
        city: "",
        zipCode: "",
        dialCode: "",
        documentType: "",
        documentName: "",
        selectedFile: "",
        subscriptions: [],
        attributeList: [],
    };

    let initialValues = initialFormValues;

    useEffect(() => {
        dispatch(fetchCountries(setLoading, setCallState))
        dispatch(getAttributeByType("customer", setLoading))
    }, [dispatch]);

    const Attributes = useSelector((state) => state.GetAttributeByType.getAttribute)
    const nonSearchableAttributes = useSelector((state) => state.GetAttributeByType.getNonSearchableAttribute)
    const searchableAttributes = useSelector((state) => state.GetAttributeByType.getSearchableAttribute)
    const [searchableValues, setSearchableValues] = useState([]);
    const [nonSearchableValues, setNonSearchableValues] = useState([]);

    useEffect(() => {
        if (searchableAttributes?.length > 0) {
            setSearchableValues(searchableAttributes.map(attr => ({ name: attr.name, value: '', touched: false })));
        }
    }, [searchableAttributes]);

    useEffect(() => {
        if (nonSearchableAttributes?.length > 0) {
            setNonSearchableValues(nonSearchableAttributes.map(attr => ({ name: attr.name, value: '' })));
        }
    }, [nonSearchableAttributes]);

    const countries = useSelector(state => state.Countries.countries)
    let countryID = commonIDFunction(countries?.countries)
    const options = optionsForCustomerDetails(countryID)

    useEffect(() => {
        if (isEdit) {
            setTitle(PAGE_TITLES.EDIT_CUSTOMER)
        }
        else {
            setTitle(PAGE_TITLES.ADD_CUSTOMER)
        }
    }, [isEdit])

    useEffect(() => {
        if (isEdit) {
            dispatch(fetchCustomer(setLoading, customerId, initialValues, customerPermission, setSubscriptionId, isEdit, setDialCode))
            dispatch(fetchDocumentsForCustomer(setLoading, customerId))
        }
    }, [dispatch])

    const fetchCustomerData = useSelector(state => state.FetchCustomer.fetchCustomer)
    const fetchDocumentData = useSelector(state => state.DocumentList.documentList)
    const [attributeNamesAndValues, setAttributeNamesAndValues] = useState([]);

    useEffect(() => {
        if (fetchCustomerData?.customerInfo?.attributeList) {
            const attributes = fetchCustomerData.customerInfo.attributeList.map(attr => ({
                name: attr.name,
                value: attr.value
            }));
            setAttributeNamesAndValues(attributes);
        }
    }, [fetchCustomerData]);

    useEffect(() => {
        if (isEdit) {
            const updatedSearchableValues = updateValues(searchableValues, attributeNamesAndValues, searchableAttributes)
            const updatedNonSearchableValues = updateValues(nonSearchableValues, attributeNamesAndValues, nonSearchableAttributes)
            setSearchableValues(updatedSearchableValues);
            setNonSearchableValues(updatedNonSearchableValues)
        }
    }, [attributeNamesAndValues, isEdit]);

    let subscriptionsLength = 0
    if (isEdit) {
        if ("subscriptionList" in fetchCustomerData) {
            subscriptionsLength = fetchCustomerData?.subscriptionList?.length
        }
    }

    useEffect(() => {
        if (callState) {
            dispatch(fetchStates(selectedCountry, setCallCity))
        }
    }, [dispatch, isEdit, selectedCountry]);

    let statesData = useSelector(state => state.States.states)

    let stateID = commonIDFunction(statesData?.states)
    optionState = optionsForCustomerDetails(stateID)

    useEffect(() => {
        if (callCity) {
            dispatch(fetchCities(selectedCountry, selectedState))
        }
    }, [dispatch, selectedCountry, selectedState]);

    let cityData = useSelector(state => state.Cities.cities)

    let cityID = commonIDFunction(cityData?.cities)
    optionCity = optionsForCustomerDetails(cityID)

    useEffect(() => {
        if (isCreate) {
            setEnableFormReinitialize(true)
            setSearchableValues(searchableValues.map(field => ({
                ...field,
                value: typeof field.value === 'object' && field.value !== null
                    ? { label: '', value: '' }
                    : ''
            })))
            setNonSearchableValues(nonSearchableValues.map(field => ({
                ...field,
                value: typeof field.value === 'object' && field.value !== null
                    ? { label: '', value: '' }
                    : ''
            })))
        }
    }, [isCreate])



    const conditionsSearchableResult = checkConditions(searchableValues, searchableAttributes);
    const conditionsNonSearchableResult = checkConditions(nonSearchableValues, nonSearchableAttributes);
    const allFieldsTrue = Object.values(conditionsSearchableResult).every(field => field === true) && Object.values(conditionsNonSearchableResult).every(field => field === true);;
    const commonTrueOrFalse = allFieldsTrue ? true : false;

    return (
        <div>
            <Formik
                validationSchema={validationSchemaForCustomer(customerPermission, state)}
                initialValues={isAIPath ? initialAIValues : initialValues}
                enableReinitialize={enableFormReinitialize}
                onSubmit={(values) => {
                    if ((roleName !== "telcoAdmin" && roleName !==  "telcoOperationUser" && roleName !== "telcoBusinessUser") || ((roleName === "telcoAdmin"  && roleName ===  "telcoOperationUser" && roleName==="telcoBusinessUser")&& isEdit && commonTrueOrFalse) || isAIPath) {
                        let isAIDialCode = isAIPath ? mobileWithDialCode : dialCode
                        onSubmitCustomerFunction(values, isEdit, subscriptionsLength, setLoading, dispatch, customerId, navigate, isAIDialCode, roleName, searchableValues, nonSearchableValues,quantity)
                    }
                    else {
                        setValuesForSubmit(values)
                        if (!confirmed && commonTrueOrFalse) {
                            toggleModal(setModal, modal);
                        }
                        else if (commonTrueOrFalse) {
                            toggleTab(2, 100, activeTab, passedSteps, setactiveTab, setPassedSteps, setprogressbarvalue);
                        }
                    }
                }}
            >
                {({ handleChange, handleBlur, handleSubmit, errors, touched, values, setFieldValue, setFieldTouched, setTouched, resetForm }) => (
                    <Form id="csms" className="needs-validation" onSubmit={handleSubmit}>
                        <div>
                            <ToastContainer position="top-center" />
                            <div className={!isAIPath ? "page-content" : "pb-5"}>
                                {((roleName !== "telcoAdmin" && roleName !==  "telcoOperationUser" && roleName !== "telcoBusinessUser") || ((roleName === "telcoAdmin"  && roleName ===  "telcoOperationUser"  && roleName==="telcoBusinessUser" ) && isEdit) || isAIPath) ?
                                    <CommonCustomerDetails isAIPath={isAIPath} searchableFormErrorsValues={searchableFormErrorsValues} setSearchableFormErrorValues={setSearchableFormErrorValues} nonSearchableFormErrorsValues={nonSearchableFormErrorsValues} setNonSearchableFormErrorValues={setNonSearchableFormErrorValues} searchableAttributes={searchableAttributes} nonSearchableAttributes={nonSearchableAttributes} setNonSearchableValues={setNonSearchableValues} searchableValues={searchableValues} nonSearchableValues={nonSearchableValues} setSearchableValues={setSearchableValues} identifiers={searchableAttributes} Attributes={nonSearchableAttributes} title={title} customerPermission={customerPermission} values={values} touched={touched} bdomOptions={bdomOptions} errors={errors} dialCode={isAIPath ? mobileWithDialCode :dialCode} setDialCode={setDialCode} setFieldValue={setFieldValue} onfocus={onfocus} setOnFocus={setOnFocus} setFieldTouched={setFieldTouched} initialValues={initialValues} handleBlur={handleBlur} handleChange={handleChange} options={options} optionCity={optionCity} optionState={optionState} setSelectedCountry={setSelectedCountry} setSelectedState={setSelectedState} setSelectedCity={setSelectedCity} cityData={cityData} setCities={setCities} isEdit={isEdit} documentPermission={documentPermission} fetchDocumentData={fetchDocumentData} open={open} setIsOpen={setIsOpen} file={file} setFile={setFile} fileName={fileName} setFileName={setFileName} setState={setState} setTouched={setTouched} roleName={roleName} navigate={navigate} />
                                    :
                                    <>
                                        <BreadCrumb title={(activeTab === 1) ? title : PAGE_TITLES.CUSTOMER_SUBSCRIBE} pageTitle={PAGE_TITLES.CUSTOMER_LIST} />
                                        <Col xxl={6} md={6}>
                                            <div className="progress-nav mb-4">
                                                <Progress
                                                    value={progressbarvalue}
                                                    style={{ height: "1px", backgroundColor: "#ADB5BD" }}
                                                    className="csv-file-color"
                                                />
                                                <Nav
                                                    className="nav-pills progress-bar-tab custom-nav"
                                                    role="tablist"
                                                >
                                                    <NavItem>
                                                        <NavLink
                                                            href="#"
                                                            id="pills-gen-info-tab"
                                                            className={classnames(
                                                                {
                                                                    active: activeTab === 1,
                                                                    done: activeTab <= 4 && activeTab >= 0,
                                                                },
                                                                "rounded-pill"
                                                            )}
                                                            onClick={() => {
                                                                toggleTab(1, 0, activeTab, passedSteps, setactiveTab, setPassedSteps, setprogressbarvalue)
                                                            }}
                                                            tag="button"
                                                        >
                                                            1
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            href="#"
                                                            id="pills-gen-info-tab"
                                                            className={classnames(
                                                                {
                                                                    active: activeTab === 2,
                                                                    done: activeTab <= 4 && activeTab > 1,
                                                                },
                                                                "rounded-pill",
                                                                activeTab !== 2 && "border-black"
                                                            )}
                                                            style={{ backgroundColor: activeTab !== 2 && "#ADB5BD", color: "white" }}

                                                            onClick={() => {
                                                                // if (!errors && commonTrueOrFalse) {
                                                                    toggleTab(2, 100, activeTab, passedSteps, setactiveTab, setPassedSteps, setprogressbarvalue);
                                                                // } else if (commonTrueOrFalse) {
                                                                //     handleSubmit();
                                                                //     const searchableFormErrors = validateForm(searchableAttributes, searchableValues);
                                                                //     setSearchableFormErrorValues(searchableFormErrors);
                                                                //     const nonSearchableFormErrors = validateForm(nonSearchableAttributes, nonSearchableValues);
                                                                //     setNonSearchableFormErrorValues(nonSearchableFormErrors);
                                                                //     customerDialCodeErrorThrow(onfocus, dialCode, setOnFocus);
                                                                // }
                                                            }}
                                                            tag="button"
                                                        >
                                                            2
                                                        </NavLink>
                                                    </NavItem>

                                                </Nav>
                                            </div>
                                        </Col>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId={1}>
                                                <CommonCustomerDetails commonTrueOrFalse={commonTrueOrFalse} searchableFormErrorsValues={searchableFormErrorsValues} setSearchableFormErrorValues={setSearchableFormErrorValues} nonSearchableFormErrorsValues={nonSearchableFormErrorsValues} setNonSearchableFormErrorValues={setNonSearchableFormErrorValues} searchableAttributes={searchableAttributes} nonSearchableAttributes={nonSearchableAttributes} setNonSearchableValues={setNonSearchableValues} searchableValues={searchableValues} nonSearchableValues={nonSearchableValues} setSearchableValues={setSearchableValues} identifiers={searchableAttributes} Attributes={nonSearchableAttributes} title={title} customerPermission={customerPermission} values={values} touched={touched} bdomOptions={bdomOptions} errors={errors} dialCode={dialCode} setDialCode={setDialCode} setFieldValue={setFieldValue} onfocus={onfocus} setOnFocus={setOnFocus} setFieldTouched={setFieldTouched} initialValues={initialValues} handleBlur={handleBlur} handleChange={handleChange} options={options} optionCity={optionCity} optionState={optionState} setSelectedCountry={setSelectedCountry} setSelectedState={setSelectedState} setSelectedCity={setSelectedCity} cityData={cityData} setCities={setCities} isEdit={isEdit} documentPermission={documentPermission} fetchDocumentData={fetchDocumentData} open={open} setIsOpen={setIsOpen} file={file} setFile={setFile} fileName={fileName} setFileName={setFileName} setState={setState} setTouched={setTouched} roleName={roleName} navigate={navigate} />
                                            </TabPane>
                                            <TabPane tabId={2}>
                                                <SubscriptionWhileCustomerCreation searchableValues={searchableValues} nonSearchableValues={nonSearchableValues} activeTab={activeTab} passedSteps={passedSteps} setactiveTab={setactiveTab} setPassedSteps={setPassedSteps} setprogressbarvalue={setprogressbarvalue} roleName={roleName} values={values} isEdit={isEdit} subscriptionsLength={subscriptionsLength} setParentLoading={setLoading} dispatch={dispatch} customerId={customerId} dialCode={dialCode} customerPermission={customerPermission} navigate={navigate} setQuantity={setQuantity} quantity={quantity} />
                                            </TabPane>
                                        </TabContent> </>
                                }
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {loading && (<Loader />)}
            <div>
                {!confirmed && <CommonModal messageForPopUp={'Do you want to create a subscription?'} closeButtonAction={() => {
                    toggleModal(setModal, modal);
                    onSubmitCustomerFunction(valuesForSubmit, isEdit, subscriptionsLength, setLoading, dispatch, customerId, navigate, dialCode, roleName, searchableValues, nonSearchableValues,quantity)
                }} closeButtonText={"No, Create Customer"} fromCustomer={"Customer"} toggle={() => { toggleModal(setModal, modal) }} open={modal} buttonText={"Yes, Proceed"} modalAction={() => {
                    toggleTab(2, 100, activeTab, passedSteps, setactiveTab, setPassedSteps, setprogressbarvalue); toggleModal(setModal, modal); setConfirmed(true)
                }} />}
            </div>
        </div>
    );
}