import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Container, Row } from "reactstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import { MaterialReactTable } from "material-react-table";
import { Loader } from "../../Utils/Loader";
import { attributeColoumns } from "./constants/AttributeColumns";
import { useNavigate } from "react-router-dom";
import { getAllAttribute } from "../../../slices/services/addservice/provisionalattribute/thunk";
import { useDispatch, useSelector } from "react-redux";
import { setEditSuccessMessage, setSuccessMessage } from "../../../slices/toastMessage/action";
import CommonModal from "../../Utils/CommonModal";
import { deleteAttribute } from "../../../slices/attribute/deleteattribute/thunk";
import { AttributeRowOptions } from "./AttributeRowOptions";
import { AttributeCustomButtons } from "./AttributeCustomButtons";
import { useColumnSettings } from "../../Utils/useColumnSettings";
import { getView } from "../../../slices/View/thunk";
import { bulkDelete } from "../../../slices/BulkDelete/thunk";
import { MenuItem } from "@mui/material";

export default function AttributeList() {
    const [loading, setLoading] = useState()
    const [messageForPopUp, setMessageForPopUp] = useState('')
    const [modal, setModal] = useState(false);
    const [attributeName, setAttributeName] = useState()
    const [modalForBulkDelete, setModalForBulkDelete] = useState(false)
    const [selectedRowsForBulkDelete, setSelectedRowsForBulkDelete] = useState()
    const [attributeArray, setAttributeArray] = useState([]);
    const [selectAllAttributes, setSelectAllAttributes] = useState(false)
    const [selectedAttribute,setSelectedAttribute] = useState([])
    const checkboxRef = useRef(null);

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isMobile = window.innerWidth <= 768;

    const toggleModal = (id, singleOrMulti) => {
        if (singleOrMulti === "single") {
            setAttributeName(id)
            setModal(!modal);
        }
        else {
            setSelectedRowsForBulkDelete(id)
            setModalForBulkDelete(!modalForBulkDelete)
        }
        document.body.style.overflow = 'auto'
    };

    useEffect(() => {
        dispatch(getAllAttribute(setLoading));
        dispatch(getView(setLoading));
    }, [dispatch]);
    const attributes = useSelector((state) => state.Provisional.provisional);
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);

    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Attribute Created Successfully`);
            dispatch(setSuccessMessage(false))
        }
        if (showEditSuccessMessage) {
            toast.success(`Attribute Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage, dispatch])

    useEffect(() => {
        if (checkboxRef.current) {
          checkboxRef.current.indeterminate = selectedAttribute.length > 0 && selectedAttribute.length < attributes.length;
        }
        setSelectAllAttributes(selectedAttribute?.length > 0 && attributes?.length > 0 && selectedAttribute?.length === attributes?.length);
      }, [selectedAttribute, attributes.length, setSelectAllAttributes]);

    const columns = attributeColoumns(selectAllAttributes,setSelectAllAttributes,attributes,setSelectedAttribute,selectedAttribute,checkboxRef,isMobile)

    const {
        settingsModal,
        visibleColumns,
        selectedView,
        viewColumns,
        modalPosition,
        settingsIconRef,
        toggleSettingsModal,
        handleColumnVisibilityChange,
        handleSaveSettings,
        handleViewChange,
        SettingsModal
    } = useColumnSettings('attribute', columns);

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title="Attributes" pageTitle="Attributes" />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Card>
                            <CardBody>
                                <Row className="mb-3 mt-1 table-responsive">
                                    {loading && (<Loader />)}
                                    {attributes ?
                                        <MaterialReactTable
                                            icons={{ DragHandleIcon: () => <i className="ri-drag-move-fill" />, }}
                                            renderTopToolbarCustomActions={({ table }) => (
                                                <AttributeCustomButtons selectedAttribute={selectedAttribute} setMessageForPopUp={setMessageForPopUp} setAttributeArray={setAttributeArray} toggleModal={toggleModal} table={table} settingsIconRef={settingsIconRef} toggleSettingsModal={toggleSettingsModal} navigate={navigate} attributes={attributes} />
                                            )}
                                            positionToolbarAlertBanner='none'
                                            muiSelectCheckboxProps={{
                                                color: 'warning'
                                            }}
                                            muiSelectAllCheckboxProps={{
                                                color: 'warning'
                                            }}
                                            displayColumnDefOptions={{
                                                'mrt-row-actions': {
                                                    header: '',
                                                    enableHiding: true,
                                                    size: isMobile ? 55 :18,
                                                    grow: true
                                                },
                                            }}
                                            initialState={{
                                                columnOrder: [
                                                    'checkbox',
                                                    'mrt-row-actions',
                                                    'Name',
                                                    'Type',
                                                    'Value Type'
                                                ]
                                            }}
                                            columns={columns.filter((column) => viewColumns.includes(column.accessorKey))}
                                            data={attributes}
                                            enableRowActions={true}
                                            enableColumnOrdering
                                            enableColumnDragging
                                            enableDensityToggle={false}
                                            defaultColumn={{
                                                minSize: 1,
                                                maxSize: 9001,
                                                size: 260,
                                                muiTableBodyCellProps: {
                                                    sx: {
                                                        fontFamily: "Inter,sans-serif"
                                                    }
                                                },
                                                muiTableHeadCellProps: {
                                                    sx: {
                                                        fontFamily: "Inter,sans-serif"
                                                    }
                                                }
                                            }}
                                            layoutMode='grid'
                                            renderRowActionMenuItems={({ closeMenu, row }) =>
                                                [
                                                    <MenuItem className='row-options' data-testid="update" key="update" onClick={() => { navigate(`/update-attribute/${row.original.name}`) }} >
                                                        <i className='bx bxs-edit me-2' /> Edit </MenuItem>,
                                                    <MenuItem className='row-options' key="delete" onClick={() => { setMessageForPopUp(`Are you sure you want to Delete this Attribute "${row.original.name}"?`), toggleModal(row,"single"), closeMenu() }}>
                                                        <i className="ri ri-delete-bin-5-line me-2" />{" "}
                                                        Delete
                                                    </MenuItem>,
                                                ]
                                            }
                                        /> : null}
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
            {SettingsModal()}
            <div>
                <CommonModal open={modalForBulkDelete ? modalForBulkDelete : modal} toggle={() => { if (modal) { toggleModal(attributeName, "single") } else { toggleModal(selectedRowsForBulkDelete, "multi") } }} buttonText={"Yes, Delete It"} messageForPopUp={messageForPopUp} modalAction={() => {
                    if (modal) {
                        dispatch(deleteAttribute(attributeName.original.name, setLoading))
                        toggleModal(attributeName, "single")
                    }
                    else {
                        let jsonObj = { ids: attributeArray, type: "Attribute" }
                        dispatch(bulkDelete(jsonObj, setLoading))
                        setSelectAllAttributes(false)
                        setSelectedAttribute([])
                        toggleModal(selectedRowsForBulkDelete, "multi")
                    }
                }} />
            </div>
        </React.Fragment>
    )
}