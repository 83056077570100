import moment from "moment";
import { deletePolicy } from "../../../slices/settlementPolicy/DeletePolicy/thunk";
import { extractPartnerData } from "./ModifyFilterColumns";

export const mergedDataForOfferList = (orderData, selectedOffers) => {
    const uniqueOfferCodes = new Set();
    return selectedOffers.reduce((acc, obj) => {
        if (!uniqueOfferCodes.has(obj.offerCode)) {
            uniqueOfferCodes.add(obj.offerCode);
            acc.push(obj);
        }
        return acc;
    }, []);
};

export const addOfferDataUsingDispatch = (selectedOffers, settlementOffers, dispatch) => {
    const newOffers = selectedOffers.filter(offer => 
        !settlementOffers.some(selected => selected.offerCode === offer.offerCode)
    );
    if (newOffers.length > 0) {
        dispatch({ type: 'ADD_OFFER_DUPLICATE', payload: newOffers });
    }
};

export const addPartnerDataUsingDispatch = (filteredArray, partnerDataArray, dispatch, settlementPartner, isEdit) => {
    const newPartners = isEdit
        ? filteredArray.filter(item => !settlementPartner.some(selected => selected.partnerId === item.partnerId))
        : partnerDataArray.filter(item => !settlementPartner.some(selected => selected.extractedId === item.extractedId));
    
    if (newPartners.length > 0) {
        dispatch({ type: 'ADD_PARTNER_DUPLICATE', payload: newPartners });
    }
};

export const handleDelete = (offerCode, dispatch) => {
    dispatch({ type: 'REMOVE_OFFER', payload: offerCode });
};

export const partnerDataFunction = (PartnerData) => {
    return PartnerData.map(item => extractPartnerData(item, 'partnerId'));
};

export const removePartner = (partnerId, dispatch) => {
    dispatch({ type: 'REMOVE_PARTNER', payload: partnerId });
};

export const mergedPartnerArray = (mergedArray, PartnerMainId) => {
    return mergedArray.filter(item => PartnerMainId.partnerId.includes(item.partnerId));
};

export const handleChangeStatus = async (dispatch, policyId, setLoading) => {
    dispatch(deletePolicy(policyId, setLoading));
};

export const toggleModal = (row, setPolicyId, setModal) => {
    if (row && row.original) {
        const policyId = row.original.id;
        setPolicyId(policyId);
        setModal(prevModal => !prevModal);
    }
};

export const closeModal = (setModal, modal) => {
    setModal(!modal);
};

export const formattedDatesFunction = (fetchAllPolicies) => {
    return fetchAllPolicies.map(item => moment(item.createdAt).format("D MMM YYYY"));
};

export const createPartnerDataFunction = (PartnerData, partnerId, setPartnerDataArray) => {
    const filteredData = PartnerData.filter(item => partnerId.includes(item.id.split('/').pop()));
    const newDataArray = filteredData.map(item => extractPartnerData(item, 'extractedId'));
    setPartnerDataArray(newDataArray);
};

export const mergeAndFilterPartners = (settlementPartner, setMergedPartners) => {
    const uniquePartners = new Set();
    const mergedArray = settlementPartner.reduce((acc, obj) => {
        if (!uniquePartners.has(obj.extractedId)) {
            uniquePartners.add(obj.extractedId);
            acc.push(obj);
        }
        return acc;
    }, []);
    setMergedPartners(mergedArray);
};

export const mergeAndFilterOffers = (settlementOffers, setMergedArray) => {
    const uniqueOffers = new Set();
    const mergedArray = settlementOffers.reduce((acc, obj) => {
        if (!uniqueOffers.has(obj.offerCode)) {
            uniqueOffers.add(obj.offerCode);
            acc.push(obj);
        }
        return acc;
    }, []);
    setMergedArray(mergedArray);
};