import { Label } from "reactstrap"

export function LabelText({
    htmlFor,
    className,
    displayText,
    important,id,
    classLabelName
}){
    return(
        <Label id={id} className={classLabelName} htmlFor={htmlFor}>{displayText}<span className={className}> {important}</span></Label>
    )
}