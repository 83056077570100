import { Col, Card, CardBody, CardHeader, Row } from "reactstrap"
import { LabelText } from "../../Utils/Input"
import SelectField from "../../Utils/SelectField"
import InputField from "../../Utils/InputField"
import { SubscriptionStartsOnOptions, TimePeriodEndOptions } from "./constants/initialValues"
import { SubscriptionEndsOnOptions } from "./constants/initialValues"
import { TimePeriodOptions } from "./constants/initialValues"
import { timePeriodOption, timePeriodOptionStart } from "./constants/initialValues"
import { initialValues } from "./constants/initialValues"
import { handleChangeForSubscriptionValidity } from "./constants/UpdateOfferJsonObj"

export default function SubscriptionValidity({ values, errors, touched, setFieldValue, handleChange, isEdit,isSubscribed }) {
    return (
        <Card>
            <CardHeader>
                <h4 className={`card-title mb-0 flex-grow-1 ${isEdit ? 'title-disable-color-edit-offer' : ''}`}>Subscription Validity</h4>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xxl={4} md={12}>
                        <LabelText htmlFor={"subscriptionStartsOn"} className={"text-danger"} displayText={"Subscription Starts On"} important={"*"} classLabelName={isSubscribed ? "label-disable-color-edit-offer":""} />
                        <SelectField isDisabled={isSubscribed ? true : false} className={(errors.subscriptionStartsOn && touched.subscriptionStartsOn) || false ? 'error-input' : ''} placeholder={"Select a value"} value={values.subscriptionStartsOn}
                            handleChange={handleChangeForSubscriptionValidity(initialValues, setFieldValue, "Start")}
                            options={[...SubscriptionStartsOnOptions]} />
                        {touched.subscriptionStartsOn && errors.subscriptionStartsOn ? (
                            <p className="text-danger">
                                {errors.subscriptionStartsOn}
                            </p>
                        ) : null}
                    </Col>
                    {values?.subscriptionStartsOn?.label === "Relative" &&
                        <>
                            <Col xxl={4} md={6}>
                                <LabelText htmlFor={"timePeriodStart"} className={"text-danger"} displayText={"Time Period"} classLabelName={isSubscribed ? "label-disable-color-edit-offer":""} />
                                <InputField name={"timePeriodStart"} disabled={isSubscribed ? true : false} type={'number'} className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`} id={"timePeriodStart"} handleChange={handleChange} value={values.timePeriodStart} />
                            </Col>
                            <Col xxl={4} md={6} className="period-option">
                                <div data-testid="select-component"><SelectField
                                    name={`timePeriodOptionStart`} isDisabled={isSubscribed ? true : false}
                                    value={values.timePeriodOptionStart || ""}
                                    handleChange={timePeriodOptionStart(values, setFieldValue)}
                                    options={TimePeriodOptions} />
                                </div>
                            </Col>
                        </>
                    }
                </Row>
                <Row className="mt-3">
                    <Col xxl={4} md={12}>
                        <LabelText htmlFor={"subscriptionEndsOn"} className={"text-danger"} displayText={"Subscription Ends On"} important={"*"} classLabelName={isSubscribed ? "label-disable-color-edit-offer":""} />
                        <SelectField className={(errors.subscriptionEndsOn && touched.subscriptionEndsOn) || false ? 'error-input' : ''} placeholder={"Select a value"} value={values.subscriptionEndsOn} 
                        handleChange={handleChangeForSubscriptionValidity(initialValues, setFieldValue, "End")} isDisabled={isSubscribed ? true : false}
                        options={[...SubscriptionEndsOnOptions]} />
                        {touched.subscriptionEndsOn && errors.subscriptionEndsOn ? (
                            <p className="text-danger">
                                {errors.subscriptionEndsOn}
                            </p>
                        ) : null}
                    </Col>
                    {values.subscriptionEndsOn.label === "Relative" &&
                        <>
                            <Col xxl={4} md={6}>
                                <LabelText htmlFor={"timePeriod"} className={"text-danger"} displayText={"Time Period"} classLabelName={isSubscribed ? "label-disable-color-edit-offer":""} />
                                <InputField name={"timePeriod"} disabled={isSubscribed ? true : false} type={'number'} className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`} id={"timePeriod"} handleChange={handleChange} value={values.timePeriod} />
                            </Col>
                            <Col xxl={4} md={6} className="period-option">
                                <div data-testid="select-component"><SelectField
                                    name={`timePeriodOption`} isDisabled={isSubscribed ? true : false}
                                    value={values.timePeriodOption || ""}
                                    handleChange={timePeriodOption(values, setFieldValue)}
                                    options={TimePeriodEndOptions} />
                                </div>
                            </Col>
                        </>
                    }
                </Row>
            </CardBody>
        </Card>
    )
}