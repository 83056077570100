import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    documentUpdateFile: [],
    loading: false,
    error: null,
};

const fetchDocumentUpdateFileSlice = createSlice({
    name: "documentUpdateFile",
    initialState,
    reducers: {
        fetchDocumentUpdateFileSuccess: (state, action) => {
            state.loading = false;
            state.documentUpdateFile = action.payload
        },
        fetchDocumentUpdateFileFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchDocumentUpdateFileSuccess,
    fetchDocumentUpdateFileFailure
} = fetchDocumentUpdateFileSlice.actions

export default fetchDocumentUpdateFileSlice.reducer