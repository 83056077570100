import { MVNO_DASHBOARD_API } from "../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor"
import { fetchGetMvnoDashboardFailure, fetchGetMvnoDashboardSuccess } from "./reducer"

export const getMvnoDashboard= (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response =  await determineBaseUrlAndPerformApiCall(`${MVNO_DASHBOARD_API}`, 'GET')
        setLoading(false)
        dispatch(fetchGetMvnoDashboardSuccess(response))
    }
    catch (error) {
        setLoading(false)
        dispatch(fetchGetMvnoDashboardFailure(error.message))
    }
}