import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    config: [],
    loading: false,
    error: null,
}

const configSlice = createSlice({
    name: "config",
    initialState,
    reducers: {
        fetchConfigSuccess: (state, action) => {
            state.loading = false,
                state.config = action.payload;
        },
        fetchConfigFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchConfigSuccess,
    fetchConfigFailure,
} = configSlice.actions;

export default configSlice.reducer;