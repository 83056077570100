import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    customerListForReservation : [],
    loading : false,
    error : null,
};

const fetchCustomerListForReservationSlice = createSlice({
    name:"customerListForReservation",
    initialState,
    reducers: {
        fetchCustomerListForReservationSuccess: (state,action) => {
            state.loading = false;
            state.customerListForReservation = action.payload
        },
        fetchCustomerListForReservationFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchCustomerListForReservationFailure,
    fetchCustomerListForReservationSuccess
} = fetchCustomerListForReservationSlice.actions

export default fetchCustomerListForReservationSlice.reducer