import { Button, CardBody, Col, Row } from "reactstrap";
import { LabelText } from "../Utils/Input";
import InputField from "../Utils/InputField";
import CommonDateTimePicker from "../Utils/CommonDateTimePicker";
import moment from "moment";

export const DateAndAttributeDetails = ({ values, setStartDate, setFieldTouched, setFieldValue, initialValues, startDate, selectedOffer, setFormData, formData }) => {
    return (
        <Row className='mt-4'>
            <Col xxl={6} md={6} className='mb-3'>
                <div>
                    <LabelText htmlFor={'Subscription starts on'} displayText={'Subscription starts on'} />
                    <CommonDateTimePicker
                        views={['year', 'month', 'day']}
                        value={values.subscriptionStartsOn || initialValues.subscriptionStartsOn}
                        className={`form-control`}
                        handleChange={(selectedDates) => {
                            setStartDate(selectedDates)
                            setFieldTouched('subscriptionStartsOn', false);
                            setFieldValue('subscriptionStartsOn', selectedDates)
                        }}
                        name={"subscriptionStartsOn"}
                        format="DD/MM/YYYY"
                        minDate={moment()}
                    />
                </div>
            </Col>
            <Col xxl={6} md={6} className='mb-3'>
                <div>
                    <LabelText htmlFor={'Subscription ends on'} displayText={'Subscription ends on'} />
                    <CommonDateTimePicker
                        views={['year', 'month', 'day']}
                        value={values.subscriptionEndsOn || initialValues.subscriptionEndsOn}
                        handleChange={(selectedDates) => {
                            setFieldTouched('subscriptionEndsOn', false);
                            setFieldValue('subscriptionEndsOn', selectedDates, false)
                        }}
                        className={`form-control`}
                        format="DD/MM/YYYY"
                        minDateTime={values.subscriptionStartsOn}
                    />
                </div>
            </Col>
            {selectedOffer?.provisioningAttributes?.length > 0 ?
                <div>
                    <CardBody className='border-bottom'>
                        <h5 className="d-inline ">Provisioning Attributes</h5>
                    </CardBody>
                    <Row lg={12}>
                        <table className="table table-borderless table-nowrap">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Value</th>
                                    <th scope='col'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedOffer?.provisioningAttributes?.map((attribute, index) => (
                                    <tr>
                                        <td>
                                            <InputField
                                                disabled={true}
                                                name={"name"}
                                                type={"text"}
                                                className={"form-control"}
                                                id={"code"}
                                                value={selectedOffer?.provisioningAttributes[index].name}
                                                handleChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                            />
                                        </td>
                                        <td>
                                            <InputField
                                                name={"value"}
                                                placeholder={"Enter the Value"}
                                                type={"text"}
                                                className={"form-control"}
                                                id={"value"}
                                                value={selectedOffer?.provisioningAttributes[index].value}
                                                handleChange={(e) => setFormData({ ...formData, value: e.target.value })}
                                            />
                                        </td>
                                        <td>
                                            <Button type="button" color="secondary" >
                                                Clear
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Row>
                </div>
                : null}
        </Row>
    )
}