import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reservations: [],
    loading: false,
    error: null,
}

const reservationsSlice = createSlice({
    name: "reservations",
    initialState,
    reducers: {
        fetchReservationStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchReservationSuccess: (state, action) => {
            state.loading = false,
            state.reservations = action.payload;
        },
        fetchReservationFailure: (state, action) => {
            state.loading = false,
            state.error = action.payload;
        },
    },
})

export const {
    fetchReservationStart,
    fetchReservationSuccess,
    fetchReservationFailure,
} = reservationsSlice.actions;

export default reservationsSlice.reducer;