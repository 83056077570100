import { VERIFY_DOCUMENT_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchDocumentVerifySuccess,fetchDocumentVerifyFailure } from "./reducer";
import { toast } from "react-toastify";
import { setDocumentSuccessMessage } from "../../toastMessage/action";
import { fetchDocumentsForCustomer } from "../documentlist/thunk";

export const verifyDocument = (jsonObj,setLoading,customerId) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${VERIFY_DOCUMENT_API}`,'PUT',jsonObj).then((response)=>{
        if (!response.error) {
            setLoading(false)
            dispatch(fetchDocumentVerifySuccess(response))
            dispatch(fetchDocumentsForCustomer(setLoading,customerId))
            toast.success(
                'Document Verified Successfully'
            )
            dispatch(setDocumentSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchDocumentVerifyFailure(response.error.message))
        }
        setLoading(false)
    }).catch((error)=>{})
}