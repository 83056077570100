import { cancelOrder } from "../../../slices/orderManagement/cancelorder/thunk"
import { placeOrder } from "../../../slices/orderManagement/placeorder/thunk"

export const cancelRequestPayload = (orderId, values) => {
    return {
        "@type": "CancelProductOrder",
        "cancellationReason": values.reason.value === "Other" ? values.comment : values.reason.value,
        "productOrderRef": [
            {
                "id": orderId,
                "@type": "ProductOrderRef"
            }
        ],
        "state": ""
    }
}

export const performCancelApiRequest = (orderId, values, dispatch) => {
    const jsonObj = cancelRequestPayload(orderId, values)
    dispatch(cancelOrder(jsonObj))
}

export const performPatchCancelRequest = (setLoading, orderId, navigate, dispatch) => {
    const jsonObj = {
        "state": "assessingCancellation"
    }
    dispatch(placeOrder(setLoading, jsonObj, orderId, navigate, "Cancel"))
}