import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getReservations: [],
    loading: false,
    error: null,
}

const getReservationSlice = createSlice({
    name: "getReservations",
    initialState,
    reducers: {
        fetchGetReservationSuccess: (state, action) => {
            state.loading = false,
            state.getReservations = action.payload;
        },
        fetchGetReservationFailure: (state, action) => {
            state.loading = false,
            state.error = action.payload;
        },
    },
})

export const {
    fetchGetReservationSuccess,
    fetchGetReservationFailure,
} = getReservationSlice.actions;

export default getReservationSlice.reducer;