import * as Yup from 'yup';

export const validationSchemaForProfile = () => {
    let validationSchema = {}
    return validationSchema = Yup.object().shape({
    newPassword:Yup.string().required('kindly enter the new password').matches(/^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/,
    'Password must contain at least 8 characters, one uppercase letter, one number, and one special character'
    ),
  confirmNewPassword: Yup.string().required('kindly enter the confirm new password ').oneOf([Yup.ref('newPassword'), null], 'Passwords must match').matches( /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/,
      'Password must contain at least 8 characters, one uppercase letter, one number, and one special character'
    ),
})
}

export const validationSchemaForCustomer = () => {
  let validationSchema = {}
  return validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Kindly enter First Name").matches(/^[a-zA-Z ]*$/, 'Firstname can contain only characters'),
    lastName: Yup.string().required('Kindly enter Last Name').matches(/^[a-zA-Z ]*$/, 'Lastname can contain only characters'),
    userName: Yup.string().required('Kindly enter User Name'),
    email: Yup.string().email('Kindly enter valid email').required('Kindly enter Email'),
    accountNumber: Yup.string().required('Kindly enter Account Number').matches(/^[0-9a-zA-Z]+$/, 'Can contain only characters and digits').test('len', 'Must be exactly 10 characters', val => val?.toString().length === 10),
    addressLine1: Yup.string().max(30, 'Must be 30 characters or less').required('Kindly enter Address'),
    country: Yup.object().required("Kindly select Country"),
    city: Yup.object().required('Kindly select City'),
    state: Yup.object().required('Kindly select State'),
    zipCode: Yup.number().typeError('It doesn\'t look like a zip code').positive('A zip code can\'t start with a minus').integer('It can\'t include a decimal point').test('len', 'Must be exactly 6 digits', val => val?.toString().length === 6).required('Kindly enter Zip code'),
  })
}