import api, { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { OFFER_LISTING_API } from "../constants/constants";
import { fetchOfferSuccess, fetchOfferFailure } from "./reducer";

export const fetchAllOffers = (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${OFFER_LISTING_API}`,'GET')
        setLoading(false)
        dispatch(fetchOfferSuccess(response.reverse()))
    }
    catch (error) {dispatch(fetchOfferFailure(error.message)) }
}