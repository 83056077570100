import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    createPartner : [],
    loading : false,
    error : null,
};

const createPartnerSlice = createSlice({
    name:"createPartner",
    initialState,
    reducers: {
        fetchCreatePartnerSuccess: (state,action) => {
            state.loading = false;
            state.createPartner = action.payload
        },
        fetchCreatePartnerFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchCreatePartnerFailure,
    fetchCreatePartnerSuccess
} = createPartnerSlice.actions

export default createPartnerSlice.reducer