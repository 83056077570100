import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    rateUsage : [],
    loading : false,
    error : null,
};

const rateUsageSlice = createSlice({
    name:"rateUsage",
    initialState,
    reducers: {
        fetchRateUsageSuccess: (state,action) => {
            state.loading = false;
            state.rateUsage = action.payload
        },
        fetchRateUsageFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchRateUsageFailure,
    fetchRateUsageSuccess
} = rateUsageSlice.actions

export default rateUsageSlice.reducer