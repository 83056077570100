import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateReservation: [],
    loading: false,
    error: null,
}

const updateReservationSlice = createSlice({
    name: "updateReservation",
    initialState,
    reducers: {
        fetchUpdateReservationSuccess: (state, action) => {
            state.loading = false,
            state.updateReservation = action.payload;
        },
        fetchUpdateReservationFailure: (state, action) => {
            state.loading = false,
            state.error = action.payload;
        },
    },
})

export const {
    fetchUpdateReservationSuccess,
    fetchUpdateReservationFailure,
} = updateReservationSlice.actions;

export default updateReservationSlice.reducer;