import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    createPolicy : [],
    loading : false,
    error : null,
};

const createPolicySlice = createSlice({
    name:"createPolicy",
    initialState,
    reducers: {
        fetchCreatePolicySuccess: (state,action) => {
            state.loading = false;
            state.createPolicy = action.payload
        },
        fetchCreatePolicyFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchCreatePolicyFailure,
    fetchCreatePolicySuccess
} = createPolicySlice.actions

export default createPolicySlice.reducer