import React from 'react';
import {
    Col,
    Card,
    CardBody,
    ListGroup,
    ListGroupItem,
    Input,
} from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';

const nameContainerStyle = {
    height: '20px',
    overflow: 'hidden',
};

const Sidebar = ({
    roleName,
    searchQuery,
    setSearchQuery,
    metricsData,
    selectedCustomer,
    handleCustomerClick,
    filteredActivitys,
    activeGroup,
    handleGroupClickWithHighlight
}) => {
    return (
        <Card>
            <CardBody>
                {roleName === 'genericAdmin' && (
                    <Input
                        type='text'
                        className='form-control mb-md-2'
                        id='firstName'
                        placeholder='Search for Teams'
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                    />
                )}
                <SimpleBar
                    style={{ height: roleName === 'genericAdmin' ? '305px' : '420px' }}
                    className='mx-n3'
                >
                    {roleName !== 'genericAdmin' ? (
                        <ListGroup className='list mb-0' flush>
                            {metricsData?.map((data) => (
                               
                                <ListGroupItem
                                    key={data.customerId}
                                    data-id={data.customerId}
                                    className={`list-group-item list-group-item-action ${selectedCustomer === data.customerId
                                        ? 'bg-primary text-primary'
                                        : ''
                                        }`}
                                >
                                    <div className='d-flex'>
                                        <div className='flex-grow-1'>
                                            <h5 className='fs-13 mb-1'>
                                                <Link
                                                    to='#'
                                                    className={`link name ${selectedCustomer === data.customerId
                                                        ? 'text-light'
                                                        : 'text-body'
                                                        }`}
                                                    onClick={() => handleCustomerClick(data)}
                                                >
                                                    <div style={nameContainerStyle}>{`${data.firstName} ${data.lastName}`}</div>
                                                </Link>
                                            </h5>
                                        </div>
                                    </div>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    ) : (
                        <ListGroup className='list mb-0' flush>
                            {filteredActivitys?.map((data) => (
                                <ListGroupItem
                                    key={data?.group.name}
                                    data-id={data.group.name}
                                    className={`list-group-item list-group-item-action ${activeGroup === data?.group?.name
                                        ? 'bg-primary text-primary'
                                        : ''
                                        }`}
                                >
                                    <div className='d-flex'>
                                        <div className='flex-grow-1'>
                                            <h5 className='fs-13 mb-1'>
                                                <Link
                                                    to='#'
                                                    className={`link name ${activeGroup === data?.group?.name
                                                        ? 'text-light'
                                                        : 'text-body'
                                                        }`}
                                                    onClick={() => handleGroupClickWithHighlight(data?.group?.name)}
                                                >
                                                    <div style={nameContainerStyle}>{data?.group?.name}</div>
                                                </Link>
                                            </h5>
                                        </div>
                                    </div>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    )}
                </SimpleBar>
            </CardBody>
        </Card>
    );
};

export default Sidebar;
