import { partnerUploadDocument } from "../../../slices/PartnerMangement/PartnerDocumentUpload/thunk";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { createNewPassword } from "../../../slices/UserManagement/PasswordManagement/thunk";
import { deleteDoc } from "../../../slices/customer/documentdelete/thunk";
import { resetDocumentViewState } from "../../../slices/customer/documentview/reducer";
import { updateUser } from "../../../slices/UserManagement/UpdateUser/thunk";

export const navigateToAddUser = (navigate) => {
    navigate("/add-user")
}

export const handleChangePassword = (values,dispatch,setLoading,navigate,userId) => {
    const jsonForPasswordManagement = {
        newpassword: values.newPassword,
        confirmnewpassword: values.confirmNewPassword
    };
   
    dispatch(createNewPassword(jsonForPasswordManagement, setLoading, navigate, userId));
    
};
export const handleChangeUser = (values,dispatch,setLoading,navigate,userId,isProfile) => {
    const phoneNumber = values.phone ? formatPhoneNumberIntl(values.phone) : '';
        const dialCodeWithPlus = phoneNumber.match(/^\+\d+(\s|$)/)[0];
        const restOfNumber = phoneNumber.slice(dialCodeWithPlus?.length);
        const mobileNumberWithoutSpaces = restOfNumber.replace(/\s/g, '');
        const dialCodeWithoutSpaces = dialCodeWithPlus.replace(/\s/g, '');
        const formattedValues = {
            userName: values?.username,
            role: values?.userrole?.value,
            email: values?.email,
            profile: {
                firstName: values?.firstname,
                lastName: values?.lastname,
            },
            phone: mobileNumberWithoutSpaces,
            dialCode: dialCodeWithoutSpaces ||"+91",
            status: status.toLowerCase(),
        };
   dispatch(updateUser(setLoading, formattedValues, navigate, userId,isProfile));
    
};

export const handleRemoveLogo = (dispatch,setLoading,setLogoFile,setImageOn,setUpdate,fileInputRef,documentName,userId,isEdit) => {
    const payload = {
        "ID": userId,
        "documentName": documentName
    }
    if(isEdit){dispatch(deleteDoc(setLoading, payload, userId,"User"));}
    setLogoFile(null);
    setImageOn(true)
    setUpdate(true)
    dispatch(resetDocumentViewState());

    if (fileInputRef.current) {
        fileInputRef.current.value = '';
    }
};
export const documentUpdateJsonForPartner = (logoFile, PartnerId, editData) => {
    const formdata = new FormData();
    formdata.append("file", logoFile);
    formdata.append("ID", PartnerId);
    formdata.append("documentName", `logo`);
    formdata.append("documentType", "logo");
    formdata.append("newDocumentName", `logo`);
    formdata.append("uploadedBy", `${editData}`);
    return formdata;
};
export const documentUpdateJson = (logoFile,userId,editData) => {
    var formdata = new FormData();
    const roleName = localStorage.getItem("roleName")
    formdata.append("file", logoFile);
    formdata.append("ID", userId);
    if(roleName === "systemUser"){
        formdata.append("userType","operator")
    }
    formdata.append("userType","operator")
    formdata.append("documentName", "logo");
    formdata.append("documentType", "logo");
    formdata.append("newDocumentName", "logo")
    formdata.append("uploadedBy", `${editData?.userDetails?.userName}`);
    return formdata
}

export const documentJson = (logoFile,userId,editData) => {
    var formdata = new FormData();
    const roleName = localStorage.getItem("roleName")
    formdata.append("file", logoFile);
    formdata.append("ID", userId);
    if(roleName === "systemUser"){
        formdata.append("userType","operator")
    }
    formdata.append("documentName", "logo");
    formdata.append("documentType", "logo");
    formdata.append("uploadedBy", `${editData?.userDetails?.userName}`);
    return formdata
}

export const uploadDocApiCall = (logoFile,userId,editData,setLoading,setFileSelected,dispatch,navigate) => {
    let documentObj =  documentJson(logoFile,userId,editData)
    let partnerOrCustomer = "User";
    dispatch(partnerUploadDocument(documentObj, setLoading, userId, editData?.userDetails?.userName, partnerOrCustomer, setFileSelected,navigate));
};
