import * as Yup from "yup";

export const ValueTypeOptions = [{
  name: "valuetype",
  value: "UOM",
  options: [
    { value: "integer", label: "Integer" },
    { value: "string", label: "Text" },
    { value: "bool", label: "Boolean" },
    { value: "float64", label: "Decimal" },
  ],
}]


export const initialFormValues = {
  name: "",
  valuetype: "",
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Kindly Enter the uom name"),
  valuetype: Yup.mixed().required("Kindly Enter the value type"),
});
