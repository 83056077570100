import { Col, FormFeedback } from "reactstrap";
import { LabelText } from "../Utils/Input";
import InputField from "../Utils/InputField";

export default function FormLine1({
  touched,
  errors,
  handleChange,
  handleBlur,
  values,
}) {
  return (
    <>
      <Col xxl={3} md={6}>
        <LabelText
          htmlFor={"name"}
          className={"text-danger"}
          displayText={"Station Name"}
          important={"*"}
        />
        <InputField
          name={"name"}
          placeholder={"Enter Station Name"}
          type={"text"}
          className={"form-control"}
          id={"name"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.name || ""}
          invalid={touched.name && errors.name ? true : false}
        />
        {touched.name && errors.name ? (
          <FormFeedback type="invalid">{errors.name}</FormFeedback>
        ) : null}
      </Col>
      <Col xxl={3} md={6}>
        <LabelText htmlFor={"name"} displayText={"Serial Number"} />
        <InputField
          name={"serialNumber"}
          placeholder={"Enter serial Number"}
          type={"text"}
          className={"form-control"}
          id={"name"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.serialNumber || ""}
        />
      </Col>
      <Col xxl={3} md={6}>
        <LabelText
          htmlFor={"address_line1"}
          className={"text-danger"}
          displayText={"Address line 1"}
          important={"*"}
        />
        <InputField
          name={"address_line1"}
          placeholder={"Enter Address line 1"}
          type={"text"}
          className={"form-control"}
          id={"address_line1"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.address_line1 || ""}
          invalid={touched.address_line1 && errors.address_line1 ? true : false}
        />
        {touched.address_line1 && errors.address_line1 ? (
          <FormFeedback type="invalid">{errors.address_line1}</FormFeedback>
        ) : null}
      </Col>
      <Col xxl={3} md={6}>
        <LabelText
          htmlFor={"address_line2"}
          className={"text-danger"}
          displayText={"Address line 2"}
          important={"*"}
        />
        <InputField
          name={"address_line2"}
          placeholder={"Enter Address line 2"}
          type={"text"}
          className={"form-control"}
          id={"address_line2"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.address_line2 || ""}
          invalid={touched.address_line2 && errors.address_line2 ? true : false}
        />
        {touched.address_line2 && errors.address_line2 ? (
          <FormFeedback type="invalid">{errors.address_line2}</FormFeedback>
        ) : null}
      </Col>
    </>
  );
}
