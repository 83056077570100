import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    customerList : [],
    loading : false,
    error : null,
};

const fetchCustomerListSlice = createSlice({
    name:"customerList",
    initialState,
    reducers: {
        fetchCustomerListSuccess: (state,action) => {
            state.loading = false;
            state.customerList = action.payload
        },
        fetchCustomerListFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchCustomerListFailure,
    fetchCustomerListSuccess
} = fetchCustomerListSlice.actions

export default fetchCustomerListSlice.reducer