import { fetchDeviceSuccess, fetchDeviceFailure } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { DEVICE_API } from "../../constants/constants";
export const getIdDevice = (deviceId, isEdit, initialValues, setLoading, setDeviceID) => async (dispatch) => {
  setLoading(true);
  await determineBaseUrlAndPerformApiCall(`${DEVICE_API}/${deviceId}`, 'GET')
    .then((response) => {
      dispatch(fetchDeviceSuccess(response));
      initialValues.deviceId = response.deviceId;
      initialValues.deviceType = { "value": response.deviceType, "label": response.deviceType };
      initialValues.status = { "value": response.status, "label": response.status };
      if ("attributeList" in response) {
        initialValues.attributeList = []
        response.attributeList.forEach((resp, index) => {
          setDeviceID(id => [...id, response.attributeList[index]])
          initialValues.attributeList.push({
            "value": resp.value,
            "name": resp.name
          })
        })
      }
    })
    .catch((error) => {
      dispatch(fetchDeviceFailure(error.message));
      setLoading(false);
    });
  setLoading(false);
};

