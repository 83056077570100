import { MaterialReactTable } from "material-react-table";
import { MenuItem } from '@mui/material';
import { downloadDocApiCall, viewDocApiCall } from "./constants/HandleChangeFunctions";
import { UploadDocumentRowOptions } from "./UploadDocumentRowOptions";
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { useMemo } from "react";
import { useSelector } from "react-redux";

export default function UploadDocumentTableForSelfcare ({setLoading,dispatch,customerId,setIsOpen,open,toggleModal,setFields,setEditingRow,setEditedDocumentName,setEditedDocumentType,deleteDocument,setButtonText,setMessageForPopUp,columns,docTableMockData, setProfileData}) {
    const layoutModeType = useSelector((state) => state.Layout.layoutModeType);
    const globalTheme = useTheme();

    const tableTheme = useMemo(() => {
        const darkMode = layoutModeType === 'dark';

        return createTheme({
            palette: {
                mode: darkMode ? 'dark' : globalTheme.palette.mode,
                primary: darkMode ? { main: '#ffffff' } : globalTheme.palette.primary,
                background: {
                    default: darkMode ? '#212529' : globalTheme.palette.background.default,
                },
                text: {
                    primary: darkMode ? '#ffffff' : globalTheme.palette.text.primary,
                },
            },
            typography: {
                button: {
                    textTransform: 'none',
                    fontSize: '1.2rem',
                },
            },
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            backgroundColor: darkMode ? '#212529' : undefined,
                            color: darkMode ? '#ffffff' : undefined,
                        },
                    },
                },
                MuiTableHeadCell: {
                    styleOverrides: {
                        root: {
                            backgroundColor: darkMode ? '#343a40' : undefined,
                            color: darkMode ? '#ffffff' : undefined,
                        },
                    },
                },
            },
        });
    }, [globalTheme, layoutModeType]);
    return(
        <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
        displayColumnDefOptions={{
            'mrt-row-actions': {
                header: '',
                enableHiding: true
            },
        }}
        muiTableHeadCellProps={{
            sx: {
                fontFamily: "Inter,sans-serif"
            }
        }}
        muiTablePaperProps={{
            elevation: 0
        }}
        icons={{
            DragHandleIcon: () => <i className="ri-drag-move-fill" style={{ color: 'white' }} />,
            SearchIcon: () => <i className="ri-search-line" style={{ color: 'white' }} />,
            ClearAllIcon: () => <i className="ri-close-line" style={{ color: 'white' }} />,
        }}
        // muiTableHeadCellProps={{
        //     sx: {
        //         fontFamily: 'Inter, sans-serif',
        //         backgroundColor: layoutModeType === 'dark' ? '#343a40' : '#f8f9fa',
        //         color: layoutModeType === 'dark' ? 'white' : '#212529',
        //     },
        // }}
        muiTableBodyRowProps={{
            sx: {
                backgroundColor: layoutModeType === 'dark' ? '#212529' : '#f8f9fa',
                '&:hover': {
                    backgroundColor: layoutModeType === 'dark' ? '#343a40' : '#e9ecef',
                },
            },
        }}
        muiTableBodyCellProps={{
            sx: {
                backgroundColor: layoutModeType === 'dark' ? '#212529' : '#ffffff',
                color: layoutModeType === 'dark' ? 'white' : '#212529',
                '&:hover': {
                    backgroundColor: layoutModeType === 'dark' ? '#343a40' : '#e9ecef',
                },
            },
        }}
        enableDensityToggle={false}
        renderRowActionMenuItems={({closeMenu,row }) => (
            [
                <MenuItem key={`${row.index}.1`} onClick={() => { viewDocApiCall(dispatch, setLoading, customerId, row.original.documentName,setProfileData), closeMenu() }}><i className="ri-survey-line me-2" /> Preview</MenuItem>,
                <MenuItem key={`${row.index}.2`} onClick={() => { setFields(true), closeMenu(), setEditingRow(row.index), setEditedDocumentName(row.original.documentName), setEditedDocumentType({ value: row.original.documentType, label: row.original.documentType }); }}><i className='bx bxs-edit me-2' /> Edit</MenuItem>,
                <MenuItem key={`${row.index}.3`} onClick={() => { downloadDocApiCall(dispatch, setLoading, customerId, row.original.documentName), closeMenu() }}><i className="ri-download-line me-2" /> Download</MenuItem>,
                row.original.status !== "Approved" && <MenuItem key={`${row.index}.4`} onClick={(e) => {
                    closeMenu()
                    e.stopPropagation()
                    toggleModal(setIsOpen, open)
                    deleteDocument(customerId, row.original.documentName)
                    setButtonText('Yes,Delete It')
                    setMessageForPopUp(`Are you sure you want to Delete this document \'${row.original.documentName}\'?`)
                }}><i className="ri-delete-bin-5-line me-2" />
                    Delete</MenuItem>
            ]
        )}
        enableRowActions enableSorting={false} enableColumnActions={false} enableTopToolbar={false} data={docTableMockData} columns={columns} />
         </ThemeProvider>
    )
}