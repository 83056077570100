import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getStatisticData: [],
    loading: false,
    error: null,
}

const getStatisticDataSlice = createSlice({
    name: "getStatisticData",
    initialState,
    reducers: {
        fetchGetStatisticDataSuccess: (state, action) => {
            state.loading = false;
            state.getStatisticData = action.payload;
        },
        fetchGetStatisticDataFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchGetStatisticDataSuccess, fetchGetStatisticDataFailure } = getStatisticDataSlice.actions;

export default getStatisticDataSlice.reducer;
