import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { USER_MANAGEMENT_CREATE_API } from "../../constants/constants";
import { fetchGetUserNameSucess, fetchGetUserNameFailure } from "./reducer";

export const getUserByName = (name, setLoading, initialValues,setEditData) => (dispatch) => {
    setLoading(true);
    determineBaseUrlAndPerformApiCall(`${USER_MANAGEMENT_CREATE_API}/${name}`, 'GET')
        .then((response) => {
            setLoading(false);
            setEditData(response)
            dispatch(fetchGetUserNameSucess(response))
                initialValues.firstname = response?.profile?.firstName || "",
                initialValues.lastname = response?.profile?.lastName || "",
                initialValues.username = response?.userDetails?.userName || "",
                initialValues.userrole = { value: response?.role, label: response?.role}
                initialValues.phone = response?.dialCode + response?.phone || "",
                initialValues.email = response?.email || "",
                initialValues.status = { value: response?.status, label: response?.status}
            })
    .catch ((errorResp) => { dispatch(fetchGetUserNameFailure(errorResp.message)) })
}


