export const setDefault = (index, setActiveTab) => {
    const setDefaultTab = () => {
        setActiveTab(`${index}.1`)
    }
    const setTab = setDefaultTab()
    return setTab
}

export const storeRow = (data, setIndex, index) => {
    if (index === null) {
        setIndex(data);
        return
    }
    else if (index === data) {
        setIndex(null)
        return
    }
    else if (index && typeof index.toggleExpanded === 'function') {
        index.toggleExpanded(false);
    }
    setIndex(data);
}

export const tabChange = (tab, activeTab, setActiveTab) => {
    if (activeTab !== tab) setActiveTab(tab);
};