import { toast } from "react-toastify"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { fetchCsvFileUploadFailure, fetchCsvFileUploadSuccess } from "./reducer"
import { setDocumentSuccessMessage } from "../../toastMessage/action"
import { IMPORT_OFFER_DATA } from "../../constants/constants"

export const csvUpload = (setLoading, navigate, jsonObj) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${IMPORT_OFFER_DATA}`, 'PUT', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchCsvFileUploadSuccess(response))
            navigate('/offer')
            dispatch(setDocumentSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchCsvFileUploadFailure(response.error.message))
        }
        setLoading(false)
    }).catch((error) => { })
}