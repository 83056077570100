import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editStation: [],
    loading: false,
    error: null,
}

const editStationSlice = createSlice({
    name: "editStation",
    initialState,
    reducers: {
        fetchEditStationSuccess: (state, action) => {
            state.loading = false,
            state.editStation = action.payload;
        },
        fetchEditStationFailure: (state, action) => {
            state.loading = false,
            state.error = action.payload;
        },
    },
})

export const {
    fetchEditStationSuccess,
    fetchEditStationFailure,
} = editStationSlice.actions;

export default editStationSlice.reducer;