import { FormFeedback } from "reactstrap";

export const RenderErrorMessage = ({ field, touched, errors, index }) => {
  switch (field.type) {
    case "select":
      if (!field?.insideTable) {
        return touched[field.name] && errors[field.name] ? (
          <p className="validation-font-size text-danger">{errors[field.name]}</p>
        ) : null;
      }
      else {
        return errors[field.arrayName] &&
          errors[field.arrayName][index] &&
          touched[field.arrayName] &&
          touched[field.arrayName][index] &&
          touched[field.arrayName][index][field.name] ? (
          <p className="text-danger">
            {errors[field.arrayName][index][field.name]}
          </p>
        ) : null
      }
    case "date":
      return touched[field.name] && errors[field.name] ? (
        <p className="text-danger error">{errors[field.name]}</p>
      ) : null;
    default:
      if (!field?.insideTable) {
        return touched[field.name] && errors[field.name] ? (
          <FormFeedback type="invalid">{errors[field.name]}</FormFeedback>
        ) : null;
      }
      else {
        return errors[field.arrayName] &&
          errors[field.arrayName][index] &&
          touched[field.arrayName] &&
          touched[field.arrayName][index] &&
          touched[field.arrayName][index][field.name] ? (
          <p className="text-danger">
            {errors[field.arrayName][index][field.name]}
          </p>
        ) : null
      }
  }
};
