import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Col, Row, Card, CardHeader, CardBody, FormGroup, Form, FormFeedback, Table } from "reactstrap";
import { useState } from "react";
import moment from "moment";
import { LabelText } from "../Utils/Input";
import { handleSelectOffer } from "./constants/OrderFilterFunctions";
import CommonDateTimePicker from "../Utils/CommonDateTimePicker";
import InputField from "../Utils/InputField";
import { Formik } from "formik";
import * as Yup from "yup"
import { useDispatch } from "react-redux";
import { subscribeOrder } from "../../slices/orderManagement/Subscribe/thunk";

export const OrderFilterAccordion = ({ open, toggle, loading, filteredResults, navigate, setLoading, subscriptionId }) => {
    const [selectedOffers, setSelectedOffers] = useState([]);
    const [startsOn, setStartsOn] = useState(null);
    const [endsOn, setEndsOn] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const roleName = localStorage.getItem('roleName');
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [selectedServiceIndex, setSelectedServiceIndex] = useState(null);
    const [attributes, setAttributes] = useState(false)
    const addOn = location.pathname.includes('add-on')
    const dispatch = useDispatch()
    const handleSelect = (offer, offerIndex, index) => {
        if (roleName === 'telcoAdmin' || roleName === 'telcoOperationUser' || roleName === 'telcoBusinessUser') {
            setSelectedOffers([offer]);
            setSelectedItemIndex(offerIndex)
            setSelectedServiceIndex(index)
            setAttributes(offer.provisioningAttributes.length > 0)
        } else {
            handleSelectOffer(offer, navigate, subscriptionId);
        }
    };

    const initialValues = {
        subscriptionId: ""
    }
    const handleConfirm = (values) => {
        const selectedData = selectedOffers.map((offer) => ({
            offer,
            startsOn,
            endsOn,
            subscriptionID: values.subscriptionId
        }));
        moment(values.offerStartsOn).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]')
        if (selectedData.length > 0) {
            const jsonData = {
                subscriptionID: addOn ? subscriptionId : selectedData[0]?.subscriptionID,
                offerCode: selectedData[0]?.offer.offerCode,
                customerId: selectedData[0]?.offer.CustomerId,
                quantity: quantity,
                ...(startsOn && { validFrom: moment(startsOn?._d).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'), }),
                ...(endsOn && { validTo: moment(endsOn?._d).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'), })
            };
            if (jsonData.subscriptionID !== "") {
                dispatch(subscribeOrder(jsonData, setLoading, navigate));
            }
        }
    };

    const validationSchema = Yup.object({
        subscriptionId: Yup.string().required("Kindly enter the subscription Id")
    })

    return (
        <Accordion open={open} toggle={toggle}>
            {loading ? (
                <p></p>
            ) : (
                <Formik initialValues={initialValues} validationSchema={validationSchema}>
                    {({ values, touched, errors, handleChange, handleSubmit, handleBlur }) => (
                        <Form onSubmit={handleSubmit} >
                            {filteredResults.map(([serviceName, offers], index) => (
                                serviceName && (
                                    <AccordionItem key={index}>
                                        <AccordionHeader targetId={`${index}`}>
                                            {serviceName}
                                        </AccordionHeader>
                                        <AccordionBody accordionId={`${index}`}>
                                            {offers.map((offer, offerIndex) => (
                                                <Row className="gy-4 mb-2 ms-2" key={offerIndex}>
                                                    <Col className="p-3" xxl={4} md={6}>
                                                        <div>
                                                            <div className="d-flex">
                                                                <h5 className="offer-name me-2 text-black">{offer.offerName}</h5>
                                                            </div>
                                                            <div className="d-flex">
                                                                {offer.Grants?.length > 0 && (
                                                                    <div className="badge-for-order me-2 p-1">
                                                                        <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Grants?.length} />
                                                                        <LabelText classLabelName="mb-0" displayText="Grant" />
                                                                    </div>
                                                                )}
                                                                {offer.Charges?.length > 0 && (
                                                                    <div className="badge-for-order me-2 p-1">
                                                                        <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Charges?.length} />
                                                                        <LabelText classLabelName="mb-0" displayText="Charge" />
                                                                    </div>
                                                                )}
                                                                {offer.Usage?.length > 0 && (
                                                                    <div className="badge-for-order me-2 p-1">
                                                                        <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Usage?.length} />
                                                                        <LabelText classLabelName="mb-0" displayText="Usage" />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="p-3" xxl={4} md={6}>
                                                        <h5 className="offer-name offer-code-color">Offer Code : <span className="text-black">{offer.offerCode}</span></h5>
                                                    </Col>
                                                    <Col xxl={3} md={6}>
                                                        <div className="d-flex flex-column justify-content-end align-items-start">
                                                            <div className="d-flex">
                                                                <h5 className="fs-6 pe-2 mt-3 fw-semibold offer-code-color">
                                                                    Term Validity:
                                                                </h5>
                                                                <h5 className="fs-6 mt-3 fw-lighter text-black">{offer.termValidity}</h5>
                                                            </div>
                                                            <div className="d-flex justify-content-center align-items-center mt-3">
                                                                <h5 className="fs-6 pe-2 fw-semibold offer-code-color" >
                                                                    Offer Validity:
                                                                </h5>
                                                                {offer.validityTo !== "Jan 01,0001" ? (
                                                                    <h5 className="fs-6 text-black fw-lighter">
                                                                        {offer.validityFrom} - {offer.validityTo}
                                                                    </h5>
                                                                ) : (
                                                                    <h5 className="fs-6 text-black fw-lighter">Never Expiring</h5>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={1} md={6}>
                                                        <div>
                                                            <Button color="secondary" className='mt-3' onClick={() => handleSelect(offer, offerIndex, index)}>
                                                                Select
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                    {((roleName === "telcoAdmin" || roleName === 'telcoOperationUser' || roleName === 'telcoBusinessUser') && selectedItemIndex === offerIndex && selectedServiceIndex === index) && selectedOffers.map((offer, index) => (
                                                        <>
                                                            <Card className=" border border-numeric-code" key={index}>
                                                                <CardHeader>
                                                                    <h4 className="card-title mb-0 flex-grow-1">Subscription</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col xxl={3} md={3}>
                                                                            <FormGroup>
                                                                                <LabelText htmlFor={'subscriptionId'} className={'text-danger'} displayText={'Subscription ID'} important={'*'} />
                                                                                <InputField
                                                                                    name={"subscriptionId"}
                                                                                    placeholder={"Enter the Subscription ID "}
                                                                                    type={"text"}
                                                                                    className={"form-control"}
                                                                                    id={"code"}
                                                                                    value={addOn ? subscriptionId : values.subscriptionId}
                                                                                    handleChange={handleChange}
                                                                                    handleBlur={handleBlur}
                                                                                    disabled={addOn}
                                                                                    invalid={touched.subscriptionId && errors.subscriptionId ? true : false}
                                                                                />
                                                                                {touched.subscriptionId && errors.subscriptionId ? (
                                                                                    <FormFeedback>{errors.subscriptionId}</FormFeedback>
                                                                                ) : null}
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col xxl={3} md={3}>
                                                                            <LabelText htmlFor={"startsOn"} displayText={"Starts On"} />
                                                                            <CommonDateTimePicker
                                                                                className={`form-control`}
                                                                                value={startsOn}
                                                                                name={`startsOn`}
                                                                                handleChange={(values) => { setStartsOn(values) }}
                                                                                format="DD/MM/YYYY"
                                                                                minDate={moment()}
                                                                                views={['year', 'month', 'day']}
                                                                            />
                                                                        </Col>
                                                                        <Col xxl={3} md={3}>
                                                                            <LabelText htmlFor={"endsOn"} displayText={"Ends On"} />
                                                                            <CommonDateTimePicker
                                                                                className={`form-control`}
                                                                                value={endsOn}
                                                                                name={`endsOn`}
                                                                                handleChange={(values) => { setEndsOn(values) }}
                                                                                format="DD/MM/YYYY"
                                                                                minDate={startsOn}
                                                                                views={['year', 'month', 'day']}
                                                                            />
                                                                        </Col>
                                                                        <Col xxl={3} md={3} className="d-flex flex-column align-items-center justify-content-start">
                                                                            <LabelText htmlFor={"Quantity"} displayText={"Quantity"} className="mb-2" /> {/* Label is now above */}
                                                                            <div className="quantity-control d-flex align-items-center">
                                                                                <button
                                                                                    className="btn-circle"
                                                                                    type="button"
                                                                                    onClick={() => setQuantity(prev => Math.max(1, prev - 1))}>
                                                                                    <span>-</span>
                                                                                </button>
                                                                                <input
                                                                                    type="number"
                                                                                    className="quantity-input mx-2"
                                                                                    id="quantity"
                                                                                    value={quantity}
                                                                                    onChange={(e) => {
                                                                                        const value = e.target.value;
                                                                                        if (value === '') {
                                                                                            setQuantity('');
                                                                                        } else {
                                                                                            setQuantity(Math.max(1, Number(value)));
                                                                                        }
                                                                                    }}
                                                                                    min={1}
                                                                                />

                                                                                <button
                                                                                    className="btn-circle"
                                                                                    type="button"
                                                                                    onClick={() => setQuantity(prev => prev + 1)}>
                                                                                    <span>+</span>
                                                                                </button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                            {attributes && <Card>
                                                                <CardHeader className="d-flex justify-content-between align-items-center ">
                                                                    <h4 className="card-title mb-0 flex-grow-1">Attributes</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Table className=' table-border-none'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Name</th>
                                                                                <th>Value</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {offer?.provisioningAttributes.map((attribute, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{attribute.name}</td>
                                                                                    <td>{attribute.value}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </CardBody>
                                                            </Card>}
                                                        </>
                                                    ))}
                                                    <hr />
                                                </Row>
                                            ))}
                                        </AccordionBody>
                                    </AccordionItem>
                                )
                            ))}
                            {(roleName === "telcoAdmin" || roleName === 'telcoOperationUser' || roleName === 'telcoBusinessUser') && selectedOffers.length > 0 && (
                                <div className="d-flex justify-content-end mt-3">
                                    <Button color="primary" type="submit" onClick={() => handleConfirm(values)}>
                                        Confirm
                                    </Button>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            )}
        </Accordion>
    );
};
