import { getCellStyle } from "../../Utils/DecideColor";
import { Box } from "@mui/material";

export const operatorColoumns = () => {
    const columns = [
      {
        accessorKey: 'operatorId',
        header: 'Operator ID',
        size: 150,
      },
      {
        accessorKey: 'tradingName',
        header: 'Business Name',
        size: 150,
      },
      {
        accessorKey: 'domain',
        header: 'Domain',
        size: 150,
      },
      {
        accessorKey: 'startDateTime',
        header: 'Created On',
        size: 150,
      },
      {
        accessorKey: 'endDateTime',
        header: 'End Date',
        size: 150,
      },
        {
          accessorKey: 'status',
          header: 'Status',
          Cell: ({ cell }) => {
            const value = cell.getValue();
            let displayValue;
            switch (value) {
              case 'Active':
                displayValue = 'Active';
                break;
              case 'Inactive':
                displayValue = 'Inactive';
                break;
              case 'Closed':
                displayValue = 'Closed';
                break;
              default:
                displayValue = 'Unknown';
                break;
            }
            return (
              <Box
                sx={(theme) => (value ? {
                  ...getCellStyle(value, "Operator"),
                } : {})}
              >
                {displayValue}
              </Box>
            );
          }
        }        
    ];
  
    return columns;
  };