import React, { useState, useEffect } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  FormFeedback,
  Table,
  CardHeader,
} from "reactstrap";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Loader } from "../Utils/Loader";
import { LabelText } from "../Utils/Input";
import {
  isEmptyOrInvalidField,
  handleChangeForAttributeValue,
  deviceJsonObj,
} from "./constants/constantFunctions";
import { addDevice } from "../../slices/Device/Adddevice/thunk";
import { getIdDevice } from "../../slices/Device/FetchDevice/thunk";
import { useLocation } from "react-router-dom";
import { updateDevice } from "../../slices/Device/EditDevice/thunk";
import { DeviceType, StatusType, validationSchema } from "./constants/constant";
import Attribute from "./Attribute";
import { DeviceForm } from "./DeviceForm";
import CommonButton from ".././Utils/CommonButton"
import { PAGE_TITLES } from "../Common/constants";

export default function createDevice() {
  const location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [enableFormReinitialize, setEnableFormReinitialize] = useState(false);
  const [deviceID, setDeviceID] = useState([]);
  const isEdit = location.pathname.includes("update-device");
  const isCreate = location.pathname.includes("create-device");
  const [title, setTitle] = useState("");
  let attributesLength = 0;

  const deviceIdPath = location.pathname.slice(
    location.pathname.lastIndexOf("/"),
    location.pathname.length
  );

  const deviceId = deviceIdPath.slice(1, deviceIdPath.length);

  const initialValues = {
    deviceId: "",
    deviceType: "",
    status: "",
    attributeList: [],
  };

  let initialFormValues = initialValues;

  useEffect(() => {
    if (isEdit) {
      setTitle(PAGE_TITLES.EDIT_DEVICE);
    } else {
      setTitle(PAGE_TITLES.ADD_DEVICE);
    }
  }, [isEdit]);

  useEffect(() => {
    if (isCreate) {
      setEnableFormReinitialize(true);
    }
  }, [isCreate]);

  useEffect(() => {
    if (isEdit) {
      dispatch(
        getIdDevice(deviceId, isEdit, initialValues, setLoading, setDeviceID)
      );
    }
  }, [dispatch, isEdit]);

  const fetchDeviceData = useSelector((state) => state.fetchDevice.fetchDevice)

  if (isEdit) {
    if ("attributeList" in fetchDeviceData) {
      attributesLength = fetchDeviceData?.attributeList?.length;
    }
  }
  const registerOrEditDevice = async (jsonObj) => {
    if (isEdit) {
      dispatch(updateDevice(jsonObj, setLoading, navigate, deviceId));
    } else {
      dispatch(addDevice(jsonObj, setLoading, navigate));
    }
  };
  const onSubmit = (values) => {
    let jsonObj;
    if (isEdit) {
      const newAttributes = values?.attributeList?.filter(
        (_, index) => index >= attributesLength
      );
      jsonObj = deviceJsonObj(values, isEdit, newAttributes);
    } else {
      jsonObj = deviceJsonObj(values, isEdit);
    }
    registerOrEditDevice(jsonObj);
  };

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize={enableFormReinitialize}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (
          values.deviceId !== "" &&
          values.deviceType !== "" &&
          values.attributeList !== ""
        ) {
          onSubmit(values);
        }
      }}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
        setTouched,
      }) => (
        <Form className="needs-validation" onSubmit={handleSubmit}>
          <div>
            <ToastContainer position="top-center" />
            {loading && <Loader />}
            <div className="page-content">
              <Container fluid>
                <BreadCrumb title={title} pageTitle={PAGE_TITLES.DEVICE_LIST} />
                <Row>
                  <div className=" mb-2">
                  </div>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <div className="live-preview">
                          <Row className="gy-4">
                            <DeviceForm
                              values={values}
                              isEdit={isEdit}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              initialValues={initialValues}
                              DeviceType={DeviceType}
                              StatusType={StatusType}
                              handleChange={handleChange}
                            />
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                    {isEdit ? (
                      <Card>
                        <CardBody className="card-body">
                          <div className="live-preview">
                            <Row className="gy-4">
                              <Col xxl={3} md={6}>
                                <LabelText
                                  htmlFor="Device Type"
                                  className="text-danger"
                                  displayText="Customer Name"
                                />
                                <p> {fetchDeviceData.customerName ? fetchDeviceData.customerName : '-'}</p>
                              </Col>
                              <Col xxl={3} md={6}>
                                <LabelText
                                  htmlFor="Device Type"
                                  className="text-danger"
                                  displayText="Customer ID"
                                />
                                <p> {fetchDeviceData.customerId ? fetchDeviceData.customerId : '-'}</p>
                              </Col>
                              <Col xxl={3} md={6}>
                                <LabelText
                                  htmlFor="Device Type"
                                  className="text-danger"
                                  displayText="Subscription ID"
                                />
                                <p> {fetchDeviceData.subscriptionId ? fetchDeviceData.subscriptionId : '-'}</p>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    ) : null}
                    <Attribute
                      values={values}
                      touched={touched}
                      errors={errors}
                      initialValues={initialValues}
                      setTouched={setTouched}
                      handleChange={handleChange}
                      handleChangeForAttributeValue={
                        handleChangeForAttributeValue
                      }
                      isEmptyOrInvalidField={isEmptyOrInvalidField}
                      deviceID={deviceID}
                      isEdit={isEdit}
                    />
                    <Row lg={6} className=" justify-content-end mb-4">
                      <div className="live-preview">
                        <div className="d-flex justify-content-end gap-2 ">
                          <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/devices')} buttonText={"Cancel"} />
                          <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} />
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
