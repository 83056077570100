import * as Yup from "yup"

export const validationSchemaForCommissionRules = Yup.object({
    policyName : Yup.string().required('Kindly enter policy name'),
    priority: Yup.string().required('Kindly enter Priority'),
    commissionRules: Yup.array().of(
        Yup.object().shape({
            charge:Yup.object().required('Kindly select a charge'),
            type: Yup.object().required("Kindly select a commission type"),
            value:Yup.number().required('Kindly enter a commission value')
        })
    )
})

export const validationSchema = Yup.object({
    businessName: Yup.string().required('Business Name is required'),
    type: Yup.object().required('Type is required'),
    firstName: Yup.string().required("Kindly enter First Name").matches(/^[a-zA-Z ]*$/, 'Firstname can contain only characters'),
    lastName: Yup.string().required('Kindly enter Last Name').matches(/^[a-zA-Z ]*$/, 'Lastname can contain only characters'),
    email: Yup.string().email('Kindly enter valid email').required('Kindly enter Email'),
    email1: Yup.string().email('Kindly enter valid email').required('Kindly enter Email'),
    mobile: Yup.number().typeError('It doesn\'t look like a mobile number').positive('A phone number can\'t start with a minus').integer('It can\'t include a decimal point').test('len', 'Must be exactly 10 digits', val => val?.toString().length === 10).required('Kindly enter Phone Number'),
    mobile1: Yup.number().typeError('It doesn\'t look like a mobile number').positive('A phone number can\'t start with a minus').integer('It can\'t include a decimal point').test('len', 'Must be exactly 10 digits', val => val?.toString().length === 10).required('Kindly enter Phone Number'),
    Address: Yup.string().max(30, 'Must be 30 characters or less').required('Kindly enter Address'),
    country: Yup.object().required("Kindly select Country"),
    city: Yup.object().required('Kindly select City'),
    state: Yup.object().required('Kindly select State'),
    // websiteLink: Yup.string().url('Invalid URL').required('Website Link is required'),
    zipCode: Yup.number().typeError('It doesn\'t look like a zip code').positive('A zip code can\'t start with a minus').integer('It can\'t include a decimal point').test('len', 'Must be exactly 6 digits', val => val?.toString().length === 6).required('Kindly enter Zip code'),
    // userName: Yup.string().required("Kindly enter User Name").matches(/^[a-zA-Z ]*$/, 'User Name can contain only characters'),
    // userRole: Yup.object().required('User Role is required'),
    // attributeList: Yup.array().of(
    //     Yup.object().shape({
    //         name: Yup.mixed().required('Kindly select the attribute name'),
    //         value: Yup.mixed().required("Kindly enter attribute value"),
    //     })
    // ),
});