import { PoliciesColoumn } from "../../../Policy/constants/PolicyColoumn";
import { columnDataFormat, endDates, handleSelectedService, startDates } from "./displayInfinite";
export function isEmptyorInvalidFieldForGrantsAndCharges(index, type) {
    const previousField = type[index];
    return !previousField || !previousField.resourceType
}

export function isEmptyOrInvalidField(index, values) {
    const previousField = values.usageRatePlan[index];
    return !previousField || !previousField.usageId || !previousField.eventType || !previousField.resourceName || !previousField.unitOfMeasurement || !previousField.priority || (previousField.pricingModel.value!=='volume' && !previousField.perUnitRate) || !previousField.flatRate || !previousField.pricingModel;
}

export function isEmptyOrInvalidFieldRecurring(index, values) {
    const previousField = values.recurringUsageRatePlan[index];
    return !previousField || !previousField.usageId || !previousField.eventType || !previousField.recurringFrequency || !previousField.cycleAlignment || !previousField.resourceName || !previousField.unitOfMeasurement || !previousField.priority || (previousField.pricingModel.value!=='volume' && !previousField.perUnitRate) || !previousField.flatRate || !previousField.pricingModel;
}

export const optionsForSelect = (value) => {
    let options = value.map(item => {
        return {
            label: item,
            value: item
        }
    })
    return options
}

export const handleStartDateChange = (selectedDate, setStartDate) => {
    let startDateList = ''
    startDateList = selectedDate[0]
    setStartDate(startDateList)
}

export const uomOptionsForUsage = (uomData, uomArray) => {
    let uomOptions = uomData
    uomOptions.forEach((value, index) => {
        uomArray.push(value.name)
    })
    return uomArray
}

export const resourceArrayData = (resourceData, resourceNameArray) => {
    resourceData.forEach((value, index) => {
        resourceNameArray.push(value.name)
    })
    return resourceNameArray
}
export const grantArrayData = (resourceData, grantResourceTypeArray) => {
    resourceData.forEach((value, index) => {
        if (value.isCurrency === false) {
            grantResourceTypeArray.push(value.name)
        }
    })
    return grantResourceTypeArray
}
export const chargeArrayData = (resourceData, chargeResourceTypeArray) => {
    resourceData.forEach((value, index) => {
        if (value.isCurrency === true) {
            chargeResourceTypeArray.push(value.name)
        }
    })
    return chargeResourceTypeArray
}

export const serviceDataOptions = (serviceData) => {
    let serviceOptions = serviceData.map(item => {
        return {
            label: item?.name,
            value: item?.serviceCode
        }
    })
    return serviceOptions
}
export const policyDataOptions=(Policies)=>{
    let policiesOptions=Policies.map(item=>{
        return {
            label:item?.policyLabel,
            value:item?.policyLabel
        }
    })
    return policiesOptions
}
export const aggregateResourceDataOptions=(aggregateResource)=>{
    let aggregateResourceOptions=aggregateResource.map(item=>{
        return {
            label:item?.name,
            value:item?.name
        }
    })
    return aggregateResourceOptions
}
export const termValidity = (unit, quantity) => {
    switch (+unit) {
        case 0:
            return <p> </p>
        case 1:
            return <p>{quantity} seconds</p>
        case 2:
            return <p>{quantity} days</p>
        case 3:
            return <p>{quantity} months</p>
        case 4:
            return <p>{quantity} quarters</p>
        case 5:
            return <p>{quantity} half years</p>
        case 6:
            return <p>{quantity} years</p>
        default:
            return <></>
    }
}

export const removeRowsFromGrantsAndCharges = (setInfinite, index, setCeiling, setFloor) => {
    setInfinite((prevInfinite) => {
        const newInfinite = [...prevInfinite];
        newInfinite.splice(index, 1);
        return newInfinite;
    });

    setCeiling((prevCeiling) => {
        const newCeiling = [...prevCeiling];
        newCeiling.splice(index, 1);
        return newCeiling;
    });

    setFloor((prevFloor) => {
        const newFloor = [...prevFloor];
        newFloor.splice(index, 1);
        return newFloor;
    });
}

export const handleServiceForOffer = (values, setFieldValue) => (val) => {
    values.serviceName = val
    setFieldValue(`serviceName`, val);
}
export const handlePolicyForOffer = (values, setFieldValue) => (val) => {
    values.policyName = val
    setFieldValue(`policyName `, val);
}
export const handleAggregateForOffer = (values, setFieldValue) => (val) => {
    values.aggregateVolume = val
    setFieldValue(`aggregateVolume `, val);
}

export const handleServiceChange = handleServiceForOffer(handleSelectedService);

export const formatBaseAndAddOnOfferColumns = (offers) => {
    const globalScope = [];
    const grant = [];
    const charge = [];
    const usage = [];
    columnDataFormat(offers, globalScope, grant, charge, usage);
    return { globalScope, grant, charge, usage };
};

export const extractBaseAndAddOnOfferDates = (offers) => {
    const endDatesArray = endDates(offers, []);
    const startDatesArray = startDates(offers, []);
    return { endDatesArray, startDatesArray };
};