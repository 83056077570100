import { UserManager, WebStorageStateStore } from 'oidc-client';

export const authConfig = {
    authority: window.env.REACT_APP_AUTHORITY, // Replace with your issuer URL
    client_id: window.env.REACT_APP_CLIENT_ID,
    client_secret: window.env.REACT_APP_CLIENT_SECRET, // Replace with your client id
    redirect_uri: window.env.REACT_APP_REDIRECT_UI,
    response_type: window.env.REACT_APP_RESPONSE_TYPE,
    scope: window.env.REACT_APP_SCOPE,
    post_logout_redirect_uri: window.env.REACT_APP_POST_LOGOUT_REDIRECT_UI,
    userinfo_endpoint: window.env.REACT_APP_USERINFO_ENDPOINT, // Replace with your user-info endpoint
    response_mode: window.env.REACT_APP_RESPONSE_MODE,
    code_challenge_method: window.env.REACT_APP_CODE_CHALLENGE_METHOD,
    automaticSilentRenew: window.env.REACT_APP_AUTOMATIC_SILENT_RENEW
}
export const UserManagerInstance = new UserManager({
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    ...authConfig,
    response_mode: 'query'
})