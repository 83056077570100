import { Card, CardBody, CardHeader } from "reactstrap"

export const CustomerDetailsInViewCancel = ({isView,isCancel,CustomerName,orderDataById,CustomerId}) => {
    return (
        <Card className="flex-fill">
            <CardHeader className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                <h4 className="card-title mb-2 mb-md-0">CUSTOMER DETAILS</h4>
            </CardHeader>
            <CardBody className="d-flex flex-column flex-md-row  align-items-md-center">
                <div className='d-flex flex-column mb-0'>
                    <h4 className='card-title mb-3'>
                        {!(isView || isCancel) ? CustomerName :
                            (orderDataById && orderDataById.relatedParty && orderDataById.relatedParty[0] && orderDataById.relatedParty[0].partyOrPartyRole && orderDataById.relatedParty[0].partyOrPartyRole.name
                                ? orderDataById.relatedParty[0].partyOrPartyRole.name
                                : "NA")}
                    </h4>
                    <h4 className='card-title'>
                        {(isView || isCancel) && orderDataById && orderDataById.relatedParty && orderDataById.relatedParty[0] && orderDataById.relatedParty[0].partyOrPartyRole
                            ? orderDataById.relatedParty[0].partyOrPartyRole.id
                            : CustomerId}
                    </h4>
                </div>
            </CardBody>
        </Card>
    )
}