import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    policyLabel: Yup.mixed().required('Kindly Enter the Policy Label'),
    resource: Yup.mixed().required('Kindly Enter the Resource'),
    attributeList: Yup.array().of(
        Yup.object().shape({
            rangeStarts: Yup.string().required('Kindly Enter Range Starts'),
            rangeEnds: Yup.string().required('Kindly Enter Range Ends'),
            statusLabel: Yup.string().required('Kindly Enter Status Label'),
        })
    ),
});
