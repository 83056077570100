export function handleGroupClick(groupName, teamData, setActivity) {
    const memberData = [];
    for (const item of teamData) {
      if (item?.group?.name === groupName) {
        const members = item.group.members;
        for (const memberKey in members) {
          const memberValue = members[memberKey];
          const parts = memberKey?.split(':');
          const extractedValue = parts[1] ? parts[1].trim() : '';
          memberData.push({ x: extractedValue, y: memberValue });
          memberData.sort((a, b) => a.value - b.value);
        }
      }
    }
    setActivity(memberData);
  }
  
  export function getFilteredActivitys(teamData, searchQuery) {
    if (!teamData || teamData.error) {
        return [];
    }
    return teamData?.filter((data) =>
      data?.group?.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
  
  export function handleSelectSingle(setSelectedCustomer, setSelectedSingle, selectedSingle) {
    setSelectedCustomer(null);
    setSelectedSingle(selectedSingle);
  }
  
  export function handleDateTimeChangeStart(setSelectedDateTimeStart, dateStr) {
    setSelectedDateTimeStart(dateStr);
  }
  
  export function handleDateTimeChangeEnd(setSelectedDateTimeEnd, dateStr) {
    setSelectedDateTimeEnd(dateStr);
  }
  
  export function handleCustomerClick(setSelectedCustomerUnits, setSelectedCustomer, customer) {
    setSelectedCustomerUnits(customer?.unitsConsumed);
    setSelectedCustomer(customer?.customerId);
  }
  
  export function handleSelectChange(setSelectedStation, selectedStation) {
    setSelectedStation(selectedStation);
  }
  
  export function handleGroupClickWithHighlight(handleGroupClick, setActiveGroup, groupName) {
    handleGroupClick(groupName);
    setActiveGroup(groupName);
  }
  
  export function getFormattedDateRange(startDate, endDate) {
    const originalStartDate = new Date(startDate);
    const startDateandTime = originalStartDate.toISOString();
    const originalEndDate = new Date(endDate);
    const endDateandTime = originalEndDate.toISOString();
    return { startDateandTime, endDateandTime };
  }
  