import { toast } from "react-toastify";
import { fetchResetPasswordCreateSucess, fetchResetPasswordCreateFailure, fetchSetPasswordCreateSucess, fetchSetPasswordCreateFailure } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { SET_CREATE_PASSWORD_API } from "../constants/constants";

export const CreateSetPassword = (jsonObj, setLoading, navigate,headerValue,token) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${SET_CREATE_PASSWORD_API}?${headerValue}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchSetPasswordCreateSucess(response))
            toast.success(`${response}`, {
                onClose: () => navigate('/')
            });
        }
        if (response.error) {
            dispatch(fetchSetPasswordCreateFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}