import { fetchdeleteUomSuccess, fetchdeleteUomFailure } from "./reducer"
import { toast } from "react-toastify";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { ADD_UOM_API } from "../../constants/constants";
import { fetchAllUom } from "../thunk";

export const deleteUom = (id, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${ADD_UOM_API}/${id}`, 'DELETE').then((response) => {
        if (!response.error) {
            dispatch(fetchdeleteUomSuccess(response))
            setLoading(false)
            dispatch(fetchAllUom(setLoading))
            toast.success(`UOM Deleted Successfully`)
        }
        if (response.error) {
            dispatch(fetchdeleteUomFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}