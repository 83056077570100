import { FormGroup, Row, Col, FormFeedback } from "reactstrap"
import { LabelText } from "../Utils/Input";
import InputField from "../Utils/InputField";
import SelectField from "../Utils/SelectField";
import 'react-phone-number-input/style.css'
import { handleChangeForCity, handleChangeForCountry, handleChangeForImsi, handleChangeForName, handleChangeForState } from "./constants/HandleChangeFunctions";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useState } from "react";
import { dialCodeFocusChange, validatePhoneNumber } from "./constants/DocumentTableFunctions";

export default function CustomerDetails({
    values,
    touched,
    errors,
    setFieldTouched,
    setFieldValue,
    initialValues,
    handleChange,
    handleBlur,
    options,
    optionState, dialCode, setDialCode,
    optionCity, cityData, onfocus, setOnFocus,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity, bdomOptions,
    setCities, customerPermission, isEdit
}) {
    return (
        <Row className="gy-4">
            <Col xxl={3} md={6}>
                <div>
                    <FormGroup >
                        <LabelText htmlFor={"firstName"} className={"text-danger"} displayText={"First name"} important={"*"} />
                        <InputField name={"firstName"} placeholder={"Enter your First Name"} type={"text"} className={"form-control"} id={"firstName"}
                            handleChange={handleChangeForName(setFieldValue, 'firstName')}
                            handleBlur={handleBlur} value={values.firstName}
                            invalid={touched.firstName && errors.firstName
                                ? true
                                : false} />
                        {touched.firstName &&
                            errors.firstName ? (
                            <FormFeedback type="invalid">
                                {errors.firstName}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                </div>
            </Col>
            <Col xxl={3} md={6}>
                <div>
                    <FormGroup >
                        <LabelText htmlFor={"lastName"} className={"text-danger"} displayText={"Last name"} important={"*"} />
                        <InputField name={"lastName"} placeholder={"Enter your Last Name"} type={"text"} className={"form-control"} id={"lastName"}
                            handleChange={handleChangeForName(setFieldValue, 'lastName')}
                            handleBlur={handleBlur} value={values.lastName}
                            invalid={touched.lastName && errors.lastName
                                ? true
                                : false} />
                        {touched.lastName &&
                            errors.lastName ? (
                            <FormFeedback type="invalid">
                                {errors.lastName}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                </div>
            </Col>
            <Col xxl={3} md={6}>
                <div>
                    <FormGroup >
                        <LabelText htmlFor={"userName"} className={"text-danger"} displayText={"User Name"} important={"*"} />
                        <InputField name={"userName"} placeholder={"Enter your User Name"} type={"text"} className={"form-control"} id={"userName"}
                            handleChange={handleChange}
                            handleBlur={handleBlur} value={values.userName}
                            invalid={touched.userName && errors.userName
                                ? true
                                : false} />
                        {touched.userName &&
                            errors.userName ? (
                            <FormFeedback type="invalid">
                                {errors.userName}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                </div>
            </Col>
            <Col xxl={3} md={6}>
                <div>
                    <FormGroup >
                        <LabelText htmlFor={"email"} className={"text-danger"} displayText={"Email Address"} important={"*"} />
                        <InputField name={"email"} placeholder={"Enter your Email"} type={"text"} className={"form-control"} id={"email"} handleChange={handleChange} handleBlur={handleBlur} value={values.email}
                            invalid={touched.email && errors.email
                                ? true
                                : false} />
                        {touched.email &&
                            errors.email ? (
                            <FormFeedback type="invalid">
                                {errors.email}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                </div>
            </Col>
            <Col xxl={3} md={6}>
                <div>
                    <FormGroup >
                        <LabelText htmlFor={"mobile"} className={"text-danger"} displayText={"Phone Number"} important={"*"} />
                        <PhoneInput international countryCallingCodeEditable={false} className={` ${onfocus && (dialCode === "" && !isValidPhoneNumber(dialCode)) ? 'error-input ' : 'form-control'} ps-3`} placeholder={"Enter your Phone Number"}
                            onChange={setDialCode} onFocus={() => dialCodeFocusChange(setOnFocus)} value={dialCode} />
                        {onfocus && (
                            <div className="text-danger validation-font-size">
                                {validatePhoneNumber(dialCode)}
                            </div>
                        )}
                    </FormGroup>
                </div>
            </Col>
            {customerPermission?.autoload && <Col xxl={3} md={6}>
                <div>
                    <FormGroup >
                        <LabelText htmlFor={"accountNumber"} className={"text-danger"} displayText={"Account Number"} important={"*"} />
                        <InputField name={"accountNumber"} placeholder={"Enter your Account Number"} type={"text"} className={"form-control"} id={"accountNumber"}
                            handleChange={handleChange} disabled={isEdit}
                            maxLength="10"
                            handleBlur={handleBlur} value={values.accountNumber}
                            invalid={touched.accountNumber && errors.accountNumber
                                ? true
                                : false} />
                        {touched.accountNumber &&
                            errors.accountNumber ? (
                            <FormFeedback type="invalid">
                                {errors.accountNumber}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                </div>
            </Col>}
            <Col xxl={3} md={6}>
                <FormGroup>
                    <LabelText htmlFor={"bdom"} className={"text-danger"} displayText={"BDOM"} important={"*"} />
                    <SelectField
                        className={(errors.bdom && touched.bdom) || false ? 'error-input' : ''}
                        value={values.bdom}
                        placeholder={"Select your billing date of month"}
                        handleChange={(value) => {
                            setFieldValue('bdom', value)
                        }}
                        options={[
                            ...bdomOptions,
                        ]}
                    />
                    {touched.bdom && errors.bdom ? (
                        <p className="text-danger validation-font-size">
                            {errors.bdom}
                        </p>
                    ) : null}
                </FormGroup>
            </Col>
            <Col xxl={3} md={6}>
                <div>
                    <FormGroup >
                        <LabelText htmlFor={"addressLine1"} className={"text-danger"} displayText={"Address"} important={"*"} />
                        <InputField name={"addressLine1"} placeholder={"Enter your Address"} type={"text"} className={"form-control"} id={"addressLine1"} handleChange={handleChange} handleBlur={handleBlur} value={values.addressLine1}
                            invalid={touched.addressLine1 && errors.addressLine1
                                ? true
                                : false} />
                        {touched.addressLine1 &&
                            errors.addressLine1 ? (
                            <FormFeedback type="invalid">
                                {errors.addressLine1}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                </div>
            </Col>
            <Col xxl={3} md={6}>
                <div>
                    <LabelText htmlFor={"addressLine2"} className={"text-danger"} displayText={"Address 2"} />
                    <InputField name={"addressLine2"} placeholder={"Enter your Address 2"} type={"text"} className={"form-control"} id={"addressLine2"} handleChange={handleChange} value={values.addressLine2} />
                </div>
            </Col>
            <Col xxl={3} md={6}>
                <div>
                    <LabelText htmlFor={"choices-single-default"} className={"text-danger"} displayText={"Country"} important={"*"} />
                    <SelectField className={(errors.country && touched.country) || false ? 'error-input' : ''}
                        value={initialValues.country ? options?.filter((country) => {
                            country.label === initialValues.country
                        })
                            : values.country}
                        placeholder={"Select your Country"}
                        handleChange={handleChangeForCountry(setSelectedCountry, setFieldValue, setFieldTouched, setSelectedState, setSelectedCity, setCities)}
                        isClearable={true}
                        options={[
                            ...options,
                        ]} />
                    {touched.country && errors.country ? (
                        <p className="text-danger validation-font-size">
                            {errors.country}
                        </p>
                    ) : null}
                </div>
            </Col>
            <Col xxl={3} md={6}>
                <div>
                    <FormGroup>
                        <LabelText htmlFor={"choices-single-default"} className={"text-danger"} displayText={"State"} important={"*"} />
                        <SelectField
                            className={(errors.state && touched.state) ? 'error-input' : ''}
                            value={initialValues.state ? optionState?.filter((state) => {
                                state.label === initialValues.state
                            }) : values.state}
                            placeholder="Select your State"
                            handleChange={handleChangeForState(setSelectedState, setFieldValue)}
                            options={[
                                ...optionState,
                            ]}
                            isClearable={true}
                            isDisabled={!values.country}
                        />
                        {touched.state && errors.state ? (
                            <p className="text-danger validation-font-size">
                                {errors.state}
                            </p>
                        ) : null}
                    </FormGroup>
                </div>
            </Col>
            <Col xxl={3} md={6}>
                <div >
                    <LabelText htmlFor={"choices-single-default"} className={"text-danger"} displayText={"City"} important={"*"} />
                    <SelectField
                        className={(errors.city && touched.city) || false ? 'error-input' : ''}
                        value={initialValues.city ? optionCity?.filter((city) => {
                            city.label === initialValues.city
                        })
                            : values.city}
                        placeholder="Select your City"
                        handleChange={handleChangeForCity(setSelectedCity, setFieldValue)}
                        handleBlur={handleBlur}
                        options={[
                            ...optionCity,
                        ]}
                        isDisabled={!values.state}
                        isClearable={true}
                    />
                    {touched.city && errors.city ? (
                        <p className="text-danger validation-font-size">
                            {errors.city}
                        </p>
                    ) : null}
                </div>
            </Col>
            <Col xxl={3} md={6}>
                <div>
                    <FormGroup >
                        <LabelText htmlFor={"zipCode"} className={"text-danger"} displayText={"Zip Code"} important={"*"} />
                        <InputField name={"zipCode"} placeholder={"Enter your Zip Code"} type={"number"} className={"form-control"} id={"zipCode"} handleChange={handleChange} handleBlur={handleBlur} value={values.zipCode}
                            invalid={touched.zipCode && errors.zipCode
                                ? true
                                : false} 
                        />
                        {touched.zipCode &&
                            errors.zipCode ? (
                            <FormFeedback type="invalid">
                                {errors.zipCode}
                            </FormFeedback>
                        ) : null}
                    </FormGroup></div>
            </Col>
        </Row>
    )
}