import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllInvoicesForCustomer } from '../../../slices/invoices/invoiceforcustomer/thunk';
import { invoiceChangeFunctionForCustomer } from '../../Customers/constants/HandleChangeFunctions';
export const useInvoiceData = (customerId, setLoading) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllInvoicesForCustomer(setLoading, customerId));
  }, [customerId, dispatch, setLoading]);
  const invoiceData = useSelector(state => state.InvoiceListForCustomer.invoiceListForCustomer);
  const invoiceArray = []; 
  const invoiceDataForCustomer = invoiceChangeFunctionForCustomer(invoiceData, invoiceArray);
  return { invoiceDataForCustomer };
};
