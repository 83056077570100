import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editPolicy: [],
    loading: false,
    error: null,
}

const editPolicySlice = createSlice({
    name: "editPolicy",
    initialState,
    reducers: {
        fetchEditPolicySuccess: (state, action) => {
            state.loading = false;
            state.editPolicy = action.payload;
        },
        fetchEditPolicyFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchEditPolicySuccess, fetchEditPolicyFailure } = editPolicySlice.actions;

export default editPolicySlice.reducer;
