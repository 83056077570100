import { Formik } from "formik"
import React from "react"
import { useState, useEffect } from "react"
import { Form } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { generateRandomString } from "../Utils/UtilityFunctions"
import SimulatePage from "./SimulatePage"
import { useDispatch, useSelector } from "react-redux"
import { fetchAllOffers } from "../../slices/offer/thunk"
import { rateUsageInSimulate } from "../../slices/customer/rateusage/thunk"
import { customerIdData, matchingOffersData, uomData, uomOptions, urpOffersData,handleStartDateChange } from "./constants/constantFunctionsView"
import { simulateJsonObj } from "./constants/CustomerJsonObj"
import { validationSchemaForSimulate } from "./constants/validationSchema"
import { initialSimulateValues } from "./constants/constantValues"
import { Loader } from "../Utils/Loader"
import { onSubmitForSimulateForm } from "./constants/SimulateFunctions"

export default function Simulate({row}) {

    const [startDate, setStartDate] = useState('')
    const [selectedUom, setSelectedUom] = useState('')
    const [loading, setLoading] = useState(false)
    const customerId = customerIdData()

    let navigate = useNavigate()
    const dispatch = useDispatch()

    const offerCodeArray = useSelector(state => state.Simulate.offerCode)

    useEffect(() => {
        dispatch(fetchAllOffers(setLoading))
    }, [dispatch])

    const offers = useSelector((state) => state.Offer.offer)

    let uom = uomData(offerCodeArray,offers)
    let options = uomOptions(uom)

    const fetchSummaryData = useSelector(state => state.Simulate.simulate)

    const matchingOffers = matchingOffersData(offers,fetchSummaryData)
    const urpOffers = urpOffersData(matchingOffers)
    const randomString = generateRandomString()

    return (
        <>
            <Formik initialValues={initialSimulateValues} validationSchema={validationSchemaForSimulate} onSubmit={
                onSubmitForSimulateForm(customerId,urpOffers,fetchSummaryData,randomString,dispatch,setLoading,navigate,row)
            } >
                {({ handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldTouched, resetForm, setFieldValue }) => (
                    <Form id="csms" className="needs-validation" onSubmit={handleSubmit}>
                        <div>
                                <SimulatePage
                                    row = {row}
                                    touched={touched}
                                    errors={errors}
                                    initialValues={initialSimulateValues}
                                    values={values}
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    options={options}
                                    handleStartDateChange={handleStartDateChange} setStartDate={setStartDate}
                                    startDate={startDate}
                                    setSelectedUom={setSelectedUom}
                                />
                        </div>
                    </Form>

                )}
            </Formik>
            {loading && (<Loader/>)}
        </>
    )
}