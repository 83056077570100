import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getService: [],
    loading: false,
    error: null,
}

const getServiceSlice = createSlice({
    name: "getService",
    initialState,
    reducers: {
        fetchGetServiceSuccess: (state, action) => {
            state.loading = false
            state.getService = action.payload;
        },
        fetchGetServiceFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchGetServiceSuccess,
    fetchGetServiceFailure,
} = getServiceSlice.actions;

export default getServiceSlice.reducer;