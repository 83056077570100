import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    orders : [],
    loading : false,
    error : null,
};

const ordersSlice = createSlice({
    name:"orders",
    initialState,
    reducers: {
        fetchOrdersSuccess: (state,action) => {
            state.loading = false;
            state.orders = action.payload
        },
        fetchOrdersFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchOrdersSuccess,
    fetchOrdersFailure
} = ordersSlice.actions

export default ordersSlice.reducer