import Select from "react-select"
import makeAnimated from 'react-select/animated';
import { useSelector } from "react-redux";

export default function SelectField({
  className,
  value,
  placeholder,
  handleChange,
  handleBlur,
  options,
  defaultValue,
  isMulti,
  isLoading,
  isDisabled,
  isClearable,
  labelName, 
  menuPortalTarget, 
  theme, 
  closeMenuOnSelect
}) {
  const layoutModeType = useSelector((state) => state.Layout.layoutModeType);

  const customStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      borderRadius: '0.5rem',
      backgroundColor: layoutModeType === "dark" ? "#212529" : "white", 
      color: layoutModeType === "dark" ? "white" : "black",
      borderColor: layoutModeType === "dark" ? (isFocused ? "#0078A3" : "#495057") : "#ced4da",
      '&:hover': {
        borderColor: layoutModeType === "dark" ? "#0078A3" : "#80bdff",
      }
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : provided.color,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#212529" : "#0078A3",
      color: "white",
    }),
  };

  const animatedComponents = makeAnimated();

  return (
    <Select
      closeMenuOnSelect={closeMenuOnSelect}
      menuPortalTarget={menuPortalTarget}
      isLoading={isLoading}
      isMulti={isMulti}
      aria-labelledby={labelName}
      className={className}
      components={animatedComponents}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      options={options}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      isClearable={isClearable}
      data-testid="valuetype"
      styles={customStyles}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: layoutModeType === "dark" ? "#495057" : "#015E7F",
          primary25: layoutModeType === "dark" ? "#343a40" : "#015E7F",
          primary50: layoutModeType === "dark" ? "#495057" : "#015E7F",
          primary75: layoutModeType === "dark" ? "#495057" : "#015E7F",
        },
      })}
    />
  )
}
