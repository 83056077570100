import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    invoiceStatistics : {},
    loading : false,
    error : null,
};

const invoiceStatisticsSlice = createSlice({
    name:"invoiceStatistics",
    initialState,
    reducers: {
        fetchInvoiceStatisticsSuccess: (state,action) => {
            state.loading = false;
            state.invoiceStatistics = action.payload
        },
        fetchInvoiceStatisticsFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchInvoiceStatisticsSuccess,
    fetchInvoiceStatisticsFailure
} = invoiceStatisticsSlice.actions

export default invoiceStatisticsSlice.reducer