import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Col, Container, Form, Label, Row } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../Utils/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Utils/Loader";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import { validationSchemaForUser } from "../UserManagement/Constant/valiadationshema";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { createUser } from "../../slices/UserManagement/CreateUser/thunk";
import { updateUser } from "../../slices/UserManagement/UpdateUser/thunk";
import { getUserByName } from "../../slices/UserManagement/GetUserByName/thunk";
import { LabelText } from "../Utils/Input";
import OperatorProviderDetail from "./operatorProviderDetail";
import { validationSchemaForOperatorProfile } from "./validationSchemaForOperatorProfile";
import { countryOptionss, OperatorOptionsFields, OperatorStatusOptions } from "../OperatorManagement/Constant/Options";
import { fetchOperatorbyId } from "../../slices/OperatorManagement/GetOperatorbyID/thunk";
import moment from "moment";
import { editOperator } from "../../slices/OperatorManagement/EditOperator/thunk";
import { validationSchema } from "../OperatorManagement/Constant/validationSchema";

export default function CreateOperatorProvider({isEdit}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const [editData, setEditData] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [fileSelected, setFileSelected] = useState(false);
    const changePassword = location?.state?.changePassword || false;
    const[changePasswordStatus,setChangePasswordStatus]=useState(false);
    const [loading, setLoading] = useState()
    const profile = location.pathname.includes('profile-operator')
    const operatorId = localStorage.getItem('operatorId')
    const profilee = []
    const [update, setUpdate] = useState(!!profilee);

    const initialValues = {
        businessName: "",
        Domain: "",
        state: "",
        city: "",
        zipCode: "",
        endDate: null,
        Address: "",
        Address2: "",
        country: "",
        firstname: "",
        lastname: "",
        username: "",
        phone: "",
        email: "",
        Status: "",
        // attributeList: []
    };
    const operatorOptions = [
        {
            name: "Domain",
            value: "Operator",
            options: OperatorOptionsFields
        },
        {
            name: "country",
            value: "Operator",
            options: countryOptionss
        },
        {
            name: "Status",
            value: "Operator",
            options: OperatorStatusOptions
        }
    ]
    useEffect(() => {
        dispatch(fetchOperatorbyId(setLoading, operatorId, initialValues, operatorOptions,OperatorStatusOptions,setEditData));
    }, [dispatch])
   
    const operatorData = useSelector((state) => state?.GetOperator?.GetOperatorId ?? null);
   
    useEffect(()=>{
        initialValues.businessName = operatorData?.tradingName || "",
        initialValues.Domain = operatorOptions?.find(optr => optr?.name === 'Domain').options?.find(option => option?.value === operatorData?.domain);
        initialValues.state =  {value: operatorData?.state, label: operatorData?.state},
        initialValues.city =  {value: operatorData?.city, label: operatorData?.city},
        initialValues.zipCode =  operatorData?.postcode,
        initialValues.endDate = moment(operatorData?.existsDuring?.endDateTime),
        initialValues.Address = operatorData?.street1,
        initialValues.Address2 = operatorData?.street2,
        initialValues.country= {value: operatorData?.country, label: operatorData?.country}
        initialValues.firstname= operatorData?.adminDetails?.profile?.firstName
        initialValues.lastname=operatorData?.adminDetails?.profile?.lastName
        initialValues.username=operatorData?.adminDetails?.userName
        initialValues.phone= operatorData?.adminDetails?.dialCode + operatorData?.adminDetails?.phone
        initialValues.email= operatorData?.adminDetails?.email
        initialValues.Status=  OperatorStatusOptions?.find(option => option?.value === operatorData?.status)
    },[operatorData])
    return (
        <React.Fragment>
            <div id='csms' >
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={async (values) => {
                       if (
                            values?.firstname !== "" && values?.lastname !== "" && values?.email !== "" && values?.Status !== " " && values?.username !== " " && values?.userrole !== " " && values?.phone !== ""
                        ) {
                            const phoneNumber = formatPhoneNumberIntl(values?.phone)
                            let dialCodeWithPlus = phoneNumber.match(/^\+\d+(\s|$)/)[0];
                            let restOfNumber = phoneNumber.slice(dialCodeWithPlus?.length);
                            let mobileNumberWithoutSpaces = restOfNumber.replace(/\s/g, '');
                            let dialCodeWithoutSpaces = dialCodeWithPlus.replace(/\s/g, '');
                            const formattedValues = {
                                tradingName: values.businessName,
                                existsDuring: {
                                    endDateTime: values.endDate,
                                    // startDateTime: "2023-01-01T00:00:00Z"
                                },
                                domain: values.Domain?.value,
                                state: values.state?.value,
                                city: values.city?.value,
                                country: values.country?.value,
                                postcode: (values.zipCode).toString(),
                                street1: values.Address,
                                street2: values.Address2,
                                // partyCharacteristic: values.attributeList,
                                adminOperator: 
                                    {
                                        userName: values.username,
                                        email: values.email,
                                        profile: {
                                            firstName: values.firstname,
                                            lastName: values.lastname
                                        },
                                        dialCode: dialCodeWithoutSpaces,
                                        phone: mobileNumberWithoutSpaces,
                                    }
                                ,
                                ...(isEdit ? { status: values.Status?.value } : {})
                            };
                            if (!isEdit) {
                                dispatch(createUser(formattedValues, setLoading, navigate));
                            } else {
                                dispatch(editOperator(formattedValues, operatorId, setLoading, navigate, profilee, update, setUpdate, fileSelected, logoFile, editData, setFileSelected,setEditData));
                            }
                        }
                    }}
                >
                    {({ handleChange, handleBlur, handleSubmit, errors, touched, values, setFieldValue, setFieldTouched }) => (
                            
                        <Form className='needs-validation' onSubmit={handleSubmit}>
                            <Container fluid> 
                                <ToastContainer position='top-center' />
                                <Row>
                                    {loading && (<Loader />)}
                                    <Col lg={12}>
                                        <div >
                                            <OperatorProviderDetail values={values} errors={errors} touched={touched} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} handleBlur={handleBlur} handleChange={handleChange} isEdit={isEdit} operatorData={operatorData} navigate={navigate} dispatch={dispatch} setLoading={setLoading} changePassword={changePassword} profile={profile} setChangePasswordStatus={setChangePasswordStatus}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    )
}