import { toast } from "react-toastify";
import { fetchresetPasswordSuccess, fetchresetPasswordFailure } from "./reducer";
import { RESET_PASSWORD_API } from "../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";

export const resetPassword = (userId, setLoading) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${RESET_PASSWORD_API}/${userId}`,'POST').then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchresetPasswordSuccess(response))
            toast.success(`Reset password link has been successfully sent to the registered Email ID!`)
        }
        if (response.error) {
            setLoading(false)
            dispatch(fetchresetPasswordFailure(response.error.message))
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}
