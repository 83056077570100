import { MaterialReactTable } from "material-react-table"
import { Card } from "reactstrap"
import { attributeColumnData } from "./constants/ListingCustomerFunctions"

export const AttributeTableInView = ({ attributeData }) => {
  const column = attributeColumnData()
  return (
    <Card>
      {attributeData ? <MaterialReactTable
        columns={column}
        data={attributeData}
        muiTablePaperProps={{
          elevation: 0,
        }}
        defaultColumn={{
          minSize: 10,
          maxSize: 9001,
          muiTableBodyCellProps: {
              sx: {
                  fontFamily: "Inter,sans-serif"
              }
          },
          muiTableHeadCellProps: {
              sx: {
                  fontFamily: "Inter,sans-serif"
              }
          }
      }}
        enableBottomToolbar={false}
        enableColumnActions={false}
        enableDensityToggle={false}
        enablePagination={false}
      /> : null}
    </Card>
  )
}