import moment from "moment";
import { Card, CardBody, CardHeader, Col, Row, Accordion, AccordionItem, AccordionHeader, AccordionBody, } from "reactstrap";
import { useState } from "react";

export const DetailedTaskView = ({ taskDetails }) => {
    const [openAccordion, setOpenAccordion] = useState("");
    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? "" : id);
    };
    const formatTaskType = (taskType) => {
        return taskType
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    return (
        <div >
            <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1 ms-2">View Task</h4>
            </CardHeader>
            <CardBody>
                <Row className="gy-4 mb-4 ms-0 d-flex justify-content-between align-items-center">
                    <Col lg={4}>
                        <div className="fw-semibold mb-2">Task Type</div>
                        <div>{formatTaskType(taskDetails?.taskType)}</div>
                    </Col>

                    <Col lg={4} className="d-flex-row justify-content-end ">
                        <div className="fw-semibold mb-2">Task Start Time</div>
                        <div>
                            {moment(taskDetails?.taskTime).format("DD MMM YYYY, HH:mm:ss")}
                        </div>
                    </Col>

                    <Col lg={4} className="d-flex-row justify-content-end ">
                        <div className="fw-semibold mb-2">Task End Time</div>
                        <div>
                            {moment(taskDetails?.endTime).format("DD MMM YYYY, HH:mm:ss")}
                        </div>
                    </Col>
                </Row>

                <Row className=" ms-1">
                    <Col lg={6}>
                        <h5 >UMP</h5>
                        <Accordion className=" ms-n3" open={openAccordion} toggle={toggleAccordion}>
                            <AccordionItem>
                                <div className="fw-semibold ms-0 mt-3 mb-1"> 
                                    <div>
                                        <Row className="d-flex justify-content-between align-items-end">
                                            <Col>
                                                <div className="fw-bold mb-2 mt-0 ms-3">Internal Status</div>
                                                <div className=" ms-3">
                                                    <span
                                                        className={`${taskDetails?.internalStatus === "FAILED"
                                                            ? "task-fail-color"
                                                            : taskDetails?.internalStatus === "SUCCESS"
                                                                ? "task-success-color"
                                                                : taskDetails?.internalStatus === "PENDING"
                                                                    ? "task-pending-color"
                                                                    : ""
                                                            }`}
                                                    >
                                                        {taskDetails?.internalStatus}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="w-100" /> 
                                </div>
                                <AccordionHeader targetId="1" className="mb-0 mt-n3 offer-name ">
                                    Request Sent
                                </AccordionHeader>
                                <AccordionBody accordionId="1">
                                    <pre className="task-color">
                                        {JSON.stringify(taskDetails?.requestSent, null, 2)}
                                    </pre>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="2" className="mb-0">
                                    Response Received
                                </AccordionHeader>
                                <AccordionBody accordionId="2" >
                                    <pre className="task-color">
                                        {JSON.stringify(taskDetails?.responseReceived, null, 2)}
                                    </pre>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </Col>
                    <Col lg={6}>
                        <h5 className="me-2">BRM</h5>
                        <Accordion className="ms-n3" open={openAccordion} toggle={toggleAccordion}>
                            <AccordionItem>
                                <div className="fw-semibold ms-0 mt-3 mb-1"> 
                                    <div>
                                        <Row className="d-flex justify-content-between align-items-end">
                                            <Col>
                                                <div className="fw-bold mb-2 mt-0 ms-3">Provisioning Status </div>
                                                <div className=" ms-3">
                                                    <span
                                                        className={`${taskDetails?.provisioningStatus === "FAILED"
                                                            ? "task-fail-color"
                                                            : taskDetails?.provisioningStatus === "SUCCESS"
                                                                ? "task-success-color"
                                                                : taskDetails?.provisioningStatus === "PENDING"
                                                                    ? "task-pending-color"
                                                                    : ""
                                                            }`}
                                                    >
                                                        {taskDetails?.provisioningStatus}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="w-100" /> 
                                </div>
                                <AccordionHeader targetId="3" className="mb-0 mt-n3">
                                    Request Sent
                                </AccordionHeader>
                                <AccordionBody accordionId="3">
                                    <pre className="task-color">
                                        {JSON.stringify(taskDetails?.externalTracker?.requestSent, null, 2)}
                                    </pre>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="4" className="mb-0">
                                    Response Received
                                </AccordionHeader>
                                <AccordionBody accordionId="4">
                                    <pre className="task-color">
                                        {JSON.stringify(taskDetails?.externalTracker?.responseReceived, null, 2)}
                                    </pre>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </Col>
                </Row>
            </CardBody>
        </div>
    );
};
