import { COUNTRIES_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchCountriesFailure, fetchCountriesSuccess } from "./reducer";

export const fetchCountries = (setLoading,setCallState) => async (dispatch) => {
    setLoading(true)
    try {
        const response = await determineBaseUrlAndPerformApiCall(`${COUNTRIES_API}`, 'GET');
        dispatch(fetchCountriesSuccess(response));
        setLoading(false)
        setCallState(true)
    } catch (error) {
        dispatch(fetchCountriesFailure(error.message));
        setLoading(false)
    }
}