import { ORDERS_LIST_API } from "../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { fetchOrdersFailure, fetchOrdersSuccess } from "./reducer";

export const fetchOrders = (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${ORDERS_LIST_API}`, 'GET')
        setLoading(false)
        dispatch(fetchOrdersSuccess(response.reverse()))
    }
    catch (error) {
        dispatch(fetchOrdersFailure(error.message))
    }
}