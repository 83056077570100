import { Col } from "reactstrap";
import { LabelText } from "../Utils/Input";
import InputField from "../Utils/InputField";
import MapWithSearch from "../GoogleMap/MapWithSearch";

export default function FormLine3({ handleChange, handleBlur, values, setFieldValue }) {
  return (
    <>
      <MapWithSearch setFieldValue={setFieldValue} values={values} />
      <Col xxl={3} md={6}>
        <LabelText
          htmlFor={"Latitude"}
          className={"text-danger"}
          displayText={"Latitude"}
        />
        <InputField
          name={"Latitude"}
          placeholder={"Enter Latitude"}
          type={"text"}
          className={"form-control"}
          id={"Latitude"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.coordinates.latitude || ""}
        />
      </Col>
      <Col xxl={3} md={6}>
        <LabelText htmlFor={"longitude"} displayText={"Longitude"} />
        <InputField
          name={"longitude"}
          placeholder={"Enter Longitude"}
          type={"text"}
          className={"form-control"}
          id={"longitude"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.coordinates.longitude || ""}
        />
      </Col>
      <Col xxl={3} md={6}>
        <LabelText htmlFor={"ICCID"} displayText={"ICCID"} />
        <InputField
          name={"ICCID"}
          placeholder={"Enter ICCID"}
          type={"text"}
          className={"form-control"}
          id={"ICCID"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.modem?.iccid || ""}
        />
      </Col>
      <Col xxl={3} md={6}>
        <LabelText htmlFor={"IMSI"} displayText={"IMSI"} />
        <InputField
          name={"IMSI"}
          placeholder={"Enter IMSI"}
          type={"text"}
          className={"form-control"}
          id={"IMSI"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.modem?.imsi || ""}
        />
      </Col>
    </>
  );
}
