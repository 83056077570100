import moment from "moment";

export const handleSelectPartner = (isEdit, partnerId, selectedOffers, policyId, navigate, toast, allOffers, selectAllPartners, values) => {
    if (isEdit && partnerId?.length > 0) {
        navigate(`/update-policy/${policyId}`, { state: { selectedOffers, partnerId, policyId, allOffers, selectAllPartners } });
    }
    else if (partnerId?.length > 0) {
        if (values.commissionRules) {
            navigate('/create-policy', {
                state: { selectedOffers, partnerId, allOffers, selectAllPartners, values }
            });
        }
        else {
            navigate('/create-policy', {
                state: { selectedOffers, partnerId, allOffers, selectAllPartners }
            });
        }
    }
    else {
        toast.error('Kindly Select a Partner')
    }
};

export const formattedOrganizationFunction = (formattedOrganizations, PartnerData, organizationsWithMaidIds, organizationsWithPhoneNum, extractedIds) => {
    const organization = formattedOrganizations.map((org, index) => {
        const partnerOrg = PartnerData[index];
        return {
            ...org,
            tradingName: partnerOrg.tradingName,
            organizationType: partnerOrg.organizationType,
            maidIds: organizationsWithMaidIds[index].maidIds,
            PhoneNums: organizationsWithPhoneNum[index].PhoneNums,
            extractedId: extractedIds[index]
        };
    });
    return organization
}

export const PartnerDataModificationFunctionsForPhoneNumber = (PartnerData, organizationsWithPhoneNum) => {
    PartnerData.forEach(organization => {
        const orgWithPhoneNum = {
            PhoneNums: []
        };
        if (organization.contactMedium) {
            organization.contactMedium.forEach(contact => {
                if (contact.characteristic && contact.characteristic.phoneNumber) {
                    orgWithPhoneNum.PhoneNums.push(contact.characteristic.phoneNumber);
                }
            });
        } else {
            orgWithPhoneNum.PhoneNums.push("-");
        }
        organizationsWithPhoneNum.push(orgWithPhoneNum);
    });
}


export const PartnerDataModificationFunctionsForMailId = (PartnerData, organizationsWithMaidIds) => {
    PartnerData.forEach(organization => {
        const orgWithMaidId = {
            maidIds: []
        };
        if (organization.contactMedium) {
            organization.contactMedium.forEach(contact => {
                if (contact.characteristic && contact.characteristic.emailAddress) {
                    orgWithMaidId.maidIds.push(contact.characteristic.emailAddress);
                }
            });
        } else {
            orgWithMaidId.maidIds.push("-");
        }
        organizationsWithMaidIds.push(orgWithMaidId);
    });
}

export const formattedOrganizationsForDate = (PartnerData) => {
    const formattedOrganizations = PartnerData.map(org => {
        if (org.createdAt) {
            const formattedDate = moment(org.createdAt).format('DD-MMM-YYYY');
            return { ...org, createdAt: formattedDate };
        }
        return org;
    });
    return formattedOrganizations
}

export const handleCheckboxChange = (row, selectedPartners, setSelectedPartners, setSelectedOnes) => {
    const isChecked = selectedPartners.some(p => p.extractedId === row.original.extractedId);
    const updatedSelectedPartners = isChecked
        ? selectedPartners.filter(p => p.extractedId !== row.original.extractedId)
        : [...selectedPartners, row.original];
    setSelectedPartners(updatedSelectedPartners);
    setSelectedOnes(true)
};

export const toggleSelectAllForPartner = (setSelectAllPartners, selectAllPartners, allData, setSelectedPartners) => {
    setSelectAllPartners(!selectAllPartners);
    if (!selectAllPartners) {
        const allPartners = allData
        setSelectedPartners(allPartners);
    } else {
        setSelectedPartners([]);
    }
};

export const partnerDataFrommTable = (selectedPartners) => {
    let partnerId = []
    selectedPartners?.forEach((value, index) => {
        partnerId.push(value.extractedId)
    })
    return partnerId
}

export const mergeAndFilterOffers = (settlementOffers, setMergedArray) => {
    const uniqueOfferCodes = new Set()
    const mergedArray = settlementOffers.reduce((acc, obj) => {
        if (!uniqueOfferCodes.has(obj.offerCode)) {
            uniqueOfferCodes.add(obj.offerCode);
            acc.push(obj);
        }
        return acc;
    }, []);
    setMergedArray(mergedArray);
};