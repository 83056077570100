import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteUser: [],
    loading: false,
    error: null,
}

const deleteUserSlice = createSlice({
    name: "deleteUser",
    initialState,
    reducers: {
        fetchDeleteUserSuccess: (state, action) => {
            state.loading = false
            state.deleteUser = action.payload;
        },
        fetchDeleteUserFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchDeleteUserSuccess,
    fetchDeleteUserFailure,
} = deleteUserSlice.actions;

export default deleteUserSlice.reducer;