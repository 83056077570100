const initialState = {
  partnerselect: [],
  settlementPartner: [],
  selectedPartner: null,
};

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_PARTNER':
      const updatedPartners = [...state.partnerselect, ...action.payload];
      return {
        ...state,
        partnerselect: updatedPartners,
      };
    case 'ADD_PARTNER_DUPLICATE':
      const updatedPartnersDuplicate = [...state.settlementPartner, ...action.payload];
      return {
        ...state,
        settlementPartner: updatedPartnersDuplicate,
      };
    case 'SELECT_ORDER':
      return {
        ...state,
        selectedPartner: action.payload,
      };
    case 'REMOVE_PARTNER':
      const updatedSettlementPartner = state.settlementPartner.filter(order => order.extractedId !== action.payload);
      return {
        ...state,
        settlementPartner: updatedSettlementPartner,
        selectedPartner: null,
      };
    case 'CLEAR_PARTNERS':
      return {
        ...state,
        settlementPartner: [],
        selectedPartner: null,
      };
    case 'CLEAR_PARTNERS_DUPLICATE':
      return {
        ...state,
        settlementPartner: [],
      };
    default:
      return state;
  }
};

export default partnerReducer;
