import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../Common/BreadCrumb';
import { ToastContainer } from 'react-toastify';
import { PAGE_TITLES } from '../Common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { fetchDocumentsForCustomer } from '../../slices/customer/documentlist/thunk';
import { Loader } from '../Utils/Loader';
import UploadDocumentForSelfcare from '../Customers/UploadDocumentSelfcare';
const UploadDocumentsSelfcare = () => {
    const fetchDocumentData = useSelector(state => state.DocumentList.documentList);
    const [open, setIsOpen] = useState(false);
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState();
    const [state, setState] = useState(false);

    const isEdit = true;
    const dispatch = useDispatch();
    const customerId = localStorage.getItem('customerId');

    useEffect(() => {
        dispatch(fetchDocumentsForCustomer(setLoading, customerId));
    }, [dispatch, customerId]);

    const formik = useFormik({
        initialValues: {
            documentType: '',
            documentName: '',
            selectedFile: '',
        },
        validate: values => {
            const errors = {};
            if (!values.documentType) {
                errors.documentType = 'Document Type is required';
            }
            if (!values.documentName) {
                errors.documentName = 'Document Name is required';
            }
            if (!file) {
                errors.selectedFile = 'File is required';
            } else if (file.size > 10 * 1024 * 1024) {
                errors.selectedFile = 'File size must be less than 10 MB';
            }
            return errors;
        },
        onSubmit: (values) => {
        },
    });
    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                {loading && (<Loader />)}
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.UPLOADDOCUMENT} pageTitle={PAGE_TITLES.UPLOADDOCUMENT} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <div className="listingjs-table " id="customerList">
                                <Card>
                                    <CardBody>
                                        <Row className="table-responsive">
                                            <Col lg={12}>
                                                <UploadDocumentForSelfcare
                                                    fetchDocumentData={fetchDocumentData}
                                                    isEdit={isEdit}
                                                    open={open}
                                                    setIsOpen={setIsOpen}
                                                    file={file}
                                                    setFile={setFile}
                                                    fileName={fileName}
                                                    setFileName={setFileName}
                                                    values={formik.values}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    handleChange={formik.handleChange}
                                                    handleBlur={formik.handleBlur}
                                                    setFieldValue={formik.setFieldValue}
                                                    setFieldTouched={formik.setFieldTouched}
                                                    setState={setState}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UploadDocumentsSelfcare;