import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    services: [],
    loading: false,
    error: null,
}

const servicesSlice = createSlice({
    name: "services",
    initialState,
    reducers: {
        fetchServiceStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchServiceSuccess: (state, action) => {
            state.loading = false,
                state.services = action.payload;
        },
        fetchServiceFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchServiceStart,
    fetchServiceSuccess,
    fetchServiceFailure,
} = servicesSlice.actions;

export default servicesSlice.reducer;