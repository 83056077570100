import { CREATE_EDIT_GET_ATTRIBUTE_API } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { setEditSuccessMessage } from "../../toastMessage/action"
import { fetchUpdateAttributeFailure, fetchUpdateAttributeSuccess } from "./reducer"
import { toast } from "react-toastify"

export const updateAttribute = (setLoading,attributeName,jsonObj,navigate) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${CREATE_EDIT_GET_ATTRIBUTE_API}/${attributeName}`,'PUT',jsonObj).then((response)=>{
        if (!response.error) {
            setLoading(false)
            dispatch(fetchUpdateAttributeSuccess(response))
            navigate('/attribute')
            dispatch(setEditSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchUpdateAttributeFailure(response.error.message))
        }
    }).catch((error)=>{
        toast.error(
            `${error.message}`
        )
    })
}