import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    resources: [],
    loading: false,
    error: null,
}

const resourcesSlice = createSlice({
    name: "resources",
    initialState,
    reducers: {
        fetchResourceStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchResourceSuccess: (state, action) => {
            state.loading = false,
                state.resources = action.payload;
        },
        fetchResourceFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})
export const {
    fetchResourceStart,
    fetchResourceSuccess,
    fetchResourceFailure,
} = resourcesSlice.actions;

export default resourcesSlice.reducer;