import { Col } from "reactstrap";
import { LabelText } from "../Utils/Input";
import InputField from "../Utils/InputField";
import Flatpickr from "react-flatpickr";

export default function FormLine4({ handleChange, handleBlur, values }) {
  return (
    <>
      <Col xxl={3} md={6}>
        <LabelText
          htmlFor={"availability start time"}
          className={"text-danger"}
          displayText={"Availability Start Time"}
          for="availability start time"
        />
        <Flatpickr
          name={"availability start time"}
          autoComplete="off"
          className="form-control"
          id="availability start time"
          options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
          }}
        />
      </Col>
      <Col xxl={3} md={6}>
        <LabelText
          htmlFor={"availability end time"}
          className={"text-danger"}
          displayText={"Availability End Time"}
          for="availability end time"
        />
        <Flatpickr
          name={"availability end time"}
          autoComplete="off"
          className="form-control"
          id="availability end time"
          options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
          }}
        />
      </Col>
      <Col xxl={3} md={6}>
        <LabelText htmlFor={"Admin Action"} displayText={"Admin Action"} />
        <InputField
          name={"Admin Action"}
          placeholder={"Enter Admin Action"}
          type={"text"}
          className={"form-control"}
          id={"Admin Action"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.status || ""}
        />
      </Col>
      <Col xxl={3} md={6}>
        <LabelText
          htmlFor={"Vendor Name"}
          displayText={"Vendor Name"}
        />
        <InputField
          name={"Vendor Name"}
          placeholder={"Enter Vendor Name"}
          type={"text"}
          className={"form-control"}
          id={"Vendor Name"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.vendorName || ""}
        />
      </Col>
      <Col xxl={3} md={6}>
        <LabelText
          htmlFor={"Model"}
          displayText={"Model"}
        />
        <InputField
          name={"Model"}
          placeholder={"Enter Model"}
          type={"text"}
          className={"form-control"}
          id={"Model"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.model || ""}
        />
      </Col>
      <Col xxl={3} md={6}>
        <LabelText
          htmlFor={"Firmware version"}
          displayText={"Firmware version"}
        />
        <InputField
          name={"Firmware version"}
          placeholder={"Enter Firmware version"}
          type={"text"}
          className={"form-control"}
          id={"Firmware version"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.firmwareVersion || ""}
        />
      </Col>
      <Col xxl={3} md={6}>
        <LabelText
          htmlFor={"timezone"}
          displayText={"Timezone"}
        />
        <InputField
          name={"timezone"}
          placeholder={"Enter Timezone"}
          type={"text"}
          className={"form-control"}
          id={"timezone"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.timeZone || ""}
        />
      </Col>
    </>
  );
}
