import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    cities : [],
    loading : false,
    error : null,
};

const citiesSlice = createSlice({
    name:"cities",
    initialState,
    reducers: {
        fetchCitiesSuccess: (state,action) => {
            state.loading = false;
            state.cities = action.payload
        },
        fetchCitiesFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchCitiesFailure,
    fetchCitiesSuccess
} = citiesSlice.actions

export default citiesSlice.reducer