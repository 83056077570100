import { fetchBalanceSuccess, fetchBalanceFailure, fetchSelectedResourceSuccess } from "./reducer";
import moment from "moment";
import { toast } from "react-toastify"
import { BALANCE_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";

export const fetchBalance = (id, setLoading, jsonObj) => async (dispatch) => {
    setLoading(true)
    const time = moment(jsonObj?.validFrom).add(1, 'minute').toISOString().replace('.000', '');
    await determineBaseUrlAndPerformApiCall(`${BALANCE_API}/${id}?mode=summary&startTime=${time}`, 'GET').then((response) => {
        if (response.error) {
            setLoading(false)
            dispatch(fetchBalanceFailure(response.error.messsage))
        }
        if (!response.error) {
            setLoading(false)
            let selectedResources
            if (jsonObj.nextBillCycle) {
                selectedResources = response?.resources?.filter(resource =>
                    (jsonObj.subscriptionId === resource.subscriptionId) && !resource.hasOwnProperty('currencyCode')
                );
            } else {
                selectedResources = response?.resources?.filter(resource =>
                    (jsonObj.subscriptionInternalId === resource.subscriptionInternalId) && !resource.hasOwnProperty('currencyCode')
                );
            }
            dispatch(fetchSelectedResourceSuccess(selectedResources));

            dispatch(fetchBalanceSuccess(response))
        }
    })
}