import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    getBillsPartner : [],
    loading : false,
    error : null,
};

const fetchGetBillsPartnerSlice = createSlice({
    name:"getBillsPartner",
    initialState,
    reducers: {
        fetchGetBillsPartnerSuccess: (state,action) => {
            state.loading = false;
            state.getBillsPartner = action.payload
        },
        fetchGetBillsPartnerFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchGetBillsPartnerSuccess,
    fetchGetBillsPartnerFailure
} = fetchGetBillsPartnerSlice.actions

export default fetchGetBillsPartnerSlice.reducer