import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { useDispatch, useSelector } from 'react-redux';
import { getAllStation } from "../../slices/stations/thunk";
import { getAllReservation } from "../../slices/reservations/thunk";
import moment from "moment";
import { fetchCustomerListForReservation } from "../../slices/reservations/customerlist/thunk";

function StationReservation(stationID) {
    const [loading, setLoading] = useState('')
    const allDate = [];
    const startDuration = [];
    const allDate1 = [];
    const endDuration = [];
    let customerName = [];
    let customerPhone = [];
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllStation());
        dispatch(getAllReservation());
        dispatch(fetchCustomerListForReservation(setLoading))
    }, [dispatch]);
    const stationData = useSelector((state) => state.Stations.station);
    const reservation = useSelector((state) => state.Reservations.reservations);
    const allcustomer = useSelector((state) => state.CustomerAllData.customerListForReservation)
    reservation.forEach((reservationItem) => {
        if (stationID.stationID === reservationItem.StationId) {
            allcustomer.forEach((customer) => {
                if (reservationItem.CustomerId === customer.CustomerId) {
                    const DateObj = moment(reservationItem?.StartTime);
                    allDate.push(DateObj.format('DD MMMM YYYY'));
                    startDuration.push(DateObj.format('hh:mm A'));
                    const DateObj1 = moment(reservationItem?.EndTime);
                    allDate1.push(DateObj1.format('DD MMMM YYYY'));
                    endDuration.push(DateObj1.format('hh:mm A'));
                    if (customer.FirstName !== "") {
                        customerName.push(customer.FirstName);
                    } else {
                        customerName.push("");
                    }
                    if (customer.Mobile !== 0) {
                        customerPhone.push(customer.Mobile);
                    } else {
                        customerPhone.push("");
                    }
                }
            });
        }
    });

    const columns = useMemo(
        () => [
            {
                accessorFn: (allDate, i) => `${customerName[i]}`,
                header: 'Customer Name',
                size: 150,
            },
            {
                accessorFn: (allDate, i) => `${customerPhone[i]}`,
                header: "Phone Number",
            },
            {
                accessorFn: (allDate, i) => `${startDuration[i]} - ${endDuration[i]}`,
                header: 'Duration',
                size: 150,
            },
            {
                accessorFn: (stationData, i) => `${allDate[i]}`,
                header: 'Date',
                size: 150,
            },
        ],
        [allDate, startDuration, endDuration, customerName, customerPhone]
    );
    return (
        <div className="table-responsive">
            {allDate ? (
                <MaterialReactTable columns={columns} data={allDate} />
            ) : null}
        </div>
    );
}
export default StationReservation;
