import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editResource: [],
    loading: false,
    error: null,
}

const editResourceSlice = createSlice({
    name: "editResource",
    initialState,
    reducers: {
        fetcheditResourceSuccess: (state, action) => {
            state.loading = false,
            state.editResource = action.payload;
        },
        fetcheditResourceFailure: (state, action) => {
            state.loading = false,
            state.error = action.payload;
        },
    },
})

export const {
    fetcheditResourceSuccess,
    fetcheditResourceFailure,
} = editResourceSlice.actions;

export default editResourceSlice.reducer;