import { toast } from "react-toastify";
import { fetcheditUomSuccess,fetcheditUomFailure } from "./reducer";
import { setEditSuccessMessage } from "../../toastMessage/action";
import { ADD_UOM_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";

export const editUom = (jsonObj,uomName, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${ADD_UOM_API}/${uomName}`, 'PUT', jsonObj).then((response) => {
        if (response.error) {
            dispatch(fetcheditUomSuccess(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
        if (!response.error) {
            setLoading(false)
            dispatch(fetcheditUomFailure(response))
            dispatch(setEditSuccessMessage(true))
            navigate('/uom');
        }
    }).catch((errorResp) => { })
};