import { Card, CardBody, Col, FormFeedback, Row } from "reactstrap";
import SelectField from "../Utils/SelectField";
import InputField from "../Utils/InputField";
import { Formik } from "formik";
import { CancelOrderOptions } from "./constants/initialValues";
import { handleChangeForReason } from "./constants/HandleReasonChange";

export const CancelOrder = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  setReason,
}) => {
  return (
    <Col>
      <Card>
        <CardBody>
          <Row>
            <Col className="d-flex flex-column flex-md-row justify-content-start">
              <Col xs={12} md={4}  xxl={2}className="card-title mt-2 mb-2 mb-md-0">
                Reason For Cancellation:
              </Col>
              <Col xs={12} md={3} className="mb-3 mb-md-0 me-3">
                <SelectField
                  className={(errors.reason && touched.reason) || false ? "error-input" : ""}
                  value={values.reason}
                  options={[...CancelOrderOptions]}
                  handleChange={handleChangeForReason(setFieldTouched, setFieldValue, "reason", setReason)}
                  placeholder={"Select reason"}
                />
                {touched.reason && errors.reason ? (
                  <p className="text-danger">{errors.reason}</p>
                ) : null}
              </Col>
              <Col xs={12} md={4} xxl={6}>
                <InputField
                  name={"comment"}
                  type={"text"}
                  className={"form-control"}
                  disabled={values.reason.value !== "Other"}
                  value={values.comment}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder={"Enter reason for cancellation"}
                  invalid={
                    values.reason.value === "Other" && touched.comment && errors.comment ? true : false
                  }
                />
                {touched.comment && errors.comment ? (
                  <FormFeedback type="invalid">{errors.comment}</FormFeedback>
                ) : null}
              </Col>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};
