import { fetchCustomerList } from "../../../slices/customer/customerlist/thunk";
import { getSearchedCustomer } from "../../../slices/customer/customerserach/thunk";
import { fetchCustomerEvents } from "../../../slices/customer/events/thunk";
import { fetchCustomer } from "../../../slices/customer/fetchcustomer/thunk";
import { fetchCustomerSummary } from "../../../slices/customer/simulate/thunk";
import { getAllReservation } from "../../../slices/reservations/thunk";
import { getAllStation } from "../../../slices/stations/thunk";

export const getCustomerData = (id, simulateOrPreview, setLoading, stationPermission, reservationPermission, customerPermission, setRenderSimulate, dispatch) => {
    stationPermission && dispatch(getAllStation(setLoading));
    reservationPermission && dispatch(getAllReservation());
    customerPermission && dispatch(fetchCustomer(setLoading, id.original.CustomerId));
    customerPermission && dispatch(
        fetchCustomerSummary(id, setLoading, simulateOrPreview, setRenderSimulate)
    );
    customerPermission && dispatch(fetchCustomerEvents(id));
};

export const navigateToCreateCustomer = (navigate) => {
    navigate("/create-customer");
};

export const handleSearch = (dispatch, setLoading,msisdn,imsi,accountNumber,setGetTable,setErrors, setErrorMessage, setErrorIcon,searchFields,setRenderSimulate,reservationPermission,stationPermission,customerPermission,searchCustomerData,custId,setCustId,setSearchExpanding) => {
    dispatch(getSearchedCustomer(setLoading,msisdn,imsi,accountNumber,setGetTable, setErrorMessage, setErrorIcon,searchFields,false,stationPermission, reservationPermission, customerPermission, setRenderSimulate,setSearchExpanding));
    setErrors('')
};

export const handleClearSearch = ( setMsisdn,setImsi,setAccountNumber, dispatch, setLoading,setGetTable,setErrors, setErrorMessage, setErrorIcon, searchFields,setSearchExpanding,setSearchFields) => {
    setMsisdn('')
    setImsi('')
    setAccountNumber('')
    setGetTable(false)
    setErrors('')
    setSearchExpanding(false);
    setErrorMessage('Search for customer using the parameters above.')
    setErrorIcon('ri-user-fill')
   
    const clearedSearchFields = searchFields.map(field => ({
        ...field,
        value: ''
    }));
    
    setSearchFields(clearedSearchFields);
};

export const handleSelectChange = (setSelectedValue, selectedOption) => {
    setSelectedValue(selectedOption)
}

export const attributeColumnData = () => {
    const attributeColumn = [
        {
            accessorKey: 'name',
            header: 'Name'
        },
        {
            accessorKey: 'value',
            header: 'Value'
        }
    ]
    return attributeColumn
}

export const validateField = (field, value,setErrors) => {
    let error = '';

    if (field === 'msisdn') {
        const msisdnRegex = /^[0-9]{0,12}$/;
        if (!msisdnRegex.test(value)) {
            error = 'MSISDN must be up to 12 numeric characters';
        }
    } else if (field === 'imsi') {
        const imsiRegex = /^[0-9]{0,15}$/;
        if (!imsiRegex.test(value)) {
            error = 'IMSI must be a numeric value with a maximum of 15 digits.';
        }
    } else if (field === 'accountNumber') {
        const accountNumberRegex = /^[a-zA-Z0-9]{0,10}$/;
        if (!accountNumberRegex.test(value)) {
            error = 'Account Number must be alphanumeric with a maximum of 10 characters.';
        }
    }

    setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: error,
    }));

    return error === '';
};

export const handleInputChange = (field, value,setErrors,setMsisdn,setImsi,setAccountNumber) => {
    if (validateField(field, value,setErrors)) {
        if (field === 'msisdn') setMsisdn(value);
        if (field === 'imsi') setImsi(value);
        if (field === 'accountNumber') setAccountNumber(value);
    }
};
