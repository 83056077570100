import { fetchProvisionSucess, fetchProvisionFailure } from "./reducer"
import { determineBaseUrlAndPerformApiCall } from "../../../interceptor/interceptor";
import { GET_ALL_ATTRIBUTE_API } from "../../../constants/constants";

export const getAllAttribute = (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${GET_ALL_ATTRIBUTE_API}`, 'GET')
        setLoading(false)
        dispatch(fetchProvisionSucess(response.reverse()));
    } catch (error) {
        dispatch(fetchProvisionFailure(error.message));
        dispatch(fetchProvisionSucess([]));
    }
};

