import { fetchBulkDeleteSuccess, fetchBulkDeleteFailure } from "./reducer"
import { toast } from "react-toastify";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { BULK_DELETE } from "../constants/constants";
import { getAllAttribute } from "../services/addservice/provisionalattribute/thunk";
import { getAllService } from "../services/thunk";
import { getAllResources } from "../resources/thunk";
import { fetchAllOffers } from "../offer/thunk";
import { fetchCustomerList } from "../customer/customerlist/thunk";
import { fetchAllUom } from "../uom/thunk";

export const bulkDelete = (jsonObj, setLoading, pageIndex, pageSize, selectedValue, searchValue) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${BULK_DELETE}`, 'DELETE', jsonObj).then((response) => {
        if (!response.error) {
            dispatch(fetchBulkDeleteSuccess(response))
            setLoading(false)
            if (response.type === "UOM") {
                dispatch(fetchAllUom(setLoading))
                let deletedUomString = response?.message?.deletableIds?.length
                let notDeletedUomString = response?.message?.nonDeletableIds?.length
                let successMessage = '';
                if (deletedUomString) {
                    successMessage += `${deletedUomString} Items Deleted, `;
                }
                if (notDeletedUomString) {
                    successMessage += `${notDeletedUomString} Items Not Deleted.`;
                }
                if (successMessage) {
                    toast.success("Bulk Action Completed. "+successMessage.trim());
                }
            }
            else if (response.type === "Attribute") {
                dispatch(getAllAttribute(setLoading))
                let deletedAttributeString = response?.message?.deletableIds?.length
                let notDeletedAttributeString = response?.message?.nonDeletableIds?.length
                let successMessage = '';
                if (deletedAttributeString) {
                    successMessage += `${deletedAttributeString} Items Deleted, `;
                }
                if (notDeletedAttributeString) {
                    successMessage += `${notDeletedAttributeString} Items Not Deleted.`;
                }
                if (successMessage) {
                    toast.success("Bulk Action Completed. "+successMessage.trim());
                }
            }
            else if (response.type === "Resource") {
                dispatch(getAllResources(setLoading))
                let deletedResourceString = response?.message?.deletableIds?.length
                let notDeletedResourceString = response?.message?.nonDeletableIds?.length
                let successMessage = '';
                if (deletedResourceString) {
                    successMessage += `${deletedResourceString} Items Deleted, `;
                }
                if (notDeletedResourceString) {
                    successMessage += `${notDeletedResourceString} Items Not Deleted.`;
                }
                if (successMessage) {
                    toast.success("Bulk Action Completed. "+successMessage.trim());
                }
            }
            else if (response.type === "Service") {
                dispatch(getAllService(setLoading))
                let deletedServiceString = response?.message?.deletableIds?.length
                let notDeletedServiceString = response?.message?.nonDeletableIds?.length
                let successMessage = '';
                if (deletedServiceString) {
                    successMessage += `${deletedServiceString} Items Deleted, `;
                }
                if (notDeletedServiceString) {
                    successMessage += `${notDeletedServiceString} Items Not Deleted.`;
                }
                if (successMessage) {
                    toast.success("Bulk Action Completed. "+successMessage.trim());
                }
            }
            else if (response.type === "Offer") {
                dispatch(fetchAllOffers(setLoading))
                let deletedOfferString = response?.message?.deletableIds?.length
                let notDeletedOfferString = response?.message?.nonDeletableIds?.length
                let successMessage = '';
                if (deletedOfferString) {
                    successMessage += `${deletedOfferString} Items Deleted, `;
                }
                if (notDeletedOfferString) {
                    successMessage += `${notDeletedOfferString} Items Not Deleted.`;
                }
                if (successMessage) {
                    toast.success("Bulk Action Completed. "+successMessage.trim());
                }
            }
            else if (response.type === "Customer") {
                dispatch(fetchCustomerList(setLoading, pageIndex, pageSize, selectedValue, searchValue))
                let deletedCustomerString = response?.message?.deletableIds?.length
                let notDeletedCustomerString = response?.message?.nonDeletableIds?.length
                let successMessage = '';
                if (deletedCustomerString) {
                    successMessage += `${deletedCustomerString} Items Deleted, `;
                }
                if (notDeletedCustomerString) {
                    successMessage += `${notDeletedCustomerString} Items Not Deleted.`;
                }
                if (successMessage) {
                    toast.success("Bulk Action Completed. "+successMessage.trim());
                }
            }
        }
        if (response.error) {
            dispatch(fetchBulkDeleteFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}