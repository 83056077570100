import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
} from "reactstrap";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUnitOfMeasurement } from "../../../slices/uom/adduom/thunk";
import { ToastContainer } from "react-toastify";
import { initialFormValues, validationSchema } from "./constants/initialvalues";
import { Loader } from "../../Utils/Loader";
import { uomJsonObj } from "./constants/uomsubmit";
import { LabelText } from "../../Utils/Input";
import CommonButton from "../../Utils/CommonButton";
import { PAGE_TITLES } from "../../Common/constants";
import formSchema from "../../Utils/schema.json";
import { RenderField } from "../../Utils/renderFields";
import { ValueTypeOptions } from './constants/initialvalues';
import { RenderErrorMessage } from "../../Utils/renderErrorMessages";
import { editUom } from "../../../slices/uom/updateUom/thunk";
import { customerIdData } from "../../Customers/constants/constantFunctionsView";
import { getUomById } from "../../../slices/uom/getUomByName/thunk";

export default function AddUnit(initialAIUomValues) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const uomName = customerIdData()
  const isEdit = location.pathname.includes('edit-uom')
  const isAiPath=location.pathname.includes('chatbot')
  const isCreate=location.pathname.includes('create-unit')
  const [loading, setLoading] = useState(false);
  const [enableReinitialize,setEnableReinitialize]=useState(false)

  let initialValues = {
    name: "",
    valuetype: "",
  };
  
  const onSubmit = (values) => {
    const jsonObj = uomJsonObj(values);
    submitUom(jsonObj);
  };
  if(isEdit){
    useEffect(() => {
      dispatch(getUomById(setLoading,uomName,initialValues,ValueTypeOptions));
  }, [dispatch])
  }
  useEffect(()=>{
    if(isCreate){
      setEnableReinitialize(true)
    }
  },[isCreate])
  const submitUom = (jsonObj) => {
    if(isEdit){
      dispatch(editUom(jsonObj,uomName,setLoading, navigate));
    }
    else{
      dispatch(addUnitOfMeasurement(jsonObj, setLoading, navigate));
    }
  };
  const pageTitleHeading = isEdit ? PAGE_TITLES.EDIT_UOM : PAGE_TITLES.ADD_UOM;

  return (
    <React.Fragment>
      <div id="csms" className={!isAiPath ? "page-content" : "pb-5"}>
        <Formik
          validationSchema={validationSchema}
          initialValues={isAiPath?initialAIUomValues.initialAIUomValues:initialValues}
          onSubmit={async (values) => {
            if (values.name !== "" && values.valuetype !== "") {

              onSubmit(values);
            }
          }}
          enableReinitialize={enableReinitialize}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            values,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form className="needs-validation" onSubmit={handleSubmit}>
              <Container fluid>
                {isAiPath?null:
                <BreadCrumb
                  title={pageTitleHeading}
                  pageTitle={PAGE_TITLES.UOM_LIST}
                />
}
                <ToastContainer position="top-center" />
                <Row>
                  <Col lg={12}>
                    <div className="listingjs-table " id="customerList">
                      <Card>
                        <CardBody className="card-body mb-5">
                          <div className="live-preview">
                            {formSchema.map((section, sectionIndex) => (
                              <Row className="gy-4 py-2" key={sectionIndex}>
                                {section.title === "UOM" && section.sections.map((subSection, subSectionIndex) => (
                                  subSection.fields && subSection.fields.map((field, fieldIndex) => (
                                    <Col xxl={6} md={6} key={fieldIndex}>
                                      <FormGroup>
                                        <LabelText
                                          htmlFor={field.name}
                                          className={field.required ? "text-danger" : ""}
                                          displayText={field.label}
                                          important={field.required ? "*" : ""}
                                        />
                                        <RenderField field={field}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          values={values}
                                          touched={touched}
                                          errors={errors}
                                          setFieldTouched={setFieldTouched}
                                          setFieldValue={setFieldValue} options={ValueTypeOptions}/>
                                        <RenderErrorMessage
                                          field={field}
                                          touched={touched}
                                          errors={errors}/>
                                      </FormGroup>
                                    </Col>
                                  ))
                                ))}
                              </Row>
                            ))}
                          </div>
                        </CardBody>
                      </Card>
                      <Row lg={6} className=" justify-content-end mb-4">
                        <div className="live-preview">
                          <div className="d-flex justify-content-end gap-2 ">
                            <CommonButton
                              outline={true}
                              className={"cancel-button-background"}
                              color={"main-color"}
                              type={"reset"}
                              buttonAction={() => navigate("/uom")}
                              buttonText={"Cancel"}
                            />
                            <CommonButton
                              color={"main-color"}
                              type={"submit"}
                              buttonText={"Confirm"}
                            />
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
        {loading && <Loader />}
      </div>
    </React.Fragment>
  );
}
