import { GET_RESERVATION_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchGetReservationFailure, fetchGetReservationSuccess } from "./reducer";
import axios from "axios";

export const getReservation = (reservationIdmain, setReservationData, setSearchValue, setSelectedAttribute, setSelectedStation, setSelectedDateTimeStart, setSelectedDateTimeEnd, initialValues, setLoadingData) => async (dispatch) => {
    setLoadingData(true)
    const reservationId = reservationIdmain;
    determineBaseUrlAndPerformApiCall(`${GET_RESERVATION_API}/${reservationId}`, 'GET')
        .then((response) => {
            setLoadingData(false)
            const existingReservationData = response;
            dispatch(fetchGetReservationSuccess(response))
            setReservationData(response);
            setSearchValue(existingReservationData.customerId);
            setSelectedAttribute({
                value: {
                    value: existingReservationData.idTokenType.idToken,
                    name: existingReservationData.idTokenType.type,
                },
                label: existingReservationData.idTokenType.type,
            });

            let newObject = {
                value: existingReservationData.stationID,
                label: existingReservationData.stationID
            };
            setSelectedStation(newObject);
            setSelectedDateTimeStart(existingReservationData.startTime);
            setSelectedDateTimeEnd(existingReservationData.endTime);
            initialValues.startTime = response.startTime
            initialValues.endTime = response.endTime
            initialValues.idTokenType = { "value": response.idTokenType.idToken, "label": response.idTokenType.type }
            initialValues.station = { "value": response.stationID, "label": response.stationID }
        })
        .catch((error) => {
            setLoadingData(false)
        });
} 
