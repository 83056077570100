import BreadCrumb from "../../Common/BreadCrumb"
import React from "react"
import { Card, Container, Col, CardBody, Row, Form, Button, FormFeedback } from "reactstrap"
import SelectField from "../../Utils/SelectField"
import { LabelText } from "../../Utils/Input"
import InputField from "../../Utils/InputField"
import { Formik } from "formik"
import { importInitialValues } from "./constants/initialValues"
import { validationSchemaForImport } from "./constants/validationSchema"
import { csvFormData, getFile, handleDownloadClick } from "./constants/GrantsAndRecurringFunctions"
import { useState } from "react"
import { Loader } from "../../Utils/Loader"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { ToastContainer } from "react-toastify"

export const ImportData = () => {
    const [validationTriggered, setValidationTriggered] = useState(false);
    const [file, setFile] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <React.Fragment>
            <Formik initialValues={importInitialValues} validationSchema={validationSchemaForImport} onSubmit={() => { csvFormData(file, setLoading, navigate, dispatch) }}>
                {({ handleBlur, values, touched, handleSubmit, handleChange }) => (
                    <Form onSubmit={handleSubmit} className="needs-validation">
                        {loading && <Loader />}
                        <div id='csms' className='page-content'>
                            <Container fluid>
                                <BreadCrumb title="Import Data" pageTitle="Offer" />
                                <ToastContainer position="top-center" />
                                <Card>
                                    <CardBody>
                                        <p className="fs-5"><span className="sample-file-color fw-semibold" onClick={handleDownloadClick}>Download a sample file</span> of how the CSV file should be formatted for import.</p>
                                        {/* <div className="d-flex justify-content-start align-items-center  ">
                                            <LabelText displayText={"Select Delimiter "} classLabelName={"fs-5"} />
                                            <SelectField isDisabled={true} className={"ms-3 "} />
                                        </div> */}
                                        <Col>
                                            <div className="d-flex align-items-center ">
                                                <LabelText htmlFor={'csvFile'} classLabelName={"fs-5 mt-3"} displayText={"Select your CSV File"} />
                                                <p className="csv-file-color mt-4 ps-1"> (File size maximum limit is 10mb, Allowed extensions : CSV)</p>
                                            </div>
                                        </Col>
                                        <Col xxl={2} md={6}>
                                            <InputField
                                                name={'csvFile'}
                                                accept={'.csv'}
                                                value={values.csvFile}
                                                className={"mt-2 no-border-form"} 
                                                handleBlur={handleBlur}
                                                type={"file"}
                                                handleChange={getFile(setFile, handleChange, setValidationTriggered)}
                                                invalid={touched.csvFile && !validationTriggered}
                                            />
                                            {touched.csvFile && !validationTriggered ? (
                                                <FormFeedback type="invalid">
                                                    {file ? "File size exceeds the limit (10MB)." : "Kindly select a file"}
                                                </FormFeedback>
                                            ) : null}
                                        </Col>

                                        <Button color='secondary' className='btn-label mt-3' type='submit' ><i className="ri-upload-line label-icon align-middle fs-16 me-2 "></i>Upload</Button>
                                    </CardBody>
                                </Card>
                            </Container>
                        </div>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}