import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    createOperator: [],
    loading: false,
    error: null,
}

const createOperatorSlice = createSlice({
    name: "createOperator",
    initialState,
    reducers: {
        fetchCreateOperatorSucess: (state, action) => {
            state.loading = false
            state.createOperator = action.payload;
        },
        fetchCreateOperatorFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchCreateOperatorSucess,
    fetchCreateOperatorFailure,
} = createOperatorSlice.actions;

export default createOperatorSlice.reducer;