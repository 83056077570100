import { countryOptionss } from "../../../Components/OperatorManagement/Constant/Options";
import { COUNTRIES_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchCitiesSuccess, fetchCitiesFailure } from "./reducer";

export const fetchCities = (selectedCountry, selectedState, isOperator, setCityData) => async (dispatch) => {
    try {
        await determineBaseUrlAndPerformApiCall(
            `${COUNTRIES_API}?country=${selectedCountry?.label}&state=${selectedState?.label}`, 'GET').then((response) => {
            if (isOperator === "isOperator") {
                let cityOptions = countryOptionss(response?.cities)
                const extraObject = {
                    name: "city",
                    value: "Operator",
                    options: cityOptions
                };
                setCityData(extraObject)
            }
            dispatch(fetchCitiesSuccess(response));
        });
    } catch (error) { dispatch(fetchCitiesFailure(error.message)) }
}