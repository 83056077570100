import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    createCustomer : [],
    loading : false,
    error : null,
};

const createCustomerSlice = createSlice({
    name:"createCustomer",
    initialState,
    reducers: {
        fetchCreateCustomerSuccess: (state,action) => {
            state.loading = false;
            state.createCustomer = action.payload
        },
        fetchCreateCustomerFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchCreateCustomerFailure,
    fetchCreateCustomerSuccess
} = createCustomerSlice.actions

export default createCustomerSlice.reducer