import { CardHeader, CardBody, Button, Card, Table, InputGroup, InputGroupText, Input } from "reactstrap";
import { FieldArray } from "formik";
import { TablesWithoutBorders } from "../../../pages/Tables/BasicTables/BasicTablesCode";
import InputField from "../../Utils/InputField";
import SelectField from "../../Utils/SelectField";
import { AttributeTypeOptions } from "./constants/initialvalues";
import useFieldAttributes from "./constants/fieldattribute";
import { Link } from "react-router-dom";
export default function Attribute({ values, touched, errors, setFieldTouched, setFieldValue, initialValues, handleChange, setTouched, usageoptions, provisionOptions, selectedAttributeName }) {
    const { isEmptyOrInvalidField, handleSelectedAttributeName, handleAttributeChangeUpdate, } = useFieldAttributes();
    return (
        <>
            <FieldArray name="attributeList">
                {({ push,remove }) => (
                    <Card >
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Attributes</h4>
                            <Button color="secondary" className="btn-label" onClick={() => {
                                const lastIndex = values.attributeList?.length - 1;
                                if (lastIndex === -1 || !isEmptyOrInvalidField(lastIndex, values)) {
                                    push({ type: '', name: '' });
                                    setTouched({
                                        ...touched,
                                        attributeList: {
                                            ...touched.attributeList,
                                            [lastIndex]: {
                                                type: false,
                                                name: false,
                                            },
                                        },
                                    });
                                }
                            }} >
                                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Attributes </Button>
                        </CardHeader>
                        <CardBody>
                            <div className="live-preview">
                                <div className="table-responsive">
                                    <div className="scrollable-view">
                                        <Table className="table-borderless align-middle table-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col ">Type</th>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Value</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {values.attributeList?.length === 0 ? <tr className="default-height" ><td colSpan={4} className="text-center">No Attributes</td></tr> :
                                                    values.attributeList?.map((val, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="w-lg"><SelectField
                                                                    className={(
                                                                        touched.attributeList &&
                                                                        touched.attributeList[index] &&
                                                                        !values.attributeList[index].type
                                                                    ) ||
                                                                        false
                                                                        ? 'error-input'
                                                                        : ''
                                                                    }
                                                                    name={`attributeList[${index}].type`}
                                                                    value={initialValues.attributeList.type ?
                                                                        AttributeTypeOptions?.filter((type) => [
                                                                            type.label === initialValues.attributeList[index].type
                                                                        ])
                                                                        : values.attributeList[index].type
                                                                    }
                                                                    handleChange={(val) => {
                                                                        values.attributeList[index].type = val
                                                                        setFieldValue(`attributeList[${index}].type`, val);
                                                                        setFieldTouched(`attributeList[${index}].type`, false);
                                                                    }}
                                                                    options={AttributeTypeOptions} />
                                                                    {errors.attributeList &&
                                                                        errors.attributeList[index] &&
                                                                        touched.attributeList &&
                                                                        touched.attributeList[index] &&
                                                                        touched.attributeList[index].type ? (
                                                                        <p className="text-danger">
                                                                            {errors.attributeList[index].type}
                                                                        </p>
                                                                    ) : null}
                                                                </td>
                                                                {values.attributeList[index].type.value === "Provisional Attributes" || values.attributeList[index].type.value === "Usage Attributes" ? (
                                                                    <td className="w-lg"><SelectField
                                                                        className={(
                                                                            touched.attributeList &&
                                                                            touched.attributeList[index] &&
                                                                            !values.attributeList[index].name
                                                                        ) ||
                                                                            false
                                                                            ? 'error-input'
                                                                            : ''
                                                                        }
                                                                        name={`attributeList[${index}].name`}
                                                                        value={initialValues.attributeList.name ?
                                                                            AttributeTypeOptions?.filter((name) => [
                                                                                name.label === initialValues.attributeList[index].name
                                                                            ])
                                                                            : values.attributeList[index].name
                                                                        }
                                                                        handleChange={(val) => {
                                                                            handleSelectedAttributeName(val);
                                                                            values.attributeList[index].name = val
                                                                            setFieldValue(`attributeList[${index}].name`, val);
                                                                            setFieldTouched(`attributeList[${index}].name`, false);
                                                                        }}
                                                                        options={values.attributeList[index].type.value === "Provisional Attributes" ? provisionOptions : usageoptions} />
                                                                        {errors.attributeList &&
                                                                            errors.attributeList[index] &&
                                                                            touched.attributeList &&
                                                                            touched.attributeList[index] &&
                                                                            touched.attributeList[index].name ? (
                                                                            <p className="text-danger">
                                                                                {errors.attributeList[index].name}
                                                                            </p>
                                                                        ) : null}
                                                                    </td>
                                                                ) : <td />}
                                                                {values.attributeList[index].type.value === "Provisional Attributes" ?
                                                                    <td className="w-lg">
                                                                        <InputGroup key={index} >
                                                                            <InputGroupText>
                                                                                {selectedAttributeName?.valueType}
                                                                            </InputGroupText>
                                                                            <InputField
                                                                                name={`attributeList[${index}].value`}
                                                                                className={(
                                                                                    touched.attributeList &&
                                                                                    touched.attributeList[index] &&
                                                                                    !values.attributeList[index].value
                                                                                ) ||
                                                                                    false
                                                                                    ? 'is-invalid'
                                                                                    : ''
                                                                                }
                                                                                placeholder="Enter your Value"
                                                                                type="text"
                                                                                handleChange={e => {
                                                                                    handleChange(e);
                                                                                    handleAttributeChangeUpdate(index, e);
                                                                                }}
                                                                                value={values.attributeList[index].value}
                                                                                invalid={
                                                                                    errors.attributeList &&
                                                                                        errors.attributeList[index] &&
                                                                                        touched.attributeList &&
                                                                                        touched.attributeList[index] &&
                                                                                        touched.attributeList[index].value
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                            />
                                                                            {errors.attributeList &&
                                                                                errors.attributeList[index] &&
                                                                                touched.attributeList &&
                                                                                touched.attributeList[index] &&
                                                                                touched.attributeList[index].value ? (
                                                                                <p className="text-danger">
                                                                                    {errors.attributeList[index].value}
                                                                                </p>
                                                                            ) : null}
                                                                        </InputGroup>
                                                                    </td>
                                                                    : <td />
                                                                }
                                                                {
                                                                    <td>
                                                                        <div className="hstack gap-3 fs-15">
                                                                            <Link to="#" className="link-danger" onClick={() => remove(index)} ><i data-testid="delete" className="ri-delete-bin-5-line"></i></Link>
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            <div className="d-none code-view">
                                <pre className="language-markup default-height">
                                    <code>
                                        <TablesWithoutBorders />
                                    </code>
                                </pre>
                            </div>
                        </CardBody>
                    </Card>
                )}
            </FieldArray>
        </>
    )
}