import { Card, CardHeader, CardBody, Row, Col, Label, Progress } from "reactstrap"
import { LabelText } from "../Utils/Input"
import { customerIdData } from "../Customers/constants/constantFunctionsView"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getViewPartnerListing } from "../../slices/PartnerMangement/ViewListPartner/thunk"

export const PartnerProfile = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const PartnerId = customerIdData()
    useEffect(() => {
        dispatch(getViewPartnerListing(setLoading, PartnerId))
    }, [dispatch]);
    let ViewListData = useSelector(state => state.PartnerViewList.partnerViewList)
    return (
        <Row lg={12} >
            <Col lg={6}>
                <CardBody className=" border border-2 rounded-3 mt-3 mb-3 ms-3  ">
                    <Row lg={6}>
                        <Col lg={6} >
                            <div className="container">
                                <div className="row">
                                    <div className="row mb-3" >
                                        <div className="col fs-5"><Label>Partner ID </Label></div>
                                        <div>{PartnerId}</div>
                                    </div>
                                    <div className="row mb-3" >
                                        <div className="col fs-5"><Label>Business Name </Label></div>
                                        <div >{ViewListData?.businessName}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col fs-5" ><Label>Type </Label></div>
                                        <div >{ViewListData?.type}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col fs-5" ><Label>Address </Label></div>
                                        <div >{ViewListData?.addressLine1}{ViewListData.addressLine2}</div>
                                    </div>
                                    <Col>
                                        <div className="col fs-5" ><Label>Country</Label></div>
                                        <div >{ViewListData?.country}</div>
                                    </Col>
                                    <Col>
                                        <div className="col fs-5" ><Label>State</Label></div>
                                        <div >{ViewListData?.state}</div>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="container">
                                <div className="row" >
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>Phone Number  </Label></div>
                                        <div >{ViewListData?.mobile}</div>
                                    </div>
                                    <div className="row mb-3" >
                                        <div className="col fs-5"><Label>Email Address  </Label></div>
                                        <div >{ViewListData?.emailAddress}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>Website Link </Label></div>
                                        <div>{ViewListData?.websiteHref}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>&nbsp;</Label></div>
                                        <div>&nbsp;</div>
                                    </div>
                                    <Col>
                                        <div className="col fs-5" ><Label>City</Label></div>
                                        <div >{ViewListData?.city}</div>
                                    </Col>
                                    <Col>
                                        <div className="col fs-5" ><Label>Zipcode</Label></div>
                                        <div >{ViewListData?.zipcode}</div>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Col>
            <Col lg={6}>
                <CardBody className=" border border-2 rounded-3  mt-3 mb-3 me-3" style={{ height: "359px" }}>
                    <Row lg={6}>
                        <Col lg={6}>
                            <div className="container">
                                <div className="row" >
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>Primary Contact </Label></div>
                                        <div >{ViewListData?.primaryContact?.firstName} {ViewListData?.primaryContact?.lastName}</div>
                                    </div>
                                    <div className="row mb-3" >
                                        <div className="col fs-5"><Label>Phone Number </Label></div>
                                        <div>{ViewListData?.primaryContact?.mobile}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="container">
                                <div className="row" >
                                    <div className="row mb-3">
                                        <div className="col fs-5"><Label>&nbsp;</Label></div>
                                        <div>&nbsp;</div>
                                    </div>
                                    <div className="row mb-3" >
                                        <div className="col fs-5"><Label>Email Address </Label></div>
                                        <div >{ViewListData?.primaryContact?.emailAddress}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Col>
            {!!ViewListData.characteristic ? (
                <Col lg={12}>
                    <CardBody className="border border-2 rounded-3 mb-3 me-3 ms-3">
                        <div className="card-header align-items-center d-flex">
                            <h5 className="card-title mb-0">Attributes</h5>
                        </div>
                        <div className="live-preview">
                            <Row className="mb-2 ms-1 mt-2">
                                <Col md={4}>
                                    <Row>
                                        <div className="col fs-5"><Label>Name</Label></div>
                                    </Row>
                                </Col>
                                <Col md={4}>
                                    <Row>
                                        <div className="col fs-5"><Label>Value</Label></div>
                                    </Row>
                                </Col>
                            </Row>
                            {ViewListData?.characteristic?.map((attribute, index) => (
                                <Row key={index} className="mb-3 ms-1">
                                    <Col md={4}>
                                        <Row>
                                            <div className="col">{attribute?.name}</div>
                                        </Row>
                                    </Col>
                                    <Col md={4}>
                                        <Row>
                                            <div className="col">{attribute?.value}</div>
                                        </Row>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </CardBody>
                </Col>
            ) : null}
        </Row>
    )
}