import React, { useEffect, useState, useMemo } from 'react';
import { Container, Card, CardHeader, Nav, NavItem, NavLink, CardBody, TabContent, TabPane } from 'reactstrap';
import BreadCrumb from '../Common/BreadCrumb';
import classnames from 'classnames';
import { tabChange } from '../Utils/SetDefaultRowData';
import { PartnerProfile } from './PartnerProfile';
import Invoice from './Invoice';
import Bills from './Bills';
import { PAGE_TITLES } from '../Common/constants';

function PartnerView() {
    const [activeTab, setActiveTab] = useState('1');

    return (
        <div id="csms" className="page-content">
            <Container fluid>
                <BreadCrumb title={PAGE_TITLES.VIEW_PARTNER} pageTitle={PAGE_TITLES.PARTNER_LIST} />
                <Card className="">
                    <CardHeader className="">
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => {
                                        tabChange('1', activeTab, setActiveTab);
                                    }}
                                >
                                    <i className="fas fa-home"></i>
                                    Profile
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => {
                                        tabChange('2', activeTab, setActiveTab);
                                    }}
                                    type="button"
                                >
                                    <i className="far fa-envelope"></i>
                                    Bills
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </CardHeader>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1" className="border">
                                <PartnerProfile />
                            </TabPane>
                            <TabPane tabId="2" className="border">
                            <CardBody className="border-top">
                                <Bills />
                                </CardBody>
                            </TabPane>
                        </TabContent>
                </Card>
            </Container>
        </div>
    );
}

export default PartnerView;
