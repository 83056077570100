import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    getTaskStatistics : [],
    loading : false,
    error : null,
};

const getTaskStatisticsSlice = createSlice({
    name:"getTaskStatistics",
    initialState,
    reducers: {
        fetchGetTaskStatisticsSuccess: (state,action) => {
            state.loading = false;
            state.getTaskStatistics = action.payload
        },
        fetchGetTaskStatisticsFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchGetTaskStatisticsFailure,
    fetchGetTaskStatisticsSuccess
} = getTaskStatisticsSlice.actions

export default getTaskStatisticsSlice.reducer