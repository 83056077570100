import { toast } from "react-toastify";
import { fetchEditServicesSuccess,fetchEditServicesFailure} from "./reducer";
import { setEditSuccessMessage } from "../../toastMessage/action";
import { ADD_SERVICE_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";

export const editService = (jsonObj,serviceCode, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${ADD_SERVICE_API}/${serviceCode}`, 'PATCH', jsonObj).then((response) => {
        if (response.error) {
            dispatch(fetchEditServicesFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
        if (!response.error) {
            setLoading(false)
            dispatch(fetchEditServicesSuccess(response))
            dispatch(setEditSuccessMessage(true))
            navigate('/service');
        }
    }).catch((errorResp) => { })
};