import moment from "moment"
export const formatDate = (date) => {
    const formattedDate = moment(date).format("DD MMM YYYY")
    return formattedDate
}

export const loopedFormatData = (data, date) => {
    data.forEach((value, index) => {
        const formattedDate = moment(value?.EndTime).format("DD MMM YYYY")
        date[index] = formattedDate
    })
    return date
}
export const SubscriptionsFormatData = (data, date, dateValue) => {
    data.forEach((value, index) => {
        let timeData = ""
        if (dateValue === "validFrom") {
            timeData = value?.validFrom
        }
        else {
            timeData = value?.validTo
        }
        const formattedDate = timeData === "0001-01-01T00:00:00Z" ? "Never Expiring" : moment(timeData).format("DD MMM YYYY");
        date[index] = formattedDate
    })
    return date
}


export function convertNumberOfDaysToMonthYear(numberOfDays) {
    const years = Math.floor(numberOfDays / 365)
    const months = Math.floor((numberOfDays % 365) / 30)
    const days = Math.floor((numberOfDays % 365) % 30)
    const yearsDisplay =
        years > 0 ? String(years) + String(years === 1 ? ' year ' : ' years ') : ''
    const monthsDisplay =
        months > 0 ? String(months) + String(months === 1 ? ' month ' : ' months ') : ''
    const daysDisplay = days > 0 ? String(days) + String(days === 1 ? ' day' : ' days') : ''
    return yearsDisplay + monthsDisplay + daysDisplay
}

export function getDayDiff(startDate, endDate) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const msInDay = 24 * 60 * 60 * 1000
    return Math.round(Math.abs(endDateObj - startDateObj) / msInDay)
}

export const duration = (time) => {
    const currentTime = moment();
    const targetTime = moment(time);
    const duration = moment.duration(targetTime.diff(currentTime));
    const formattedDuration = moment.utc(duration.asMilliseconds()).format("hh:mm A");
    return `${formattedDuration}`
}

export const loopedDuration = (data, timeData, timeValue) => {
    data.forEach((value, index) => {
        let time = ""
        if (timeValue === "StartTime") {
            time = value?.StartTime
        }
        else {
            time = value?.EndTime
        }
        const currentTime = moment();
        const targetTime = moment(time);
        const duration = moment.duration(targetTime.diff(currentTime));
        const formattedDuration = moment.utc(duration.asMilliseconds()).format("hh:mm A");
        timeData[index] = formattedDuration
    })
    return timeData
}

export const hourData = (data, hours) => {
    data.forEach((value, index) => {
        const StartDateObj = new Date((value?.StartTime))
        const EndDateObj = new Date((value?.EndTime))
        const difference = (EndDateObj.getTime() - StartDateObj.getTime()) / 1000
        const hourDifference = difference / 3600
        const Days = Math.floor(hourDifference / 24)
        const Remainder = hourDifference % 24
        const Hours = Math.floor(Remainder)
        const Minutes = Math.round(60 * (Remainder - Hours))
        const dayDiff = Days > 0 ? `${Days}d` : ''
        const hour = Hours > 0 ? `${Hours}h` : ''
        const minute = Minutes > 0 ? `${Minutes}m` : ''
        hours[index] = `in ${dayDiff} ${(hour)} ${(minute)}`
    })
    return hours
}

export const stationData = (reservationData, stationData, stationArray, stationValue) => {
    reservationData.forEach((value, index) => {
        stationData.forEach((value, i) => {
            if (reservationData[index].StationId === stationData[i].stationDetails.stationID) {
                if (stationValue === "Name") {
                    stationArray[index] = stationData[i].stationDetails.name
                }
                else {
                    stationArray[index] = stationData[i].stationDetails.address
                }
            }
        })
    })
    return stationArray
}
