import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cancelOrder: [],
    loading: false,
    error: null,
}

const cancelOrderSlice = createSlice({
    name: "cancelOrder",
    initialState,
    reducers: {
        fetchCancelOrderSuccess: (state, action) => {
            state.loading = false;
            state.cancelOrder = action.payload;
        },
        fetchCancelOrderFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchCancelOrderSuccess, fetchCancelOrderFailure } = cancelOrderSlice.actions;

export default cancelOrderSlice.reducer;
