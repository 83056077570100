import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Spinner } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ToastContainer } from 'react-toastify';
import ParticlesAuth from '../../pages/AuthenticationInner/ParticlesAuth';
import { Loader } from '../Utils/Loader';
import { ResetPasswordExpiryBox } from '../Utils/ResetPasswordExpiry';
import { CreateSetPassword } from '../../slices/SetPassword/thunk';
import { forgotPassword } from '../../slices/ForgotPassword/thunk';

const ForgetPassword = ({ userManager }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryString = location.search;
    const headerValue = queryString.substring(1);
    const tokens = [];
    for (const [key, value] of queryParams.entries()) {
        if (key === 'token') {
            tokens.push(value);
        }
    }
    const token = tokens[1];
    const validation = useFormik({
        initialValues: {
            UserName: '',
        },
        validationSchema: Yup.object({
            UserName: Yup.string().required('Please enter your User Name'),
        }),
        onSubmit: async (values) => {
            const jsonObj = {
                "UserName": values.UserName,
            };
            dispatch(forgotPassword(jsonObj, setLoading,navigate));
        },
    });

    document.title = 'K2charge';
    const expiryError = useSelector((state) => state.ExpiryCheck.expiryCheck);
    const shouldShowResetPasswordExpiryBox =
        expiryError?.error || (Array.isArray(expiryError) && expiryError.length === 0);

    return (
        <ParticlesAuth>
            <div className='auth-page-content'>
                <Container>
                    <ToastContainer position='top-center' />
                    {loading && <Loader />}
                    {!shouldShowResetPasswordExpiryBox && time ? (
                        <ResetPasswordExpiryBox />
                    ) : (
                        <Row className='justify-content-center'>
                            <Col md={8} lg={6} xl={5}>
                                <Card className='login-box'>
                                    <CardBody className='p-4'>
                                        <div className='text-center mt-2'>
                                            <h4 className='text-black fw-semibold'>Forgot Password?</h4>
                                            <p className='text-muted'>Enter the User Name associated with the account
                                                and we’ll send a link to reset the password. </p>
                                        </div>
                                        <div className='p-2 mt-4'>
                                            <Form onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}>
                                                <div className='mb-3'>
                                                    <Label className='form-label' htmlFor='UserName'>User Name</Label>
                                                    <div className='position-relative auth-pass-inputgroup mb-3'>
                                                        <Input
                                                            id='UserName'
                                                            name='UserName'
                                                            type={'text'}
                                                            value={validation.values.UserName || ''}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={validation.touched.UserName && !!validation.errors.UserName}
                                                            className='form-control pe-5'
                                                            placeholder='Enter UserName '
                                                        />
                                                        {validation.touched.UserName && validation.errors.UserName && (
                                                            <FormFeedback>{validation.errors.UserName}</FormFeedback>
                                                        )}

                                                    </div>
                                                </div>

                                                <Button
                                                    type='submit'
                                                    className='btn bg-secondary w-100 mt-2  fw-bolder'
                                                    disabled={loading}
                                                >
                                                    {loading ? <Spinner size='sm' className='me-2' /> : null}
                                                    Continue
                                                </Button>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default ForgetPassword;