import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    device:[],
    loading: false,
    error: null,
}

const deviceSlice = createSlice({
    name: "device",
    initialState,
    reducers: {
        fetchDeviceSucess: (state, action) => {
            state.loading = false,
                state.device = action.payload;
        },
        fetchDeviceFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchDeviceSucess,
    fetchDeviceFailure,
} = deviceSlice.actions;

export default deviceSlice.reducer;