import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editService: [],
    loading: false,
    error: null,
}

const editServiceSlice = createSlice({
    name: "editService",
    initialState,
    reducers: {
        fetchEditServicesSuccess: (state, action) => {
            state.loading = false,
            state.editService = action.payload;
        },
        fetchEditServicesFailure: (state, action) => {
            state.loading = false,
            state.error = action.payload;
        },
    },
})

export const {
    fetchEditServicesSuccess,
    fetchEditServicesFailure,
} = editServiceSlice.actions;

export default editServiceSlice.reducer;