import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Spinner
} from 'reactstrap';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { loginUser } from '../../slices/login/thunk';
import { ToastContainer } from 'react-toastify';
import { createZitadelAuth } from "@zitadel/react";

const Login = ({ userManager }) => {
  const dispatch = useDispatch();
  const [userLogin, setUserLogin] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const zitadel = createZitadelAuth(userManager);
  const environment = window.env.REACT_APP_LOGIN_ENVIRONMENT;

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userLogin.email,
      password: userLogin.password,
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your User ID'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: async (values) => {
      dispatch(loginUser(values, navigate, setLoading));
    },
  });

  const handleLogin = () => {
    setLoading(true);
    zitadel.authorize();
  };

  document.title = 'K2charge';

  return (
    <ParticlesAuth>
      <div className='auth-page-content'>
        <Container>
          <ToastContainer position='top-center' />
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card className='login-box'>
                <CardBody className='p-4'>
                  <div className='text-center mt-2'>
                    <h5 className='text-main-color'>Welcome Back !</h5>
                    <p className='text-muted'>Sign in to continue to K2charge</p>
                  </div>
                  <div className='p-2 mt-4'>
                    {environment === "Native" ? (
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action='#'
                      >
                        <div className='mb-3'>
                          <Label htmlFor='email' className='form-label'>
                            User ID
                          </Label>
                          <Input
                            name='email'
                            className='form-control'
                            placeholder='Enter User ID'
                            type='text'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={
                              validation.touched.email && validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className='mb-3'>
                          <Label className='form-label' htmlFor='password-input'>
                            Password
                          </Label>
                          <div className='position-relative auth-pass-inputgroup mb-3'>
                            <Input
                              name='password'
                              value={validation.values.password || ''}
                              type={showPassword ? 'text' : 'password'}
                              className='form-control pe-5'
                              placeholder='Enter Password'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none'
                              onClick={() => setShowPassword(!showPassword)}
                              type='button'
                              id='password-addon'
                            >
                              <i className='ri-eye-fill align-middle'></i>
                            </button>
                          </div>
                        </div>
                        <div className='mt-1'>
                          <a href="/forgot-password" className='text-main-color text-decoration-underline'>
                            Forgot Password?
                          </a>
                          <Button
                            className='btn bg-secondary w-100 mt-2'
                            type='submit'
                            disabled={loading}
                          >
                            {loading ? (
                              <Spinner size='sm' className='me-2'>
                                Loading...
                              </Spinner>
                            ) : null}
                            Sign In
                          </Button>
                        </div>
                      </Form>
                    ) : (
                      <Form>
                        <div className='mt-1'>
                          <Button
                            className='btn bg-secondary w-100 mt-2'
                            type='button'
                            onClick={handleLogin}
                            disabled={loading}
                          >
                            {loading ? (
                              <Spinner size='sm' className='me-2'>
                                Loading...
                              </Spinner>
                            ) : null}
                            Sign In with Zitadel
                          </Button>
                        </div>
                      </Form>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

export default Login;
