import { fetchPartnerViewListFailure, fetchPartnerViewListSuccess } from "./reducer"

import { PARTNER_ALLLISTING_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";

export const getViewPartnerListing = (setLoading, PartnerId) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${PARTNER_ALLLISTING_API}/${PartnerId}`, 'GET')
        setLoading(false)
        dispatch(fetchPartnerViewListSuccess(response));
    } catch (error) {
        dispatch(fetchPartnerViewListFailure(error.message));
    }
};

