import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import profileimg1 from "../../assets/images/users/user-dummy-img.jpg";
import { createSelector } from 'reselect';
import { authConfig, UserManagerInstance } from './functions/authConfig';
import { useNavigate } from 'react-router-dom';
import { fetchDocumentsForCustomer } from '../../slices/customer/documentlist/thunk';
import { viewDoc } from '../../slices/customer/documentview/thunk';
import { systemUserLogoViewDoc } from '../../slices/Logo/SystemUserViewLogo/thunk';
import { ProfilelogoDoc } from '../../slices/Logo/ProfileLogo/thunk';

const ProfileDropdown = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const userId = localStorage.getItem('userId')
    const roleName = localStorage.getItem("roleName")
    const [loading, setLoading] = useState(false)
    const [profileData, setProfileData] = useState()
    const permissions = useSelector((state) => state.permissions.permissions);
    const profilePermission = permissions.find((resource) => resource.resourceName === 'profileManagement');

    function clearAuth() {
        const environment = window.env.REACT_APP_LOGIN_ENVIRONMENT;
        if (environment === "Native") {
            window.location.href = process.env.PUBLIC_URL + "/logout";
        } else {
            UserManagerInstance.signoutRedirect({ post_logout_redirect_uri: authConfig.post_logout_redirect_uri });
        }
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('permissionss');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('roleName');
        localStorage.removeItem('operatorId');
        localStorage.removeItem('customerId');
        localStorage.removeItem('operatorName');
        localStorage.removeItem('userName');
        localStorage.removeItem('PartnerId');
        localStorage.removeItem('searchResponses');
    }

    const handleLogout = () => {
        clearAuth()
        // localStorage.clear()
        // window.location.href = process.env.PUBLIC_URL + "/logout";
    };

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    const userName = localStorage.getItem('userName') || '';
    const customerId = localStorage.getItem('customerId') || '';

    useEffect(() => {
        if (roleName === "telcoAdmin" || roleName === "operatorAdmin" || roleName === "genericAdmin" || roleName === "ISPOperatorAdmin" || roleName === "providerOperationUser" || roleName === "operatorOperationUser" || roleName === "operatorBusinessUser" || roleName === "telcoOperationUser" || roleName === "telcoBusinessUser" || roleName === "genericOperationUser" || roleName === "genericBusinessUser" || roleName === "ISPOperationUser" || roleName === "ISPBusinessUser") {
            let partnerOrCustomer = "User"
            dispatch(ProfilelogoDoc(setLoading, userId, "logo", partnerOrCustomer, setProfileData));
        }
        else if (roleName === "systemUser") {
            let partnerOrCustomer = "User"
            dispatch(systemUserLogoViewDoc(setLoading, userId, "logo", partnerOrCustomer, setProfileData));
        }
        else if (roleName === "Customer"){
            let partnerOrCustomer = "Customer"
            dispatch(ProfilelogoDoc(setLoading, customerId, "logo", partnerOrCustomer, setProfileData));
        }
        else if (roleName === "ISPPartnerAdmin") {
            let partnerOrCustomer = "Partner"
            const partnerId = localStorage.getItem('PartnerId')
            dispatch(viewDoc(setLoading, partnerId, "logo", partnerOrCustomer, setProfileData));
        }
    }, [dispatch])
    let base64ImageData = []
    let profile = useSelector((state) => state.ViewDocument.documentView)
    let ViewDocument = useSelector((state) => state.ProfileLogo.profileLogo);
    let systemUserProfile = useSelector((state) => state.SystemLogoView.logoView)
    if (roleName === "systemUser") {
        base64ImageData = systemUserProfile?.filePath
    }
    else if (roleName === "Customer" || roleName === "telcoAdmin" || roleName === "operatorAdmin" || roleName === "genericAdmin" || roleName === "ISPOperatorAdmin" || roleName === "providerOperationUser" || roleName === "operatorOperationUser" || roleName === "operatorBusinessUser" || roleName === "telcoOperationUser" || roleName === "telcoBusinessUser" || roleName === "genericOperationUser" || roleName === "genericBusinessUser" || roleName === "ISPOperationUser" || roleName === "ISPBusinessUser"){
        base64ImageData = ViewDocument?.filePath
    }
    else {
        base64ImageData = profile?.filePath
    }
    // const operatorId = localStorage.getItem('operatorId') || '';
    // const initialValues = [];
    // const operatorOptions=[];
    // const OperatorStatusOptions=[];
    // useEffect(() => {
    //         dispatch(fetchOperatorbyId(setLoading, operatorId, initialValues, operatorOptions, OperatorStatusOptions));
    // }, [dispatch]);

    // const operatorData = useSelector((state) => state?.GetOperator?.GetOperatorId ?? null);
    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn shadow-none">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user me-2" src={profileData ? base64ImageData : profileimg1} alt="Header Avatar" />
                        <span className="text-center ms-xl-2">
                            <span className="d-block fw-medium user-name-text">
                                {userName}
                            </span>
                            {/* <span className="d-block fw-medium user-name-text">
                                {operatorNameFinal}
                            </span> */}
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome {localStorage.getItem('userName')}!</h6>
                    {/* <DropdownItem ><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle">Profile</span></DropdownItem> */}
                    <DropdownItem ><i
                        className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Help</span></DropdownItem>
                    {(roleName !== 'ISPPartnerAdmin' || profilePermission) && (
                        <>
                            <DropdownItem onClick={() => {
                                if (roleName === "Customer") {
                                    navigate("/customer/profile")
                                }
                                else {
                                    navigate(`/profile`)
                                }
                            }} ><i
                                className="ri-profile-line text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle"  >Profile</span></DropdownItem>
                        </>
                    )}
                    <div className="dropdown-divider"></div>

                    {/* <DropdownItem onClick={() => navigate(`/edit-user/${userId}`, { state: { changePassword: true } })}><i
                        className="ri-lock-password-line text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Change Password</span></DropdownItem>
                    <div className="dropdown-divider"></div> */}
                    {/* <DropdownItem><i
                        className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Balance : <b>$00.00</b></span></DropdownItem> */}
                    <DropdownItem onClick={handleLogout}>
                        <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle" data-key="t-logout">Logout</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;