export const initialValues = {
  idTokenType: "",
  startTime: "",
  endTime: "",
  station: ""
}

export const connectorTypeOptions = [
  { value: 'type1', label: 'Type 1' },
  { value: 'type2', label: 'Type 2' },
];

export const evseoptions = [
  { value: 'evse1', label: 'EVSE 1' },
  { value: 'evse2', label: 'EVSE 2' },
];
