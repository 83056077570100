import { handleLoginFailure, handleLoginSuccess } from "../../Routes/authUtils";
import { fetchPermissions } from "../../permissionsSlice";
import { LOGIN_API } from "../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";

import 'react-toastify/dist/ReactToastify.css';

export const loginUser = (values,navigate,setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const requestData = {
      userName: values.email,
      password: values.password,
    };

    const response = await determineBaseUrlAndPerformApiCall(`${LOGIN_API}`, 'POST', requestData);

    if (response && response.accessToken) {
      handleLoginSuccess(response, dispatch, setLoading,navigate);
    } else {
      handleLoginFailure(response.error.message,setLoading);
    }
  } catch (error) {
    handleLoginFailure(error, setLoading);
  }
};
