import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    provisional: [],
    loading: false,
    error: null,
}

const provisionSlice = createSlice({
    name: "provisional",
    initialState,
    reducers: {
        fetchProvisionSucess: (state, action) => {
            state.loading = false,
                state.provisional = action.payload;
        },
        fetchProvisionFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchProvisionSucess,
    fetchProvisionFailure,
} = provisionSlice.actions;

export default provisionSlice.reducer;