import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    updateUser : [],
    loading : false,
    error : null,
};

const updateUserSlice = createSlice({
    name:"updateUser",
    initialState,
    reducers: {
        fetchUpdateUserSuccess: (state,action) => {
            state.loading = false;
            state.updateUser = action.payload
        },
        fetchUpdateUserFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchUpdateUserSuccess,
    fetchUpdateUserFailure
} = updateUserSlice.actions

export default updateUserSlice.reducer