import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    documentList : [],
    loading : false,
    error : null,
};

const fetchDocumentListSlice = createSlice({
    name:"documentList",
    initialState,
    reducers: {
        fetchDocumentListSuccess: (state,action) => {
            state.loading = false;
            state.documentList = action.payload
        },
        fetchDocumentListFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        },
        resetDocumentListState: (state) => {
            state.loading = false;
            state.documentList = [];
            state.error = null;
        },
    }
})

export const {
    fetchDocumentListSuccess,
    fetchDocumentListFailure,
    resetDocumentListState
} = fetchDocumentListSlice.actions

export default fetchDocumentListSlice.reducer