import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    addUom : [],
    loading : false,
    error : null,
};

const addUomSlice = createSlice({
    name:"addUom",
    initialState,
    reducers: {
        fetchAddUomSuccess: (state,action) => {
            state.loading = false;
            state.addUom = action.payload;
        },
        fetchAddUomFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchAddUomSuccess,
    fetchAddUomFailure
} = addUomSlice.actions

export default addUomSlice.reducer