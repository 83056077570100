import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editDevice: [],
    loading: false,
    error: null,
}

const editDeviceSlice = createSlice({
    name: "editDevice",
    initialState,
    reducers: {
        fetchEditDeviceSuccess: (state, action) => {
            state.loading = false,
            state.editDevice = action.payload;
        },
        fetchEditDeviceFailure: (state, action) => {
            state.loading = false,
            state.error = action.payload;
        },
    },
})
export const {
    fetchEditDeviceSuccess,
    fetchEditDeviceFailure,
} = editDeviceSlice.actions;

export default editDeviceSlice.reducer;