import { MenuItem } from '@mui/material';
import { navigateToSubscribe } from './constants/DocumentTableFunctions';

export const CustomerListRowOptions = ({ dispatch, setLoading, setRenderSimulate, navigate, simulateOrPreview, fetchCustomerDetails, storeRow, setDefault, closeMenu, row, getCustomerData, setActiveTab, setIndex, index, customerPermission, orderPermission, stationPermission, reservationPermission, setMessageForPopUp, toggleModal, setCustomerId }) => {
    return (
        [
            customerPermission && customerPermission.permissionList.includes('view') ? <MenuItem className='row-options' key="view" onClick={() => { simulateOrPreview = "View"; navigate(`/view-customer/${row.original.CustomerId}`) }}>
                <i className='ri  ri-account-circle-line me-2' /> View
            </MenuItem> : null,
            customerPermission && customerPermission.permissionList.includes('update') ? <MenuItem className='row-options' key="edit" onClick={() => { navigate(`/update-customer/${row.original.CustomerId}`) }}>
                <i className='bx bxs-edit me-2' /> Edit
            </MenuItem> : null,
            <MenuItem className='row-options' key="delete" onClick={(e) => {
                simulateOrPreview = "Simulate"
                fetchCustomerDetails(dispatch, setLoading, setRenderSimulate, row, simulateOrPreview)
                storeRow(row, setIndex, index), setDefault(row.index, setActiveTab)
                closeMenu()
            }}>
                <i className=' bx bx-info-circle me-2' /> Simulate
            </MenuItem>,
            <MenuItem className='row-options' key="preview" onClick={(e) => {
                simulateOrPreview = "Preview"
                e.stopPropagation();
                getCustomerData(row, simulateOrPreview, setLoading, stationPermission, reservationPermission, customerPermission, setRenderSimulate, dispatch)
                row.toggleExpanded(); storeRow(row, setIndex, index), setDefault(row.index, setActiveTab), closeMenu()
            }}>
                <i className="ri-survey-line me-2" /> Preview
            </MenuItem>,
            orderPermission && orderPermission.permissionList.includes('create') ? <MenuItem className='row-options' key="subscribe" onClick={() => {
                navigateToSubscribe(row, dispatch, navigate)
            }}>
                <i className="ri-add-circle-line me-2 " ></i> Subscribe
            </MenuItem> : null,
            customerPermission && customerPermission.permissionList.includes('delete') ? <MenuItem className='row-options'
                key="close"
                onClick={() => {
                    setMessageForPopUp(`Are you sure you want to Delete this Customer "${row.original.CustomerId}"?`)
                    toggleModal(row, "single");
                    setCustomerId(row.original.CustomerId)
                    closeMenu();
                }}
            >
                <i className="ri ri-delete-bin-5-line me-2" />{" "}
                Close
            </MenuItem> : null,
        ]

    )
}

