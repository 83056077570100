import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export default function CommonDateTimePicker({
    className,
    value, format,
    name,style,
    handleChange,minDate,
    disabled, minDateTime,views
}) {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
                name={name}
                className={className}
                disabled={disabled}
                views={views}
                sx={style}
                onChange={handleChange}
                value={value}
                format={format}
                minDateTime={minDateTime}
                minDate={minDate}
            />
        </LocalizationProvider>
    )
}