import { toast } from "react-toastify";
import { fetchResetPasswordCreateSucess, fetchResetPasswordCreateFailure } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { RESET_CREATE_PASSWORD_API } from "../../constants/constants";

export const CreateResetPassword = (jsonObj, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${RESET_CREATE_PASSWORD_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchResetPasswordCreateSucess(response))
            toast.success(`${response}`, {
                onClose: () => navigate('/')
            });
        }
        if (response.error) {
            dispatch(fetchResetPasswordCreateFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}