import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    simulate : [],
    offerCode: [],
    loading : false,
    error : null,
};

const simulateSlice = createSlice({
    name:"simulate",
    initialState,
    reducers: {
        fetchSimulateSuccess: (state,action) => {
            state.loading = false;
            state.simulate = action.payload
        },
        fetchSimulateFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        },
        fetchOfferSuccess:  (state,action) => {
            state.offerCode = action.payload
        },
    }
})

export const {
    fetchSimulateFailure,
    fetchSimulateSuccess,
    fetchOfferSuccess
} = simulateSlice.actions

export default simulateSlice.reducer