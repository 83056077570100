import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getAttribute: [],
    getSearchableAttribute:[],
    getNonSearchableAttribute:[],
    loading: false,
    error: null,
}

const getAttributeSlice = createSlice({
    name: "getAttribute",
    initialState,
    reducers: {
        fetchGetAttributeSucess: (state, action) => {
            state.loading = false
            state.getAttribute = action.payload;
        },
        fetchSearchableAttributeSucess: (state, action) => {
            state.loading = false
            state.getSearchableAttribute = action.payload;
        },
        fetchNonSearchableAttributeSucess: (state, action) => {
            state.loading = false
            state.getNonSearchableAttribute = action.payload;
        },
        fetchGetAttributeFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchGetAttributeSucess,fetchNonSearchableAttributeSucess,fetchSearchableAttributeSucess,
    fetchGetAttributeFailure,
} = getAttributeSlice.actions;

export default getAttributeSlice.reducer;