import { DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchDocumentUpdateFailure,fetchDocumentUpdateSuccess } from "./reducer";
import { toast } from "react-toastify";
import { setDocumentSuccessMessage } from "../../toastMessage/action";
import { fetchDocumentsForCustomer } from "../documentlist/thunk";

export const updateDoc = (jsonObj,setLoading,customerId) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API}`,'PUT',jsonObj).then((response)=>{
        if (!response.error) {
            setLoading(false)
            dispatch(fetchDocumentUpdateSuccess(response))
            dispatch(fetchDocumentsForCustomer(setLoading,customerId))
            toast.success(
                'Document Updated Successfully'
            )
            dispatch(setDocumentSuccessMessage(true))
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchDocumentUpdateFailure(response.error.message))
        }
        setLoading(false)
    }).catch((error)=>{})
}