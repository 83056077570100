import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    updateAttribute : [],
    loading : false,
    error : null,
};

const updateAttributeSlice = createSlice({
    name:"updateAttribute",
    initialState,
    reducers: {
        fetchUpdateAttributeSuccess: (state,action) => {
            state.loading = false;
            state.updateAttribute = action.payload
        },
        fetchUpdateAttributeFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchUpdateAttributeSuccess,
    fetchUpdateAttributeFailure
} = updateAttributeSlice.actions

export default updateAttributeSlice.reducer