import { Card, CardBody, CardHeader, Col, FormFeedback, FormGroup, Row } from "reactstrap"
import { LabelText } from "../Utils/Input"
import InputField from "../Utils/InputField"
import { handleChangeForImsi } from "./constants/HandleChangeFunctions"
import { useState } from "react"
import { handleBlurForAttributes, handleInputChange, handleKeyPress, handleSelectInputChange } from "./constants/TelcoSubmitFunctions"
import SelectField from "../Utils/SelectField"

export const Identifiers = ({ identifiers, setFieldValue, isEdit, handleBlur, values, touched, errors, handleChange, roleName, searchableValues, setSearchableValues, searchableFormErrorsValues }) => {
    return (
        <>
            {identifiers?.length ? <Card>
                <CardHeader><h4 className="card-title mb-0 flex-grow-1">Identifiers</h4></CardHeader>
                <CardBody>
                    <Row>
                        {identifiers?.map((value, index) => {
                            const attrValue = searchableValues.find(attr => attr.name === value.name) || {};
                            const checkIfKeyIsPresentInArray = () => searchableFormErrorsValues && searchableValues && Object.keys(searchableFormErrorsValues).some(key => searchableValues.some(element => element.name === key));
                            const check = checkIfKeyIsPresentInArray()
                            const convertedValue = value?.values ? value?.values.map(item => ({
                                label: item,
                                value: item
                            })) : [];
                            return (
                                <Col xxl={3} md={6} key={value.name}>
                                    <FormGroup>
                                        <LabelText important={value.mandatory && "*"} className={value.mandatory && "text-danger"} displayText={value.name} />
                                        {(value?.values === null || !value?.values?.length) ? <InputField
                                            type={"text"} onKeyDown={(e) => handleKeyPress(e, value?.valueType)}
                                            value={searchableValues[index]?.value}
                                            maxLength={value?.maxValue}
                                            minLength={value?.minValue}
                                            disabled={((searchableValues[index]?.name === "imsi" || searchableValues[index]?.name === "msisdn") && isEdit) ? true : false}
                                            handleChange={(e) => handleInputChange(value.name, e.target.value, setSearchableValues)}
                                            handleBlur={() => handleBlurForAttributes(value.name, setSearchableValues)}
                                            invalid={(value.mandatory && searchableValues[index]?.value == "" && searchableValues[index]?.touched) || (check && searchableValues[index]?.value == "" && value.mandatory) || (value.mandatory && check && value.valueType !== "list" && searchableValues[index]?.value.length < value.minValue)}
                                        /> : <SelectField
                                            className={(value.mandatory && searchableValues[index]?.value == "" && searchableValues[index]?.touched) || (check && searchableValues[index]?.value == "" && value.mandatory) || false
                                                ? 'error-input'
                                                : ""}
                                            options={convertedValue}
                                            value={searchableValues[index]?.value ? convertedValue.filter((item)=>item.value === searchableValues[index]?.value) :searchableValues[index]?.value}
                                            handleChange={(e) => { handleSelectInputChange(value.name, e, setSearchableValues) }} />}
                                        {((value.mandatory && searchableValues[index]?.value === "" && searchableValues[index]?.touched) ||
                                            (check && searchableValues[index]?.value === "" && value.mandatory)) ? (
                                            <p className="validation-font-size text-danger">Please fill out this field.</p>
                                        ) : (value.mandatory && check && value.valueType !== "list" && searchableValues[index]?.value.length < value.minValue) ? (
                                            <p className="validation-font-size text-danger">Minimum length is {value.minValue} characters.</p>
                                        ) : (value.mandatory && check && value.valueType !== "list" && searchableValues[index]?.value.length > value.maxValue) ? (
                                            <p className="validation-font-size text-danger">Maximum length is {value.maxValue} characters.</p>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            );
                        })}

                    </Row>
                </CardBody>
            </Card>:null}
        </>
    )
}