import React from 'react';
import { Card, CardHeader, CardBody, Label, Nav, NavItem, NavLink, Row, Col, TabContent, TabPane, } from 'reactstrap';
import classnames from "classnames";
import EvseList from './EvseList';
import ComponentList from './ComponentList';
import stationReservation from './StationReservation';
import StationReservation from './StationReservation';

const Preview = ({ row, activeTab, tabChange }) => (
    <Card>
        <Label >{row.original.stationDetails.name}</Label>
        <Label className='fs-2'>{row.original.stationDetails.stationID}</Label>
        <CardHeader>
            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                            tabChange("1");
                        }}>
                        <i className="fas fa-home"></i>
                        Information
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="#"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                            tabChange("2");
                        }}
                        type="button">
                        <i className="far fa-user"></i>
                        Component
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="#"
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                            tabChange("3");
                        }}
                        type="button">
                        <i className="far fa-user"></i>
                        Reservation
                    </NavLink>
                </NavItem>
            </Nav>
        </CardHeader>
        <CardBody className="p-4">
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col lg={12}>
                            <div className="mb-3 align-items-sm-center ">
                                <i className="bx bx-map fs-4 me-1 "></i>
                                {row.original.stationDetails.address}<br></br><br></br>
                                <Label className='text-primary text-decoration-underline text-decoration-overline   overflow-x-auto'>Station Availability</Label><br></br><br></br>
                                <Label className='fs-5'>EVSE LIST :</Label>
                                <EvseList stationID={row.original.stationDetails.stationID} /> </div>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col lg={12}>
                            <div className="mb-3">
                                <Label className="form-label fs-5">
                                    COMPONENT LIST: </Label>
                                <ComponentList />
                            </div>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="3">
                    <StationReservation stationID={row.original.stationDetails.stationID} />
                </TabPane>
            </TabContent>
        </CardBody>
        <TabContent></TabContent>
    </Card>
);

export default Preview;