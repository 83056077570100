import { setSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";
import { fetchcreateConfigOperatorSucess, fetchcreateConfigOperatorFailure } from "./reducer";
import { CREATE_CONFIG_OPERATOR } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { uploadDocument } from "../../customer/documentupload/thunk";

export const createConfigOperator = (jsonObj, setLoading,navigate) => (dispatch) => {
    setLoading(true)
    const roleName = localStorage.getItem("roleName")
    determineBaseUrlAndPerformApiCall(`${CREATE_CONFIG_OPERATOR}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchcreateConfigOperatorSucess(response))
            navigate('/operator-listing');
        }
        if (response.error) {
            dispatch(fetchcreateConfigOperatorFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}
