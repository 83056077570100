import { toast } from "react-toastify";
import { fetchDeleteOfferSuccess, fetchDeleteOfferFailure } from "./reducer";
import api, { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { DELETE_OFFER_API } from "../../constants/constants";
import { fetchAllOffers } from "../thunk";

export const deleteOffer = (offerCode, setLoading) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${DELETE_OFFER_API}/${offerCode}`,'DELETE').then((response) => {
        if (response.error) {
            dispatch(fetchDeleteOfferFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
        if (!response.error) {
            dispatch(fetchAllOffers(setLoading))
            dispatch(fetchDeleteOfferSuccess(response))
            setLoading(false)
            toast.success(`${offerCode} Offer Deleted Successfully`)
        }

    }).catch((errorResp) => {
    })
}