import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deletePolicies: [],
    loading: false,
    error: null,
}

const deletePoliciesSlice = createSlice({
    name: "deletePolicies",
    initialState,
    reducers: {
        fetchDeletePoliciesSuccess: (state, action) => {
            state.loading = false
            state.deletePolicies = action.payload;
        },
        fetchDeletePoliciesFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchDeletePoliciesSuccess,
    fetchDeletePoliciesFailure,
} = deletePoliciesSlice.actions;

export default deletePoliciesSlice.reducer;