const setEmptyValues = (obj, prefix, idx, fields) => {
    fields.forEach(field => {
        obj[`${prefix} ${field} ${idx}`] = 'None'; 
    });
}


export const exportDataForCustomer = (customer = []) => {
    if (!Array.isArray(customer)) {
        console.error("Expected 'customer' to be an array but received:", customer);
        return []; 
    }

    let customerDataArray = [];
    let maxAttributes = Math.max(...customer.map(value => value.AttributeList ? value.AttributeList.length : 0));

    customer.forEach((value) => {
        let customerObj = {
            "Customer ID": value.CustomerId,
            "Full Name": value.Name,
            "Email Address": value.Email,
            "Mobile": value.Mobile,
            "Address": value.Address,
        };

        if (value.AttributeList && value.AttributeList.length) {
            new Array(maxAttributes).fill().forEach((_, idx) => {
                if (idx < value.AttributeList.length) {
                    const attribute = value.AttributeList[idx];
                    customerObj[`Attribute Name ${idx}`] = attribute.Name;
                    customerObj[`Attribute Value ${idx}`] = attribute.Value;
                } else {
                    setEmptyValues(customerObj, 'Attribute', idx, ['Name', 'Value']);
                }
            });
        } else {
            new Array(maxAttributes).fill().forEach((_, idx) => {
                setEmptyValues(customerObj, 'Attribute', idx, ['Name', 'Value']);
            });
        }

        customerDataArray.push(customerObj);
    });

    customerDataArray.sort((a, b) => {
        let customerIdA = parseInt(a["Customer ID"].replace('CUST_', ''));
        let customerIdB = parseInt(b["Customer ID"].replace('CUST_', ''));

        if (customerIdA < customerIdB) return -1;
        if (customerIdA > customerIdB) return 1;
        return 0;
    });

    return customerDataArray;
}


