import React from 'react';
import { Card, CardBody, CardHeader, Button, Table } from 'reactstrap';
import { FieldArray } from 'formik';
import { TablesWithoutBorders } from "../../pages/Tables/BasicTables/BasicTablesCode";
import { Link } from "react-router-dom";
import InputField from '../Utils/InputField';

export default function Attribute({
  values,
  touched,
  errors,
  handleChange,
  setTouched,
  handleChangeForAttributeValue,
  isEmptyOrInvalidField,
  deviceID,
  isEdit
}) {
  return (
    <FieldArray name="attributeList">
      {({ push, remove }) => (
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Rules</h4>
            <Button
              color="secondary"
              className="btn-label"
              onClick={() => {
                const lastIndex = values.attributeList.length - 1;
                if (lastIndex === -1 || !isEmptyOrInvalidField(lastIndex, values)) {
                  const newRangeStarts = lastIndex === -1 ? (0).toString(): (parseInt(values.attributeList[lastIndex].rangeEnds, 10) + 1).toString();
                  push({ rangeStarts: newRangeStarts, rangeEnds: "", statusLabel: "" });
                  setTouched({
                    ...touched,
                    attributeList: {
                      ...touched.attributeList,
                      [lastIndex + 1]: {
                        rangeStarts: false,
                        rangeEnds: false,
                        statusLabel: false
                      },
                    },
                  });
                }
              }}
            >
              <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Rule
            </Button>
          </CardHeader>
          <CardBody>
            <div className="live-preview">
              <div className="table-responsive">
                <div className="scrollable-view">
                  <Table className="table-borderless align-middle table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Range Starts<span className="text-danger">*</span></th>
                        <th scope="col">Range Ends<span className="text-danger">*</span></th>
                        <th scope="col">Status Label<span className="text-danger">*</span></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.attributeList.length === 0 ? (
                        <tr className="default-height">
                          <td colSpan={4} className="text-center">No Attributes</td>
                        </tr>
                      ) : (
                        values.attributeList.map((val, index) => (
                          <tr key={index}>
                            <td className="w-lg">
                              <InputField
                                name={`attributeList[${index}].rangeStarts`}
                              
                                className={
                                  (touched.attributeList &&
                                    touched.attributeList[index] &&
                                    errors.attributeList &&
                                    errors.attributeList[index] &&
                                    touched.attributeList[index].rangeStarts) 
                                    ? "is-invalid" 
                                    : ""
                                }
                                placeholder="Enter Range Starts"
                                type="text"
                                handleChange={handleChangeForAttributeValue(handleChange)}
                                value={values.attributeList[index].rangeStarts || ""}
                                disabled={true}
                                invalid={
                                  errors.attributeList &&
                                  errors.attributeList[index] &&
                                  touched.attributeList &&
                                  touched.attributeList[index] &&
                                  errors.attributeList[index].rangeStarts
                                    ? true
                                    : false
                                }
                                readOnly 
                              />
                              {errors.attributeList &&
                                errors.attributeList[index] &&
                                touched.attributeList &&
                                touched.attributeList[index] &&
                                touched.attributeList[index].rangeStarts && (
                                  <p className="text-danger">
                                    {errors.attributeList[index].rangeStarts}
                                  </p>
                              )}
                            </td>
                            <td>
                              <InputField
                                name={`attributeList[${index}].rangeEnds`}
                                className={
                                  (touched.attributeList &&
                                    touched.attributeList[index] &&
                                    errors.attributeList &&
                                    errors.attributeList[index] &&
                                    touched.attributeList[index].rangeEnds) 
                                    ? "is-invalid" 
                                    : ""
                                }
                                placeholder="Enter Range Ends"
                                type="number"
                                handleChange={handleChangeForAttributeValue(handleChange)}
                                value={values.attributeList[index].rangeEnds || ""}
                                 
                                invalid={
                                  errors.attributeList &&
                                  errors.attributeList[index] &&
                                  touched.attributeList &&
                                  touched.attributeList[index] &&
                                  errors.attributeList[index].rangeEnds
                                    ? true
                                    : false
                                }
                              />
                              {errors.attributeList &&
                                errors.attributeList[index] &&
                                touched.attributeList &&
                                touched.attributeList[index] &&
                                touched.attributeList[index].rangeEnds && (
                                  <p className="text-danger">
                                    {errors.attributeList[index].rangeEnds}
                                  </p>
                              )}
                            </td>
                            <td>
                              <InputField
                                name={`attributeList[${index}].statusLabel`}
                                className={
                                  (touched.attributeList &&
                                    touched.attributeList[index] &&
                                    errors.attributeList &&
                                    errors.attributeList[index] &&
                                    touched.attributeList[index].statusLabel) 
                                    ? "is-invalid" 
                                    : ""
                                }
                                placeholder="Enter Status Label"
                                type="text"
                                handleChange={handleChangeForAttributeValue(handleChange)}
                                value={values.attributeList[index].statusLabel || ""}
                                invalid={
                                  errors.attributeList &&
                                  errors.attributeList[index] &&
                                  touched.attributeList &&
                                  touched.attributeList[index] &&
                                  errors.attributeList[index].statusLabel
                                    ? true
                                    : false
                                }
                              />
                              {errors.attributeList &&
                                errors.attributeList[index] &&
                                touched.attributeList &&
                                touched.attributeList[index] &&
                                touched.attributeList[index].statusLabel && (
                                  <p className="text-danger">
                                    {errors.attributeList[index].statusLabel}
                                  </p>
                              )}
                            </td>
                            {!deviceID[index] && (
                              <td>
                                <div className="hstack gap-3 fs-15">
                                  <Link
                                    to="#"
                                    className="link-danger"
                                    onClick={() => remove(index)}
                                  >
                                    <i data-testid="delete" className="ri-delete-bin-5-line"></i>
                                  </Link>
                                </div>
                              </td>
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="d-none code-view">
              <pre className="language-markup default-height">
                <code>
                  <TablesWithoutBorders />
                </code>
              </pre>
            </div>
          </CardBody>
        </Card>
      )}
    </FieldArray>
  );
}