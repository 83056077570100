export const typevalue = {
    name: "isCurrency",
    value: "Resource",
    options: [
        { value: true, label: 'Monetary' },
        { value: false, label: 'Non-Monetary' },
    ]
};

export const Formvalues = {
    isCurrency: '',
    name: "",
    resourceId: "",
    currencyCode: "",
    ceiling: "",
    floor: "",
    uom:'',
};
