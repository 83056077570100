import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getselectDevice } from '../../../slices/orderManagement/CreateOrder/SelectDevice/thunk';

export const useDeviceData = (setLoading) => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(getselectDevice(setLoading));
    }, [dispatch, setLoading]);

    const SelectDeviceData = useSelector((state) => state.SelectedDevice.device);
    const idArray = SelectDeviceData?.map((val) => ({
        value: val?.deviceId,
        label: val?.deviceId
    }));

    return idArray;
};
