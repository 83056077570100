import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    aggregateResources: [],
    loading: false,
    error: null,
}

const aggregateResourcesSlice = createSlice({
    name: "aggregateResources",
    initialState,
    reducers: {
        fetchAggregateResourcesStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchAggregateResourcesSuccess: (state, action) => {
            state.loading = false,
                state.aggregateResources = action.payload;
        },
        fetchAggregateResourcesFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})
export const {
    fetchAggregateResourcesStart,
    fetchAggregateResourcesSuccess,
    fetchAggregateResourcesFailure,
} = aggregateResourcesSlice.actions;

export default aggregateResourcesSlice.reducer;