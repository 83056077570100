import { fetchDeleteAttributeFailure, fetchDeleteAttributeSuccess } from "./reducer"
import { toast } from "react-toastify";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { CREATE_EDIT_GET_ATTRIBUTE_API } from "../../constants/constants";
import { getAllAttribute } from "../../services/addservice/provisionalattribute/thunk";

export const deleteAttribute = (name, setLoading) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${CREATE_EDIT_GET_ATTRIBUTE_API}/${name}`, 'DELETE').then((response) => {
        if (!response.error) {
            dispatch(getAllAttribute(setLoading));
            dispatch(fetchDeleteAttributeSuccess(response))
            setLoading(false)
            toast.success(`Attribute Deleted Successfully`)
        }
        if (response.error) {
            dispatch(fetchDeleteAttributeFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}