import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    createorder: [],
    loading: false,
    error: null,
}

const createOrderSlice = createSlice({
    name: "createorder",
    initialState,
    reducers: {
        fetchCreateOrderSuccess: (state, action) => {
            state.loading = false;
            state.createorder = action.payload;
        },
        fetchCreateOrderFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchCreateOrderSuccess, fetchCreateOrderFailure } = createOrderSlice.actions;

export default createOrderSlice.reducer;
