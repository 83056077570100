import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteresources: [],
    loading: false,
    error: null,
}

const deleteresourcesSlice = createSlice({
    name: "deleteresources",
    initialState,
    reducers: {
        fetchdeleteresourceSuccess: (state, action) => {
            state.loading = false,
                state.deleteresources = action.payload;
        },
        fetchdeleteresourceFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchdeleteresourceSuccess,
    fetchdeleteresourceFailure,
} = deleteresourcesSlice.actions;

export default deleteresourcesSlice.reducer;