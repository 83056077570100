import { Box } from '@mui/material';
import { getCellStyle } from '../../../Utils/DecideColor';
import { RatePlanOrAttributesColumn } from '../../../Utils/RatePlanColumn';
import { Input } from 'reactstrap';

export const offerColumns = (grant, charge, usage, globalScope, allStartDate, allEndDate, rolename, selectAllOffer, setSelectAllOffer, offers, setSelectedOffer, selectedOffer, checkboxRef, isMobile) => {
    const columns = [
        {
            accessorKey: 'checkbox',
            header: 'checkbox',
            Header: ({ row }) => (
                <Input type="checkbox" ref={checkboxRef} className=' border-black checkbox-size rounded-1' checked={selectAllOffer} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
                    setSelectAllOffer(!selectAllOffer)
                    if (!selectAllOffer) {
                        setSelectedOffer(offers)
                    }
                    else {
                        setSelectedOffer([])
                    }
                }} />
            ),
            enableColumnActions: false,
            enableColumnDragging: false,
            Cell: ({ row }) => (
                <Input type="checkbox" className=' border-black checkbox-size  rounded-1' checked={selectedOffer.some(p => p.offerCode === row.original.offerCode)} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
                    handleCheckboxChange(row, selectedOffer, setSelectedOffer)
                }
                } />
            ),
            size: isMobile ? 50 : 43
        },

        {
            accessorKey: 'offerName',
            id: 'offerName',
            header: 'Offer Name',
            size: 200
        },
        {
            accessorKey: 'offerCode',
            id: 'offerCode',
            header: 'Code',
        },
        {
            accessorFn: (offer, index) => `${grant[index]} ${charge[index]} ${usage[index]} `,
            id: 'ratePlan',
            header: 'Rate Plan',
            Cell: ({ cell }) => {
                const attributes = cell.getValue();
                const [grant, charge, usage] = attributes?.split(' ');
                return (<RatePlanOrAttributesColumn grant={grant} charge={charge} usage={usage} offerOrPolicy={false} />);
            },
            size: 200
        },
        {
            accessorFn: (offer, index) => `${globalScope[index]}`,
            id: 'globalScope',
            header: 'Global Scope',
            size: 200
        },
        {
            accessorFn: (offer, index) => `${allStartDate[index]}`,
            id: 'offerStartsOn',
            header: 'Offer Starts On',
            size: 230
        },
        {
            accessorFn: (offer, index) => `${allEndDate[index]}`,
            id: 'offerEndsOn',
            header: 'Offer Ends On',
            size: 230
        },
    ];

    if (rolename === 'telcoAdmin') {
        columns.push({
            accessorKey: 'policySpecification',
            header: 'PolicySpecification',
            size: 250
        });
    }

    return columns;
};

export const grantsColumnData = () => {
    const grantsColumn = [
        {
            accessorKey: 'resourceName',
            header: 'Resource Name'
        },
        {
            accessorFn: (row) => `${row.recurringFrequency === 1 ? "Recurring" : "One Time"}`,
            header: 'Type'
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            size: 200,
        },
        {
            accessorFn: (row) => `${row.prorate === false ? "False" : "True"}`,
            header: 'Prorate'
        },
    ];
    return grantsColumn;
};

export const chargesColumnData = () => {
    const chargesColumn = [
        {
            accessorKey: 'resourceName',
            header: 'Resource Name'
        },
        {
            accessorFn: (row) => `${row.recurringFrequency === 1 ? "Recurring" : "One Time"}`,
            header: 'Type'
        },
        {
            accessorKey: 'amount',
            header: 'Price',
            size: 200,
        },
        {
            accessorFn: (row) => `${row.prorate === false ? "False" : "True"}`,
            header: 'Prorate'
        },
    ];
    return chargesColumn;
};

export const usageRatePlanColumnData = () => {
    const usageRatePlanColumn = [
        {
            accessorKey: 'eventType',
            header: 'Event Type'
        },
        {
            accessorKey: 'resourceName',
            header: 'Resource Name'
        },
        {
            accessorKey: 'uomName',
            header: 'UOM'
        },
        {
            accessorKey: 'priority',
            header: 'Priority',
            size: 200,
        },
        {
            accessorFn: (row, index) => `${row.rateTier[index]?.perUnitRate}`,
            header: 'Per Unit Rate'
        },
    ];
    return usageRatePlanColumn;
};

export const handleCheckboxChange = (row, selectedOffer, setSelectedOffer) => {
    let isChecked = selectedOffer.some(p => p.offerCode === row.original.offerCode);
    let updatedSelectedOffer = isChecked
        ? selectedOffer.filter(p => p.offerCode !== row.original.offerCode)
        : [...selectedOffer, row.original];
    setSelectedOffer(updatedSelectedOffer);
};
