import { PAGE_TITLES } from "../constants";

export const generateBreadcrumbLink = (pageTitle, title) => {
    if (
        pageTitle !== "Pricing" && title !== "Listing Station" && 
        title !== "Listing Reservation" && title !== "Listing Customer" && 
        title !== "Settlement Policy" && title !== "Partner List View" && 
        title !== "Device List"
    ) {
        switch (true) {
            case pageTitle === PAGE_TITLES.UOM_LIST:
                return "/uom";
            case pageTitle === PAGE_TITLES.OFFER_LIST:
                return "/offer";
            case pageTitle === PAGE_TITLES.SERVICE_LIST:
                return "/service";
            case pageTitle === PAGE_TITLES.RESOURCE_LIST:
                return "/resource";
            case title === PAGE_TITLES.EDIT_STATION:
                return "/station";
            case title === PAGE_TITLES.ADD_RESERVATION || title === PAGE_TITLES.EDIT_RESERVATION:
                return "/reservation";
            case title === PAGE_TITLES.ADD_CUSTOMER || title === PAGE_TITLES.EDIT_CUSTOMER || title === PAGE_TITLES.VIEW_CUSTOMER || title === PAGE_TITLES.CUSTOMER_ORDER || title === PAGE_TITLES.CUSTOMER_INVOICE || title ===PAGE_TITLES.CUSTOMER_SUBSCRIBE:
                return "/customer";
            case title === PAGE_TITLES.ADD_PARTNER || title === PAGE_TITLES.EDIT_PARTNER|| title === PAGE_TITLES.VIEW_PARTNER || title === PAGE_TITLES.SETTLEMENT_POLICY_LIST:
                return "/partner-listing";
            case title ===PAGE_TITLES.ADD_DEVICE || title === PAGE_TITLES.EDIT_DEVICE:
                return "/devices";
            case title === PAGE_TITLES.ADD_SETTLEMENT_POLICY || title === PAGE_TITLES.EDIT_SETTLEMET_POLICY:
                return "/policy";
            case title === PAGE_TITLES.VIEW_ORDER:
                return "/orders"
            default:
                return "";
        }
    } else {
        return "";
    }
};
