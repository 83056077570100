import * as Yup from "yup"
export const validationstation = (state) => {
    let validationSchema = {}
    return validationSchema =  Yup.object().shape({
        name: Yup.string().required("Kindly enter Station Name"),
        address_line1: Yup.string()
          .max(30, "Must be 30 characters or less")
          .required("Kindly enter Address"),
        address_line2: Yup.string()
          .max(30, "Must be 30 characters or less")
          .required("Kindly enter Address"),
      });
}
