import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getView: [],
    loading: false,
    error: null,
}

const  getViewSlice = createSlice({
    name: "getView",
    initialState,
    reducers: {
        fetchGetViewSucess: (state, action) => {
            state.loading = false
            state. getView = action.payload;
        },
        fetchGetViewFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchGetViewSucess,
    fetchGetViewFailure,
} =  getViewSlice.actions;

export default getViewSlice.reducer;