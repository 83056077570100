import moment from "moment"
export function generateRandomString(length = 20) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let result = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    result += characters[randomIndex]
  }

  return result
}

export const formatDateForOffer = (date) => {
  return moment(date[0]).utcOffset(0).format("YYYY-MM-DDTHH:mm:ss\\Z")
}

export const formatDate = (date) => {
  return moment(date[0]).utcOffset(0).format("YYYY-MM-DDTHH:mm:ss\\Z")
}