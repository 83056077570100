import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  device: [],
  loading: false,
  error: null,
};

const DeviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    fetchDeviceSuccess: (state, action) => {
      (state.loading = false), (state.device = action.payload);
    },
    fetchDeviceFailure: (state, action) => {
      (state.loading = false), (state.error = action.payload);
    },
  },
});

export const { fetchDeviceSuccess, fetchDeviceFailure } = DeviceSlice.actions;

export default DeviceSlice.reducer;
