import * as Yup from 'yup';

export const validationSchema = (state, formValues) => {
    const isBoolCustomer = formValues.valueType.value === 'bool' && formValues.type.value === 'customer';

    let schema = {
        name: Yup.string().required('Kindly Enter the attribute name'),
        type: Yup.mixed().required('Kindly Enter the type'),
        valueType: Yup.mixed().required('Kindly Enter the value type'),
    };

    if (isBoolCustomer) {
        schema.SelfcareAttribute = Yup.array()
            .of(Yup.mixed())
            .min(2, 'You must select at least 2 values for Selfcare Attribute')
            .required('Kindly Enter the attribute value');
    }
    if (!isBoolCustomer) {
        schema.minimumValue = state ? Yup.number().required('Kindly enter the minimum value') : Yup.number();
        schema.maximumValue = state ? Yup.number().required('Kindly enter the maximum value') : Yup.number();

    }
    return Yup.object().shape(schema);
}
