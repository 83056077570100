import { useState } from 'react';
import * as Yup from 'yup';

export const useFormData = () => {
    const [formData, setFormData] = useState({
        subscriptionID: '',
        selectDevice: '',
        subscriptionStartsOn: '',
        subscriptionEndsOn: '',
        name: '',
        value: '',
        date: null,
        sms: '',
    });
    return { formData, setFormData };
};

export const validationForSelectedOrder = (subscriptionId) => {
    let validationshema = {}
    return validationshema = Yup.object({
        subscriptionID: !subscriptionId && Yup.string().required('Kindly enter subscriptionID'),
    });
}

export const validationForCancelOrder = (reason) => {
    let validationSchema = {}

    return validationSchema = Yup.object({
        reason: Yup.object().required("Kindly select a reason"),
        comment: reason.value === "Other" && Yup.string().required('Kindly enter the reason'),
    })
}