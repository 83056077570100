
const useReservationColumns = (allDate, allName, allNumber, allstationadress, startDuration, endDuration, allstationName, finalData, allres) => {
    return [
        {
            accessorFn: (allres, index) => `${allName[index]}`,
            header: 'Customer',
        },
        {
            accessorFn: (allres, index) => `${allNumber[index]}`,
            header: 'Phone Number',
        },
        {
            accessorFn: (station, index) => `${allstationName[index]}`,
            header: 'Station Name',
        },
        {
            accessorFn: (station, index) => `${allstationadress[index]}`,
            header: 'Station Address',
        },
        {
            accessorFn: (customer, index) => `${startDuration[index]} - ${endDuration[index]}`,
            header: 'Duration',
        },
        {
            accessorFn: (customer, index) => `${allDate[index]}`,
            header: 'Date',
        },
        {
            accessorFn: (customer, index) => `${finalData[index].connectorType}`,
            header: 'Connector Type',
        },
        {
            accessorFn: (customer, index) => `${finalData[index].evseId}`,
            header: 'Evse',
        },
    ];
};

export default useReservationColumns;
