import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    createView: [],
    loading: false,
    error: null,
}

const createViewSlice = createSlice({
    name: "createView",
    initialState,
    reducers: {
        fetchCreateViewSucess: (state, action) => {
            state.loading = false
            state.createView = action.payload;
        },
        fetchCreateViewFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchCreateViewSucess,
    fetchCreateViewFailure,
} = createViewSlice.actions;

export default createViewSlice.reducer;