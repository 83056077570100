import React, { useEffect, useState } from "react";
import { Card, CardBody, Container, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { MaterialReactTable } from "material-react-table";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../Common/BreadCrumb";
import { Loader } from "../Utils/Loader";
import { userColoumns } from "../UserManagement/Constant/UserManagementColoumn";
import { getAllUser } from "../../slices/UserManagement/thunk";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import { MenuItem } from "@mui/material";
import { deactivateUser } from "../../slices/UserManagement/Deactivate/thunk";

export default function UserListingOperator() {
    const [loading, setLoading] = useState()
    const dispatch = useDispatch()
    const columns = userColoumns()
    const operatorId = customerIdData();
    const location = useLocation()
    useEffect(() => {
        dispatch(getAllUser(setLoading, operatorId, location));
    }, [dispatch]);
    const user = useSelector((state) => state?.GetAllUser?.getAllUser)

    const updatedData = user.map(item => {
        const { firstName, lastName } = item.profile;
        const { userId, userName } = item.userDetails;
        return {
            ...item,
            fullName: `${firstName} ${lastName}`,
            userId,
            userName,
        };
    });

    return (
        <React.Fragment>
            <div id='csms'>
                <Container fluid>
                    <ToastContainer position="top-center" />
                    <Row className="table-responsive">
                        {loading && (<Loader />)}
                        {user ?
                            <MaterialReactTable
                                icons={{ DragHandleIcon: () => <i className="ri-drag-move-fill" />, }}
                                muiTableHeadCellProps={{
                                    sx: {
                                        fontFamily: "Inter,sans-serif"
                                    }
                                }}
                                displayColumnDefOptions={{
                                    'mrt-row-actions': {
                                        header: '',
                                        enableHiding: true
                                    }
                                }}
                                muiTableBodyCellProps={{
                                    sx: {
                                        fontFamily: "Inter,sans-serif"
                                    }
                                }}
                                initialState={{
                                    columnVisibility: {
                                        'userDetails.userId': false,
                                    },
                                }}
                                enableRowActions
                                renderRowActionMenuItems={({ closeMenu, row }) => [
                                    <MenuItem className='row-options' data-testid="deactivate" key="deactivate" onClick={() => { dispatch(deactivateUser(row.original.userDetails.userId, setLoading, location, operatorId)) }}>
                                        <i className='ri-close-circle-line me-2' /> Deactivate </MenuItem>
                                ]}
                                columns={columns}
                                data={updatedData}
                                enableColumnOrdering
                                enableColumnDragging
                                enableDensityToggle={false}
                            /> : null}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}