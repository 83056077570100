import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { INVOICE_STATISTICS_API } from "../../constants/constants"
import { fetchInvoiceStatisticsSuccess,fetchInvoiceStatisticsFailure } from "./reducer"

export const fetchInvoiceStatistics = (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${INVOICE_STATISTICS_API}`,'GET')
        setLoading(false)
        dispatch(fetchInvoiceStatisticsSuccess(response))
    }
    catch (error) {dispatch(fetchInvoiceStatisticsFailure(error.message)) }
}