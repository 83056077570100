import React from 'react';
import { Card, CardBody, CardHeader, Button, Table } from 'reactstrap';
import { FieldArray } from 'formik';
import { TablesWithoutBorders } from "../../pages/Tables/BasicTables/BasicTablesCode";
import { Link } from "react-router-dom";
import InputField from "../Utils/InputField";

export default function Attribute({
  values,
  touched,
  errors,
  handleChange,
  setTouched,
  handleChangeForAttributeValue,
  isEmptyOrInvalidField,
  deviceID,
  isEdit
}) {
  return (
    <FieldArray name="attributeList">
      {({ push, remove }) => (
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Attributes
            </h4>
            <Button
              color="secondary"
              className="btn-label"
              onClick={() => {
                const lastIndex =
                  values.attributeList.length - 1;
                if (
                  lastIndex === -1 ||
                  !isEmptyOrInvalidField(lastIndex, values)
                ) {
                  push({ name: "", value: "" });
                  setTouched({
                    ...touched,
                    attributeList: {
                      ...touched.attributeList,
                      [lastIndex]: {
                        name: false,
                        value: false,
                      },
                    },
                  });
                }
              }}
            >
              {" "}
              <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>{" "}
              Add Attributes{" "}
            </Button>
          </CardHeader>
          <CardBody>
            <div className="live-preview">
              <div className="table-responsive">
                <div className="scrollable-view">
                  <Table className="table-borderless align-middle table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col ">Name<span className="text-danger">*</span></th>
                        <th scope="col">Value<span className="text-danger">*</span></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.attributeList.length === 0 ? (
                        <tr className="default-height">
                          <td colSpan={4} className="text-center" >
                            No Attributes
                          </td>
                        </tr>
                      ) : (
                        values.attributeList.map(
                          (val, index) => {
                            return (
                              <tr key={index}>
                                <td className="w-lg">
                                  <InputField
                                    name={`attributeList[${index}].name`}
                                    className={
                                      (touched.attributeList &&
                                        touched.attributeList[
                                        index
                                        ] &&
                                        !values.attributeList[
                                          index
                                        ].name) ||
                                        false
                                        ? "is-invalid"
                                        : ""
                                    }
                                    disabled={deviceID[index] && isEdit ? true : false}
                                    placeholder="Enter  name"
                                    type="text"
                                    handleChange={handleChangeForAttributeValue(
                                      handleChange
                                    )}
                                    value={
                                      values.attributeList[
                                        index
                                      ].name || ""
                                    }
                                    invalid={
                                      errors.attributeList &&
                                        errors.attributeList[
                                        index
                                        ] &&
                                        touched.attributeList &&
                                        touched.attributeList[
                                        index
                                        ] &&
                                        touched.attributeList[
                                          index
                                        ].value
                                        ? true
                                        : false
                                    }
                                  />
                                  {errors.attributeList &&
                                    errors.attributeList[
                                    index
                                    ] &&
                                    touched.attributeList &&
                                    touched.attributeList[
                                    index
                                    ] &&
                                    touched.attributeList[index]
                                      .value ? (
                                    <p className="text-danger">
                                      {
                                        errors.attributeList[
                                          index
                                        ].name
                                      }
                                    </p>
                                  ) : null}
                                </td>
                                <td>
                                  <InputField
                                    name={`attributeList[${index}].value`}
                                    className={
                                      (touched.attributeList &&
                                        touched.attributeList[
                                        index
                                        ] &&
                                        !values.attributeList[
                                          index
                                        ].value) ||
                                        false
                                        ? "is-invalid"
                                        : ""
                                    }
                                    disabled={deviceID[index] && isEdit ? true : false}
                                    placeholder="Enter  Value"
                                    type="text"
                                    handleChange={handleChangeForAttributeValue(
                                      handleChange
                                    )}
                                    value={
                                      values.attributeList[
                                        index
                                      ].value || ""
                                    }
                                    invalid={
                                      errors.attributeList &&
                                        errors.attributeList[
                                        index
                                        ] &&
                                        touched.attributeList &&
                                        touched.attributeList[
                                        index
                                        ] &&
                                        touched.attributeList[
                                          index
                                        ].value
                                        ? true
                                        : false
                                    }
                                  />
                                  {errors.attributeList &&
                                    errors.attributeList[
                                    index
                                    ] &&
                                    touched.attributeList &&
                                    touched.attributeList[
                                    index
                                    ] &&
                                    touched.attributeList[index]
                                      .value ? (
                                    <p className="text-danger">
                                      {
                                        errors.attributeList[
                                        index
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </td>
                                {!deviceID[index] && <td>
                                  <div className="hstack gap-3 fs-15">
                                    <Link
                                      to="#"
                                      className="link-danger"
                                      onClick={() =>
                                        remove(index)
                                      }
                                    >
                                      <i
                                        data-testid="delete"
                                        className="ri-delete-bin-5-line"
                                      ></i>
                                    </Link>
                                  </div>
                                </td>}
                              </tr>
                            );
                          }
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="d-none code-view">
              <pre className="language-markup default-height">
                <code>
                  <TablesWithoutBorders />
                </code>
              </pre>
            </div>
          </CardBody>
        </Card>
      )}
    </FieldArray>
  );
}

