import React from 'react';
import { Col, FormFeedback } from 'reactstrap';
import InputField from "../Utils/InputField";
import { LabelText } from "../Utils/Input";
import SelectField from "../Utils/SelectField";

const renderFormFields = (fields, values, errors, touched, handleChange, setFieldValue, isEdit) => {
  return fields.map((field, index) => {
    const isInvalid = touched[field.name] && errors[field.name];
    switch (field.type) {
      case 'text':
        return (
          <Col xxl={4} md={4} key={index}>
            <LabelText
              htmlFor={field.name}
              className="text-danger"
              displayText={field.label}
              important={"*"}
            />
            <InputField
              value={values[field.name]}
              type={field.type}
              disabled={field.disabled || isEdit}
              className={"form-control"}
              handleChange={handleChange}
              name={field.name}
              placeholder={field.placeholder}
              invalid={isInvalid}
            />
            {isInvalid && (
              <FormFeedback type="invalid">{errors[field.name]}</FormFeedback>
            )}
          </Col>
        );
      case 'select':
        return (
          <Col xxl={4} md={4} key={index}>
            <LabelText
              htmlFor={field.name}
              className="text-danger"
              displayText={field.label}
              important={"*"}
            />
            <SelectField
              className={isInvalid ? "error-input" : ""}
              id={field.name}
              value={values[field.name]}
              placeholder={field.placeholder}
              handleChange={(selectedOption) => {
                setFieldValue(field.name, selectedOption);
                setFieldValue('unitofmeasurement', selectedOption.unitofmeasurement || '');
              }}
              options={field.options}
              isDisabled={isEdit}
            />
            {isInvalid && (
              <p className="text-danger">{errors[field.name]}</p>
            )}
          </Col>
        );
      default:
        return null;
    }
  });
};
export function PolicyForm({
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  schema,
  isEdit
}) {
  return (
    <>
      {renderFormFields(schema.fields, values, errors, touched, handleChange, setFieldValue, isEdit)}
    </>
  );
}
