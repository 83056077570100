import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deletePolicy: [],
    loading: false,
    error: null,
}

const deletePolicySlice = createSlice({
    name: "deletePolicy",
    initialState,
    reducers: {
        fetchDeletePolicySuccess: (state, action) => {
            state.loading = false;
            state.deletePolicy = action.payload;
        },
        fetchDeletePolicyFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchDeletePolicySuccess, fetchDeletePolicyFailure } = deletePolicySlice.actions;

export default deletePolicySlice.reducer;
