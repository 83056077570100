import { MaterialReactTable } from "material-react-table";
import { Card, CardBody, Row } from "reactstrap";
import { CustomerListRowOptions } from "./CustomerListRowOptions";
import { CustomerPreview } from "./CustomerPreview";
import Simulate from "./Simulate";
import React, { useEffect, useState } from "react";
import CommonModal from "../Utils/CommonModal";
import { deletecustomer } from "../../slices/customer/deletecustomer/thunk";
import { MenuItem } from '@mui/material';
import { navigateToSubscribe } from "./constants/DocumentTableFunctions";

export const TableForTelco = ({
    getTable,
    searchColumns,
    searchData,
    orderPermission,
    customerPermission,
    navigate,
    dispatch,
    setRenderSimulate,
    setLoading,
    simulateOrPreview,
    index,
    setIndex,
    setActiveTab,
    fetchCustomerDetails,
    stationPermission,
    reservationPermission,
    getCustomerData,
    storeRow,
    setDefault,
    renderSimulate,
    pagination,
    customerData,
    activeTab,
    tabChange,
    setOpen,
    toggle,
    noCurrencyArray,
    currencyArray,
    fetchCustomerSummaryData,
    reservationDataArray,
    duration,
    subscriptionsArray,
    convertNumberOfDaysToMonthYear,
    formatDate,
    getDayDiff,
    current,
    errorMessage,
    errorIcon,
    setGetTable,
    toggleModal,
    setMessageForPopUp,
    setCustomerId,
    messageForPopUp,
    modal,
    customerId,
    TotalCustomers,
    searchExpanding
}) => {
    function transformData(response) {
        return response?.Customers?.map(customer => ({
            CustomerId: customer.CustomerId,
            Name: customer.Name,
            Email: customer.Email,
            Mobile: customer.Mobile,
            Address: customer.Address,
            msisdn: customer.AttributeList?.find(attr => attr.Name?.toLowerCase() === "msisdn")?.Value || "",
            imsi: customer.AttributeList?.find(attr => attr.Name?.toLowerCase() === "imsi")?.Value || ""
        }));
    }
    const transformedData = transformData(TotalCustomers);
    return (
        <>
            <CardBody>
                {!getTable && searchExpanding ? (
                    <div className="border rounded static-height d-flex align-items-center justify-content-center">
                        <div className="flex-column" style={{ textAlign: 'center' }}>
                            <i className={`${errorIcon} icon-size flex-column`} />
                            <p className="text-color-telco ">
                                {errorMessage}
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="container-fluid px-0">
                        <Card className="disable-margin-card w-100">
                            <CardBody>
                                <Row className="mb-3 mt-1 table-responsive">
                                    <MaterialReactTable
                                        data={!getTable ? (transformedData || []) : (searchData || [])}
                                        columns={searchColumns}
                                        icons={{
                                            DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                        }}
                                        defaultColumn={{
                                            minSize: 10,
                                            maxSize: 9001,
                                            size: 260,
                                            muiTableBodyCellProps: {
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            },
                                            muiTableHeadCellProps: {
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }
                                        }}
                                        initialState={{
                                            columnVisibility: {
                                                "mrt-row-expand": false,
                                            },
                                        }}
                                        layoutMode='grid'
                                        muiTableDetailPanelProps={{
                                            sx: {
                                                fontFamily: "Inter,sans-serif",
                                            },
                                        }}
                                        enableGlobalFilter={false}
                                        enableColumnOrdering={true}
                                        enableColumnDragging={true}
                                        enableDensityToggle={false}
                                        displayColumnDefOptions={{
                                            "mrt-row-actions": {
                                                header: "",
                                                enableHiding: true,
                                            },
                                        }}
                                        enableRowActions={true}                                       
                                        renderRowActionMenuItems={({ closeMenu, row }) => (
                                            [
                                                customerPermission && customerPermission.permissionList.includes('view') ? <MenuItem className='row-options' key="view" onClick={() => { simulateOrPreview = "View"; navigate(`/view-customer/${row.original.CustomerId}`) }}>
                                                    <i className='ri  ri-account-circle-line me-2' /> View
                                                </MenuItem> : null,
                                                customerPermission && customerPermission.permissionList.includes('update') ? <MenuItem className='row-options' key="edit" onClick={() => { navigate(`/update-customer/${row.original.CustomerId}`) }}>
                                                    <i className='bx bxs-edit me-2' /> Edit
                                                </MenuItem> : null,
                                                <MenuItem className='row-options' key="delete" onClick={(e) => {
                                                    simulateOrPreview = "Simulate"
                                                    fetchCustomerDetails(dispatch, setLoading, setRenderSimulate, row, simulateOrPreview)
                                                    storeRow(row, setIndex, index), setDefault(row.index, setActiveTab)
                                                    closeMenu()
                                                }}>
                                                    <i className=' bx bx-info-circle me-2' /> Simulate
                                                </MenuItem>,
                                                <MenuItem className='row-options' key="preview" onClick={(e) => {
                                                    simulateOrPreview = "Preview"
                                                    e.stopPropagation();
                                                    getCustomerData(row, simulateOrPreview, setLoading, stationPermission, reservationPermission, customerPermission, setRenderSimulate, dispatch)
                                                    row.toggleExpanded(); storeRow(row, setIndex, index), setDefault(row.index, setActiveTab), closeMenu()
                                                }}>
                                                    <i className="ri-survey-line me-2" /> Preview
                                                </MenuItem>,
                                                orderPermission && orderPermission.permissionList.includes('create') ? <MenuItem className='row-options' key="subscribe" onClick={() => {
                                                    navigateToSubscribe(row, dispatch, navigate)
                                                }}>
                                                    <i className="ri-add-circle-line me-2 " ></i> Subscribe
                                                </MenuItem> : null,
                                                customerPermission && customerPermission.permissionList.includes('delete') ? <MenuItem className='row-options'
                                                    key="close"
                                                    onClick={() => {
                                                        setMessageForPopUp(`Are you sure you want to Delete this Customer "${row.original.CustomerId}"?`)
                                                        toggleModal(row,"single");
                                                        setCustomerId(row.original.CustomerId)
                                                        closeMenu();
                                                    }}
                                                >
                                                    <i className="ri ri-delete-bin-5-line me-2" />{" "}
                                                    Close
                                                </MenuItem> : null,
                                            ]
                                        )}
                                        renderDetailPanel={({ row }) => (
                                            <>
                                                {renderSimulate ? (
                                                    <Simulate row={row} />
                                                ) : (
                                                    <CustomerPreview
                                                        customerData={customerData}
                                                        row={row}
                                                        activeTab={activeTab}
                                                        setActiveTab={setActiveTab}
                                                        tabChange={tabChange}
                                                        open={setOpen}
                                                        setOpen={setOpen}
                                                        toggle={toggle}
                                                        customerAttributeData={customerData?.customerInfo?.attributeList}
                                                        noCurrencyArray={noCurrencyArray}
                                                        currencyArray={currencyArray}
                                                        fetchCustomerSummaryData={fetchCustomerSummaryData}
                                                        reservationDataArray={reservationDataArray}
                                                        duration={duration}
                                                        subscriptionsArray={subscriptionsArray}
                                                        convertNumberOfDaysToMonthYear={convertNumberOfDaysToMonthYear}
                                                        formatDate={formatDate}
                                                        getDayDiff={getDayDiff}
                                                        current={current}
                                                    />
                                                )}
                                            </>
                                        )}
                                    />
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                )}
            </CardBody>
            <CommonModal
                messageForPopUp={messageForPopUp}
                toggle={()=>toggleModal(customerId,"single")}
                open={modal}
                buttonText={"Yes, Delete It"}
                modalAction={() => {
                    dispatch(deletecustomer(customerId, setLoading, 1, pagination));
                    toggleModal(customerId,"single");
                }}
            />
        </>
    );
};
