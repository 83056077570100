import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Container, Input, Button, Row, Col, Form, Label } from 'reactstrap';
import BreadCrumb from '../Common/BreadCrumb';
import axios from 'axios';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStation } from '../../slices/stations/thunk';
import { updateReservation } from '../../slices/reservations/updatereservation/thunk';
import { postReservation } from '../../slices/reservations/postreservation/thunk';
import { getReservation } from '../../slices/reservations/getreservation/thunk';
import { Loader } from '../Utils/Loader';
import { validationSchema } from './constants/validationschema';
import { connectorTypeOptions } from './constants/reservationinitialvalues';
import { evseoptions } from './constants/reservationinitialvalues';
import { fetchAllOffers } from '../../slices/offer/thunk';
import SelectField from '../Utils/SelectField';
import CommonButton from '../Utils/CommonButton';
import { PAGE_TITLES } from '../Common/constants';
import CommonDateTimePicker from '../Utils/CommonDateTimePicker';
import moment from 'moment';

function CreateReservations() {
  const [searchValue, setSearchValue] = useState('');
  const [storedData, setStoredData] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [selectedDateTimeStart, setSelectedDateTimeStart] = useState(null);
  const [selectedDateTimeEnd, setSelectedDateTimeEnd] = useState(null);
  const [selectedEVSE, setSelectedEVSE] = useState(null);
  const [selectedConnectorType, setSelectedConnectorType] = useState(null);
  const [reservationData, setReservationData] = useState(null);
  const [isSearchBarVisible, setSearchBarVisible] = useState(false);
  const [enableFormReinitialize, setEnableFormReinitialize] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchFieldError, setSearchFieldError] = useState('');
  const isEdit = location.pathname.includes('update-reservation');
  const isCreate = location.pathname.includes('create-reservation-customer');
  const [loadingData, setLoadingData] = useState(false);
  const reservationIdPath = location.pathname.slice(
    location.pathname.lastIndexOf('/'),
    location.pathname.length
  );
  const reservationIdmain = reservationIdPath.slice(
    1,
    reservationIdPath.length
  );
  const initialValues = {
    idTokenType: "",
    startTime: null,
    endTime: null,
    station: ""
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerId = reservationData?.customerId;
  const submitReservationData = () => {

    if (!searchValue) {
      setSearchFieldError('Please enter a Customer ID');
      return;
    }

    const stationId = selectedStation ? selectedStation.value : '';
    const idToken = selectedAttribute ? selectedAttribute.value.value : '';
    const type = selectedAttribute ? selectedAttribute.value.name : '';
    const formattedDateTime = selectedDateTimeStart
      ? new Date(selectedDateTimeStart).toISOString().replace(/\.\d{3}Z$/, 'Z')
      : '';
    const formattedEndDateTime = selectedDateTimeEnd
      ? new Date(selectedDateTimeEnd).toISOString().replace(/\.\d{3}Z$/, 'Z')
      : '';

    const formData = {
      customerId: searchValue,
      stationId: stationId,
      startTime: formattedDateTime,
      endTime: formattedEndDateTime,
      evseId: 0,
      idTokenType: {
        idToken: idToken,
        type: type,
      },
    };

    if (isEdit) {
      formData.reservationId = reservationData?.reservationId;
    }
    const requestBody = JSON.stringify(formData);
    if (isEdit) {
      dispatch(updateReservation(requestBody, isEdit, navigate, setLoadingData))
    } else {
      dispatch(postReservation(requestBody, isEdit, navigate, setLoadingData))
    }
  };

  const offerData = useSelector((state) => state.Offer.offer)
  const globalOffers = (offerData?.filter((offer) => offer.globalScope)).reverse()

  const fetchReservationData = () => {
    setLoadingData(true);
    const url = `${window.env.REACT_APP_GET_CUSTOMER_API}/${customerId}?includeSubscription=true`;
    const accessToken = localStorage.getItem('token');

    let options = {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios.get(url, options).then((response) => {
      setStoredData(response.data)
      setLoadingData(false)
    })
      .catch((error) => {
        setLoadingData(false)
      });
  };

  const fetchOffers = () => {
    dispatch(fetchAllOffers(setLoading));
  }

  useEffect(() => {
    if (isEdit) {
      fetchOffers()
      setSearchBarVisible(true)
      const customerId = reservationData?.customerId
      if (customerId) {
        fetchReservationData(customerId)
      }
    }
  }, [isEdit, reservationData]);

  useEffect(() => {
    if (!isEdit) {
      setEnableFormReinitialize(true)
    }
  }, [isEdit])

  useEffect(() => {
    if (isEdit) {
      dispatch(getReservation(reservationIdmain, setReservationData, setSearchValue, setSelectedAttribute, setSelectedStation, setSelectedDateTimeStart, setSelectedDateTimeEnd, initialValues, setLoadingData))
    } else {
      setEnableFormReinitialize(true)
      setSearchBarVisible(false);
      setSearchValue('');
      setStoredData(null);
      setSelectedAttribute(null);
      setSelectedStation(null);
      setSelectedDateTimeStart(null);
      setSelectedDateTimeEnd(null);
      setSelectedEVSE(null);
      setSelectedConnectorType(null);
      setReservationData(null);
    }
  }, [dispatch, isEdit]);

  const handleSearch = () => {
    fetchOffers()
    if (!searchValue) {
      setSearchFieldError('Please enter a Customer ID');
      return;
    }
    setLoadingData(true);
    const url = `${window.env.REACT_APP_GET_CUSTOMER_API}/${searchValue}?includeSubscription=true`;
    const accessToken = localStorage.getItem('token');
    let options = {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      validateStatus: function (status) {
        return status >= 200 && status <= 505
      }
    };
    axios.get(url, options).then((response) => {
      if (!response?.data?.error) {
        setLoadingData(false)
        setStoredData(response?.data);
      }
      if (response?.data?.error) {
        toast.error(response?.data?.error?.message)
        setStoredData(null);
        setLoadingData(false)
      }
    }).catch((error) => {
      setLoadingData(false)
    });
  };
  useEffect(() => {
    dispatch(getAllStation(setLoading))
  }, [dispatch]);

  useEffect(() => {
    if (isCreate && reservationIdPath) {
      const cleanedReservationIdPath = reservationIdPath.substring(1)
      setSearchValue(cleanedReservationIdPath)
    }
    else {
      setSearchValue('');
      setStoredData(null);
    }
  }, [reservationIdPath]);

  useEffect(() => {
    if (isCreate) {
      handleSearch()
    }
  }, [searchValue]);

  const stations = useSelector(state => state.Stations.station)

  useEffect(() => {
    if (!storedData) {
      setSelectedAttribute(null);
    }
  }, [storedData]);

  const attributeOptions = storedData?.customerInfo?.attributeList?.map(
    (attribute) => ({
      value: attribute,
      label: attribute.name,
    })
  );

  const updatedStations = stations
    ?.filter((station) => station.stationDetails.status === 'Accepted')
    .map((station) => ({
      label: station.stationDetails.name,
      value: station.stationDetails.stationID,
      ...station,
    }));

  const handleDateTimeChangeStart = (dateStr) => {
    setSelectedDateTimeStart(dateStr);
  };

  const handleDateTimeChangeEnd = (dateStr) => {
    setSelectedDateTimeEnd(dateStr);
  };

  return (
    <React.Fragment>
      {loadingData && (<Loader />)}
      <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize={enableFormReinitialize} onSubmit={submitReservationData}  >
        {({ handleSubmit, errors, touched, values, setFieldTouched, setFieldValue }) => (
          <Form id='csms' className="needs-validation" onSubmit={handleSubmit} >
            <div className='page-content'>
              <Container fluid>
                <BreadCrumb title={isEdit ? PAGE_TITLES.EDIT_RESERVATION : PAGE_TITLES.ADD_RESERVATION} pageTitle={PAGE_TITLES.RESERVATION_LIST} />
                <Row>
                  <Col lg={12}>
                    <CardBody>
                      <ToastContainer position='top-center' />
                      <div>
                        <Card>
                          {!isSearchBarVisible && (
                            <CardBody>
                              <div className='d-flex align-items-center'>
                                <Input
                                  placeholder='Enter the Customer ID'
                                  value={searchValue}
                                  onChange={(e) => {
                                    setSearchValue(e.target.value);
                                    setSearchFieldError('');
                                  }}
                                  className={`me-3 ${searchFieldError ? 'is-invalid' : ''}`}
                                  disabled={isCreate}
                                />
                                {!isCreate && (
                                  <Button onClick={handleSearch} color='secondary'>
                                    Search
                                  </Button>
                                )}
                              </div>
                            </CardBody>
                          )}
                          {searchFieldError && (
                            <p className='text-danger ms-3 '>{searchFieldError}</p>
                          )}
                        </Card>
                        <CardBody>
                          <div>
                            <Row lg={12}>
                              <Col lg={6}>
                                <Card>
                                  <CardHeader className="d-flex align-items-center">
                                    <i className="ri-account-circle-line fs-3 me-2 invoice-percent-and-icon-color"></i>
                                    <Label className="invoice-percent-and-icon-color fs-5 mt-2" >Customer Details</Label>
                                  </CardHeader>
                                  <CardBody>
                                    <Row lg={6}>
                                      <Col lg={6} >
                                        <div className="container">
                                          <div className="row">
                                            <div className="row mb-3" >
                                              <div className="col fs-5" ><Label>Name: </Label></div>
                                              <div > {storedData?.customerInfo?.firstName} {storedData?.customerInfo?.lastName}</div>
                                            </div>
                                            <div className="row mb-3">
                                              <div className="col fs-5"><Label>Email : </Label></div>
                                              <div >{storedData?.customerInfo?.email}</div>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="container">
                                          <div className="row" >
                                            <div className="row mb-3">
                                              <div className="col fs-5"><Label>Contact No : </Label></div>
                                              <div >{storedData?.customerInfo?.mobile}</div>
                                            </div>
                                            <div className="row mb-3">
                                              <div className="col fs-5"><Label>Address : </Label></div>
                                              <div >{storedData?.customerInfo?.addressLine1}{' '}{storedData?.customerInfo?.addressLine2}</div>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className='mt-1 ms-2'>
                                      <label htmlFor='idTokenType' className='fs-6'>
                                        Select IdTokenType{' '}
                                        <span style={{ color: 'red' }}>*</span>
                                      </label>
                                      <SelectField
                                        id='idTokenType'
                                        value={initialValues?.idTokenType?.value?.value ?
                                          attributeOptions?.filter((idTokenType) => idTokenType?.value === initialValues?.idTokenType?.value)
                                          : values.idTokenType}
                                        className={(touched.idTokenType && !values.idTokenType) || false ? 'error-input' : ''}
                                        handleChange={(selectedOption) => {
                                          setSelectedAttribute(selectedOption),
                                            setFieldValue('idTokenType', selectedOption, false),
                                            setFieldTouched('idTokenType', false)
                                        }
                                        }
                                        options={attributeOptions}
                                        placeholder='Select an attribute'
                                        isDisabled={isEdit || !storedData}
                                      />
                                      {
                                        errors.idTokenType && !values.idTokenType && touched.idTokenType ? (
                                          <p className='text-danger'>
                                            {errors.idTokenType}
                                          </p>
                                        ) : null
                                      }
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col lg={6}>
                                <Card>
                                  <CardHeader className="d-flex align-items-center">
                                    <i className="bx bxs-offer fs-3 me-2 invoice-percent-and-icon-color"></i>
                                    <Label className="invoice-percent-and-icon-color fs-5 mt-2">Offer Details</Label>
                                  </CardHeader>
                                  <CardBody>
                                    <div className=''>
                                      {globalOffers[0]?.usageRatePlan?.length > 0 && storedData ? (
                                        globalOffers[0]?.usageRatePlan.map((ratePlan, index) => (
                                          <div key={index}>
                                            <Row>
                                              <label className="fs-5">Resource Name:</label>
                                              <p className="fs-6">{ratePlan.resourceName}</p>
                                            </Row>
                                            {ratePlan.rateTier.map((rate, index) => (
                                              <div className="mt-3" key={index}>
                                                <Row>
                                                  <Col>
                                                    <label className="fs-5">Uom:</label>
                                                    <span className="ms-2 fs-6">{ratePlan.uomName}</span>
                                                  </Col>
                                                  <Col>
                                                    <label className="fs-5">Per unit:</label>
                                                    <span className="ms-2 fs-6">{rate.perUnitRate}</span>
                                                  </Col>
                                                  <Col>
                                                    <label className="fs-5">Flat Rate:</label>
                                                    <span className="ms-2 fs-6">{rate.flatRate}</span>
                                                  </Col>
                                                </Row>
                                              </div>
                                            ))}
                                          </div>
                                        ))
                                      ) : (
                                        <div>
                                          <Row>
                                            <label className="fs-5">Resource Name:</label>
                                            <p className="fs-6">{' '}</p>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <label className="fs-5">Uom:</label>
                                              <span className="ms-2 fs-6">{''}</span>
                                            </Col>
                                            <Col>
                                              <label className="fs-5">Per unit:</label>
                                              <span className="ms-2 fs-6">{''}</span>
                                            </Col>
                                            <Col>
                                              <label className="fs-5">Flat Rate:</label>
                                              <span className="ms-2 fs-6">{''}</span>
                                            </Col>
                                          </Row>
                                        </div>
                                      )}
                                      <div style={{ marginTop: '20px' }}>
                                        <label htmlFor='stationSelect' className='fs-6'>
                                          Select a Station
                                        </label>
                                        <span style={{ color: 'red' }}> *</span>
                                        <SelectField
                                          id='stationSelect'
                                          className={(touched.station && !values.station) || false ? 'error-input' : ''}
                                          value={initialValues?.station.value ?
                                            updatedStations?.filter((station) => station.value === initialValues.station.value)
                                            : values.station}
                                          handleChange={(selectedOption) => {
                                            setSelectedStation(selectedOption),
                                              setFieldValue('station', selectedOption, false),
                                              setFieldTouched('station', false)
                                          }
                                          }
                                          options={updatedStations}
                                          placeholder='Select a station'
                                          isDisabled={isEdit || !values.idTokenType}
                                        />
                                        {
                                          errors.station && !values.station && touched.station ? (
                                            <p className='text-danger'>
                                              {errors.station}
                                            </p>
                                          ) : null
                                        }
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                            <Card>
                              <CardHeader className="d-flex align-items-center">
                                <i className=" las la-calendar fs-3 me-2 invoice-percent-and-icon-color"></i>
                                <Label className="invoice-percent-and-icon-color fs-5 mt-2" >Reservation Details</Label>
                              </CardHeader>
                              <CardBody>
                                <Row className="gy-4" lg={12}>
                                  <Col xxl={3} md={6}>
                                    <div className=''>
                                      <label htmlFor='StartTime'>
                                        Select Start Date & Time{' '}
                                        <span style={{ color: 'red' }}>*</span>
                                      </label>
                                      <CommonDateTimePicker
                                        className={`form-control ${touched.startTime && !values.startTime ? ("error-input") : ""} `}
                                        name={`startTime`}
                                        value={values.startTime}
                                        handleChange={(data) => {
                                          values.startTime = data, handleDateTimeChangeStart(data), setFieldTouched('startTime', false)
                                        }}
                                        style={values.idTokenType ? {} : { backgroundColor: '#f2f2f2', color: '#6c757d', cursor: 'not-allowed' }}
                                        format="DD/MM/YYYY hh:mm A"
                                        minDateTime={moment()}
                                        disabled={!values.idTokenType}
                                      />
                                      {
                                        errors.startTime && touched.startTime && !values.startTime && (
                                          <p className="text-danger error">
                                            {" "}
                                            {errors.startTime}{" "}
                                          </p>
                                        )
                                      }
                                    </div>
                                  </Col>
                                  <Col xxl={3} md={6}>
                                    <div>
                                      <label htmlFor='EndTime'>
                                        Select End Date & Time{' '}
                                        <span style={{ color: 'red' }}>*</span>
                                      </label>
                                      <CommonDateTimePicker
                                        className={`form-control ${touched.endTime && !values.endTime ? ("error-input") : ""} `}
                                        name={`endTime`}
                                        value={values.endTime}
                                        handleChange={(data) => {
                                          values.endTime = data, handleDateTimeChangeEnd(data), setFieldTouched('endTime', false)
                                        }}
                                        style={values.idTokenType ? {} : { backgroundColor: '#f2f2f2', color: '#6c757d', cursor: 'not-allowed' }}
                                        format="DD/MM/YYYY hh:mm A"
                                        minDateTime={values.startTime}
                                        disabled={!values.idTokenType}
                                      />
                                      {
                                        errors.endTime && touched.endTime && !values.endTime && (
                                          <p className="text-danger error">
                                            {" "}
                                            {errors.endTime}{" "}
                                          </p>
                                        )
                                      }
                                    </div>
                                  </Col>
                                  <Col xxl={3} md={6}>
                                    <div className=''>
                                      <label htmlFor='evseSelect'>EVSE</label>
                                      <SelectField
                                        id='evseSelect'
                                        value={selectedEVSE}
                                        isDisabled={true}
                                        options={evseoptions}
                                        placeholder='Select EVSE'
                                      />
                                    </div>
                                  </Col>
                                  <Col xxl={3} md={6}>
                                    <div>
                                      <label htmlFor='connectorTypeSelect'>
                                        Connector Type
                                      </label>
                                      <SelectField
                                        id='connectorTypeSelect'
                                        value={selectedConnectorType}
                                        isDisabled={true}
                                        options={connectorTypeOptions}
                                        placeholder='Select Connector Type'
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                            <div>
                              <div className=' d-flex mt-4'>
                              </div>
                            </div>
                            <div className='d-flex mt-4'>
                            </div>
                            <div className=' d-flex  justify-content-end  gap-2'>
                              <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/reservation')} buttonText={"Cancel"} />
                              <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} />
                            </div>
                          </div>
                        </CardBody>
                      </div>
                    </CardBody>
                  </Col>
                </Row>
              </Container>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}
export default CreateReservations;