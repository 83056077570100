import React, { useState, useEffect } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Card, CardBody, Col, Container, Row, Form } from "reactstrap";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Loader } from "../Utils/Loader";
import { validationSchema } from "./constants/ValidationSchema";
import CommonButton from "../Utils/CommonButton";
import { PAGE_TITLES } from "../Common/constants";
import { PolicyForm } from "./PolicyForm";
import Attribute from "./Attribute";
import { handleChangeForAttributeValue } from "./constants/jsonconstant";
import { isEmptyOrInvalidField } from "./constants/jsonconstant";
import schema from './schema.json';
import { getAllResources } from "../../slices/resources/thunk";
import { createPolicies } from "../../slices/Policy/CreatePolicy/thunk";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import { getPoliciesByName } from "../../slices/Policy/GetPolicyByName/thunk";
import { updatePolicies } from "../../slices/Policy/UpadatePolicy/thunk";

export default function CreatePolicy() {
  const location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [enableFormReinitialize, setEnableFormReinitialize] = useState(false);
  const [deviceID, setDeviceID] = useState([]);
  const [initialValues, setInitialValues] = useState({
    policyLabel: "",
    resource: "",
    status: "",
    attributeList: [
      {
        rangeStarts: '0',
        rangeEnds: '',
        statusLabel: '',
      }
    ],
    unitofmeasurement: ""
  });

  const isEdit = location.pathname.includes('/edit-policies');
  const policy = customerIdData();
  const [title, setTitle] = useState("");
  useEffect(() => {
    setTitle(PAGE_TITLES.ADD_POLICY);
    if (isEdit) {
      setTitle(PAGE_TITLES.UPDATE_POLICY);
      dispatch(getPoliciesByName(policy, setLoading, setInitialValues));
    }
  }, [dispatch, isEdit, policy]);
  useEffect(() => {
    dispatch(getAllResources(setLoading));
  }, [dispatch]);
  const resources = useSelector((state) => state.Resources.resources);
  useEffect(() => {
    if (resources?.length > 0) {
      const filteredResources = resources?.filter(resource => !resource.isCurrency);
      schema.fields[1].options = filteredResources?.map((resource) => ({
        value: resource?.name,
        label: resource?.name,
        unitofmeasurement: resource?.unitofmeasurement
      }));
      setEnableFormReinitialize(true);
    }
  }, [resources]);
  
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={enableFormReinitialize}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (
          values?.policyLabel !== "" &&
          values?.resource !== "" &&
          values?.attributeList?.length > 0
        ) {
          const formattedValues = {
            policyLabel: values?.policyLabel,
            resourceName: values?.resource?.value,
            rules: values?.attributeList.map(attr => ({
              rangeStart: parseInt(attr?.rangeStarts),
              rangeEnd: parseInt(attr?.rangeEnds),
              statusLabel: attr?.statusLabel
            }))
          };
          if (!isEdit) {
            dispatch(createPolicies(formattedValues, setLoading, navigate));
          } else {
            dispatch(updatePolicies(setLoading, formattedValues, navigate));
          }
        }
      }}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
        setTouched,
      }) => (
        <Form className="needs-validation" onSubmit={handleSubmit}>
          <div>
            <ToastContainer position="top-center" />
            {loading && <Loader />}
            <div className="page-content">
              <Container fluid>
                <BreadCrumb title={title} pageTitle="Policy" />
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <div className="live-preview">
                          <Row className="gy-4">
                            <PolicyForm
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              schema={schema}
                              isEdit={isEdit}
                            />
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                    <Attribute
                      values={values}
                      touched={touched}
                      errors={errors}
                      initialValues={initialValues}
                      setTouched={setTouched}
                      handleChange={handleChange}
                      handleChangeForAttributeValue={handleChangeForAttributeValue}
                      isEmptyOrInvalidField={isEmptyOrInvalidField}
                      deviceID={deviceID}
                      isEdit={isEdit}
                    />
                    <Row lg={6} className="justify-content-end mb-4">
                      <div className="live-preview">
                        <div className="d-flex justify-content-end gap-2">
                          <CommonButton color={"cancel-button-color"} type={"reset"} buttonAction={() => navigate('/policies')} buttonText={"Cancel"} />
                          <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} />
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
