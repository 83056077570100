import { toast } from "react-toastify";
import { fetchEditStationFailure, fetchEditStationSuccess } from "./reducer";
import { STATION_LISTING_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { setSuccessMessage } from "../../toastMessage/action";

export const editStation = (jsonObj, state, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${STATION_LISTING_API}/${state.stationDetails.stationID}`, 'PUT', jsonObj).then((response) => {
        if (response.error) {
            dispatch(fetchEditStationFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
        if (!response.error) {
            setLoading(false)
            dispatch(fetchEditStationSuccess(response))
            navigate('/station');
            dispatch(setSuccessMessage(true))
        }
    }).catch((errorResp) => { })
};