import { POPULAR_PLAN } from "../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor"
import { fetchGetPopularPlanFailure, fetchGetPopularPlanSuccess } from "./reducer"

export const getPopularPlan= (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response =  await determineBaseUrlAndPerformApiCall(`${POPULAR_PLAN}`, 'GET')
        setLoading(false)
        dispatch(fetchGetPopularPlanSuccess(response))
    }
    catch (error) {
        setLoading(false)
        dispatch(fetchGetPopularPlanFailure(error.message))
    }
}