import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    createservices: [],
    loading: false,
    error: null,
}

const createServicesSlice = createSlice({
    name: "createservices",
    initialState,
    reducers: {
        fetchCreateServicesSuccess: (state, action) => {
            state.loading = false,
                state.createservices = action.payload;
        },
        fetchCreateServicesFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {    
    fetchCreateServicesSuccess,
    fetchCreateServicesFailure,
} = createServicesSlice.actions;

export default createServicesSlice.reducer;