import moment from "moment";

const setEmptyValues = (obj, prefix, idx, fields) => {
    fields.forEach(field => {
        obj[`${prefix} ${field} ${idx}`] = '';
    });
};

export const convertOfferFieldsForExporting = (offer) => {
    let offerExportDataArray = []
    let maxCharges = Math.max(...offer.map(value => value.Charges ? value.Charges.length : 0));
    let maxGrants = Math.max(...offer.map(value => value.Grants ? value.Grants.length : 0));
    let maxUsage = Math.max(...offer.map(value => value.usageRatePlan ? value.usageRatePlan.length : 0));

    offer.forEach((values) => {
        let offerObj = {
            "Offer Code": values.offerCode,
            "Offer Name": values.offerName,
            "Offer Type": values.offerType,
            "Description": values.description,
            "Effective At": moment(values.effectiveAt).format('DD-MM-YYYY hh:mm:ss a'),
            "Global Scope": values.globalScope,
            "Service Code": values.serviceCode ? values.serviceCode : "NA",
            "Offer Start Date and Time": moment(values.validity.from).format('DD-MM-YYYY hh:mm:ss a'),
            "Offer End Date and Time": moment(values.validity.to).format('DD-MM-YYYY hh:mm:ss a'),
            "Policy Specification": values.policySpecification || '',
            "Subscription Validity End Time Type": values?.subscriptionValidity?.endTimeType,
            "Subscription Validity Relative End Time Unit": values?.subscriptionValidity?.relativeEndTimeUnit,
            "Subscription Validity Relative End Time Value": values?.subscriptionValidity?.relativeEndTimeValue,
            "Subscription Validity Relative Start Time Unit": values?.subscriptionValidity?.relativeStartTimeUnit,
            "Subscription Validity Relative Start Time Value": values?.subscriptionValidity?.relativeStartTimeValue,
            "Subscription Validity Start Time Type": values?.subscriptionValidity?.startTimeType,
        }

        if (values.Charges && values.Charges.length) {
            new Array(maxCharges).fill().forEach((_, idx) => {
                if (idx < values.Charges.length) {
                    const charge = values.Charges[idx];
                    offerObj[`Charges Amount ${idx}`] = charge.amount
                    offerObj[`Charges glCode ${idx}`] = charge.glCode
                    offerObj[`Charges Id ${idx}`] = charge.id
                    offerObj[`Charges Prorate ${idx}`] = charge.prorate
                    offerObj[`Charges Recurring Frequency ${idx}`] = charge.recurringFrequency
                    offerObj[`Charges Resource Id ${idx}`] = charge.resourceId
                    offerObj[`Charges Resource Name $idx`] = charge.resourceName
                    offerObj[`Charges Tax Code ${idx}`] = charge.taxCode
                    offerObj[`Charges Type ${idx}`] = charge.type
                    offerObj[`Charges Start Date and Time`] = moment(charge.valid.from).format('DD-MM-YYYY hh:mm:ss a')
                    offerObj[`Charges End Date and Time`] = moment(charge.valid.to).format('DD-MM-YYYY hh:mm:ss a')
                }
                else {
                    setEmptyValues(offerObj, 'Charges', idx, ['Amount', 'glCode', 'Id', 'Prorate', 'Recurring Frequency', 'Resource Id', 'Resource Name', 'Tax Code', 'Type', 'Start Date and Time', 'End Date and Time']);
                }
            })
        }
        else {
            new Array(maxCharges).fill().forEach((_, idx) => {
                setEmptyValues(offerObj, 'Charges', idx, ['Amount', 'glCode', 'Id', 'Prorate', 'Recurring Frequency', 'Resource Id', 'Resource Name', 'Tax Code', 'Type', 'Start Date and Time', 'End Date and Time']);
            })
        }

        if (values.Grants && values.Grants.length) {
            new Array(maxGrants).fill().forEach((_, idx) => {
                if (idx < values.Grants.length) {
                    const grant = values.Grants[idx];
                    offerObj[`Grants Amount ${idx}`] = grant.amount
                    offerObj[`Grants Charge Id ${idx}`] = grant.chargeId
                    offerObj[`Grants Created At ${idx}`] = moment(grant.createdAt).format('DD-MM-YYYY hh:mm:ss a')
                    offerObj[`Grants Custom Validity End Time Type ${idx}`] = grant.customValidity?.endTimeType
                    offerObj[`Grants Custom Validity Relative End Time Unit ${idx}`] = grant.customValidity?.relativeEndTimeUnit
                    offerObj[`Grants Custom Validity Relative End Time Value ${idx}`] = grant.customValidity?.relativeEndTimeValue
                    offerObj[`Grants Custom Validity Relative Start Time Unit ${idx}`] = grant.customValidity?.relativeStartTimeUnit
                    offerObj[`Grants Custom Validity Relative Start Time Value ${idx}`] = grant.customValidity?.relativeStartTimeValue
                    offerObj[`Grants Custom Validity Start Time Type ${idx}`] = grant.customValidity?.startTimeType
                    offerObj[`Grants Description ${idx}`] = grant.description
                    offerObj[`Grants glCode ${idx}`] = grant.glCode
                    offerObj[`Grants Increment ${idx}`] = grant.increment
                    offerObj[`Grants Measuring Units ${idx}`] = grant.measuringUnits
                    offerObj[`Grants Minimum ${idx}`] = grant.minimum
                    offerObj[`Grants Name ${idx}`] = grant.name
                    offerObj[`Grants Prorate ${idx}`] = grant.prorate
                    offerObj[`Grants Recurring Frequency ${idx}`] = grant.recurringFrequency
                    offerObj[`Grants Resource Id ${idx}`] = grant.resourceId
                    offerObj[`Grants Resource Name $idx`] = grant.resourceName
                    offerObj[`Grants Threshold ${idx}`] = grant.threshold
                    offerObj[`Grants Type ${idx}`] = grant.type
                    offerObj[`Grants Start Date and Time`] = moment(grant.valid.from).format('DD-MM-YYYY hh:mm:ss a')
                    offerObj[`Grants End Date and Time`] = moment(grant.valid.to).format('DD-MM-YYYY hh:mm:ss a')
                }
                else {
                    setEmptyValues(offerObj, 'Grants', idx, ['Amount', 'Charge Id', 'Created At', 'Custom Validity End Time Type', 'Custom Validity Relative End Time Unit', 'Custom Validity Relative End Time Value', 'Custom Validity Relative Start Time Unit', 'Custom Validity Relative Start Time Value', 'Custom Validity Start Time Type', 'Description', 'glCode', 'Increment', 'Measuring Units', 'Minimum', 'Name', 'Prorate', 'Recurring Frequency', 'Resource Id', 'Resource Name', 'Threshold', 'Type', 'Start Date and Time', 'End Date and Time']);
                }
            })
        }
        else {
            new Array(maxGrants).fill().forEach((_, idx) => {
                setEmptyValues(offerObj, 'Grants', idx, ['Amount', 'Charge Id', 'Created At', 'Custom Validity End Time Type', 'Custom Validity Relative End Time Unit', 'Custom Validity Relative End Time Value', 'Custom Validity Relative Start Time Unit', 'Custom Validity Relative Start Time Value', 'Custom Validity Start Time Type', 'Description', 'glCode', 'Increment', 'Measuring Units', 'Minimum', 'Name', 'Prorate', 'Recurring Frequency', 'Resource Id', 'Resource Name', 'Threshold', 'Type', 'Start Date and Time', 'End Date and Time']);
            })
        }

        if (values.usageRatePlan && values.usageRatePlan.length) {
            new Array(maxUsage).fill().forEach((_, idx) => {
                if (idx < values.usageRatePlan.length) {
                    const usage = values.usageRatePlan[idx];
                    offerObj[`Usage Rate Plan Event Type ${idx}`] = usage.eventType
                    offerObj[`Usage Rate Plan Id ${idx}`] = usage.id
                    offerObj[`Usage Rate Plan Priority ${idx}`] = usage.priority
                    offerObj[`Usage Rate Plan Rate Tier Flat Rate ${idx}`] = usage.rateTier[idx]?.flatRate
                    offerObj[`Usage Rate Plan Rate Tier glCode ${idx}`] = usage.rateTier[idx]?.glCode
                    offerObj[`Usage Rate Plan Rate Tier Id ${idx}`] = usage.rateTier[idx]?.id
                    offerObj[`Usage Rate Plan Rate Tier Max Quantity ${idx}`] = usage.rateTier[idx]?.maxQuantity
                    offerObj[`Usage Rate Plan Rate Tier Min Quantity ${idx}`] = usage.rateTier[idx]?.minQuantity
                    offerObj[`Usage Rate Plan Rate Tier Per Unit Rate ${idx}`] = usage.rateTier[idx]?.perUnitRate
                    offerObj[`Usage Rate Plan Rate Tier Tier Type ${idx}`] = usage.rateTier[idx]?.tierType
                    offerObj[`Usage Rate Plan Resource Name ${idx}`] = usage.resourceName
                    offerObj[`Usage Rate Plan Uom Name ${idx}`] = usage.uomName
                }
                else {
                    setEmptyValues(offerObj, 'Usage Rate Plan', idx, ['Event Type', 'Id', 'Priority', 'Rate Tier Flat Rate', 'Rate Tier glCode', 'Rate Tier Id', 'Rate Tier Max Quantity', 'Rate Tier Min Quantity', 'Rate Tier Per Unit Rate', 'Rate Tier Tier Type', 'Resource Name', 'Uom Name']);
                }
            })
        }
        else {
            new Array(maxUsage).fill().forEach((_, idx) => {
                setEmptyValues(offerObj, 'Usage Rate Plan', idx, ['Event Type', 'Id', 'Priority', 'Rate Tier Flat Rate', 'Rate Tier glCode', 'Rate Tier Id', 'Rate Tier Max Quantity', 'Rate Tier Min Quantity', 'Rate Tier Per Unit Rate', 'Rate Tier Tier Type', 'Resource Name', 'Uom Name']);
            })
        }
        offerExportDataArray.push(offerObj)
    })
    return offerExportDataArray
}