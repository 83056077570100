import { MaterialReactTable } from "material-react-table";
import { BillsColumns } from "./constants/CustomerTableColumns";
import { Card ,Button} from "reactstrap";
export default function Bills({billData}) {
  const columns = BillsColumns(billData);
  return (
    <Card>
      <MaterialReactTable
        columns={columns}
        data={billData}
        muiTablePaperProps={{
          elevation: 0,
        }}
        defaultColumn={{
          minSize: 10,
          maxSize: 9001,
          muiTableBodyCellProps: {
              sx: {
                  fontFamily: "Inter,sans-serif"
              }
          },
          muiTableHeadCellProps: {
              sx: {
                  fontFamily: "Inter,sans-serif"
              }
          }
      }}
        enableBottomToolbar={false}
        enableColumnActions={false}
        enableDensityToggle={false}
        enablePagination={false}
      />
    </Card>
  );
}
