import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    invoiceList : [],
    loading : false,
    error : null,
};

const invoiceListSlice = createSlice({
    name:"invoiceList",
    initialState,
    reducers: {
        fetchInvoiceListSuccess: (state,action) => {
            state.loading = false;
            state.invoiceList = action.payload
        },
        fetchInvoiceListFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchInvoiceListSuccess,
    fetchInvoiceListFailure
} = invoiceListSlice.actions

export default invoiceListSlice.reducer