import React, { useEffect, useRef, useState } from 'react'
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Row, Col, Button } from 'reactstrap'
import { MaterialReactTable } from 'material-react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllService } from '../../../slices/services/thunk';
import { useColumns } from './constants/coloumns';
import { Loader } from '../../Utils/Loader';
import moment from 'moment';
import { handleExportData } from '../../Utils/ExportToCsv';
import { setEditSuccessMessage, setSuccessMessage } from '../../../slices/toastMessage/action';
import { ToastContainer, toast } from 'react-toastify';
import { exportDataForService } from './constants/exportDataForService';
import { PAGE_TITLES } from '../../Common/constants';
import { MenuItem } from "@mui/material";
import CommonModal from '../../Utils/CommonModal';
import { deleteService } from '../../../slices/services/deleteservice/thunk';
import { getConfig } from '../../../slices/services/addservice/eventconfiguration/thunk';
import { bulkDelete } from '../../../slices/BulkDelete/thunk';

function ServicesListings() {
    const [services, setServices] = useState([])
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false);
    const [messageForPopUp, setMessageForPopUp] = useState('')
    const [serviceName, setServiceName] = useState("");
    const [modalForBulkDelete, setModalForBulkDelete] = useState(false)
    const [selectedRowsForBulkDelete, setSelectedRowsForBulkDelete] = useState()
    const [serviceArray, setServiceArray] = useState([]);
    const allServiceData = [];
    const allStartDate = [];
    const allEndDate = [];
    const allModifyDate = [];
    const isBundle = [];
    const isProvisioning = [];
    const isUsageAttribute = [];
    let navigate = useNavigate()
    const dispatch = useDispatch();
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);
    const permissions = useSelector((state) => state.permissions.permissions);
    const servicePermission = permissions.find((resource) => resource.resourceName === 'service');
    const [selectAllService, setSelectAllService] = useState(false)
    const [selectedService, setSelectedService] = useState([])
    const checkboxRef = useRef(null);
    const isMobile = window.innerWidth <= 768;

    const toggleModal = (id, singleOrMulti) => {
        if (singleOrMulti === "single") {
            setServiceName(id);
            setModal(!modal);
        }
        else {
            setSelectedRowsForBulkDelete(id)
            setModalForBulkDelete(!modalForBulkDelete)
        }
        document.body.style.overflow = 'auto'
    };

    useEffect(() => {
        dispatch(getAllService(setLoading))
        dispatch(getConfig());
    }, [dispatch])

    const serviceData = useSelector((state) => state.Services.services)

    const navigateToAddService = () => {
        let path = "/create-service"
        navigate(path)
    }

    useEffect(() => {
        if (checkboxRef.current) {
          checkboxRef.current.indeterminate = selectedService.length > 0 && selectedService.length < serviceData.length;
        }
        setSelectAllService(selectedService?.length > 0 && serviceData?.length > 0 && selectedService?.length === serviceData?.length);
      }, [selectedService, serviceData.length, setSelectAllService]);

    const columns = useColumns(allStartDate, allEndDate, allModifyDate, isBundle, isProvisioning, isUsageAttribute, selectAllService, setSelectAllService, serviceData, setSelectedService, selectedService, checkboxRef, isMobile);
    serviceData.forEach((service, i) => {
        const EndDateObj = moment.utc(service?.Validity?.to);
        const endDate = EndDateObj.format('D MMM, YYYY');
        allEndDate[i] = endDate;
        const ModifiedDateObj = moment.utc(service?.modifiedDate);
        const modifyDate = ModifiedDateObj.format('D MMM, YYYY');
        allModifyDate[i] = modifyDate;
        const StartDateObj = moment.utc(service?.Validity?.from);
        const startTime = StartDateObj.format('D MMM, YYYY');
        allStartDate[i] = startTime;
        isBundle[i] = services[i]?.isBundle ? "Yes" : "No";
        isProvisioning[i] = services[i]?.provisioningAttributes ? "Provisioning" : "";
        isUsageAttribute[i] = services[i]?.usageAttributes ? "Usage" : "";
    });

    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Service Created Successfully`);
            dispatch(setSuccessMessage(false))
        }
        if (showEditSuccessMessage) {
            toast.success(`Service Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage,dispatch])
    const exportData = exportDataForService(serviceData)
    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.SERVICE_LIST} pageTitle={PAGE_TITLES.PRICING} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <div className="listingjs-table " id="customerList">
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {loading && (<Loader />)}
                                            {services ?
                                                <MaterialReactTable
                                                    columns={columns}
                                                    data={serviceData}
                                                    enableColumnOrdering
                                                    enableColumnDragging
                                                    enableDensityToggle={false}
                                                    displayColumnDefOptions={{
                                                        'mrt-row-actions': {
                                                            header: '',
                                                            enableHiding: true,
                                                            size: isMobile ? 55 : 38,
                                                            grow: true
                                                        },
                                                    }}
                                                    defaultColumn={{
                                                        minSize: 1,
                                                        maxSize: 9001,
                                                        size: 260,
                                                        muiTableBodyCellProps: {
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif"
                                                            }
                                                        },
                                                        muiTableHeadCellProps: {
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif"
                                                            }
                                                        }
                                                    }}
                                                    layoutMode='grid'
                                                    initialState={{
                                                        columnOrder: [
                                                            'checkbox',
                                                            'mrt-row-actions',
                                                            'Name',
                                                            'Service Code',
                                                            'Attributes',
                                                            'Bundled',
                                                            'Validity',
                                                            'Last Modified'
                                                        ]
                                                    }}
                                                    enableRowActions
                                                    renderRowActionMenuItems={({ closeMenu, row }) => [
                                                        <MenuItem className='row-options' key={row.index} onClick={() => { navigate(`/clone-service/${row.original.serviceCode}`) }} ><i className="ri ri-file-copy-2-line me-2" /> Clone</MenuItem>,
                                                        <MenuItem className='row-options' key={row.index} onClick={()=>{ navigate (`/edit-service/${row.original.serviceCode}`)}}><i className="bx bx-edit me-2"/> Edit</MenuItem>,
                                                        <MenuItem className='row-options'
                                                            key={row.index}
                                                            onClick={() => {
                                                                setMessageForPopUp(`Are you sure you want to delete this service "${row.original.name}"?`)
                                                                toggleModal(row, "single");
                                                                closeMenu();
                                                            }}
                                                        >
                                                            <i className="ri ri-delete-bin-5-line me-2" />{" "}
                                                            Delete
                                                        </MenuItem>,
                                                    ]}
                                                    positionToolbarAlertBanner='none'
                                                    muiSelectCheckboxProps={{
                                                        color: 'warning'
                                                    }}
                                                    muiSelectAllCheckboxProps={{
                                                        color: 'warning'
                                                    }}
                                                    renderTopToolbarCustomActions={({ table }) => (
                                                        <div className="d-flex flex-column flex-md-row align-items-md-center">
                                                            <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(services) }}>
                                                                <i className="ri-upload-line label-icon align-middle fs-16 me-2 " ></i> Export Data
                                                            </Button>
                                                            {servicePermission && servicePermission.permissionList.includes('create') && <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={navigateToAddService}>
                                                                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Service
                                                            </Button>}
                                                            {selectedService?.length ? <Button color='main-color' className='btn me-2' onClick={() => {
                                                                const names = selectedService.map(row => row.serviceCode);
                                                                let uniqueServices = Array.from(new Set(names))
                                                                setServiceArray(uniqueServices);
                                                                setMessageForPopUp(`Are you sure you want to delete the selected items?`)
                                                                toggleModal(selectedService, "multi")
                                                            }}>Delete</Button> : null}
                                                        </div>
                                                    )}
                                                    icons={{
                                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                    }}
                                                />
                                                : null
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <CommonModal messageForPopUp={messageForPopUp} toggle={() => { if (modal) { toggleModal(serviceName, "single") } else { toggleModal(selectedRowsForBulkDelete, "multi") } }} open={modalForBulkDelete ? modalForBulkDelete : modal} buttonText={"Yes, Delete It"} modalAction={() => {
                if (modal) {
                    dispatch(deleteService(serviceName, setLoading))
                    toggleModal(serviceName, "single");
                }
                else {
                    let jsonObj = { ids: serviceArray, type: "Service" }
                    dispatch(bulkDelete(jsonObj, setLoading))
                    setSelectAllService(false)
                    setSelectedService([])
                    toggleModal(selectedRowsForBulkDelete, "multi")
                }
            }} />
        </React.Fragment>
    )
}
export default ServicesListings;