import { toast } from "react-toastify";
import { fetcheditResourceSuccess,fetcheditResourceFailure } from "./reducer";
import { setEditSuccessMessage } from "../../toastMessage/action";
import { ADD_RESOURCE_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";

export const editResource = (jsonObj,resourceName, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${ADD_RESOURCE_API}/${resourceName}`, 'PATCH', jsonObj).then((response) => {
        if (response.error) {
            dispatch(fetcheditResourceFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
        if (!response.error) {
            setLoading(false)
            dispatch(fetcheditResourceSuccess(response))
            dispatch(setEditSuccessMessage(true))
            navigate('/resource');
        }
    }).catch((errorResp) => { })
};