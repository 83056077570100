import { DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchDocumentDeleteFailure, fetchDocumentDeleteSuccess } from "./reducer";
import { toast } from "react-toastify";
import { setDocumentSuccessMessage } from "../../toastMessage/action";
import { fetchDocumentsForCustomer } from "../documentlist/thunk";

export const deleteDoc = (setLoading, jsonObj, customerId, role) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API}`, 'DELETE', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchDocumentDeleteSuccess(response))
            dispatch(fetchDocumentsForCustomer(setLoading, customerId))
            if (role !== "User" && role !== "Operator") {
                toast.success(
                    'Document Deleted Successfully'
                )
                dispatch(setDocumentSuccessMessage(true))
            }
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchDocumentDeleteFailure(response.error.message))
        }
        setLoading(false)
    }).catch((error) => { })
}