import { Card, CardBody, Row, Col, Button, FormFeedback } from "reactstrap"
import { LabelText } from "../Utils/Input"
import SelectField from "../Utils/SelectField"
import { DocumentUploadOptions } from "./constants/constantValues"
import InputField from "../Utils/InputField"
import { documentTableColumns } from "./constants/CustomerTableColumns"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { deleteDocument, emptyFieldsOfDocuments, getFile, handleChangeForDocumentName, handleChangeForDocumentType, payload, submitDocument, toggleModal, setFieldEmpty, verifyDocumentApiCall } from "./constants/HandleChangeFunctions"
import { customerIdData } from "./constants/constantFunctionsView"
import UploadDocumentTable from "./UploadDocumentTable"
import { ToastContainer, toast } from "react-toastify"
import { Loader } from "../Utils/Loader"
import { documentDataFunction } from "./constants/CustomerJsonObj"
import CommonModal from "../Utils/CommonModal"
import { deleteDoc } from "../../slices/customer/documentdelete/thunk"
import { clearDocFunction, displayVerifyModal, uploadSubmitFunction } from "./constants/DocumentTableFunctions"

export default function UploadDocument({ fetchDocumentData, isEdit, open, setIsOpen, file, setFile, fileName, setFileName, values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, setState }) {
    const [messageForPopUp, setMessageForPopUp] = useState('')
    const [buttonText, setButtonText] = useState('')
    const [fields, setFields] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editingRow, setEditingRow] = useState(null)
    const [editedDocumentName, setEditedDocumentName] = useState('');
    const [editedDocumentType, setEditedDocumentType] = useState(null);
    const [validationTriggered, setValidationTriggered] = useState(false);
    const [profileData,setProfileData] = useState()
    const roleName = localStorage.getItem('roleName')

    const customerId = roleName === "Customer" 
    ? localStorage.getItem('customerId') 
    : customerIdData();
    const [fileSizeValid, setFileSizeValid] = useState(true); 
    const dispatch = useDispatch()
    let approvePayload = {}
    let rejectPayload = {}
    let documentsOfCustomer = []
    let partnerOrCustomer = ""
    documentsOfCustomer = documentDataFunction(fetchDocumentData)

    const columns = documentTableColumns(documentsOfCustomer, open, setIsOpen, setButtonText, setMessageForPopUp, fields, setFields, handleChange, editingRow, editedDocumentName, setEditedDocumentName, editedDocumentType, setEditedDocumentType, customerId, approvePayload, rejectPayload, dispatch, setLoading)

    return (
        <div>
            {isEdit &&
                <Card>
                    <h5 className="px-3 pt-3">Documents</h5>
                    <hr className="m-0 mt-1 hr-width ms-3" />
                    {/* <ToastContainer position="top-center" /> */}
                    {loading && (<Loader />)}
                    <CardBody>
                        <Row className="gy-4">
                            <Col xxl={3} md={6}>
                                <LabelText displayText={"Document Type"}  />
                                <SelectField handleChange={handleChangeForDocumentType(setFieldValue, setFieldTouched, setState)} className={(errors.documentType && touched.documentType) || false ? 'error-input' : ''} name={'documentType'} value={values.documentType} options={DocumentUploadOptions} />
                                {touched.documentType && errors.documentType ? (
                                    <p className="text-danger">
                                        {errors.documentType}
                                    </p>
                                ) : null}
                            </Col>
                            <Col xxl={3} md={6}>
                                <LabelText htmlFor={"documentName"} displayText={"Document Name"} />
                                <InputField name={"documentName"} type={"text"} className={"form-control"} handleChange={handleChangeForDocumentName(setState, handleChange)} handleBlur={handleBlur} value={values.documentName}
                                    invalid={touched.documentName && errors.documentName
                                        ? true
                                        : false} />
                                {touched.documentName &&
                                    errors.documentName ? (
                                    <FormFeedback type="invalid">
                                        {errors.documentName}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                            <Col xxl={3} md={6}>
                                <LabelText htmlFor={'selectedFile'} displayText={"Attachments"}/>
                                <InputField
                                    name={"selectedFile"}
                                    className={'form-control'}
                                    handleBlur={handleBlur} accept={'.png, .jpg, .jpeg, .pdf'}
                                    handleChange={getFile(setFile, setFileName, handleChange, setState, setValidationTriggered,setFileSizeValid)}
                                    value={values.selectedFile}
                                    type={'file'}
                                    invalid={validationTriggered}
                                />
                                {(validationTriggered) ? (
                                    <FormFeedback type="invalid">
                                        {file ? "File size exceeds the limit (10MB)." : "Kindly select a file"}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                            <Col className=" d-flex justify-content-end align-items-center mt-5">
                                <Button className="me-2" type="button" disabled={!fileSizeValid} onClick={() => uploadSubmitFunction(values, file, customerId, dispatch, setLoading, setFieldEmpty, setFieldValue, setState, setFieldTouched)}>Submit</Button>
                                <Button onClick={() => clearDocFunction(emptyFieldsOfDocuments, setFieldValue)}>Clear</Button>
                            </Col>
                        </Row>
                        <div className="mt-3">
                            <UploadDocumentTable setProfileData={setProfileData} partnerOrCustomer={partnerOrCustomer} setLoading={setLoading} dispatch={dispatch} customerId={customerId} setIsOpen={setIsOpen} open={open} toggleModal={toggleModal} setFields={setFields} setEditingRow={setEditingRow} setEditedDocumentName={setEditedDocumentName} setEditedDocumentType={setEditedDocumentType} deleteDocument={deleteDocument} setButtonText={setButtonText} setMessageForPopUp={setMessageForPopUp} columns={columns} docTableMockData={documentsOfCustomer} />
                        </div>
                    </CardBody>
                    {open ? (
                        <CommonModal toggle={()=>toggleModal(setIsOpen, open)} open={open} messageForPopUp={messageForPopUp} buttonText={buttonText} modalAction={() => {
                            displayVerifyModal(buttonText,dispatch,payload,setLoading,customerId,setIsOpen,open)
                        }} />
                    ) : <></>}
                </Card>}
        </div>
    )
}