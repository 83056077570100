import { fetchDeviceSuccess, fetchDeviceFailure } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { DEVICE_API_LISTING } from "../../constants/constants";

export const getAllDevice = (setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const response = await determineBaseUrlAndPerformApiCall(
      `${DEVICE_API_LISTING}`,
      "GET"
    );
    setLoading(false);
    dispatch(fetchDeviceSuccess(response.reverse()));
  } catch (error) {
    dispatch(fetchDeviceFailure(error.message));
  }
};
