import CreatableSelect from 'react-select/creatable';

export const CreatableSelectField = ({
  name,
  options,
  isMulti,
  values,
  handleChange,
  placeholder,
  onKeyDown,
  fieldLabel,
  fieldName,
  setFieldError,
  setFieldValue,
  errors,
  touched,
  valuess,
  isAttribute
}) => {
  const customStyles = {
    control: (styles, state) => ({
      ...styles,
      borderRadius: '0.5rem',
      borderColor: errors && touched ? 'red' : styles.borderColor, // Apply red border if there is an error
      '&:hover': {
        borderColor: errors && touched ? 'red' : styles['&:hover'].borderColor
      }
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : provided.color,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#0078A3",
      color: "white",
    }),
  };
  
  const handleSelectChange = (selectedOptions) => {
    if (fieldName === "SelfcareAttribute" && selectedOptions.length > 2) {
      setFieldError(name, "You can select only up to 2 options");
    } else {
      setFieldError(name, ""); 
      setFieldValue(name, selectedOptions); 
    }
  };

  return (
    <>
      <CreatableSelect
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#015E7F",
            primary25: "#015E7F",
            primary50: "#015E7F",
            primary75: "#015E7F",
          },
        })}
        value={values}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        onChange={isAttribute ? handleSelectChange : handleChange}
        styles={customStyles}
        name={name}
        isMulti={isMulti}
        options={options}
        noOptionsMessage={() => "Create a new option"}
      />
      {touched && errors && (
        <div className="text-danger mt-1 fs-6">{errors}</div>
      )}
    </>
  );
};
