export const recurringFrequencyOptions = [
    { value: "0", label: "Yearly" },
    { value: "1", label: "Monthly" },
    { value: "3", label: "Half-Yearly" },
    { value: "2", label: "Quarterly" }
]

export const TimePeriodOptions = [
    { value: '6', label: 'Yearly' },
    { value: '5', label: 'Semi-Annually' },
    { value: '4', label: 'Quarterly' },
    { value: '3', label: 'Months' },
    { value: '2', label: 'Days' },
    { value: '1', label: 'Seconds' },
]
export const TimePeriodGrantOptions = [
    { value: 6, label: "Annual" },
    { value: 5, label: 'Semi Annual' },
    { value: 4, label: 'Quarterly' },
    { value: 3, label: 'Monthly ' },
    { value: 7, label: 'Billing Cycle' },
]

export const TimePeriodEndOptions = [
    { value: '6', label: 'Yearly' },
    { value: '5', label: 'Semi-Annually' },
    { value: '4', label: 'Quarterly' },
    { value: '3', label: 'Months' },
]

export let SubscriptionEndsOnOptions = [
    { value: 3, label: "Relative" },
    { value: 0, label: "Indefinite" },
]
export let GrantsValidityEnd = [
    { value: 3, label: "Relative" },
    { value: 0, label: "Forever" },
]
export const SubscriptionStartsOnOptions = [
    { value: 1, label: "Immediate Usage" },
    { value: 3, label: "Relative" },
]
export const ValidityStartsOnOptions = [
    { value: 1, label: "Immediate Usage" },

]
export const CycleAlignmentOption = [
    { value: 0, label: "Billing Cycle" }
]

export let initialValues = {
    offerName: '',
    offerCode: '',
    subscriptionStartsOn: '',
    timePeriodStart: 1,
    timePeriodOptionStart: TimePeriodOptions[0],
    subscriptionEndsOn: '',
    timePeriod: 1,
    timePeriodOption: TimePeriodEndOptions[0],
    description: '',
    offerStartsOn: '',
    offerEndsOn: '',
    usageId: "1",
    eventType: '',
    resourceName: '',
    unitOfMeasurement: '',
    priority: "1",
    perUnitRate: '',
    flatRate: '',
    serviceName: '',
    usageRatePlan: [],
    recurringUsageRatePlan: [],
    grants: [],
    recurringGrants: [],
    charges: [],
    recurringCharges: []
}

export const infiniteOptions = [
    { value: 'ceiling', label: 'Ceiling' },
    { value: 'floor', label: 'Floor' },
];

export const pricingModelOptions = [
    { label: 'Fixed', value: 'threshold' },
    { label: 'Tier', value: 'volume' }
];

export const importInitialValues = {
    csvFile: ""
}

export const timePeriodOption = (values, setFieldValue) => (val) => {
    values.timePeriodOption = val
    setFieldValue(`timePeriodOption`, val);
}

export const timePeriodOptionStart = (values, setFieldValue) => (val) => {
    values.timePeriodOptionStart = val
    setFieldValue(`timePeriodOptionStart`, val);
}

export const handleStartDate = (handleStartDateChange, setStartDate, setFieldTouched, setFieldValue) => (data) => {
    handleStartDateChange(data, setStartDate);
    setFieldTouched(`offerStartsOn`, false)
    setFieldValue('offerStartsOn', data[0], false)
}

export const handleEndDate = (setFieldTouched, setFieldValue) => (data) => {
    setFieldTouched(`offerEndsOn`, false)
    setFieldValue('offerEndsOn', data, false)
}

export const handleEffectiveAtDate = (setFieldTouched, setFieldValue) => (data) => {
    setFieldTouched(`effectiveAt`, false)
    setFieldValue('effectiveAt', data, false)
}

export const validityStartOptions = [
    { value: 1, label: 'Immediate' },
    { value: 2, label: 'First Usage' },
    { value: 3, label: 'Specific Time' },
];

export const validityTimeOptions = [
    { value: 1, label: 'Day(s)' },
    { value: 2, label: 'Week(s)' },
    { value: 3, label: 'Month(s)' },
    { value: 4, label: 'Year(s)' },
];

export const validityEndOptions = [
    { value: 1, label: 'Relative' },
    { value: 2, label: 'Specific Time' },
];
