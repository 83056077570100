import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mobile: Yup.string().required('Contact No is required'),
    addressLine1: Yup.string().required('Address 1 is required'),
    zipCode: Yup.string().required('Zipcode is required'),
});

export default validationSchema;
