import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteAttribute: [],
    loading: false,
    error: null,
}

const deleteAttributeSlice = createSlice({
    name: "deleteAttribute",
    initialState,
    reducers: {
        fetchDeleteAttributeSuccess: (state, action) => {
            state.loading = false
            state.deleteAttribute = action.payload;
        },
        fetchDeleteAttributeFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchDeleteAttributeSuccess,
    fetchDeleteAttributeFailure,
} = deleteAttributeSlice.actions;

export default deleteAttributeSlice.reducer;