import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    createAttribute: [],
    loading: false,
    error: null,
}

const createAttributeSlice = createSlice({
    name: "createAttribute",
    initialState,
    reducers: {
        fetchCreateAttributeSucess: (state, action) => {
            state.loading = false
            state.createAttribute = action.payload;
        },
        fetchCreateAttributeFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchCreateAttributeSucess,
    fetchCreateAttributeFailure,
} = createAttributeSlice.actions;

export default createAttributeSlice.reducer;