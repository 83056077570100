import { fetchEditDeviceSuccess, fetchEditDeviceFailure } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { setEditSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";
import { DEVICE_API } from "../../constants/constants";

export const updateDevice =
  (jsonObj, setLoading, navigate, deviceId) => async (dispatch) => {
    setLoading(true);
    await determineBaseUrlAndPerformApiCall(
      `${DEVICE_API}/${deviceId}`,
      "PUT",
      jsonObj
    )
      .then((response) => {
        if (!response.error) {
          setLoading(false)
          dispatch(fetchEditDeviceSuccess(response));
          navigate("/devices");
          dispatch(setEditSuccessMessage(true));
        }
        if (response.error) {
          setLoading(false)
          dispatch(fetchEditDeviceFailure(response.error.message));
          toast.error(`${response.error.message}`);
        }
      })
      .catch((errorResp) => { });
  };
