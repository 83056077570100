import * as Yup from 'yup';

export const validationSchemaForUser = (changePassword) => {
    let validationSchema = {}
    return validationSchema = Yup.object().shape({
    firstname: Yup.string().required('Kindly Enter the first name'),
    lastname: Yup.string().required('Kindly Enter the last name'),
    username: Yup.string().required('Kindly Enter the user name'),
    userrole: Yup.mixed().required('Kindly Enter the user role name'),
    phone: Yup.number().required('Kindly Enter the phone').test('len', 'Must be exactly 10 digits', val => val?.toString().length === 12),
    email: Yup.string().email('Kindly enter valid email').required('Kindly enter Email'),
    newPassword:changePassword? Yup.string().required('kindly enter the new password').matches(/^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/,
    'Password must contain at least 8 characters, one uppercase letter, one number, and one special character'
    ):Yup.object(),
  confirmNewPassword:changePassword? Yup.string().required('kindly enter the confirm new password ').oneOf([Yup.ref('newPassword'), null], 'Passwords must match').matches( /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/,
      'Password must contain at least 8 characters, one uppercase letter, one number, and one special character'
    ):Yup.object(),
})
}