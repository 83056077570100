import { SUCCESS_MESSAGE, EDIT_SUCCESS_MESSAGE, DOCUMENT_SUCCESS_MESSAGE, DELETE_SUCCESS_MESSAGE, PASSWORD_SUCCESS_MESSAGE, EMAIL_UPDATE_SUCCESS_MESSAGE } from "./action";
const initialState = {
    showSuccessMessage: false,
    showEditSuccessMessage: false,
    showDocumentSuccessMessage: false,
    showDeleteSuccessMessage: false,
    showPasswordSuccessMessage: false,
    showEmailUpdateSuccessMessage: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCCESS_MESSAGE:
            return {
                ...state,
                showSuccessMessage: action.payload,
            };
        case EDIT_SUCCESS_MESSAGE:
            return {
                ...state,
                showEditSuccessMessage: action.payload
            }
        case DOCUMENT_SUCCESS_MESSAGE:
            return {
                ...state,
                showDocumentSuccessMessage: action.payload
            }
        case DELETE_SUCCESS_MESSAGE:
            return {
                ...state,
                showDeleteSuccessMessage: action.payload
            }
        case PASSWORD_SUCCESS_MESSAGE:
            return {
                ...state,
                showPasswordSuccessMessage: action.payload
            }
        case EMAIL_UPDATE_SUCCESS_MESSAGE:
            return {
                ...state,
                showEmailUpdateSuccessMessage: action.payload
            }
        default:
            return state;
    }
};

export default reducer;