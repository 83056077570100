import { Col } from "reactstrap";
import { LabelText } from "../Utils/Input";
import InputField from "../Utils/InputField";

export default function FormLine2({ handleChange, handleBlur, values }) {
  return (
    <>
      <Col xxl={3} md={6}>
        <LabelText
          htmlFor={"City"}
          className={"text-danger"}
          displayText={"City"}
        />
        <InputField
          name={"City"}
          placeholder={"Enter City"}
          type={"text"}
          className={"form-control"}
          id={"City"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.City || ""}
        />
      </Col>
      <Col xxl={3} md={6}>
        <LabelText htmlFor={"state"} displayText={"State"} />
        <InputField
          name={"state"}
          placeholder={"Enter State"}
          type={"text"}
          className={"form-control"}
          id={"state"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.state || ""}
        />
      </Col>
      <Col xxl={3} md={6}>
        <LabelText htmlFor={"zipCode"} displayText={"ZipCode"} />
        <InputField
          name={"zipCode"}
          placeholder={"Enter Zip Code"}
          type={"text"}
          className={"form-control"}
          id={"zipCode"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.zipCode || ""}
        />
      </Col>
      <Col xxl={3} md={6}>
        <LabelText htmlFor={"Country"} displayText={"Country"} />
        <InputField
          name={"Country"}
          placeholder={"Enter Country"}
          type={"text"}
          className={"form-control"}
          id={"Country"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.Country || ""}
        />
      </Col>
    </>
  );
}
