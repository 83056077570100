import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { OPERATOR_ALLLISTING_API } from "../constants/constants";
import { fetchOperatorListFailure, fetchOperatorListSuccess } from "./reducer";

export const fetchAllOperatorList = (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${OPERATOR_ALLLISTING_API}`,'GET')
        setLoading(false)
        dispatch(fetchOperatorListSuccess(response.reverse()))
    }
    catch (error) {
        setLoading(false)
        dispatch(fetchOperatorListFailure(error.message))
    }
}