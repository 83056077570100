import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    createresources: [],
    loading: false,
    error: null,
}

const createresourcesSlice = createSlice({
    name: "createresources",
    initialState,
    reducers: {
        fetchCreateResourceSuccess: (state, action) => {
            state.loading = false,
                state.createresources = action.payload;
        },
        fetchCreateResourceFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchCreateResourceSuccess,
    fetchCreateResourceFailure,
} = createresourcesSlice.actions;

export default createresourcesSlice.reducer;