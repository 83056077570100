import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    addOffer : [],
    loading : false,
    error : null,
};

const addOfferSlice = createSlice({
    name:"addOffer",
    initialState,
    reducers: {
        fetchAddOfferSuccess: (state,action) => {
            state.loading = false;
            state.addOffer = action.payload
        },
        fetchAddOfferFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchAddOfferFailure,
    fetchAddOfferSuccess
} = addOfferSlice.actions

export default addOfferSlice.reducer