import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    partnerList : [],
    loading : false,
    error : null,
};

const partnerListSlice = createSlice({
    name:"partnerList",
    initialState,
    reducers: {
        fetchPartnerListSuccess: (state,action) => {
            state.loading = false;
            state.partnerList = action.payload;
        },
        fetchPartnerListFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchPartnerListSuccess,
    fetchPartnerListFailure
} = partnerListSlice.actions

export default partnerListSlice.reducer