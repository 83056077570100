import { fetchGetPaymentFailure, fetchGetPaymentSuccess } from "./reducer"
import { PAYMENT_API } from "../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor"

export const getPayment= (customerId, setLoading) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${PAYMENT_API}/${customerId}`, 'GET').then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchGetPaymentSuccess(response))
        }
        if (response.error) {
            setLoading(false)
            dispatch(fetchGetPaymentFailure(response.error.message))
        }
        
    }).catch((error) => { 
        setLoading(false);
        toast.error("An error occurred. Please try again later.");
    })
}