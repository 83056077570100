import moment from "moment"

export const orderData = (order, orderList, taxIncludedValues,namesArray,createdBy) => {
    order.forEach((value, index) => {
        orderList.push({
            id: value.id,
            customer:namesArray[index],
            createdBy:createdBy[index],          
            createdDate: moment(value.creationDate).format("DD MMM, YYYY"),
            state: value.state,
            amount: taxIncludedValues[index] 
        });
    });
    return orderList;
};
