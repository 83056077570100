import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    createUser: [],
    loading: false,
    error: null,
}

const createUserSlice = createSlice({
    name: "createUser",
    initialState,
    reducers: {
        fetchCreateUserSucess: (state, action) => {
            state.loading = false
            state.createUser = action.payload;
        },
        fetchCreateUserFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchCreateUserSucess,
    fetchCreateUserFailure,
} = createUserSlice.actions;

export default createUserSlice.reducer;