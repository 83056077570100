import { download } from "export-to-csv";
import { csvConfig } from "../../Utils/ExportToCsv";

export const handleExportDataForPolicy = (value) => {
    if (value.length > 0) {
        const csvHeader = [
            "Policy Name", "id", "allOffers", "offers", "allPartners", "partners", 
            "commissionType", "commissionValue", "chargeType", 
            "Priority", "createdBy", "createdAt", "operatorId"
        ];

        const csvRows = value?.map(row => {
            const commissions = row.commissions[0] || {}; 
            return [
                row.name,
                row.id,
                row.allOffers,
                row.offers.join('|'),
                row.allPartners,
                row.partners.join('|'), 
                commissions.commissionType || '',
                commissions.commissionValue || '',
                commissions.chargeType || '',
                row.precedence,
                row.createdBy,
                row.createdAt,
                row.operatorId
            ].join(csvConfig.fieldSeparator);
        });

        const csvContent = [csvHeader.join(csvConfig.fieldSeparator), ...csvRows].join('\n');
        download(csvConfig)(csvContent);
    } else {
        console.error('No data to export.');
    }
};
