import { LIST_DOCUMENTS_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchDocumentListSuccess, fetchDocumentListFailure, resetDocumentListState } from "./reducer";

export const fetchDocumentsForCustomer = (setLoading, customerId) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${LIST_DOCUMENTS_API}/${customerId}`, 'GET').then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchDocumentListSuccess(response.reverse()))
        }
        if (response.error) {
            setLoading(false)
            dispatch(fetchDocumentListFailure(response.error.message))
            dispatch(resetDocumentListState())
        }
    }).catch((error) => { dispatch(resetDocumentListState()) })
}
