import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    documentUpload : [],
    loading : false,
    error : null,
};

const fetchDocumentUploadSlice = createSlice({
    name:"documentUpload",
    initialState,
    reducers: {
        fetchDocumentUploadSuccess: (state,action) => {
            state.loading = false;
            state.documentUpload = action.payload
        },
        fetchDocumentUploadFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchDocumentUploadFailure,
    fetchDocumentUploadSuccess
} = fetchDocumentUploadSlice.actions

export default fetchDocumentUploadSlice.reducer