import { createSlice } from "@reduxjs/toolkit";
import { Navigate } from "react-router-dom";

const initialState = {
    placeOrder: [],
    loading: false,
    error: null,
}

const placeOrderSlice = createSlice({
    name: "placeOrder",
    initialState,
    reducers: {
        fetchPlaceOrderSuccess: (state, action) => {
            state.loading = false; 
            
            state.placeOrder = action.payload;
        },
        fetchPlaceOrderFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchPlaceOrderSuccess, fetchPlaceOrderFailure } = placeOrderSlice.actions;

export default placeOrderSlice.reducer;
