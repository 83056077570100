import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomer } from '../../../slices/customer/fetchcustomer/thunk';
import { getAllService } from '../../../slices/services/thunk';
import { fetchAllOffers } from '../../../slices/offer/thunk';
import { getAllResources } from '../../../slices/resources/thunk';

export const useOfferServiceData = (customerId, setLoading) => {
    const dispatch = useDispatch();
    const [offerResponse, setOfferResponse] = useState([]);
    const [serviceResponse, setServiceResponse] = useState([]);
    const [customerData, setCustomerData] = useState(null);
    const[resourceData,setResourceData]=useState("");

    useEffect(() => {
        dispatch(fetchAllOffers(setLoading));
        dispatch(getAllService(setLoading));
        dispatch(fetchCustomer(setLoading, customerId));
        dispatch(getAllResources(setLoading));
    }, [dispatch, customerId, setLoading]);

    const offer = useSelector((state) => state.Offer.offer);
    const serviceData = useSelector((state) => state.Services.services);
    const customerInfo = useSelector((state) => state.FetchCustomer.fetchCustomer.customerInfo);
    const resource = useSelector((state) => state.Resources.resources);
    useEffect(() => {
        const sampleOfferResponse = offer;
        const sampleServiceResponse = serviceData;
        setOfferResponse(sampleOfferResponse);
        setServiceResponse(sampleServiceResponse);
        setCustomerData(customerInfo);
        setResourceData(resource)
    }, [offer, serviceData, customerInfo,resource]);

    return { offerResponse, serviceResponse, customerData,resourceData };
};
