import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';

const MapWithSearch = ({ setFieldValue, values }) => {
  const libraries = ['places'];
  const mapContainerStyle = {
    height: '100vh',
    width: '100%',
  };
  const defaultCenter = {
    lat: parseFloat(values.coordinates.latitude),
    lng: parseFloat(values.coordinates.longitude),
  };

  const [position, setPosition] = useState(defaultCenter);
  const [center, setCenter] = useState(defaultCenter);
  const [searchBoxValue, setSearchBoxValue] = useState('');
  const [apiLoaded, setApiLoaded] = useState(false);

  const searchBoxRef = useRef(null);

  const getAddressFromLatLng = (lat, lng) => {
    if (window.google && window.google.maps) {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = { lat, lng };

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            setSearchBoxValue(results[0].formatted_address);
          }
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
      });
    }
  };

  useEffect(() => {
    if (apiLoaded) {
      getAddressFromLatLng(defaultCenter.lat, defaultCenter.lng);
    }
  }, [apiLoaded]);

  const onMapClick = useCallback((event) => {
    const newPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setPosition(newPosition);
    setCenter(newPosition);
    setFieldValue("coordinates.latitude", newPosition.lat);
    setFieldValue("coordinates.longitude", newPosition.lng);
    getAddressFromLatLng(newPosition.lat, newPosition.lng);
  }, [setFieldValue]);

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length === 0) return;

    const place = places[0];
    const location = place.geometry.location;
    const newPosition = {
      lat: location.lat(),
      lng: location.lng(),
    };
    setPosition(newPosition);
    setCenter(newPosition);
    setFieldValue("coordinates.latitude", newPosition.lat);
    setFieldValue("coordinates.longitude", newPosition.lng);
    setSearchBoxValue(place.formatted_address);
  };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyC0hRm7CxBGSbLL-z91YVRVGhXiCfF_JoU"
      libraries={libraries}
      onLoad={() => setApiLoaded(true)}
    >
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={13}
        onClick={onMapClick}
      >
        {position && (
          <Marker
            position={position}
            title={`Latitude: ${position.lat}, Longitude: ${position.lng}`}
          />
        )}
        <StandaloneSearchBox
          onLoad={(ref) => (searchBoxRef.current = ref)}
          onPlacesChanged={onPlacesChanged}
        >
          <input
            type="text"
            value={searchBoxValue}
            onChange={(e) => setSearchBoxValue(e.target.value)}
            placeholder="Search location"
            style={{
              boxSizing: 'border-box',
              border: '1px solid transparent',
              width: '240px',
              height: '32px',
              marginTop: '10px',
              padding: '0 12px',
              borderRadius: '3px',
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
              fontSize: '14px',
              outline: 'none',
              textOverflow: 'ellipses',
              position: 'absolute',
              left: '50%',
              marginLeft: '-120px',
            }}
          />
        </StandaloneSearchBox>
      </GoogleMap>
    </LoadScript>
  );
};

export default MapWithSearch;
