import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = ({ offerNames, offerCounts }) => {
  const sanitizeData = (data, defaultValue) => {
    return data.map(item => item ?? defaultValue);
  };

  const sanitizedOfferNames = sanitizeData(offerNames || [], 'Unknown');
  const sanitizedOfferCounts = sanitizeData(offerCounts || [], 0);

  const options = {
    colors: ['#0078A3'],
    chart: {
      id: 'basic-bar'
    },
    xaxis: {
      categories: sanitizedOfferNames,
      title: {
        text: 'Subscriptions',
        style: {
          colors: ['#000'],
          fontSize: '12px',
          fontWeight: 'normal',
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Plans',
        style: {
          color: '#000',
          fontSize: '12px',
          fontWeight: 'normal',
          fontFamily: 'Helvetica, Arial, sans-serif'
        }
      }
    },
    grid: {
      show: false,
    },
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
  };

  const series = [
    {
      name: 'Monthly Sales',
      data: sanitizedOfferCounts
    }
  ];

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={options}
            series={series}
            type="bar"
            height="400"
          />
        </div>
      </div>
    </div>
  );
}

export default BarChart;
