import { FieldArray } from "formik";
import { CardHeader, Button, CardBody, Row, Col, Label } from "reactstrap";
import SelectField from "../../Utils/SelectField";
import InputField from "../../Utils/InputField";
import { Link } from "react-router-dom";
import { removeRowsFromGrantsAndCharges } from "./constants/checkEmptyFields";
import { grantsAndChargesHandle, handleGrantsAndChargesResourceValueAndUsageFields, handleInfiniteForGrantsAndCharges } from "./constants/GrantsAndRecurringFunctions";
import { GrantsValidityEnd, SubscriptionEndsOnOptions, SubscriptionStartsOnOptions, TimePeriodGrantOptions, TimePeriodOptions, ValidityStartsOnOptions } from "./constants/initialValues";

export default function GrantsOneTime({
    touched, isEdit,
    errors, loading, setInfinite,
    values, infinite, floor, ceiling, resourceData, displayCeilingAndFloorForOptions,
    handleChange, displayInfiniteForOptions, setCeiling, setFloor,
    infiniteOptions, grantResourceOptions,
    setTouched, setFieldTouched, setFieldValue,
    isEmptyorInvalidFieldForGrantsAndCharges,isSubscribed
}) {
    return (
        <FieldArray name="grants">
            {({ push, remove }) => (
                <>
                    <CardHeader className="d-flex justify-content-center align-items-center">
                        <h4 className="card-title mb-0 flex-grow-1 me-2"> Grants</h4>
                         {!isSubscribed && <Button color="secondary" className="btn-label" onClick={() => {
                            const lastIndex = values.grants.length - 1;
                            if (lastIndex === -1 || !isEmptyorInvalidFieldForGrantsAndCharges(lastIndex, values.grants)) {
                                push({ resourceType: '', resourceValue: "0", ceiling: '', floor: '', validityStartsOn: '', validityEndsOn: '', prorate: false, type: "1", recurringFrequency: { value: '0', label: "false" } });
                                setTouched({
                                    ...touched,
                                    grants: {
                                        ...touched.grants,
                                        [lastIndex]: {
                                            resourceType: false,
                                        },
                                    },
                                });
                            }
                        }} > <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Grant </Button>}
                    </CardHeader>
                    <CardBody>
                        <div className="live-preview">
                            {values.grants.length === 0 ? (
                                <div className="text-center">No Grants</div>
                            ) : (
                                values.grants.map((val, index) => (
                                    <div key={index} className="border border-opacity-50 rounded mb-3">
                                        <Row className="mb-3 px-3 pt-3">
                                            <Col xs={12} className="d-flex justify-content-between align-items-center">
                                                <h3 className="card-title mb-0">Grant {index + 1}</h3>
                                                {!isEdit && (
                                                    <Link to="#" className="link-danger" onClick={() => {
                                                        remove(index);
                                                        removeRowsFromGrantsAndCharges(setInfinite, index, setCeiling, setFloor);
                                                    }}>
                                                        <i data-testid="delete" className="ri-delete-bin-5-line"></i>
                                                    </Link>
                                                )}
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row className="mb-3 p-3">
                                            <Col md={4} lg={4}>
                                                <Label for={`grants[${index}].resourceType`}>Resource Type<span className="text-danger"> *</span></Label>
                                                <SelectField
                                                    isLoading={loading}
                                                    className={(
                                                        touched.grants &&
                                                        touched.grants[index] &&
                                                        !values.grants[index].resourceType
                                                    ) || false ? 'error-input' : ''}
                                                    name={`grants[${index}].resourceType`}
isDisabled={isSubscribed}
                                                    value={values.grants[index].resourceType}
                                                    handleChange={grantsAndChargesHandle(`grants[${index}].resourceType`, values.grants[index], displayInfiniteForOptions, displayCeilingAndFloorForOptions, setFieldValue, setFieldTouched, index, resourceData, setInfinite, setCeiling, setFloor)}
                                                    options={grantResourceOptions}
                                                />
                                                {errors.grants &&
                                                    errors.grants[index] &&
                                                    touched.grants &&
                                                    touched.grants[index] &&
                                                    touched.grants[index].resourceType ? (
                                                    <p className="text-danger">
                                                        {errors.grants[index].resourceType}
                                                    </p>
                                                ) : null}
                                            </Col>
                                            <Col md={4} lg={4}>
                                                <Label for={`grants[${index}].resourceValue`}>Resource Value</Label>
                                                <InputField
                                                    name={`grants[${index}].resourceValue`}
                                                    className={'form-control'}
                                                    placeholder="Enter Amount"
                                                    type="number"
                                                    handleChange={handleGrantsAndChargesResourceValueAndUsageFields(handleChange)}
                                                    value={values.grants[index].resourceValue}
                                                    invalid={
                                                        errors.grants &&
                                                        errors.grants[index] &&
                                                        touched.grants &&
                                                        touched.grants[index] &&
                                                        touched.grants[index].resourceValue
                                                        && errors.grants[index].resourceValue ? true : false
                                                    }
                                                />
                                            </Col>
                                           
                                            <Col md={4} lg={4} >
                                                <Label>Type of Resource</Label>
                                                <InputField
                                                    className={` ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                                                    value={"Non-Currency"}
                                                    disabled
                                                />
                                            </Col>
                                            <Col md={4} lg={4} className="mt-3">
                                                <Label for={`grants[${index}].ceiling`}>Ceiling</Label>
                                                {ceiling[index] || ceiling[index] === 0 ? (
                                                    <InputField
                                                        className={` ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                                                        value={values.grants[index].ceiling}
                                                        disabled
                                                    />
                                                ) : null}
                                            </Col>
                                            <Col md={4} lg={4} className="mt-3">
                                                <Label for={`grants[${index}].floor`}>Floor</Label>
                                                {floor[index] || floor[index] === 0 ? (
                                                    <InputField
                                                        className={` ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                                                        value={values.grants[index].floor}
                                                        disabled
                                                    />
                                                ) : null}
                                            </Col>
                                            <Col md={4} lg={4} className="mt-3">
                                                <Label for={`grants[${index}].infinite`}>Infinite</Label>
                                                {infinite[index] && (
                                                    <SelectField
                                                        name={`grants[${index}].infinite`}
                                                        value={values.grants[index].infinite}
isDisabled={isSubscribed}
                                                        handleChange={handleInfiniteForGrantsAndCharges(`grants[${index}].infinite`, values.grants[index], setFieldValue)}
                                                        options={infiniteOptions}
                                                    />
                                                )}
                                            </Col>
                                            <Col md={4} lg={4} className="mt-3">
                                                <Label for={`grants[${index}].validityStartsOn`}>Validity Starts<span className="text-danger"> *</span></Label>
                                                <SelectField
                                                    isLoading={loading}
                                                    className={(
                                                        touched.grants &&
                                                        touched.grants[index] &&
                                                        !values.grants[index].validityStartsOn
                                                    ) || false ? 'error-input' : ''}
                                                    name={`grants[${index}].validityStartsOn`}
isDisabled={isSubscribed}
                                                    value={values.grants[index].validityStartsOn}
                                                    handleChange={(value) => {
                                                        setFieldValue(`grants[${index}].validityStartsOn`, value)
                                                    }}
                                                    options={ValidityStartsOnOptions}
                                                />
                                                {errors.grants &&
                                                    errors.grants[index] &&
                                                    touched.grants &&
                                                    touched.grants[index] &&
                                                    touched.grants[index].validityStartsOn ? (
                                                    <p className="text-danger">
                                                        {errors.grants[index].validityStartsOn}
                                                    </p>
                                                ) : null}
                                            </Col>
                                            <Col md={4} lg={4} className="mt-3">
                                                <Label for={`grants[${index}].validityEndsOn`}>Validity Ends<span className="text-danger"> *</span></Label>
                                                <SelectField
                                                    isLoading={loading}
                                                    className={(
                                                        touched.grants &&
                                                        touched.grants[index] &&
                                                        !values.grants[index].validityEndsOn
                                                    ) || false ? 'error-input' : ''}
                                                    name={`grants[${index}].validityEndsOn`}
isDisabled={isSubscribed}
                                                    value={values.grants[index].validityEndsOn}
                                                    handleChange={(value) => {
                                                        setFieldValue(`grants[${index}].validityEndsOn`, value)
                                                    }}
                                                    options={GrantsValidityEnd}
                                                />
                                                {errors.grants &&
                                                    errors.grants[index] &&
                                                    touched.grants &&
                                                    touched.grants[index] &&
                                                    touched.grants[index].validityEndsOn ? (
                                                    <p className="text-danger">
                                                        {errors.grants[index].validityEndsOn}
                                                    </p>
                                                ) : null}
                                            </Col>
                                            <Col md={4} lg={4}>
                                                {values.grants[index]?.validityEndsOn?.label === "Relative" && (
                                                    <Row className="mt-3">
                                                        <Col xxl={6} md={6} lg={6}>
                                                            <Label>Time Period</Label>
                                                            <InputField
                                                                name={`grants[${index}].grantValidityEndsOnTimeperiod`}
disabled={isSubscribed}
                                                                type={'number'}
                                                                className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`}
                                                                id={"grantValidityEndsOnTimeperiod"}
                                                                handleChange={handleChange}
                                                                value={values.grants[index].grantValidityEndsOnTimeperiod}
                                                            />
                                                        </Col>
                                                        <Col xxl={6} md={6} data-testid="select-component" className="mt-4">
                                                            <SelectField
                                                                name={`grants[${index}].grantValidityEndsOn`}
isDisabled={isSubscribed}
                                                                value={values.grants[index].grantValidityEndsOn || ""}
                                                                handleChange={(val) => {
                                                                    values.grants[index].grantValidityEndsOn = val
                                                                    setFieldValue(`grants[${index}].grantValidityEndsOn`, val);
                                                                }}
                                                                options={TimePeriodGrantOptions}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                ))
                            )}
                        </div>
                    </CardBody>
                </>
            )}
        </FieldArray>
    );
}