import { fetchCancelSubscriptionFailure, fetchCancelSubscriptionSuccess } from "./reducer";
import { toast } from "react-toastify"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { UNSUBSCRIBE_OFFER_API } from "../../constants/constants";
import { fetchCustomerSummary } from "../simulate/thunk";
import { fetchCustomer } from "../fetchcustomer/thunk";

export const cancelSubscription = (jsonObj, setLoading) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${UNSUBSCRIBE_OFFER_API}`, 'POST', jsonObj).then((response) => {
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchCancelSubscriptionFailure(response.error.message))
        }
        if (!response.error) {
            dispatch(fetchCancelSubscriptionSuccess(response))
            dispatch(fetchCustomer(setLoading,response.customerId))
            setLoading(false)
            toast.success(
                `Subscription unsubscribed successfully!`,
            );
            dispatch(fetchCustomerSummary(response?.customerId,setLoading,"View"))
        }
    }).catch((errorResp) => {
    })
}