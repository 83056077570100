import React from 'react';
import { FieldArray } from 'formik';
import { Card, CardBody, CardHeader, Table, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import InputField from '../../Utils/InputField';

const PricingModelTable = ({
    values,
    errors,
    isEdit,
    touched,
    setFieldTouched,
    setFieldValue,
    setTouched,
    handleChange, togglePricingModel,
    index, setShowPricingModel,
    onClose
}) => {
    const pricingModelNew = values.usageRatePlan[index]?.pricingModelNew || [];
    const canAddRow = () => {
        return pricingModelNew.every(row =>
            row.startingUnit !== '' && row.endingUnit !== '' && row.pricePerUnit !== ''
        );
    };
    return (
        <FieldArray name={`usageRatePlan[${index}].pricingModelNew`}>
            {({ push, remove }) => (
                <Card>
                    <CardBody>
                        <div className="live-preview">
                            <div className="table-responsive">
                                <div className="">
                                    <Table className="table-borderless align-middle table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Starting Unit</th>
                                                <th scope="col">Ending Unit</th>
                                                <th scope="col">Price Per Unit</th>
                                                <th>
                                                    <div className="d-flex align-items-center justify-content-end ">
                                                        <Button
                                                            color="secondary"
                                                            className="btn-label me-2"
                                                            onClick={() => {
                                                                if (canAddRow()) {
                                                                    push({ startingUnit: '', endingUnit: '', pricePerUnit: '' });
                                                                }
                                                            }}
                                                        >
                                                            <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>Add
                                                        </Button>
                                                        <i
                                                            className="ri-close-fill fs-20 clickable-icon"
                                                            onClick={() => togglePricingModel(index)}
                                                        ></i>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pricingModelNew.map((val, idx) => (
                                                <tr key={idx}>
                                                    <td>
                                                        <InputField
                                                            name={`usageRatePlan[${index}].pricingModelNew[${idx}].startingUnit`}
                                                            className={`form-control ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                                                            placeholder="Enter Starting Unit"
                                                            type="number"
                                                            disabled={isEdit}
                                                            handleChange={(e) => {
                                                                handleChange(e);
                                                                setFieldValue(`usageRatePlan[${index}].pricingModelNew[${idx}].startingUnit`, e.target.value);
                                                            }}
                                                            value={pricingModelNew[idx].startingUnit}
                                                            invalid={
                                                                errors?.usageRatePlan?.[index]?.pricingModelNew?.[idx]?.startingUnit &&
                                                                touched?.usageRatePlan?.[index]?.pricingModelNew?.[idx]?.startingUnit &&
                                                                !val.startingUnit
                                                            }
                                                        />
                                                        {errors?.usageRatePlan?.[index]?.pricingModelNew?.[idx]?.startingUnit &&
                                                            touched?.usageRatePlan?.[index]?.pricingModelNew?.[idx]?.startingUnit ? (
                                                            <p className="text-danger">
                                                                {errors.usageRatePlan[index].pricingModelNew[idx].startingUnit}
                                                            </p>
                                                        ) : null}
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            name={`usageRatePlan[${index}].pricingModelNew[${idx}].endingUnit`}
                                                            className={`form-control ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                                                            placeholder="Enter Ending Unit"
                                                            type="number"
                                                            disabled={isEdit}
                                                            handleChange={(e) => {
                                                                handleChange(e);
                                                                setFieldValue(`usageRatePlan[${index}].pricingModelNew[${idx}].endingUnit`, e.target.value);
                                                            }}
                                                            value={pricingModelNew[idx].endingUnit}
                                                            invalid={
                                                                errors?.usageRatePlan?.[index]?.pricingModelNew?.[idx]?.endingUnit &&
                                                                touched?.usageRatePlan?.[index]?.pricingModelNew?.[idx]?.endingUnit &&
                                                                !val.endingUnit
                                                            }
                                                        />
                                                        {errors?.usageRatePlan?.[index]?.pricingModelNew?.[idx]?.endingUnit &&
                                                            touched?.usageRatePlan?.[index]?.pricingModelNew?.[idx]?.endingUnit ? (
                                                            <p className="text-danger">
                                                                {errors.usageRatePlan[index].pricingModelNew[idx].endingUnit}
                                                            </p>
                                                        ) : null}
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            name={`usageRatePlan[${index}].pricingModelNew[${idx}].pricePerUnit`}
                                                            className={`form-control ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                                                            placeholder="Enter Price Per Unit"
                                                            type="number"
                                                            disabled={isEdit}
                                                            handleChange={(e) => {
                                                                handleChange(e);
                                                                setFieldValue(`usageRatePlan[${index}].pricingModelNew[${idx}].pricePerUnit`, e.target.value);
                                                            }}
                                                            value={pricingModelNew[idx].pricePerUnit}
                                                            invalid={
                                                                errors?.usageRatePlan?.[index]?.pricingModelNew?.[idx]?.pricePerUnit &&
                                                                touched?.usageRatePlan?.[index]?.pricingModelNew?.[idx]?.pricePerUnit &&
                                                                !val.pricePerUnit
                                                            }
                                                        />
                                                        {errors?.usageRatePlan?.[index]?.pricingModelNew?.[idx]?.pricePerUnit &&
                                                            touched?.usageRatePlan?.[index]?.pricingModelNew?.[idx]?.pricePerUnit ? (
                                                            <p className="text-danger">
                                                                {errors.usageRatePlan[index].pricingModelNew[idx].pricePerUnit}
                                                            </p>
                                                        ) : null}
                                                    </td>
                                                    {!isEdit && (
                                                        <td>
                                                            <div className="hstack gap-3 fs-15">
                                                                <Link to="#" className="link-danger" onClick={() => remove(idx)}>
                                                                    <i data-testid="delete" className="ri-delete-bin-5-line"></i>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )}
        </FieldArray>
    );
};

export default PricingModelTable;