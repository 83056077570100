import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editUom: [],
    loading: false,
    error: null,
}

const editUomSlice = createSlice({
    name: "editUom",
    initialState,
    reducers: {
        fetcheditUomSuccess: (state, action) => {
            state.loading = false,
            state.editUom = action.payload;
        },
        fetcheditUomFailure: (state, action) => {
            state.loading = false,
            state.error = action.payload;
        },
    },
})

export const {
    fetcheditUomSuccess,
    fetcheditUomFailure,
} = editUomSlice.actions;

export default editUomSlice.reducer;