import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    countries : [],
    loading : false,
    error : null,
};

const countriesSlice = createSlice({
    name:"countries",
    initialState,
    reducers: {
        fetchCountriesSuccess: (state,action) => {
            state.loading = false;
            state.countries = action.payload
        },
        fetchCountriesFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchCountriesFailure,
    fetchCountriesSuccess
} = countriesSlice.actions

export default countriesSlice.reducer