export const countryOptionss = (countryData) => {
    const eventOptions = countryData?.map((item) => {
        return {
            label: item,
            value: item,
        };
    }) || [];

    return eventOptions;
};

export const OperatorOptionsFields = [
    { value: 'telcoAdmin', label: 'Telco' },
    { value: "operatorAdmin", label: 'EV' },
    { value: "genericAdmin", label: 'Generic' },
    { value: "ISPOperatorAdmin", label: 'ISP' }
]

export const OperatorStatusOptions = [
    { value: 'validated', label: 'Active' },
    { value: 'initialized', label: 'Inactive' },
    { value: 'closed', label: 'Cancel' }
]