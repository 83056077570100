import { fetchReservationFailure, fetchReservationStart, fetchReservationSuccess } from "./reducer"
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { RESERVATION_LISTING_API } from "../constants/constants";

export const getAllReservation = () => async (dispatch) => {
    dispatch(fetchReservationStart());
    try {
        const graphqlQuery = {
            query: "query eventsPaginate($first:Int, $offset:Int, $filter: ReservationFilter,$sortCriteria: SortCriteria,) {\n    reservationsPaginate(first: $first, offset: $offset, filter: $filter, sortCriteria: $sortCriteria ) {\n        ReservationId\n        CustomerId\n        StationId\n        StartTime\n        EndTime\n   EvseId\n  ConnectorType\n  \n }\n}",
            variables: { "first": 1000, "offset": 1, "filter": {} }
        };
        const response = await determineBaseUrlAndPerformApiCall(`${RESERVATION_LISTING_API}`, 'POST', graphqlQuery)
        dispatch(fetchReservationSuccess(response.data?.reservationsPaginate.reverse()));
    } catch (error) {
        dispatch(fetchReservationFailure(error.message));
    }
};

