import React from 'react';
import { FieldArray } from 'formik';
import { Row, Col, Card, CardBody, CardHeader, Button, Table } from 'reactstrap';
import InputField from '../Utils/InputField';
import { handleChangeForAttributeValue } from "../Customers/constants/HandleChangeFunctions";
import { Link } from 'react-router-dom';
import { isEmptyOrInvalidField } from '../Customers/constants/constantFunctions';

const AttributeListForm = ({ values, handleChange, errors, touched, setTouched }) => {
    return (
        <Row>
            <Col lg={12}>
                <FieldArray name="attributeList">
                    {({ push, remove }) => (
                        <Card >
                            <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">Attributes</h4>
                                <Button
                                    color="secondary"
                                    className="btn-label"
                                    onClick={() => {
                                        const lastIndex = values?.attributeList?.length - 1;
                                        if (lastIndex === undefined || lastIndex < 0 || !values.attributeList || !isEmptyOrInvalidField(lastIndex, values)) {
                                            push({ name: '', value: '' });
                                            setTouched((prevTouched) => ({
                                                ...prevTouched,
                                                attributeList: {
                                                    ...(prevTouched.attributeList || {}),
                                                    [lastIndex]: {
                                                        name: false,
                                                        value: false,
                                                    },
                                                },
                                            }));
                                        }
                                    }}
                                >
                                    <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Attributes </Button>
                            </CardHeader>
                            <CardBody>
                                <Col lg={7}>
                                    <div className="live-preview">
                                        <div className="table-responsive">
                                            <div className="scrollable-view h-100 ">
                                                <Table className="table-borderless align-middle  mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col ">Name</th>
                                                            <th scope="col">Value</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {values?.attributeList?.length === 0 ? <tr className="default-height"><td colSpan={4} className="text-center">No Attributes</td></tr> : values.attributeList?.map((val, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <Col >
                                                                            <InputField
                                                                                name={`attributeList[${index}].name`}
                                                                                className={(
                                                                                    touched.attributeList &&
                                                                                    touched.attributeList[index] &&
                                                                                    !values.attributeList[index].name
                                                                                ) || false ? 'is-invalid' : ''}
                                                                                placeholder="Enter your Value"
                                                                                type="text"
                                                                                handleChange={handleChangeForAttributeValue(handleChange)}
                                                                                value={values.attributeList[index].name || ""}
                                                                                invalid={
                                                                                    errors.attributeList &&
                                                                                        errors.attributeList[index] &&
                                                                                        touched.attributeList &&
                                                                                        touched.attributeList[index] &&
                                                                                        touched.attributeList[index].name ? true : false
                                                                                }
                                                                            />
                                                                            {errors.attributeList &&
                                                                                errors.attributeList[index] &&
                                                                                touched.attributeList &&
                                                                                touched.attributeList[index] &&
                                                                                touched.attributeList[index].name ? (
                                                                                <p className="text-danger">
                                                                                    {errors.attributeList[index].name}
                                                                                </p>
                                                                            ) : null}
                                                                        </Col>
                                                                    </td>
                                                                    <td>
                                                                        <Col >
                                                                            <InputField
                                                                                name={`attributeList[${index}].value`}
                                                                                className={(
                                                                                    touched.attributeList &&
                                                                                    touched.attributeList[index] &&
                                                                                    !values.attributeList[index].value
                                                                                ) || false ? 'is-invalid' : ''}
                                                                                placeholder="Enter your Value"
                                                                                type="text"
                                                                                handleChange={handleChangeForAttributeValue(handleChange)}
                                                                                value={values.attributeList[index].value || ""}
                                                                                invalid={
                                                                                    errors.attributeList &&
                                                                                        errors.attributeList[index] &&
                                                                                        touched.attributeList &&
                                                                                        touched.attributeList[index] &&
                                                                                        touched.attributeList[index].value ? true : false
                                                                                }
                                                                            />
                                                                            {errors.attributeList &&
                                                                                errors.attributeList[index] &&
                                                                                touched.attributeList &&
                                                                                touched.attributeList[index] &&
                                                                                touched.attributeList[index].value ? (
                                                                                <p className="text-danger">
                                                                                    {errors.attributeList[index].value}
                                                                                </p>
                                                                            ) : null}
                                                                        </Col>
                                                                    </td>
                                                                    <td>
                                                                        <div className="hstack gap-3 fs-15">
                                                                            <Link to="#" className="link-danger" onClick={() => remove(index)}>
                                                                                <i data-testid="delete" className="ri-delete-bin-5-line"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </CardBody>
                        </Card>
                    )}
                </FieldArray>
            </Col>
        </Row>
    );
};

export default AttributeListForm;
