import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchDocumentViewSuccess, fetchDocumentViewFailure, resetDocumentViewState } from "./reducer";
import { DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API } from "../../constants/constants";

export const viewDoc = (setLoading, customerId, documentName, partnerOrCustomer,setProfileData) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API}/${customerId}/${documentName}`, 'GET').then((response) => {
        if (!response.error) {
            setLoading(false)
            setProfileData(true)
            dispatch(fetchDocumentViewSuccess(response))
            if (partnerOrCustomer !== "Partner" && partnerOrCustomer !== "Operator" && partnerOrCustomer !=="User" && partnerOrCustomer !== "System User" && partnerOrCustomer !== "Customer") {
                const base64Data = response.filePath;
                fetch(base64Data)
                    .then(res => res.blob())
                    .then((blob_res) => {
                        let fileUrl = URL.createObjectURL(blob_res);
                        window.open(fileUrl)
                    })
            }
        }
        if (response.error) {
            setLoading(false)
            setProfileData(false)
            dispatch(fetchDocumentViewFailure(response.error.message))
            dispatch(resetDocumentViewState());
        }
        setLoading(false)
    }).catch((error) => {
        // setProfileData(false)
        dispatch(resetDocumentViewState())
     })
}