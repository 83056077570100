import { USER_MANAGEMENT_API } from "../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { fetchGetAllUserFailure, fetchGetAllUserSuccess } from "./reducer";

export const getAllUser = (setLoading,operatorId,location) => async (dispatch) => {
    try {
        const roleName = localStorage.getItem("roleName")
        const isInView = location?.pathname?.includes('view-operator')
        setLoading(true)
        let apiUrl = USER_MANAGEMENT_API;
        if (roleName === "systemUser" || roleName === "providerOperationUser") {
            if (isInView) {
                apiUrl += `/${operatorId}`;
            }
        } else {
            apiUrl += `/${operatorId}`;
        }
        apiUrl += "?excludeCurrentUser=true"

        const response = await determineBaseUrlAndPerformApiCall(apiUrl, 'GET');
        setLoading(false)
        dispatch(fetchGetAllUserSuccess(response.reverse()))
    }
    catch (error) {
        setLoading(false)
        dispatch(fetchGetAllUserFailure(error.message))
    }
}