import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    csvFileUpload: [],
    loading: false,
    error: null,
};

const fetchCsvFileUploadSlice = createSlice({
    name: "csvFileUpload",
    initialState,
    reducers: {
        fetchCsvFileUploadSuccess: (state, action) => {
            state.loading = false;
            state.csvFileUpload = action.payload
        },
        fetchCsvFileUploadFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchCsvFileUploadFailure,
    fetchCsvFileUploadSuccess
} = fetchCsvFileUploadSlice.actions

export default fetchCsvFileUploadSlice.reducer