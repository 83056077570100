import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    cancelStatus : [],
    loading : false,
    error : null,
};

const cancelStatusSlice = createSlice({
    name:"cancelStatus",
    initialState,
    reducers: {
        fetchCancelStatusSuccess: (state,action) => {
            state.loading = false;
            state.cancelSubscription = action.payload
        },
        fetchCancelStatusFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchCancelStatusFailure,
    fetchCancelStatusSuccess
} = cancelStatusSlice.actions

export default cancelStatusSlice.reducer