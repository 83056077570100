import { CUSTOMER_EVENTS_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchCustomerEventsSuccess, fetchCustomerEventsFailure } from "./reducer";

export const fetchCustomerEvents = (id) => async (dispatch) => {
    try {
        await determineBaseUrlAndPerformApiCall(`${CUSTOMER_EVENTS_API}?customerId=${id.original.CustomerId}`, 'GET').then((response) => {
            if (!response.error) {
                dispatch(fetchCustomerEventsSuccess(response));
            }
            if (response.error) {
                dispatch(fetchCustomerEventsFailure(response.error.message))
            }
        }).catch((error) => { });
    } catch (error) { dispatch(fetchCustomerEventsFailure(error.message)) }
}